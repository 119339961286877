export const fakeCampaignData = {
    "tenantid": 9,
    "campaign": {
        "id": 239,
        "name": "4",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":559,\"source_type\":\"departments\",\"source_ids\":[84,12],\"launched\":false,\"scheduled\":true}",
        "startdate": 1669335061,
        "duedate": 1700784661,
        "expirydate": 1700784661,
        "archived": false,
        "program_info": {
            "id": 398,
            "name": "unlaunched test",
            "archived": 0,
            "description": "",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 7,
                    "shortname": "password-protection",
                    "fullname": "Password Protection"
                },
                {
                    "id": 8,
                    "shortname": "mobile-security",
                    "fullname": "Mobile Security"
                },
                {
                    "id": 9,
                    "shortname": "multi-factor-authentication",
                    "fullname": "Multi-Factor Authentication"
                }
            ],
            "certCount": 0
        },
        "users_num": 0,
        "rules": [
            {
                "id": 4824,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4825,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4826,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4827,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4828,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4829,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4830,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4831,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4832,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4833,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4834,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 560,
                "name": "5-1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 561,
                "name": "5-2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 563,
                "name": "5-3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 564,
                "name": "unlaunched test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    }
};

export const fakeSystemData = {
    "departments": {
        "12": {
            "id": "12",
            "tenantid": "9",
            "name": "Training",
            "parentid": "6",
            "pathlevel": "2",
            "path": "/6/12",
            "idnumber": null,
            "description": null,
            "descriptionformat": "0",
            "sortorder": "0",
            "archived": "0",
            "shared": "0",
            "timecreated": "1655736609",
            "timemodified": "1655736609",
            "timearchived": null,
            "count(mtoj.id)": "4"
        },
        "84": {
            "id": "84",
            "tenantid": "9",
            "name": "Department1",
            "parentid": "6",
            "pathlevel": "2",
            "path": "/6/84",
            "idnumber": "Department1",
            "description": null,
            "descriptionformat": "0",
            "sortorder": "1",
            "archived": "0",
            "shared": "0",
            "timecreated": "1659623225",
            "timemodified": "1659623225",
            "timearchived": null,
            "count(mtoj.id)": "1"
        }
    },
    "positions": {
        "13": {
            "id": "13",
            "tenantid": "9",
            "name": "Tester",
            "parentid": "111",
            "pathlevel": "3",
            "path": "/6/111/13",
            "idnumber": null,
            "description": null,
            "descriptionformat": "0",
            "sortorder": "0",
            "archived": "0",
            "departmentmanager": "0",
            "globalmanager": "0",
            "departmentpermissions": "0",
            "globalpermissions": "0",
            "shared": "0",
            "timecreated": "1655736609",
            "timemodified": "1661280333",
            "timearchived": null,
            "count(mtoj.id)": "1"
        },
        "86": {
            "id": "86",
            "tenantid": "9",
            "name": "Devops",
            "parentid": "111",
            "pathlevel": "3",
            "path": "/6/111/86",
            "idnumber": null,
            "description": null,
            "descriptionformat": "0",
            "sortorder": "1",
            "archived": "0",
            "departmentmanager": "0",
            "globalmanager": "0",
            "departmentpermissions": "0",
            "globalpermissions": "0",
            "shared": "0",
            "timecreated": "1658952262",
            "timemodified": "1661280333",
            "timearchived": null,
            "count(mtoj.id)": "1"
        },
        "109": {
            "id": "109",
            "tenantid": "9",
            "name": "dev",
            "parentid": "111",
            "pathlevel": "3",
            "path": "/6/111/109",
            "idnumber": null,
            "description": null,
            "descriptionformat": "0",
            "sortorder": "3",
            "archived": "0",
            "departmentmanager": "0",
            "globalmanager": "0",
            "departmentpermissions": "0",
            "globalpermissions": "0",
            "shared": "0",
            "timecreated": "1659041901",
            "timemodified": "1661280337",
            "timearchived": null,
            "count(mtoj.id)": "1"
        },
        "111": {
            "id": "111",
            "tenantid": "9",
            "name": "Manager",
            "parentid": "6",
            "pathlevel": "2",
            "path": "/6/111",
            "idnumber": "",
            "description": "",
            "descriptionformat": "0",
            "sortorder": "112",
            "archived": "0",
            "departmentmanager": "1",
            "globalmanager": "1",
            "departmentpermissions": "7",
            "globalpermissions": "7",
            "shared": "0",
            "timecreated": "1659123164",
            "timemodified": "1661283195",
            "timearchived": null,
            "count(mtoj.id)": "1"
        },
        "129": {
            "id": "129",
            "tenantid": "9",
            "name": "Title1",
            "parentid": "111",
            "pathlevel": "3",
            "path": "/6/111/129",
            "idnumber": "Title1",
            "description": null,
            "descriptionformat": "0",
            "sortorder": "2",
            "archived": "0",
            "departmentmanager": "0",
            "globalmanager": "0",
            "departmentpermissions": "0",
            "globalpermissions": "0",
            "shared": "0",
            "timecreated": "1659623225",
            "timemodified": "1661280337",
            "timearchived": null,
            "count(mtoj.id)": "1"
        }
    },
    "modules": {
        "6": {
            "id": "6",
            "category": "2",
            "sortorder": "20044",
            "fullname": "Introduction to Information Security",
            "shortname": "introduction-to-information-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1629500400",
            "enddate": "0",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629470816",
            "timemodified": "1643827209",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "7": {
            "id": "7",
            "category": "2",
            "sortorder": "20058",
            "fullname": "Password Protection",
            "shortname": "password-protection",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1629500400",
            "enddate": "0",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629470886",
            "timemodified": "1643827531",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "8": {
            "id": "8",
            "category": "2",
            "sortorder": "20056",
            "fullname": "Mobile Security",
            "shortname": "mobile-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1629500400",
            "enddate": "1661036400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629487671",
            "timemodified": "1643827368",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "9": {
            "id": "9",
            "category": "2",
            "sortorder": "20057",
            "fullname": "Multi-Factor Authentication",
            "shortname": "multi-factor-authentication",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1629500400",
            "enddate": "1661036400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629488922",
            "timemodified": "1643827433",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "10": {
            "id": "10",
            "category": "2",
            "sortorder": "20062",
            "fullname": "Social Engineering",
            "shortname": "social-engineering",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "5",
            "startdate": "1629500400",
            "enddate": "1661036400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629488952",
            "timemodified": "1643827933",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "11": {
            "id": "11",
            "category": "2",
            "sortorder": "20033",
            "fullname": "Access Control",
            "shortname": "access-control",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1629500400",
            "enddate": "1661036400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629488964",
            "timemodified": "1643820976",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "12": {
            "id": "12",
            "category": "2",
            "sortorder": "20039",
            "fullname": "Data Security",
            "shortname": "data-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "5",
            "startdate": "1629500400",
            "enddate": "1661036400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629488976",
            "timemodified": "1643826824",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "13": {
            "id": "13",
            "category": "2",
            "sortorder": "20041",
            "fullname": "Email Security",
            "shortname": "email-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1629500400",
            "enddate": "1661036400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629489003",
            "timemodified": "1643826911",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "14": {
            "id": "14",
            "category": "2",
            "sortorder": "20042",
            "fullname": "Insider Threat",
            "shortname": "insider-threat",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1629932400",
            "enddate": "1661468400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629900916",
            "timemodified": "1643826990",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "15": {
            "id": "15",
            "category": "2",
            "sortorder": "20065",
            "fullname": "Working Remotely",
            "shortname": "working-remotely",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1629932400",
            "enddate": "1661468400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629901136",
            "timemodified": "1643828250",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "16": {
            "id": "16",
            "category": "2",
            "sortorder": "20064",
            "fullname": "Web Conference Security",
            "shortname": "web-conference-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "5",
            "startdate": "1629932400",
            "enddate": "1661468400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629904218",
            "timemodified": "1643828202",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "17": {
            "id": "17",
            "category": "2",
            "sortorder": "20045",
            "fullname": "Malware",
            "shortname": "malware",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1629932400",
            "enddate": "1661468400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629905863",
            "timemodified": "1643827299",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "18": {
            "id": "18",
            "category": "2",
            "sortorder": "20059",
            "fullname": "Phishing",
            "shortname": "phishing",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1629932400",
            "enddate": "1661468400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629906584",
            "timemodified": "1643827651",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "19": {
            "id": "19",
            "category": "2",
            "sortorder": "20038",
            "fullname": "Data Privacy",
            "shortname": "data-privacy",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1630364400",
            "enddate": "1661900400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1630341439",
            "timemodified": "1643826686",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "20": {
            "id": "20",
            "category": "2",
            "sortorder": "20036",
            "fullname": "Clean Desk",
            "shortname": "clean-desk",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1630364400",
            "enddate": "1661900400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1630341449",
            "timemodified": "1643826609",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "21": {
            "id": "21",
            "category": "2",
            "sortorder": "20034",
            "fullname": "Bad Actors",
            "shortname": "bad-actors",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1630364400",
            "enddate": "1661900400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1630341466",
            "timemodified": "1643821548",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "22": {
            "id": "22",
            "category": "2",
            "sortorder": "20035",
            "fullname": "Business Email Compromise",
            "shortname": "business-email-compromise",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1643846400",
            "enddate": "0",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629488964",
            "timemodified": "1644954395",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "23": {
            "id": "23",
            "category": "2",
            "sortorder": "20043",
            "fullname": "Intellectual Property",
            "shortname": "intellectual-property",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1643846400",
            "enddate": "0",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629488964",
            "timemodified": "1643820976",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "24": {
            "id": "24",
            "category": "2",
            "sortorder": "20060",
            "fullname": "Secure Travel",
            "shortname": "secure-travel",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1643846400",
            "enddate": "0",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629488964",
            "timemodified": "1643820976",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "25": {
            "id": "25",
            "category": "2",
            "sortorder": "20063",
            "fullname": "Social Media",
            "shortname": "social-media",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1643846400",
            "enddate": "0",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1629488964",
            "timemodified": "1643820976",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "26": {
            "id": "26",
            "category": "2",
            "sortorder": "20061",
            "fullname": "Security Awareness for Managers",
            "shortname": "security-awareness-for-managers",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1644987600",
            "enddate": "0",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1644954389",
            "timemodified": "1644954389",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "27": {
            "id": "27",
            "category": "2",
            "sortorder": "20037",
            "fullname": "Cyber Security Frameworks for Managers",
            "shortname": "cyber-security-frameworks-for-managers",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1644987600",
            "enddate": "0",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "0",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1644955045",
            "timemodified": "1644955045",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "28": {
            "id": "28",
            "category": "2",
            "sortorder": "20040",
            "fullname": "Deploying and Managing the Fortinet Security Awareness and Training Service",
            "shortname": "deploying-and-managing-the-fortinet-security-awareness-and-training-service",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1644987600",
            "enddate": "0",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1644955583",
            "timemodified": "1660761431",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "29": {
            "id": "29",
            "category": "2",
            "sortorder": "20046",
            "fullname": "Micro Module - Business Email Compromise",
            "shortname": "micro-module-business-email-compromise",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1649476800",
            "enddate": "1681012800",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1649425933",
            "timemodified": "1649425933",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "30": {
            "id": "30",
            "category": "2",
            "sortorder": "20047",
            "fullname": "Micro Module - Clean Desk",
            "shortname": "micro-module-clean-desk",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "5",
            "startdate": "1649476800",
            "enddate": "1681012800",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1649426253",
            "timemodified": "1649426253",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "31": {
            "id": "31",
            "category": "2",
            "sortorder": "20048",
            "fullname": "Micro Module - Data Privacy",
            "shortname": "micro-module-data-privacy",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1649476800",
            "enddate": "1681012800",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1649426412",
            "timemodified": "1649426412",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "32": {
            "id": "32",
            "category": "2",
            "sortorder": "20049",
            "fullname": "Micro Module - Data Security",
            "shortname": "micro-module-data-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1649476800",
            "enddate": "1681012800",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1649426546",
            "timemodified": "1649426546",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "33": {
            "id": "33",
            "category": "2",
            "sortorder": "20050",
            "fullname": "Micro Module - Email Security",
            "shortname": "micro-module-email-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1649476800",
            "enddate": "1681012800",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1649426674",
            "timemodified": "1649426674",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "34": {
            "id": "34",
            "category": "2",
            "sortorder": "20051",
            "fullname": "Micro Module - Insider Threat",
            "shortname": "micro-module-insider-threat",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1649476800",
            "enddate": "1681012800",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1649426892",
            "timemodified": "1649426892",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "35": {
            "id": "35",
            "category": "2",
            "sortorder": "20052",
            "fullname": "Micro Module - Malware",
            "shortname": "micro-module-malware",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1649476800",
            "enddate": "1681012800",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1649426991",
            "timemodified": "1649426991",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "36": {
            "id": "36",
            "category": "2",
            "sortorder": "20053",
            "fullname": "Micro Module - Password Protection",
            "shortname": "micro-module-password-protection",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1649476800",
            "enddate": "1681012800",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1649427075",
            "timemodified": "1649427075",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "37": {
            "id": "37",
            "category": "2",
            "sortorder": "20054",
            "fullname": "Micro Module - Phishing",
            "shortname": "micro-module-phishing",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1649476800",
            "enddate": "1681012800",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1649427156",
            "timemodified": "1649427156",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "38": {
            "id": "38",
            "category": "2",
            "sortorder": "20055",
            "fullname": "Micro Module - Social Engineering",
            "shortname": "micro-module-social-engineering",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1649476800",
            "enddate": "1681012800",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1649427255",
            "timemodified": "1649427255",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "40": {
            "id": "40",
            "category": "2",
            "sortorder": "20032",
            "fullname": "Education - Access Control",
            "shortname": "education-access-control",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663607402",
            "timemodified": "1663607740",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "41": {
            "id": "41",
            "category": "2",
            "sortorder": "20031",
            "fullname": "Education - Bad Actors",
            "shortname": "education-bad-actors",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663607828",
            "timemodified": "1663607828",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "42": {
            "id": "42",
            "category": "2",
            "sortorder": "20030",
            "fullname": "Education - Business Email Compromise",
            "shortname": "education-business-email-compromise",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663607907",
            "timemodified": "1663607907",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "43": {
            "id": "43",
            "category": "2",
            "sortorder": "20029",
            "fullname": "Education - Clean Desk",
            "shortname": "education-clean-desk",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663607951",
            "timemodified": "1663607951",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "44": {
            "id": "44",
            "category": "2",
            "sortorder": "20028",
            "fullname": "Education - Data Privacy",
            "shortname": "education-data-privacy",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608006",
            "timemodified": "1663608006",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "45": {
            "id": "45",
            "category": "2",
            "sortorder": "20027",
            "fullname": "Education - Data Security",
            "shortname": "education-data-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608062",
            "timemodified": "1663608062",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "46": {
            "id": "46",
            "category": "2",
            "sortorder": "20026",
            "fullname": "Education - Email Security",
            "shortname": "education-email-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608412",
            "timemodified": "1663608412",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "47": {
            "id": "47",
            "category": "2",
            "sortorder": "20025",
            "fullname": "Education - Insider Threat",
            "shortname": "education-insider-threat",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608454",
            "timemodified": "1663608454",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "48": {
            "id": "48",
            "category": "2",
            "sortorder": "20024",
            "fullname": "Education - Intellectual Property",
            "shortname": "education-intellectual-property",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608496",
            "timemodified": "1663608496",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "49": {
            "id": "49",
            "category": "2",
            "sortorder": "20023",
            "fullname": "Education - Introduction to Information Security",
            "shortname": "education-introduction-to-information-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608544",
            "timemodified": "1663608544",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "50": {
            "id": "50",
            "category": "2",
            "sortorder": "20022",
            "fullname": "Education - Malware",
            "shortname": "education-malware",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608607",
            "timemodified": "1663608607",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "51": {
            "id": "51",
            "category": "2",
            "sortorder": "20021",
            "fullname": "Education - Mobile Security",
            "shortname": "education-mobile-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608644",
            "timemodified": "1663608644",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "52": {
            "id": "52",
            "category": "2",
            "sortorder": "20020",
            "fullname": "Education - Multi-Factor Authentication",
            "shortname": "education-multi-factor-authentication",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608685",
            "timemodified": "1663608685",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "53": {
            "id": "53",
            "category": "2",
            "sortorder": "20019",
            "fullname": "Education - Password Protection",
            "shortname": "education-password-protection",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608730",
            "timemodified": "1663608730",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "54": {
            "id": "54",
            "category": "2",
            "sortorder": "20018",
            "fullname": "Education - Phishing",
            "shortname": "education-phishing",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608761",
            "timemodified": "1663608761",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "55": {
            "id": "55",
            "category": "2",
            "sortorder": "20017",
            "fullname": "Education - Secure Travel",
            "shortname": "education-secure-travel",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608799",
            "timemodified": "1663608799",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "56": {
            "id": "56",
            "category": "2",
            "sortorder": "20016",
            "fullname": "Education - Social Engineering",
            "shortname": "education-social-engineering",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608842",
            "timemodified": "1663608842",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "57": {
            "id": "57",
            "category": "2",
            "sortorder": "20015",
            "fullname": "Education - Social Media",
            "shortname": "education-social-media",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608877",
            "timemodified": "1663608877",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "58": {
            "id": "58",
            "category": "2",
            "sortorder": "20014",
            "fullname": "Education - Web Conference Security",
            "shortname": "education-web-conference-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608912",
            "timemodified": "1663608912",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "59": {
            "id": "59",
            "category": "2",
            "sortorder": "20013",
            "fullname": "Education - Working Remotely",
            "shortname": "education-working-remotely",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663608941",
            "timemodified": "1663608941",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "60": {
            "id": "60",
            "category": "2",
            "sortorder": "20012",
            "fullname": "Education Micro Module - Business Email Compromise",
            "shortname": "education-micro-module-business-email-compromise",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663609481",
            "timemodified": "1663609481",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "61": {
            "id": "61",
            "category": "2",
            "sortorder": "20011",
            "fullname": "Education Micro Module - Clean Desk",
            "shortname": "education-micro-module-clean-desk",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663609536",
            "timemodified": "1663609536",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "62": {
            "id": "62",
            "category": "2",
            "sortorder": "20010",
            "fullname": "Education Micro Module - Data Privacy",
            "shortname": "education-micro-module-data-privacy",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663609581",
            "timemodified": "1663609581",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "63": {
            "id": "63",
            "category": "2",
            "sortorder": "20009",
            "fullname": "Education Micro Module - Data Security",
            "shortname": "education-micro-module-data-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663609628",
            "timemodified": "1663609628",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "64": {
            "id": "64",
            "category": "2",
            "sortorder": "20008",
            "fullname": "Education Micro Module - Email Security",
            "shortname": "education-micro-module-email-security",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663609670",
            "timemodified": "1663609670",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "65": {
            "id": "65",
            "category": "2",
            "sortorder": "20007",
            "fullname": "Education Micro Module - Insider Threat",
            "shortname": "education-micro-module-insider-threat",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663609714",
            "timemodified": "1663609714",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "66": {
            "id": "66",
            "category": "2",
            "sortorder": "20006",
            "fullname": "Education Micro Module - Malware",
            "shortname": "education-micro-module-malware",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663609761",
            "timemodified": "1663609761",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "67": {
            "id": "67",
            "category": "2",
            "sortorder": "20005",
            "fullname": "Education Micro Module - Password Protection",
            "shortname": "education-micro-module-password-protection",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663609806",
            "timemodified": "1663609806",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "68": {
            "id": "68",
            "category": "2",
            "sortorder": "20004",
            "fullname": "Education Micro Module - Phishing",
            "shortname": "education-micro-module-phishing",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663609857",
            "timemodified": "1663609857",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "69": {
            "id": "69",
            "category": "2",
            "sortorder": "20003",
            "fullname": "Education Micro Module - Social Engineering",
            "shortname": "education-micro-module-social-engineering",
            "idnumber": "",
            "summary": "",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "0",
            "startdate": "1663646400",
            "enddate": "1695182400",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "1",
            "groupmodeforce": "1",
            "defaultgroupingid": "0",
            "lang": "",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663609905",
            "timemodified": "1663609905",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "70": {
            "id": "70",
            "category": "2",
            "sortorder": "20002",
            "fullname": "Quick Completion Course for Testing",
            "shortname": "quick_completion_course",
            "idnumber": "",
            "summary": "<p dir=\"ltr\" style=\"text-align: left;\">This is a quick completion course for testing.<br></p>",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "5",
            "startdate": "1663819200",
            "enddate": "1695355200",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "0",
            "groupmodeforce": "0",
            "defaultgroupingid": "0",
            "lang": "en",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663788354",
            "timemodified": "1663788354",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": null,
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        },
        "71": {
            "id": "71",
            "category": "2",
            "sortorder": "20001",
            "fullname": "Quick Completion Course for QA Testing",
            "shortname": "quick_completion_course_qa",
            "idnumber": "",
            "summary": "<p dir=\"ltr\" style=\"text-align: left;\">This is a quick completion course for testing.<br></p>",
            "summaryformat": "1",
            "format": "topics",
            "showgrades": "1",
            "newsitems": "5",
            "startdate": "1663819200",
            "enddate": "0",
            "relativedatesmode": "0",
            "marker": "0",
            "maxbytes": "0",
            "legacyfiles": "0",
            "showreports": "0",
            "visible": "1",
            "visibleold": "1",
            "downloadcontent": null,
            "groupmode": "0",
            "groupmodeforce": "0",
            "defaultgroupingid": "0",
            "lang": "en",
            "calendartype": "",
            "theme": "",
            "timecreated": "1663788354",
            "timemodified": "1663788354",
            "requested": "0",
            "enablecompletion": "1",
            "completionnotify": "0",
            "cacherev": "1668572447",
            "originalcourseid": "70",
            "showactivitydates": "1",
            "showcompletionconditions": "1"
        }
    },
    "premium": true,
    "timezone": "America/New_York",
    "env": "local"
};

export const fakeCampaignsData = [
    {
        "id": 5,
        "name": "Test Campaign for ML Quizzes",
        "config": "{\"source_type\":\"departments\",\"source_ids\":[12],\"launched\":true}",
        "startdate": 1655697600,
        "duedate": 1659326399,
        "expirydate": 1659326399,
        "archived": false,
        "program_info": {
            "id": 8,
            "name": "ML Quiz",
            "archived": 0,
            "description": "",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 6,
                    "shortname": "introduction-to-information-security",
                    "fullname": "Introduction to Information Security"
                },
                {
                    "id": 7,
                    "shortname": "password-protection",
                    "fullname": "Password Protection"
                },
                {
                    "id": 8,
                    "shortname": "mobile-security",
                    "fullname": "Mobile Security"
                },
                {
                    "id": 9,
                    "shortname": "multi-factor-authentication",
                    "fullname": "Multi-Factor Authentication"
                },
                {
                    "id": 10,
                    "shortname": "social-engineering",
                    "fullname": "Social Engineering"
                },
                {
                    "id": 11,
                    "shortname": "access-control",
                    "fullname": "Access Control"
                },
                {
                    "id": 12,
                    "shortname": "data-security",
                    "fullname": "Data Security"
                },
                {
                    "id": 13,
                    "shortname": "email-security",
                    "fullname": "Email Security"
                },
                {
                    "id": 14,
                    "shortname": "insider-threat",
                    "fullname": "Insider Threat"
                },
                {
                    "id": 15,
                    "shortname": "working-remotely",
                    "fullname": "Working Remotely"
                },
                {
                    "id": 16,
                    "shortname": "web-conference-security",
                    "fullname": "Web Conference Security"
                },
                {
                    "id": 17,
                    "shortname": "malware",
                    "fullname": "Malware"
                },
                {
                    "id": 18,
                    "shortname": "phishing",
                    "fullname": "Phishing"
                },
                {
                    "id": 19,
                    "shortname": "data-privacy",
                    "fullname": "Data Privacy"
                },
                {
                    "id": 20,
                    "shortname": "clean-desk",
                    "fullname": "Clean Desk"
                },
                {
                    "id": 21,
                    "shortname": "bad-actors",
                    "fullname": "Bad Actors"
                },
                {
                    "id": 22,
                    "shortname": "business-email-compromise",
                    "fullname": "Business Email Compromise"
                },
                {
                    "id": 23,
                    "shortname": "intellectual-property",
                    "fullname": "Intellectual Property"
                },
                {
                    "id": 24,
                    "shortname": "secure-travel",
                    "fullname": "Secure Travel"
                },
                {
                    "id": 25,
                    "shortname": "social-media",
                    "fullname": "Social Media"
                },
                {
                    "id": 26,
                    "shortname": "security-awareness-for-managers",
                    "fullname": "Security Awareness for Managers"
                },
                {
                    "id": 27,
                    "shortname": "cyber-security-frameworks-for-managers",
                    "fullname": "Cyber Security Frameworks for Managers"
                },
                {
                    "id": 28,
                    "shortname": "deploying-and-managing-the-fortinet-security-awareness-and-training-service",
                    "fullname": "Deploying and Managing the Fortinet Security Awareness and Training Service"
                }
            ],
            "certCount": 1
        },
        "users_num": 1,
        "rules": [
            {
                "id": 102,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 103,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 104,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 105,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 106,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 107,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 108,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 109,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 110,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 42,
        "name": "NEW ML CAMP",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":191,\"source_type\":\"positions\",\"source_ids\":[13],\"launched\":true}",
        "startdate": 1658808000,
        "duedate": 1662004799,
        "expirydate": 1664596799,
        "archived": false,
        "program_info": {
            "id": 80,
            "name": "Single Module Template",
            "archived": 0,
            "description": "Template Test MSG",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 20,
                    "shortname": "clean-desk",
                    "fullname": "Clean Desk"
                }
            ],
            "certCount": 2
        },
        "users_num": 1,
        "rules": [
            {
                "id": 878,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": true,
                "archived": false
            },
            {
                "id": 879,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 880,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 881,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 882,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 883,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 884,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 885,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 886,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 51,
        "name": "NEW CAMP TEST ZLAN",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":211,\"source_type\":\"departments\",\"source_ids\":[12],\"launched\":true}",
        "startdate": 1659067200,
        "duedate": 1690862399,
        "expirydate": 1696132799,
        "archived": false,
        "program_info": {
            "id": 101,
            "name": "TEST NEW TEMPLATE",
            "archived": 0,
            "description": "template msg",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 6,
                    "shortname": "introduction-to-information-security",
                    "fullname": "Introduction to Information Security"
                }
            ],
            "certCount": 2
        },
        "users_num": 3,
        "rules": [
            {
                "id": 1089,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": true,
                "archived": false
            },
            {
                "id": 1090,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 1091,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 1092,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 1093,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 1094,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 1095,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 1096,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 1097,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 101,
        "name": "New version Module Test",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":331,\"source_type\":\"positions\",\"source_ids\":[13],\"launched\":true}",
        "startdate": 1661140800,
        "duedate": 1725163199,
        "expirydate": 1756699199,
        "archived": false,
        "program_info": {
            "id": 198,
            "name": "New module version",
            "archived": 0,
            "description": "ttt",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 6,
                    "shortname": "introduction-to-information-security",
                    "fullname": "Introduction to Information Security"
                },
                {
                    "id": 7,
                    "shortname": "password-protection",
                    "fullname": "Password Protection"
                },
                {
                    "id": 8,
                    "shortname": "mobile-security",
                    "fullname": "Mobile Security"
                },
                {
                    "id": 9,
                    "shortname": "multi-factor-authentication",
                    "fullname": "Multi-Factor Authentication"
                },
                {
                    "id": 10,
                    "shortname": "social-engineering",
                    "fullname": "Social Engineering"
                },
                {
                    "id": 11,
                    "shortname": "access-control",
                    "fullname": "Access Control"
                },
                {
                    "id": 12,
                    "shortname": "data-security",
                    "fullname": "Data Security"
                },
                {
                    "id": 13,
                    "shortname": "email-security",
                    "fullname": "Email Security"
                },
                {
                    "id": 14,
                    "shortname": "insider-threat",
                    "fullname": "Insider Threat"
                },
                {
                    "id": 15,
                    "shortname": "working-remotely",
                    "fullname": "Working Remotely"
                },
                {
                    "id": 16,
                    "shortname": "web-conference-security",
                    "fullname": "Web Conference Security"
                },
                {
                    "id": 17,
                    "shortname": "malware",
                    "fullname": "Malware"
                },
                {
                    "id": 18,
                    "shortname": "phishing",
                    "fullname": "Phishing"
                },
                {
                    "id": 19,
                    "shortname": "data-privacy",
                    "fullname": "Data Privacy"
                },
                {
                    "id": 20,
                    "shortname": "clean-desk",
                    "fullname": "Clean Desk"
                },
                {
                    "id": 21,
                    "shortname": "bad-actors",
                    "fullname": "Bad Actors"
                },
                {
                    "id": 22,
                    "shortname": "business-email-compromise",
                    "fullname": "Business Email Compromise"
                },
                {
                    "id": 23,
                    "shortname": "intellectual-property",
                    "fullname": "Intellectual Property"
                },
                {
                    "id": 24,
                    "shortname": "secure-travel",
                    "fullname": "Secure Travel"
                },
                {
                    "id": 25,
                    "shortname": "social-media",
                    "fullname": "Social Media"
                },
                {
                    "id": 26,
                    "shortname": "security-awareness-for-managers",
                    "fullname": "Security Awareness for Managers"
                },
                {
                    "id": 27,
                    "shortname": "cyber-security-frameworks-for-managers",
                    "fullname": "Cyber Security Frameworks for Managers"
                },
                {
                    "id": 28,
                    "shortname": "deploying-and-managing-the-fortinet-security-awareness-and-training-service",
                    "fullname": "Deploying and Managing the Fortinet Security Awareness and Training Service"
                },
                {
                    "id": 31,
                    "shortname": "micro-module-data-privacy",
                    "fullname": "Micro Module - Data Privacy"
                },
                {
                    "id": 35,
                    "shortname": "micro-module-malware",
                    "fullname": "Micro Module - Malware"
                }
            ],
            "certCount": 1
        },
        "users_num": 2,
        "rules": [
            {
                "id": 2127,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": true,
                "archived": false
            },
            {
                "id": 2128,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2129,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2130,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2131,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2132,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2133,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2134,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2135,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 104,
        "name": "Test Campaign Remediation",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":344,\"source_type\":\"departments\",\"source_ids\":[12],\"launched\":true,\"learner_rule_active\":true,\"manager_rule_active\":true,\"rule_id\":\"2218\"}",
        "startdate": 1661227200,
        "duedate": 1669870799,
        "expirydate": 1675227599,
        "archived": false,
        "program_info": {
            "id": 207,
            "name": "Test Campaign Remediation",
            "archived": 0,
            "description": "test",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 8,
                    "shortname": "mobile-security",
                    "fullname": "Mobile Security"
                }
            ],
            "certCount": 2
        },
        "users_num": 4,
        "rules": [
            {
                "id": 2210,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": true,
                "archived": false
            },
            {
                "id": 2211,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2212,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2213,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2214,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2215,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2216,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2217,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2218,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": true,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 105,
        "name": "Test Campaign Remediation - Completion",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":345,\"source_type\":\"departments\",\"source_ids\":[12],\"launched\":true,\"learner_rule_active\":true,\"manager_rule_active\":true,\"rule_id\":\"2227\"}",
        "startdate": 1661227200,
        "duedate": 1669870799,
        "expirydate": 1688183999,
        "archived": false,
        "program_info": {
            "id": 207,
            "name": "Test Campaign Remediation",
            "archived": 0,
            "description": "test",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 8,
                    "shortname": "mobile-security",
                    "fullname": "Mobile Security"
                }
            ],
            "certCount": 2
        },
        "users_num": 4,
        "rules": [
            {
                "id": 2219,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": true,
                "archived": false
            },
            {
                "id": 2220,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2221,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": true,
                "archived": false
            },
            {
                "id": 2222,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2223,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2224,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2225,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2226,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 2227,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": true,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 153,
        "name": "Micro Modules Only",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":420,\"source_type\":\"departments\",\"source_ids\":[12],\"launched\":true}",
        "startdate": 1663300800,
        "duedate": 1693713599,
        "expirydate": 1693886399,
        "archived": false,
        "program_info": {
            "id": 270,
            "name": "Micro Modules",
            "archived": 0,
            "description": "",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 29,
                    "shortname": "micro-module-business-email-compromise",
                    "fullname": "Micro Module - Business Email Compromise"
                },
                {
                    "id": 30,
                    "shortname": "micro-module-clean-desk",
                    "fullname": "Micro Module - Clean Desk"
                },
                {
                    "id": 31,
                    "shortname": "micro-module-data-privacy",
                    "fullname": "Micro Module - Data Privacy"
                },
                {
                    "id": 32,
                    "shortname": "micro-module-data-security",
                    "fullname": "Micro Module - Data Security"
                },
                {
                    "id": 33,
                    "shortname": "micro-module-email-security",
                    "fullname": "Micro Module - Email Security"
                },
                {
                    "id": 34,
                    "shortname": "micro-module-insider-threat",
                    "fullname": "Micro Module - Insider Threat"
                },
                {
                    "id": 35,
                    "shortname": "micro-module-malware",
                    "fullname": "Micro Module - Malware"
                },
                {
                    "id": 36,
                    "shortname": "micro-module-password-protection",
                    "fullname": "Micro Module - Password Protection"
                },
                {
                    "id": 37,
                    "shortname": "micro-module-phishing",
                    "fullname": "Micro Module - Phishing"
                },
                {
                    "id": 38,
                    "shortname": "micro-module-social-engineering",
                    "fullname": "Micro Module - Social Engineering"
                }
            ],
            "certCount": 1
        },
        "users_num": 4,
        "rules": [
            {
                "id": 3049,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": true,
                "archived": false
            },
            {
                "id": 3050,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3051,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3052,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3053,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3054,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3055,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3056,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3057,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3058,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 156,
        "name": "Test Campaign Certificate - Completion",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":423,\"source_type\":\"positions\",\"source_ids\":[129],\"launched\":true}",
        "startdate": 1663732800,
        "duedate": 1664596799,
        "expirydate": 1667275199,
        "archived": false,
        "program_info": {
            "id": 273,
            "name": "Test Campaign Certificate - Completion",
            "archived": 0,
            "description": "",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 70,
                    "shortname": "quick_completion_course",
                    "fullname": "Quick Completion Course for Testing"
                }
            ],
            "certCount": 1
        },
        "users_num": 2,
        "rules": [
            {
                "id": 3097,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3098,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3099,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3100,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3101,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3102,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3103,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3104,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3105,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3106,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": true,
                "archived": false
            },
            {
                "id": 3107,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": true,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 157,
        "name": "Test Campaign Certificate - Completion 2",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":424,\"source_type\":\"departments\",\"source_ids\":[84],\"launched\":true}",
        "startdate": 1663732800,
        "duedate": 1664596799,
        "expirydate": 1667275199,
        "archived": false,
        "program_info": {
            "id": 274,
            "name": "Test Campaign Certificate - Completion 2",
            "archived": 0,
            "description": "",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 71,
                    "shortname": "quick_completion_course_qa",
                    "fullname": "Quick Completion Course for QA Testing"
                }
            ],
            "certCount": 1
        },
        "users_num": 1,
        "rules": [
            {
                "id": 3114,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3115,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3116,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3117,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3118,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3119,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3120,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3121,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3122,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3123,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": true,
                "archived": false
            },
            {
                "id": 3124,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": true,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 175,
        "name": "Clean Desk Education",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":463,\"source_type\":\"departments\",\"source_ids\":[12],\"launched\":true}",
        "startdate": 1664424000,
        "duedate": 1664596799,
        "expirydate": 1664596799,
        "archived": false,
        "program_info": {
            "id": 332,
            "name": "CD Edu",
            "archived": 0,
            "description": "",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 43,
                    "shortname": "education-clean-desk",
                    "fullname": "Education - Clean Desk"
                }
            ],
            "certCount": 1
        },
        "users_num": 4,
        "rules": [
            {
                "id": 3665,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3666,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3667,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3668,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3669,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3670,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3671,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3672,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3673,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3674,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3675,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 177,
        "name": "Education modules",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":465,\"source_type\":\"departments\",\"source_ids\":[12],\"launched\":true,\"learner_rule_active\":false,\"manager_rule_active\":false,\"rule_id\":\"3701\"}",
        "startdate": 1664424000,
        "duedate": 1664596799,
        "expirydate": 1664596799,
        "archived": false,
        "program_info": {
            "id": 333,
            "name": "Education",
            "archived": 0,
            "description": "Let's Check this",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 40,
                    "shortname": "education-access-control",
                    "fullname": "Education - Access Control"
                },
                {
                    "id": 41,
                    "shortname": "education-bad-actors",
                    "fullname": "Education - Bad Actors"
                },
                {
                    "id": 42,
                    "shortname": "education-business-email-compromise",
                    "fullname": "Education - Business Email Compromise"
                },
                {
                    "id": 43,
                    "shortname": "education-clean-desk",
                    "fullname": "Education - Clean Desk"
                },
                {
                    "id": 44,
                    "shortname": "education-data-privacy",
                    "fullname": "Education - Data Privacy"
                },
                {
                    "id": 45,
                    "shortname": "education-data-security",
                    "fullname": "Education - Data Security"
                },
                {
                    "id": 46,
                    "shortname": "education-email-security",
                    "fullname": "Education - Email Security"
                },
                {
                    "id": 47,
                    "shortname": "education-insider-threat",
                    "fullname": "Education - Insider Threat"
                },
                {
                    "id": 48,
                    "shortname": "education-intellectual-property",
                    "fullname": "Education - Intellectual Property"
                },
                {
                    "id": 49,
                    "shortname": "education-introduction-to-information-security",
                    "fullname": "Education - Introduction to Information Security"
                },
                {
                    "id": 50,
                    "shortname": "education-malware",
                    "fullname": "Education - Malware"
                },
                {
                    "id": 51,
                    "shortname": "education-mobile-security",
                    "fullname": "Education - Mobile Security"
                },
                {
                    "id": 52,
                    "shortname": "education-multi-factor-authentication",
                    "fullname": "Education - Multi-Factor Authentication"
                },
                {
                    "id": 53,
                    "shortname": "education-password-protection",
                    "fullname": "Education - Password Protection"
                },
                {
                    "id": 54,
                    "shortname": "education-phishing",
                    "fullname": "Education - Phishing"
                },
                {
                    "id": 55,
                    "shortname": "education-secure-travel",
                    "fullname": "Education - Secure Travel"
                },
                {
                    "id": 56,
                    "shortname": "education-social-engineering",
                    "fullname": "Education - Social Engineering"
                },
                {
                    "id": 57,
                    "shortname": "education-social-media",
                    "fullname": "Education - Social Media"
                },
                {
                    "id": 58,
                    "shortname": "education-web-conference-security",
                    "fullname": "Education - Web Conference Security"
                },
                {
                    "id": 59,
                    "shortname": "education-working-remotely",
                    "fullname": "Education - Working Remotely"
                }
            ],
            "certCount": 1
        },
        "users_num": 4,
        "rules": [
            {
                "id": 3693,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3694,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3695,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3696,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3697,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3698,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3699,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3700,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3701,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3702,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 3703,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 213,
        "name": "Resp test",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":527,\"source_type\":\"departments\",\"source_ids\":[12],\"launched\":true}",
        "startdate": 1665979200,
        "duedate": 1667275199,
        "expirydate": 1667275199,
        "archived": false,
        "program_info": {
            "id": 370,
            "name": "test resp",
            "archived": 0,
            "description": "",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 70,
                    "shortname": "quick_completion_course",
                    "fullname": "Quick Completion Course for Testing"
                }
            ],
            "certCount": 1
        },
        "users_num": 4,
        "rules": [
            {
                "id": 4314,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4315,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4316,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4317,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4318,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4319,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4320,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4321,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4322,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4323,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4324,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 214,
        "name": "phiushing test quiz",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":528,\"source_type\":\"departments\",\"source_ids\":[12],\"launched\":true,\"learner_rule_active\":false,\"manager_rule_active\":false,\"rule_id\":\"4339\"}",
        "startdate": 1665979200,
        "duedate": 1667275199,
        "expirydate": 1667275199,
        "archived": false,
        "program_info": {
            "id": 371,
            "name": "phishing test quiz",
            "archived": 0,
            "description": "",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 18,
                    "shortname": "phishing",
                    "fullname": "Phishing"
                }
            ],
            "certCount": 1
        },
        "users_num": 4,
        "rules": [
            {
                "id": 4331,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4332,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4333,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4334,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4335,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4336,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4337,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4338,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4339,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4340,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4341,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 220,
        "name": "Test",
        "config": "{}",
        "startdate": 1667275200,
        "duedate": 1669870799,
        "expirydate": 1669870799,
        "archived": true,
        "program_info": {
            "id": 80,
            "name": "Single Module Template",
            "archived": 0,
            "description": "Template Test MSG",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 20,
                    "shortname": "clean-desk",
                    "fullname": "Clean Desk"
                }
            ],
            "certCount": 2
        },
        "users_num": 0,
        "rules": [
            {
                "id": 4421,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4422,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4423,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4424,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4425,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4426,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4427,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4428,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4429,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4430,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4431,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 221,
        "name": "Test3",
        "config": "{\"learner_rule_active\":false,\"manager_rule_active\":false,\"rule_id\":\"4440\"}",
        "startdate": 1667275200,
        "duedate": 1669870799,
        "expirydate": 1669870799,
        "archived": true,
        "program_info": {
            "id": 270,
            "name": "Micro Modules",
            "archived": 0,
            "description": "",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 29,
                    "shortname": "micro-module-business-email-compromise",
                    "fullname": "Micro Module - Business Email Compromise"
                },
                {
                    "id": 30,
                    "shortname": "micro-module-clean-desk",
                    "fullname": "Micro Module - Clean Desk"
                },
                {
                    "id": 31,
                    "shortname": "micro-module-data-privacy",
                    "fullname": "Micro Module - Data Privacy"
                },
                {
                    "id": 32,
                    "shortname": "micro-module-data-security",
                    "fullname": "Micro Module - Data Security"
                },
                {
                    "id": 33,
                    "shortname": "micro-module-email-security",
                    "fullname": "Micro Module - Email Security"
                },
                {
                    "id": 34,
                    "shortname": "micro-module-insider-threat",
                    "fullname": "Micro Module - Insider Threat"
                },
                {
                    "id": 35,
                    "shortname": "micro-module-malware",
                    "fullname": "Micro Module - Malware"
                },
                {
                    "id": 36,
                    "shortname": "micro-module-password-protection",
                    "fullname": "Micro Module - Password Protection"
                },
                {
                    "id": 37,
                    "shortname": "micro-module-phishing",
                    "fullname": "Micro Module - Phishing"
                },
                {
                    "id": 38,
                    "shortname": "micro-module-social-engineering",
                    "fullname": "Micro Module - Social Engineering"
                }
            ],
            "certCount": 1
        },
        "users_num": 0,
        "rules": [
            {
                "id": 4432,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4433,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4434,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4435,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4436,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4437,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4438,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4439,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4440,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4441,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4442,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 222,
        "name": "Test",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":536}",
        "startdate": 1667275200,
        "duedate": 1669870799,
        "expirydate": 1669870799,
        "archived": false,
        "program_info": {
            "id": 273,
            "name": "Test Campaign Certificate - Completion",
            "archived": 0,
            "description": "",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 70,
                    "shortname": "quick_completion_course",
                    "fullname": "Quick Completion Course for Testing"
                }
            ],
            "certCount": 1
        },
        "users_num": 0,
        "rules": [
            {
                "id": 4443,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4444,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4445,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4446,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4447,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4448,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4449,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4450,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4451,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4452,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4453,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 223,
        "name": "QUIZ TESTING",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":537,\"source_type\":\"departments\",\"source_ids\":[12],\"launched\":true}",
        "startdate": 1667361600,
        "duedate": 1701406799,
        "expirydate": 1733029199,
        "archived": false,
        "program_info": {
            "id": 376,
            "name": "QUIZ TESTING",
            "archived": 0,
            "description": "abc",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 70,
                    "shortname": "quick_completion_course",
                    "fullname": "Quick Completion Course for Testing"
                }
            ],
            "certCount": 1
        },
        "users_num": 4,
        "rules": [
            {
                "id": 4460,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4461,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4462,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4463,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4464,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4465,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4466,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4467,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4468,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4469,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4470,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 235,
        "name": "sf",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":555}",
        "startdate": 1668488400,
        "duedate": 1668661199,
        "expirydate": 1669870799,
        "archived": false,
        "program_info": {
            "id": 393,
            "name": "test mb",
            "archived": 0,
            "description": "",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 8,
                    "shortname": "mobile-security",
                    "fullname": "Mobile Security"
                }
            ],
            "certCount": 0
        },
        "users_num": 0,
        "rules": [
            {
                "id": 4695,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4696,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4697,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4698,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4699,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4700,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4701,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4702,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4703,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4704,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4705,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 236,
        "name": "1",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":556,\"source_type\":\"positions\",\"source_ids\":[13],\"launched\":true}",
        "startdate": 1668488400,
        "duedate": 1668661199,
        "expirydate": 1668747599,
        "archived": false,
        "program_info": {
            "id": 101,
            "name": "TEST NEW TEMPLATE",
            "archived": 0,
            "description": "template msg",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 6,
                    "shortname": "introduction-to-information-security",
                    "fullname": "Introduction to Information Security"
                }
            ],
            "certCount": 2
        },
        "users_num": 1,
        "rules": [
            {
                "id": 4706,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4707,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4708,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4709,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4710,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4711,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4712,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4713,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4714,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4715,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4716,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 237,
        "name": "2",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":557,\"source_type\":\"positions\",\"source_ids\":[13,86],\"launched\":true}",
        "startdate": 1668574800,
        "duedate": 1669265999,
        "expirydate": 1669870799,
        "archived": false,
        "program_info": {
            "id": 80,
            "name": "Single Module Template",
            "archived": 0,
            "description": "Template Test MSG",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 20,
                    "shortname": "clean-desk",
                    "fullname": "Clean Desk"
                }
            ],
            "certCount": 2
        },
        "users_num": 0,
        "rules": [
            {
                "id": 4717,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4718,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4719,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4720,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4721,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4722,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4723,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4724,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4725,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4726,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4727,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 238,
        "name": "3",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":558}",
        "startdate": 1668488400,
        "duedate": 1669870799,
        "expirydate": 1669870799,
        "archived": false,
        "program_info": {
            "id": 394,
            "name": "333",
            "archived": 0,
            "description": "",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 7,
                    "shortname": "password-protection",
                    "fullname": "Password Protection"
                },
                {
                    "id": 66,
                    "shortname": "education-micro-module-malware",
                    "fullname": "Education Micro Module - Malware"
                }
            ],
            "certCount": 0
        },
        "users_num": 0,
        "rules": [
            {
                "id": 4734,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4735,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4736,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4737,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4738,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4739,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4740,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4741,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4742,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4743,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4744,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    },
    {
        "id": 239,
        "name": "4",
        "config": "{\"active\":false,\"schedule\":\"\",\"report_format\":\"pdf\",\"audience_ids\":[],\"report_id\":559,\"source_type\":\"departments\",\"source_ids\":[84,12],\"launched\":false, \"scheduled\":true}",
        "startdate": 1669335061,
        "duedate": 1700784661,
        "expirydate": 1700784661,
        "archived": false,
        "program_info": {
            "id": 395,
            "name": "four",
            "archived": 0,
            "description": "",
            "descriptionformat": "1",
            "modules": [
                {
                    "id": 7,
                    "shortname": "password-protection",
                    "fullname": "Password Protection"
                },
                {
                    "id": 9,
                    "shortname": "multi-factor-authentication",
                    "fullname": "Multi-Factor Authentication"
                },
                {
                    "id": 10,
                    "shortname": "social-engineering",
                    "fullname": "Social Engineering"
                },
                {
                    "id": 11,
                    "shortname": "access-control",
                    "fullname": "Access Control"
                }
            ],
            "certCount": 1
        },
        "users_num": 5,
        "rules": [
            {
                "id": 4751,
                "shared": 0,
                "name": "Users allocated to certification",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4752,
                "shared": 0,
                "name": "Certification overdue",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4753,
                "shared": 0,
                "name": "Certification certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4754,
                "shared": 0,
                "name": "Certification not certified",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4755,
                "shared": 0,
                "name": "Certification expired",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4756,
                "shared": 0,
                "name": "Certification suspended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4757,
                "shared": 0,
                "name": "Recertification period started",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4758,
                "shared": 0,
                "name": "Recertification grace period ends",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4759,
                "shared": 0,
                "name": "User failed a quiz in campaign 3 times",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4760,
                "shared": 0,
                "name": "Users allocated to certification Extended",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            },
            {
                "id": 4761,
                "shared": 0,
                "name": "User completed a campaign",
                "componentarea": "certification",
                "enabled": false,
                "archived": false
            }
        ],
        "reports": [
            {
                "id": 34,
                "name": "Executive Report Current Year (Canned) ",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_executive",
                "shared": false
            },
            {
                "id": 35,
                "name": "Manager Campaign Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 36,
                "name": "Manager Module Report(Canned)",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_course_completion_extend",
                "shared": false
            },
            {
                "id": 37,
                "name": "FortiPhish Report Current Year (Canned)",
                "source": "local_fortiphish\\tool_reportbuilder\\datasources\\report_campaign_users",
                "shared": false
            },
            {
                "id": 84,
                "name": "System report for rules list",
                "source": "tool_dynamicrule\\rules_list",
                "shared": false
            },
            {
                "id": 85,
                "name": "System report for report lists",
                "source": "tool_reportbuilder\\local\\systemreports\\reports_list",
                "shared": false
            },
            {
                "id": 86,
                "name": "System report for certification users",
                "source": "tool_certification\\local\\reports\\users_table",
                "shared": false
            },
            {
                "id": 87,
                "name": "Users",
                "source": "tool_tenant\\users_report",
                "shared": false
            },
            {
                "id": 151,
                "name": "System report for schedules list",
                "source": "tool_reportbuilder\\local\\systemreports\\schedules_list",
                "shared": false
            },
            {
                "id": 191,
                "name": "NEW ML CAMP[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 211,
                "name": "NEW CAMP TEST ZLAN[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 221,
                "name": "CP",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 251,
                "name": "System report for active certifications",
                "source": "tool_certification\\local\\reports\\active_table",
                "shared": false
            },
            {
                "id": 281,
                "name": "System report for active programs",
                "source": "tool_program\\local\\reports\\active_programs_report",
                "shared": false
            },
            {
                "id": 331,
                "name": "New version Module Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 343,
                "name": "Job assignments",
                "source": "tool_organisation\\jobs_list",
                "shared": false
            },
            {
                "id": 344,
                "name": "Test Campaign Remediation[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 345,
                "name": "Test Campaign Remediation - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 346,
                "name": "RETEST",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 420,
                "name": "Micro Modules Only[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 423,
                "name": "Test Campaign Certificate - Completion[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 424,
                "name": "Test Campaign Certificate - Completion 2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 463,
                "name": "Clean Desk Education[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 465,
                "name": "Education modules[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 527,
                "name": "Resp test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 528,
                "name": "phiushing test quiz[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 536,
                "name": "Test[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 537,
                "name": "QUIZ TESTING[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 552,
                "name": "test",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certification_user_allocation_extend",
                "shared": false
            },
            {
                "id": 555,
                "name": "sf[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 556,
                "name": "1[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 557,
                "name": "2[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 558,
                "name": "3[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            },
            {
                "id": 559,
                "name": "4[campaign]",
                "source": "local_fortinet_extend\\tool_reportbuilder\\datasources\\report_certifications_extend",
                "shared": false
            }
        ]
    }
];

export const fakeProgramsData = [
    {
        "id": 8,
        "name": "ML Quiz",
        "archived": 0,
        "description": "",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 6,
                "shortname": "introduction-to-information-security",
                "fullname": "Introduction to Information Security"
            },
            {
                "id": 7,
                "shortname": "password-protection",
                "fullname": "Password Protection"
            },
            {
                "id": 8,
                "shortname": "mobile-security",
                "fullname": "Mobile Security"
            },
            {
                "id": 9,
                "shortname": "multi-factor-authentication",
                "fullname": "Multi-Factor Authentication"
            },
            {
                "id": 10,
                "shortname": "social-engineering",
                "fullname": "Social Engineering"
            },
            {
                "id": 11,
                "shortname": "access-control",
                "fullname": "Access Control"
            },
            {
                "id": 12,
                "shortname": "data-security",
                "fullname": "Data Security"
            },
            {
                "id": 13,
                "shortname": "email-security",
                "fullname": "Email Security"
            },
            {
                "id": 14,
                "shortname": "insider-threat",
                "fullname": "Insider Threat"
            },
            {
                "id": 15,
                "shortname": "working-remotely",
                "fullname": "Working Remotely"
            },
            {
                "id": 16,
                "shortname": "web-conference-security",
                "fullname": "Web Conference Security"
            },
            {
                "id": 17,
                "shortname": "malware",
                "fullname": "Malware"
            },
            {
                "id": 18,
                "shortname": "phishing",
                "fullname": "Phishing"
            },
            {
                "id": 19,
                "shortname": "data-privacy",
                "fullname": "Data Privacy"
            },
            {
                "id": 20,
                "shortname": "clean-desk",
                "fullname": "Clean Desk"
            },
            {
                "id": 21,
                "shortname": "bad-actors",
                "fullname": "Bad Actors"
            },
            {
                "id": 22,
                "shortname": "business-email-compromise",
                "fullname": "Business Email Compromise"
            },
            {
                "id": 23,
                "shortname": "intellectual-property",
                "fullname": "Intellectual Property"
            },
            {
                "id": 24,
                "shortname": "secure-travel",
                "fullname": "Secure Travel"
            },
            {
                "id": 25,
                "shortname": "social-media",
                "fullname": "Social Media"
            },
            {
                "id": 26,
                "shortname": "security-awareness-for-managers",
                "fullname": "Security Awareness for Managers"
            },
            {
                "id": 27,
                "shortname": "cyber-security-frameworks-for-managers",
                "fullname": "Cyber Security Frameworks for Managers"
            },
            {
                "id": 28,
                "shortname": "deploying-and-managing-the-fortinet-security-awareness-and-training-service",
                "fullname": "Deploying and Managing the Fortinet Security Awareness and Training Service"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 1
    },
    {
        "id": 80,
        "name": "Single Module Template",
        "archived": 0,
        "description": "Template Test MSG",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 20,
                "shortname": "clean-desk",
                "fullname": "Clean Desk"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 2
    },
    {
        "id": 101,
        "name": "TEST NEW TEMPLATE",
        "archived": 0,
        "description": "template msg",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 6,
                "shortname": "introduction-to-information-security",
                "fullname": "Introduction to Information Security"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 2
    },
    {
        "id": 198,
        "name": "New module version",
        "archived": 0,
        "description": "ttt",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 6,
                "shortname": "introduction-to-information-security",
                "fullname": "Introduction to Information Security"
            },
            {
                "id": 7,
                "shortname": "password-protection",
                "fullname": "Password Protection"
            },
            {
                "id": 8,
                "shortname": "mobile-security",
                "fullname": "Mobile Security"
            },
            {
                "id": 9,
                "shortname": "multi-factor-authentication",
                "fullname": "Multi-Factor Authentication"
            },
            {
                "id": 10,
                "shortname": "social-engineering",
                "fullname": "Social Engineering"
            },
            {
                "id": 11,
                "shortname": "access-control",
                "fullname": "Access Control"
            },
            {
                "id": 12,
                "shortname": "data-security",
                "fullname": "Data Security"
            },
            {
                "id": 13,
                "shortname": "email-security",
                "fullname": "Email Security"
            },
            {
                "id": 14,
                "shortname": "insider-threat",
                "fullname": "Insider Threat"
            },
            {
                "id": 15,
                "shortname": "working-remotely",
                "fullname": "Working Remotely"
            },
            {
                "id": 16,
                "shortname": "web-conference-security",
                "fullname": "Web Conference Security"
            },
            {
                "id": 17,
                "shortname": "malware",
                "fullname": "Malware"
            },
            {
                "id": 18,
                "shortname": "phishing",
                "fullname": "Phishing"
            },
            {
                "id": 19,
                "shortname": "data-privacy",
                "fullname": "Data Privacy"
            },
            {
                "id": 20,
                "shortname": "clean-desk",
                "fullname": "Clean Desk"
            },
            {
                "id": 21,
                "shortname": "bad-actors",
                "fullname": "Bad Actors"
            },
            {
                "id": 22,
                "shortname": "business-email-compromise",
                "fullname": "Business Email Compromise"
            },
            {
                "id": 23,
                "shortname": "intellectual-property",
                "fullname": "Intellectual Property"
            },
            {
                "id": 24,
                "shortname": "secure-travel",
                "fullname": "Secure Travel"
            },
            {
                "id": 25,
                "shortname": "social-media",
                "fullname": "Social Media"
            },
            {
                "id": 26,
                "shortname": "security-awareness-for-managers",
                "fullname": "Security Awareness for Managers"
            },
            {
                "id": 27,
                "shortname": "cyber-security-frameworks-for-managers",
                "fullname": "Cyber Security Frameworks for Managers"
            },
            {
                "id": 28,
                "shortname": "deploying-and-managing-the-fortinet-security-awareness-and-training-service",
                "fullname": "Deploying and Managing the Fortinet Security Awareness and Training Service"
            },
            {
                "id": 31,
                "shortname": "micro-module-data-privacy",
                "fullname": "Micro Module - Data Privacy"
            },
            {
                "id": 35,
                "shortname": "micro-module-malware",
                "fullname": "Micro Module - Malware"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 1
    },
    {
        "id": 207,
        "name": "Test Campaign Remediation",
        "archived": 0,
        "description": "test",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 8,
                "shortname": "mobile-security",
                "fullname": "Mobile Security"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 3
    },
    {
        "id": 270,
        "name": "Micro Modules",
        "archived": 0,
        "description": "",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 29,
                "shortname": "micro-module-business-email-compromise",
                "fullname": "Micro Module - Business Email Compromise"
            },
            {
                "id": 30,
                "shortname": "micro-module-clean-desk",
                "fullname": "Micro Module - Clean Desk"
            },
            {
                "id": 31,
                "shortname": "micro-module-data-privacy",
                "fullname": "Micro Module - Data Privacy"
            },
            {
                "id": 32,
                "shortname": "micro-module-data-security",
                "fullname": "Micro Module - Data Security"
            },
            {
                "id": 33,
                "shortname": "micro-module-email-security",
                "fullname": "Micro Module - Email Security"
            },
            {
                "id": 34,
                "shortname": "micro-module-insider-threat",
                "fullname": "Micro Module - Insider Threat"
            },
            {
                "id": 35,
                "shortname": "micro-module-malware",
                "fullname": "Micro Module - Malware"
            },
            {
                "id": 36,
                "shortname": "micro-module-password-protection",
                "fullname": "Micro Module - Password Protection"
            },
            {
                "id": 37,
                "shortname": "micro-module-phishing",
                "fullname": "Micro Module - Phishing"
            },
            {
                "id": 38,
                "shortname": "micro-module-social-engineering",
                "fullname": "Micro Module - Social Engineering"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 1
    },
    {
        "id": 273,
        "name": "Test Campaign Certificate - Completion",
        "archived": 0,
        "description": "",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 70,
                "shortname": "quick_completion_course",
                "fullname": "Quick Completion Course for Testing"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 1
    },
    {
        "id": 274,
        "name": "Test Campaign Certificate - Completion 2",
        "archived": 0,
        "description": "",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 71,
                "shortname": "quick_completion_course_qa",
                "fullname": "Quick Completion Course for QA Testing"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 1
    },
    {
        "id": 332,
        "name": "CD Edu",
        "archived": 0,
        "description": "",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 43,
                "shortname": "education-clean-desk",
                "fullname": "Education - Clean Desk"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 1
    },
    {
        "id": 333,
        "name": "Education",
        "archived": 0,
        "description": "Let&#39;s Check this",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 40,
                "shortname": "education-access-control",
                "fullname": "Education - Access Control"
            },
            {
                "id": 41,
                "shortname": "education-bad-actors",
                "fullname": "Education - Bad Actors"
            },
            {
                "id": 42,
                "shortname": "education-business-email-compromise",
                "fullname": "Education - Business Email Compromise"
            },
            {
                "id": 43,
                "shortname": "education-clean-desk",
                "fullname": "Education - Clean Desk"
            },
            {
                "id": 44,
                "shortname": "education-data-privacy",
                "fullname": "Education - Data Privacy"
            },
            {
                "id": 45,
                "shortname": "education-data-security",
                "fullname": "Education - Data Security"
            },
            {
                "id": 46,
                "shortname": "education-email-security",
                "fullname": "Education - Email Security"
            },
            {
                "id": 47,
                "shortname": "education-insider-threat",
                "fullname": "Education - Insider Threat"
            },
            {
                "id": 48,
                "shortname": "education-intellectual-property",
                "fullname": "Education - Intellectual Property"
            },
            {
                "id": 49,
                "shortname": "education-introduction-to-information-security",
                "fullname": "Education - Introduction to Information Security"
            },
            {
                "id": 50,
                "shortname": "education-malware",
                "fullname": "Education - Malware"
            },
            {
                "id": 51,
                "shortname": "education-mobile-security",
                "fullname": "Education - Mobile Security"
            },
            {
                "id": 52,
                "shortname": "education-multi-factor-authentication",
                "fullname": "Education - Multi-Factor Authentication"
            },
            {
                "id": 53,
                "shortname": "education-password-protection",
                "fullname": "Education - Password Protection"
            },
            {
                "id": 54,
                "shortname": "education-phishing",
                "fullname": "Education - Phishing"
            },
            {
                "id": 55,
                "shortname": "education-secure-travel",
                "fullname": "Education - Secure Travel"
            },
            {
                "id": 56,
                "shortname": "education-social-engineering",
                "fullname": "Education - Social Engineering"
            },
            {
                "id": 57,
                "shortname": "education-social-media",
                "fullname": "Education - Social Media"
            },
            {
                "id": 58,
                "shortname": "education-web-conference-security",
                "fullname": "Education - Web Conference Security"
            },
            {
                "id": 59,
                "shortname": "education-working-remotely",
                "fullname": "Education - Working Remotely"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 1
    },
    {
        "id": 370,
        "name": "test resp",
        "archived": 0,
        "description": "",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 70,
                "shortname": "quick_completion_course",
                "fullname": "Quick Completion Course for Testing"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 1
    },
    {
        "id": 371,
        "name": "phishing test quiz",
        "archived": 0,
        "description": "",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 18,
                "shortname": "phishing",
                "fullname": "Phishing"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 1
    },
    {
        "id": 376,
        "name": "QUIZ TESTING",
        "archived": 0,
        "description": "abc",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 70,
                "shortname": "quick_completion_course",
                "fullname": "Quick Completion Course for Testing"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 1
    },
    {
        "id": 393,
        "name": "test mb",
        "archived": 0,
        "description": "",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 8,
                "shortname": "mobile-security",
                "fullname": "Mobile Security"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 0
    },
    {
        "id": 394,
        "name": "333",
        "archived": 0,
        "description": "",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 7,
                "shortname": "password-protection",
                "fullname": "Password Protection"
            },
            {
                "id": 66,
                "shortname": "education-micro-module-malware",
                "fullname": "Education Micro Module - Malware"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 0
    },
    {
        "id": 395,
        "name": "four",
        "archived": 0,
        "description": "",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 7,
                "shortname": "password-protection",
                "fullname": "Password Protection"
            },
            {
                "id": 9,
                "shortname": "multi-factor-authentication",
                "fullname": "Multi-Factor Authentication"
            },
            {
                "id": 10,
                "shortname": "social-engineering",
                "fullname": "Social Engineering"
            },
            {
                "id": 11,
                "shortname": "access-control",
                "fullname": "Access Control"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 1
    },
    {
        "id": 396,
        "name": "four-two",
        "archived": 0,
        "description": "five two",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 11,
                "shortname": "access-control",
                "fullname": "Access Control"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 2
    },
    {
        "id": 398,
        "name": "unlaunched test",
        "archived": 0,
        "description": "",
        "descriptionformat": "1",
        "modules": [
            {
                "id": 7,
                "shortname": "password-protection",
                "fullname": "Password Protection"
            },
            {
                "id": 8,
                "shortname": "mobile-security",
                "fullname": "Mobile Security"
            },
            {
                "id": 9,
                "shortname": "multi-factor-authentication",
                "fullname": "Multi-Factor Authentication"
            }
        ],
        "shared": 0,
        "presetup": false,
        "certCount": 0
    }
];