import React from "react";
import {Form, Select} from 'antd';
import {useSelector} from "react-redux";

export const FortiphishCampaignCompleted = ({conditionId=0}) => {
    const {fortiphish_campaigns_for_options: fortiphishCampaignOptions} = useSelector((state) => state?.remediation?.remediationData) || [];

    if(fortiphishCampaignOptions && !fortiphishCampaignOptions.find(el=> el.value===0)) fortiphishCampaignOptions.push({label:'All Campaigns', value:0});

    const inputItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 16,
            },
        },
    };

    return (
        <div className={'condition-input-item-content'}>
            <Form.Item
                name={`${conditionId}_condition_campaignid`}
                initialValue={0}
                {...inputItemLayout}
                label={'FortiPhish Campaigns'}
                rules={[{required: true, message: 'You must select at least one campaign'}]}>
                <Select showSearch
                        placeholder={'Select campaigns'}
                        className={'campaign-select input-ht-lg'}
                        options={fortiphishCampaignOptions}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }>
                </Select>
            </Form.Item>
        </div>
    )
}
