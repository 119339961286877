import React, {useEffect} from "react";
import {Form, Select} from 'antd';
import {useSelector} from "react-redux";
import {REMEDIATION_CONDITIONS_SELECTOR_LABEL} from "../../../redux/actiontypes/remediationConstants";

export const FortiphishUserSubmitted = ({conditionId = 0, form}) => {
    const {fortiphish_campaigns_for_options: fortiphishCampaignOptions} = useSelector((state) => state?.remediation?.remediationData) || [];
    const campaignSpecified = Form.useWatch(`${conditionId}_condition_campaignid`, form);

    useEffect(() => {
        if (campaignSpecified > 0) {
            form.setFieldValue(`${conditionId}_condition_numberoftimes`, 1);
        }
    }, [campaignSpecified])

    if (fortiphishCampaignOptions && !fortiphishCampaignOptions.find(el => el.value === 0)) fortiphishCampaignOptions.push({
        label: 'All Campaigns',
        value: 0
    });

    const inputItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 24,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 24,
            },
        },
    };

    return (
        <div className={'condition-input-item-content fortiphish-condition-container'}>
            <Form.Item
                className={"fortiphish-condition-selector"}
                name={`${conditionId}_condition_campaignid`}
                initialValue={0}
                {...inputItemLayout}
                label={REMEDIATION_CONDITIONS_SELECTOR_LABEL['campaign_selector']}
                rules={[{required: true, message: 'You must select at least one campaign'}]}>
                <Select showSearch
                        placeholder={'Select campaigns'}
                        className={'campaign-select input-ht-lg'}
                        options={fortiphishCampaignOptions}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }>
                </Select>
            </Form.Item>
            <Form.Item
                className={"fortiphish-condition-selector"}
                hidden={Boolean(campaignSpecified)}
                name={`${conditionId}_condition_numberoftimes`}
                initialValue={1}
                {...inputItemLayout}
                label={REMEDIATION_CONDITIONS_SELECTOR_LABEL['fortiphish_submitted_number_selector']}
                rules={[{required: true, message: 'You must select the total number of times user submitted'}]}>

                <Select placeholder={'Select user reported'}
                        className={'input-ht-lg'}
                        style={{width: '100px'}}
                >
                    <Select.Option value={1}>1</Select.Option>
                    <Select.Option value={2}>2</Select.Option>
                    <Select.Option value={3}>3</Select.Option>
                    <Select.Option value={4}>4</Select.Option>
                    <Select.Option value={5}>5</Select.Option>
                    <Select.Option value={6}>6</Select.Option>
                    <Select.Option value={7}>7</Select.Option>
                    <Select.Option value={8}>8</Select.Option>
                    <Select.Option value={9}>9</Select.Option>
                    <Select.Option value={10}>10</Select.Option>
                </Select>
            </Form.Item>
        </div>
    )
}
