import {
    LOAD_METADATAURL_REQUEST,
    LOAD_METADATAURL_REQUEST_SUCCESS,
    LOAD_METADATAURL_REQUEST_FAIL,
    SAVE_METADATAURL_REQUEST,
    SAVE_METADATAURL_REQUEST_SUCCESS,
    SAVE_METADATAURL_REQUEST_FAIL,
    AUTH_SAVE_RESET
} from "../actiontypes/authConstants";

const initialState = {
    metadataurl: null,
    save_auth_form: {
        loading: true,
    }
};

export const authDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_METADATAURL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOAD_METADATAURL_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload?.success,
                message: action.payload?.message || '',
                metadataurl: action.payload?.formdata.metadataurl,
                metadatatype: action.payload?.formdata.metadatatype,
                metadatafile: action.payload?.formdata.metadatafile
            };
        case LOAD_METADATAURL_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                success: action.payload?.success,
                message: action.payload?.message || '',
                metadataurl: '',
                metadatatype: '',
                metadatafile: ''
            };
        case SAVE_METADATAURL_REQUEST:
            return {
                ...state,
                save_auth_form: {
                    loading: true,
                    success: false,
                    ...action.payload,
                }
            };
        case SAVE_METADATAURL_REQUEST_SUCCESS:
            return {
                ...state,
                save_auth_form: {
                    ...state.save_auth_form,
                    loading: false,
                    success: true,
                    updated: true,
                    message: action.payload?.message || '',
                }
            };
        case SAVE_METADATAURL_REQUEST_FAIL:
            return {
                ...state,
                save_auth_form: {
                    ...state.save_auth_form,
                    loading: false,
                    success: false,
                    updated: false,
                    message: action.payload?.message || action.payload?.exception.message || '',
                }
            };
        case AUTH_SAVE_RESET:
            return {
                ...state,
                save_user: {}
            };
        default:
            return state;
    }
};
