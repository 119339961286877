import React from 'react';
import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getCourseShortName} from "../../shared/helper";

const ModuleBread = ({programid, programName, courseid, courseName = '', isQuiz = false}) => {
    const {t} = useTranslation();

    return (
        <div className="bread_wrap">
            <Breadcrumb separator='>'>
                {/*<Breadcrumb.Item>{t('pageutilities_navbar_home')}</Breadcrumb.Item>*/}
                <Breadcrumb.Item>
                    <Link to='/programs'>
                        {t('take_a_quiz_navbar_all')}
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/programs/${programid}`}>
                        {programName}
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/programs/${programid}/courses/${courseid}`}>
                        {isQuiz ?
                            t(getCourseShortName(courseName), courseName) : (<>{t(getCourseShortName(courseName), courseName)}</>)
                        }
                    </Link>
                </Breadcrumb.Item>
                {isQuiz &&
                <Breadcrumb.Item>
                    <>{t('take_a_quiz_navbar_quiz')}</>
                </Breadcrumb.Item>
                }
            </Breadcrumb>
        </div>
    )
};

export default ModuleBread;