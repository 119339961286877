export const SYNC_ADMIN_STATE = 'SYNC_ADMIN_STATE';
export const SYNC_LEARNER_STATE = 'SYNC_LEARNER_STATE';

export const LOAD_SITE_INFO = 'LOAD_SITE_INFO';
export const LOADED_SITE_CONFIG = 'LOADED_SITE_CONFIG';
export const UPDATE_SITE_CONFIG = 'UPDATE_SITE_CONFIG';
export const LOADED_PARENT_SITE_CONFIG = 'LOADED_PARENT_SITE_CONFIG';
export const LOADED_FORTICLOUD_DATA = 'LOADED_FORTICLOUD_DATA';
export const LOADED_SITE_WHITE_LABEL = 'LOADED_SITE_WHITE_LABEL';
export const LOADED_TENANT = 'LOADED_TENANT';
export const LOADING_TENANT = 'LOADING_TENANT';
export const LOADED_CAMPAIGNS = 'LOADED_CAMPAIGNS';
export const START_LOAD_CAMPAIGNS = 'START_LOAD_CAMPAIGNS';
export const FORCE_CAMPAIGNS_RELOAD = 'FORCE_CAMPAIGNS_RELOAD';
export const GET_SETTINGS_DATA_REQUEST = 'GET_SETTINGS_DATA_REQUEST';
export const GET_SETTINGS_DATA_SUCCESS = 'GET_SETTINGS_DATA_SUCCESS';
export const UPDATE_SETTINGS_DATA = 'UPDATE_SETTINGS_DATA';
export const GET_SETTINGS_DATA_FAIL = 'GET_SETTINGS_DATA_FAIL';
export const SETTINGS_RESET_PASSWORD_TOKEN_REQUEST = 'SETTINGS_RESET_PASSWORD_TOKEN_REQUEST';
export const SETTINGS_RESET_PASSWORD_TOKEN_SUCCESS = 'SETTINGS_RESET_PASSWORD_TOKEN_SUCCESS';
export const SETTINGS_RESET_PASSWORD_TOKEN_FAIL = 'SETTINGS_RESET_PASSWORD_TOKEN_FAIL';
export const STORE_WS_TOKEN = 'STORE_WS_TOKEN';
export const SET_TENANTADMIN = 'SET_TENANTADMIN';
export const SET_DOM_READY = 'SET_DOM_READY';
export const RESET_AUTO_REDIRECTION = 'RESET_AUTO_REDIRECTION';
export const EXTEND_TOKEN_VALIDITY_REQUEST = 'EXTEND_TOKEN_VALIDITY_REQUEST';
export const EXTEND_TOKEN_VALIDITY_SUCCESS = 'EXTEND_TOKEN_VALIDITY_SUCCESS';
export const EXTEND_TOKEN_VALIDITY_FAIL = 'EXTEND_TOKEN_VALIDITY_FAIL';
export const EXTEND_TOKEN_VALIDITY_RESET = 'EXTEND_TOKEN_VALIDITY_RESET';
export const CLEAR_MOODLE_NOTIFICATION = 'CLEAR_MOODLE_NOTIFICATION';
export const SET_USER_STATUS_CHECKED = 'SET_USER_STATUS_CHECKED';
export const SET_SSO_USER_ADMIN = 'SET_SSO_USER_ADMIN';
export const GET_SAC_COURSES_REQUEST = 'GET_SAC_COURSES_REQUEST';
export const GET_SAC_COURSES_FAIL = 'GET_SAC_COURSES_FAIL';
export const GET_SAC_COURSES_SUCCESS = 'GET_SAC_COURSES_SUCCESS';
export const NOTIFICATION_ADD_REDIRECT = 'NOTIFICATION_ADD_REDIRECT';
export const NOTIFICATION_CLEAR_REDIRECT = 'NOTIFICATION_CLEAR_REDIRECT';
