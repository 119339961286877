import React, {useEffect, useState} from "react";
import {Form, Input, Button, Alert} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {local_user_login, local_user_mfa_code_resend, local_user_mfa_code_verify} from "../../shared/service";
import {useHistory} from "react-router-dom";

export const LoginFACForm = ({userIdentifier, setUserIdentifier, hash: _hash}) => {
    let ajaxLock = false;
    const history = useHistory();
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [hash, setHash] = useState(_hash);
    const {t} = useTranslation();
    const [loginForm] = Form.useForm();
    const [tokenForm] = Form.useForm();
    const [loginFormFeedback, setLoginFormFeedback] = useState({
        show: false,
        success: false,
        message: '',
        className: ''
    });
    const [tokenFormFeedback, setTokenFormFeedback] = useState({show: false, success: false, message: ''});
    const [loginFormError, setLoginFormError] = useState(null);

    useEffect(() => {
        if (loginForm && userIdentifier) {
            loginForm.setFieldsValue({username: userIdentifier});
        }
    }, [loginForm, userIdentifier]);

    useEffect(() => {
        if (Array.isArray(loginFormError)) {
            let fieldErrors = loginFormError.map(error => ({
                name: error.field,
                errors: error.message !== '' ? [t('account_not_found')] : ''
            }));
            loginForm.setFields(fieldErrors);
        }
    }, [loginFormError]);

    // to remove validation error on field value change
    const rmvValidMessage = (changedFields) => {
        if (!loginFormFeedback.show) {
            return;
        }

        setTokenFormFeedback({...tokenFormFeedback, show: false});
        setLoginFormFeedback({...loginFormFeedback, show: false, className: ''});

        changedFields.map(field => {
            setLoginFormError([{field: field.name[0], message: ''}]);
        })
    }

    const onLoginFinish = (formData) => {
        setFormSubmitting(true);
        setLoginFormError(null);
        if (ajaxLock) {
            return;
        }
        ajaxLock = true;
        local_user_login(formData.username, formData.password).then(result => {
            setUserIdentifier(formData.username);
            setLoginFormFeedback({
                show: true,
                success: Boolean(result.success),
                message: String(result.message),
                className: 'usr_lgn_fb'
            });

            if (result.form) {
                setLoginFormError(result.form);
                setLoginFormFeedback({className: 'usr_not_exist'});
            }
            if (result.success) {
                if (result.token) {
                    window.location.href = '/login?token=' + result.token;
                } else if (result.hash) {
                    setHash(result.hash);
                    history.push('/login?session=' + encodeURIComponent(result.hash));
                }
            }
        }).finally(() => {
            ajaxLock = false;
            setFormSubmitting(false);
        })
    };

    const onVerifyFinish = (formData) => {
        if (ajaxLock) {
            return;
        }
        if (!hash) {
            resetToLogin(`Login session timeout, please try to login again.`);
            return;
        }
        ajaxLock = true;
        setFormSubmitting(true);
        local_user_mfa_code_verify(hash, formData.code).then(result => {
            setTokenFormFeedback({show: true, message: result.message, success: result.success});
            if (result.success && result.token) {
                setTimeout(() => {
                    window.location.href = '/login?token=' + result.token;
                }, 500);
            } else {
                resetToLogin(result.message);
            }
        }).finally(() => {
            setFormSubmitting(false);
            ajaxLock = false;
            tokenForm.resetFields(['code']);
        })
    };

    const resetToLogin = (message) => {
        history.push('/login');
        setHash('');
        setLoginFormFeedback({
            show: true,
            success: false,
            message: message,
            className: 'usr_vrf_fail'
        })
    };

    const resendMFACode = () => {
        if (!hash || ajaxLock) {
            return;
        }
        ajaxLock = true;
        local_user_mfa_code_resend(hash).then(result => {
            if (result.hasOwnProperty('success')) {
                setTokenFormFeedback({show: true, success: result.success, message: result.message});
            }
        }).finally(() => {
            ajaxLock = false;
        })
    }

    const handleResetRedirect = () => {
        history.push('/forgot');
    };

    return (
        <div id="login_fac_page" className="login_process_page_content">
            {!hash ?
                <>
                    <div className="title">
                        <span id="id_login_title">Login to Security Awareness and Training Service</span>
                    </div>
                    <div className="inner_wrapper">
                        <Form
                            name="basic"
                            form={loginForm}
                            onFinish={onLoginFinish}
                            className="login-form"
                            onFieldsChange={rmvValidMessage}
                        >
                            <Form.Item
                                validateTrigger='onSubmit'
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please provide a properly formatted email address',
                                    }
                                ]}
                            >
                                <Input prefix={<UserOutlined/>} placeholder={'Email'}/>
                            </Form.Item>
                            <Form.Item
                                validateTrigger='onSubmit'
                                name="password"
                                className={loginFormFeedback.className}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    }
                                ]}
                            >
                                <Input.Password className={'input_pwd'} prefix={<LockOutlined/>}
                                                placeholder={t('login_page_iamuser_psw')}/>
                            </Form.Item>

                            {
                                loginFormFeedback.show &&
                                <div className={`account_not_found ${loginFormFeedback.className}`}>
                                    <Alert message={loginFormFeedback.message}
                                           type={loginFormFeedback.success ? "warning" : "error"}/>
                                </div>
                            }

                            <div className="forgot_password_link">
                                <Button id="id_forgot_password_link"
                                        onClick={handleResetRedirect}>{t('login_page_iamuser_forget_psw')}</Button>
                            </div>

                            <Form.Item>
                                <Button className='primary full-width' type="primary"
                                        loading={formSubmitting}
                                        htmlType="submit">
                                    {t('login_page_button_continue')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </>
                :
                <>
                    <div id="verify">
                        <div className="title">
                            <span id="id_verify_title">{t('login_page_verification_header')}</span>
                        </div>
                        <div className="subtitle">
                            <span id="id_verification_subtitle">{t('login_page_verification_content')}</span>
                        </div>
                        <div className="inner_wrapper">
                            <Form
                                name="basic"
                                form={tokenForm}
                                onFinish={onVerifyFinish}
                                className="verify_form"
                            >
                                <Form.Item
                                    validateTrigger='onSubmit'
                                    name="code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        }
                                    ]}
                                >
                                    <Input type='text' placeholder={t('login_page_verification_code')}/>
                                </Form.Item>

                                {
                                    tokenFormFeedback.show &&
                                    <div className='verf_code_alrt mb-3'>
                                        <Alert message={tokenFormFeedback.message}
                                               type={tokenFormFeedback.success ? "info" : "error"}/>
                                    </div>
                                }

                                <div className="resnd-verf-code">
                                    <Button id="id-resnd-verf-code"
                                            onClick={resendMFACode}
                                    >{t('login_page_verification_resend')}</Button>
                                </div>

                                <Form.Item>
                                    <Button id="id_verify_btn"
                                            loading={formSubmitting}
                                            className='primary full-width' type="primary"
                                            htmlType="submit">
                                        {t('login_page_verification_button_verify')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};
