// On 03/20/2023, content team had to change the language code(en->en_us, es->es_mx) for some of modules(not all modules)
// If content in language(e.g en_us, es_mx) is missing, load the fallback content in original language(e.g en, es_mx)
export const FALLBACK_LOCALE_TRANSITION = {
    en_us: 'en',
    es_mx: 'es',
};

export const FALLBACK_LOCALE_STRING = {
    "welcomeemail_subject": "Welcome to the Security Awareness and Training Service",
    "login_page_verification_email_content": "Hi, \nFor security purposes, enter the verification code below to access the Security Awareness and Training Service. The code will expire after {{{??}}} minutes. Requesting a new code will void this code.",
    "welcomeemail_button_login": "Login Now",
    "login_page_main_header": "Welcome. What is your email address?",
    "login_page_button_continue": "Continue",
    "login_page_main_errorprevention": "We can’t find your account, please try again.",
    "login_page_iamuser_tab": "Sign in as IAM user",
    "login_page_iamuser_alias": "Alias",
    "login_page_iamuser_username": "IAM username",
    "login_page_iamuser_psw": "Password",
    "login_page_iamuser_forget_psw": "Forgot your password?",
    "login_page_iamuser_errorprevention": "Please enter correct credentials. Note that password is case-sensitive.",
    "resetpassword_title": "Reset Password",
    "resetpassword_email": "Email",
    "resetpassword_content": "To recover your password, please select your method of identification.",
    "resetpassword_option": "Recover by:",
    "resetpassword_useanme": "Username",
    "forgotpassword_main_header": "Type the email you used to sign up and we'll send you a password reset email.",
    "resetpassword_main_header": "Please type a new password and confirm.",
    "resetpassword_button_next": "Next",
    "resetpassword_button_cancel": "Cancel",
    "resetpassword_successtitle": "Password reset successful",
    "resetpassword_successcontent": "We have sent the password resetting instructions to your email account. You should receive it shortly. If the email does not reach you, please contact your site administrator.",
    "forgotpassword_successcontent": "We have sent the password resetting instructions to your email account. You should receive it shortly. If the email does not reach you, please contact your site administrator.",
    "resetpassword_failed_message": "Resetting password failed, please try again later.",
    "forgotpassword_failed_message": "Resetting password failed, please try again later.",
    "resetpassword_email_subject": "Password Reset Instructions",
    "resetpassword_email_content": "Hi there! Need to reset your password? No problem! Just click the button below and you’ll be on your way. If you did not make this request, please ignore this email.",
    "forgotpassword_email_button_reset": "Send Reset Email",
    "forgotpassword_back_login_text": "Take me back to ",
    "forgotpassword_back_login_link": "Login",
    "forgotpassword_email_input_placeholder": "yourname@company.com",
    "forgotpassword_submit_success_text": "Check your email. We'll send an email to {{$a}} if it's associated with your account.<br/><br/>Bear in mind it will be valid for <b>30 minutes</b> only.",
    "resetpassword_email_reset_button_text": "Reset Password",
    "resetpassword_email_input_placeholder": "new password",
    "resetpassword_email_input_placeholder_confirm": "confirm password",
    "forgotpassword_email_field_required": "This field is required",
    "forgotpassword_email_field_rule_email": "Please provide the email address which you want to reset the password",
    "resetpassword_email_field_required": "This field is required",
    "resetpassword_email_field_rule_match": "The two passwords should match.",
    "resetpassword_email_field_rule_length": "Passwords must be at least 8 characters long",
    "resetpassword_email_field_rule_regex": "Your password must contain: \n1. At least 8 characters\n2. Must contain 1 lower case letter(s), 1 upper case letter(s), 1 digit(s) and 1 non-alphanumeric character(s)",
    "resetpassword_submit_success_text": "You’ve successfully changed your password.",
    "resetpassword_email_login_button_text": "Continue to Login",
    "login_page_verification_header": "Verification Code",
    "login_page_verification_content": "Check your email and enter the verification code below:",
    "login_page_verification_code": "verification code",
    "login_page_verification_resend": "Didn’t receive? Resend the code",
    "login_page_verification_button_verify": "Verify",
    "login_page_verification_email_subject": "Verification Code for Security Awareness and Training Service",
    "login_page_verification_email_code": "Verification Code",
    "pageutilities_gotomainpage": "Go to the main page",
    "pageutilities_navbar_home": "Home",
    "pageutilities_navbar_programs": "Programs",
    "pageutilities_navbar_assets": "Assets",
    "pageutilities_navbar_settings": "Settings",
    "pageutilities_navbar_terms": "Terms of use",
    "pageutilities_navbar_privacy": "Privacy Policy",
    "pageutilities_avatar_profile": "Profile",
    "pageutilities_avatar_settings": "Settings",
    "pageutilities_avatar_gotoadmin": "Go to Admin",
    "pageutilities_avatar_backtoadmin": "Back to Admin",
    "pageutilities_avatar_visitlearnerexperience": "Visit Learner Experience",
    "pageutilities_avatar_getsupport": "Get Support",
    "pageutilities_avatar_logout": "Log out",
    "pageutilities_avatar_profile_email": "Email",
    "pageutilities_avatar_profile_firstname": "First name",
    "pageutilities_avatar_profile_lastname": "Last name",
    "pageutilities_avatar_profile_org": "Organization",
    "pageutilities_avatar_profile_manager_email": "Manager Email",
    "homepage_title": "Welcome to the Security Awareness and Training Service.",
    "homepage_student_content": "Start here to learn about today's most common cyber threats. Learn how to spot and avoid threats to keep yourself and your organization more secure.",
    "homepage_feature": "Train to Spot Cyber Threats",
    "homepage_feature_educate_title": "Educate yourself",
    "homepage_feature_educate_content": "Keep yourself and your organization more secure",
    "homepage_feature_flexibility_title": "Flexibility",
    "homepage_feature_flexibility_content": "Self-paced cybersecurity training modules",
    "homepage_feature_user_title": "User friendly",
    "homepage_feature_user_content": "Available in select additional languages",
    "homepage_feature_interactive_title": "Interactive learning videos",
    "homepage_feature_interactive_content": "Make learning fun",
    "homepage_feature_better_title": "Better components",
    "homepage_feature_better_content": "Developed by cybersecurity training experts",
    "homepage_feature_suitable_title": "Suitable for everyone",
    "homepage_feature_suitable_content": "From technical to non-technical learners",
    "homepage_mylearning": "My Learning",
    "homepage_mylearning_emptytitle": "Ready to go?",
    "homepage_mylearning_emptycontent": "You have not been assigned a program yet.",
    "homepage_mylearning_duedate": "Due on",
    "homepage_mylearning_moduleleft": "Modules completed",
    "homepage_mylearning_video": "Video",
    "homepage_mylearning_quiz": "Quiz",
    "homepage_mylearning_button_getstarted": "Get Started",
    "homepage_mylearning_button_startnow": "Start Now",
    "homepage_mylearning_button_continue": "Continue",
    "homepage_mylearning_button_continue_learning": "Continue Learning",
    "homepage_admin_content": "Educate your workforce about today’s cyber threats, such as phishing, social engineering, and ransomware attacks, and how to protect against them.",
    "homepage_admin_button_launch": "Launch Admin Portal",
    "program_page_title": "Programs",
    "program_status_inprogress": "In Progress",
    "program_status_completed": "Completed",
    "message_no_program_inprogress": "No program in progress.",
    "no_question_found_in_quiz": "No questions found in the quiz",
    "no_question_found": "No questions",
    "program_status_overdue": "Overdue",
    "program_card_button_startnow": "Start Now",
    "program_card_button_view": "View",
    "program_card_button_resume": "Resume",
    "program_page_howtopass": "How to Pass",
    "program_page_howtopass_passquiz": "Complete all modules and pass all quizzes with a score of 80% or higher.",
    "program_page_howtopass_bydate": "Complete all modules by",
    "program_page_modules": "Modules",
    "program_page_modulesleft": " module left to complete",
    "program_page_modulesleft_plural": " modules left to complete",
    "program_page_takethequiz": "Take the Quiz",
    "program_page_takethequiz_fail": "Can't attempt this quiz",
    "program_page_takethequiz_fail_content": "Not available unless: The video of {{$a}} is marked complete.",
    "take_a_quiz_navbar_all": "All Programs",
    "take_a_quiz_navbar_quiz": "Quiz",
    "take_a_quiz_questionnumber": "Question ",
    "take_a_quiz_button_next": "Next Question",
    "take_a_quiz_button_previous": "Previous",
    "take_a_quiz_button_finish": "Finish",
    "quiz_status_ruletopass": "To pass this quiz, you must achieve a score of 80% or higher.",
    "quiz_status_yourgrade": "Your Grade",
    "quiz_status_comment_attempt": "You did not finish this attempt last time, click the button below to continue the last attempt.",
    "quiz_status_button_continue": "Continue the Last Attempt",
    "quiz_status_comment_goodtry": "Good try, but it looks like you need to go back and review a few things.",
    "quiz_status_button_backtoprogram": "Back to Program",
    "quiz_status_button_tryagain": "Try Again",
    "quiz_status_button_viewfeedback": "View Feedback",
    "quiz_status_congrats": "Congratulations you have passed the quiz and completed the lesson!",
    "quiz_status_comment_passed": "Well done! You passed the quiz.",
    "quiz_status_tooltop_viewmore": "Click to view more attempts",
    "quiz_status_button_keeplearning": "Keep Learning",
    "quiz_status_quizsummary_button_backtosummary": "Back to quiz summary",
    "quiz_status_quizsummary_feedback": "You may want to review the video <a>{{$a}}</a> before you try again.",
    "setting_page_account": "Account",
    "setting_page_preferredlanguage": "Preferred Language",
    "setting_page_timezone": "Timezone",
    "setting_page_timezone_default": "Canada/Pacific (GMT-8)",
    "setting_page_button_save": "Save Changes",
    "setting_page_security": "Security",
    "setting_page_pswreset": "Password Reset",
    "setting_page_mfa_setting": "Two-Factor Authentication(2FA)",
    "account_not_found": "We can’t find your account, please try again.",
    "message_preferences_saving": "Saving your preferences",
    "message_preferences_saved": "We've updated your preferences",
    "message_preferences_error": "There's been an error updating your preferences",
    "setting_page_button_pswreset": "Change My Password",
    "setting_page_pswreset_modal_title": "Password Reset",
    "setting_page_pswreset_modal_content": "You are about to logout before navigating to the Password Reset page.",
    "setting_page_pswreset_modal_button_okay": "Reset My Password",
    "setting_page_pswreset_notify": "You will be logged out and redirected to an external page for password reset.",
    "setting_page_tooltip_timezone": "Change to your timezone will be available soon.",
    "warning_title": "Warning",
    "video_loading_failed": "Failed to load Video, please refresh the page to try again",
    "studentapp_video_sgstn_mob": "*Suggest to rotate your screen to landscape mode",
    "studentapp_module_progress_tooltip": "This module is in progress",
    "studentapp_module_no_progress_tooltip": "This module has no progress",
    "video_play_btn": "Play video",
    "studentapp_notfound_message": "The page you're looking for could not be found or doesn't exist.",
    "studentapp_notfound_title": "Page not found",
    "studentapp_quizfeedback_review_msg": "Review is not allowed, please contact the administrator if you want to review the quiz",
    "studentapp_login_message": "Directing to Security Awareness and Training Service as",
    "studentapp_pwdreset_inputpwd_err": "Please input your password!",
    "studentapp_pwdreset_cnfrmpwd_err": "Please confirm your password!",
    "studentapp_pwdreset_pwd_mtch_err": "The two passwords that you entered do not match!",
    "studentapp_pwdreset_updt_pwd_err": "Update Password",
    "studentapp_modulepage_coursequiz_err": "No quiz found",
    "studentapp_modulepage_coursequiz_err_dsc": "Can not start this quiz, please contact your administrator.",
    "message_no_program_complete": "No program completed.",
    "admin_users_upload_result_code_1": 'Email Domain not verified.',
    "admin_users_upload_result_code_2": 'User has already enrolled in another place.',
    "admin_users_upload_result_code_3": 'User creation failed.',
    "admin_users_upload_result_code_4": 'User is already existed, and updated.',
    "admin_users_upload_result_code_5": 'New User has been created, but no job assigned.',
    "admin_users_upload_result_code_6": 'New User has been created, and new job assigned.',
    "admin_users_upload_result_code_7": 'User creation failed, username contains invalid characters.',
    "local_campaigns_home_remove_campaign": "Remove from campaign",
    "studentapp_sac_title": "Security Awareness Curriculum for Classrooms",
    "studentapp_sac_desc": "The curriculum helps students learn how to apply cybersecurity skills to their digital interactions at school, at home and everywhere they go.",
    "studentapp_sac_course_button" : "Download Package",
    "studentapp_sac_course_age_tag" : "Age 12-14",
    "studentapp_sac_course_strands" : "Digital Safety",
    "studentapp_sac_sac_banner_title" : "More lessons in 2024, spanning various age groups and languages",
    "studentapp_sac_sac_banner_text" : "Stay tuned for updates as we release new content.",
    "studentapp_sac_profile_org" : "Organization",
    "studentapp_sac_profile_manager_email" : "Manager email",
    "studentapp_modules_showless_txt": "Show less",
    "studentapp_modules_showmore_txt": "Show more",
    "studentapp_modules_showmore_num_txt": 'Show {{$a}} more',
    "studentapp_modules_quiz_length_1": "Quiz (1 question)",
    "studentapp_modules_quiz_length_7": "Quiz (7 questions)",
    "studentapp_modules_video_txt": 'Video ({{$a}})',
};
