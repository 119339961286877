export default {
    MOODLE_HOST: process.env.REACT_APP_MOODLE_HOST,
    LOCAL_HOST: 'http://localhost:8000',
    MOODLE_SERVICE: process.env.REACT_APP_MOODLE_SERVICE,
    MOODLE_WSEXTSERVICE: process.env.REACT_APP_MOODLE_WSEXTSERVICE,
    MOODLE_ADDITIONAL_SERVICE_TOKEN: process.env.REACT_APP_MOODLE_ADDITIONAL_SERVICE_TOKEN,
    MOODLE_CUSTOMURLSCHEME: process.env.REACT_APP_MOODLE_CUSTOMURLSCHEME,
    MOODLE_MAINTENANCE: process.env.REACT_APP_MOODLE_MAINTENANCE_WINDOW,
    FORTIPHISH_HOST: process.env.REACT_APP_FORTIPHISH_HOST,
    FORTICLOUD_HOST:process.env.REACT_APP_FORTICLOUD_HOST,
    MOODLE_USER_TOKEN: 'MOODLE_USER_TOKEN',
    MOODLE_USER_DETAILS: 'MOODLE_USER_DETAILS',
    DEFAULT_LANG: 'en_us',
    DEFAULT_SERVER_TIMEZONE: 'America/Vancouver',
    LAUNCH_URL_PATH: '/local/bridge/launch.php',
    SERVER_REQUEST_PATH: '/webservice/rest/server.php',
    DEFAULT_PROGRAM_ID: 'default_campaign',
    MFA_SETTING_AVAILABILITY: false,
    SATS_APPLICATION_ID: 47,
    FORTIPHISH_APPLICATION_ID: 40,
    DEFAULT_FAC_PASSWORD_FORGOT_LINK: 'https://auth.training.fortinet.com/user/reset-password/',
    DEFAULT_ADMIN_PASSWORD_FORGOT_LINK: 'https://support.fortinet.com/cred/#/forgot/password/',
    DEFAULT_BRANDED_NANO_VIDEO_LINK: 'https://nsei.wistia.com/projects/g80rhnmpel',
    DEFAULT_CUSTOM_BRANDED_NANO_VIDEO_LINK: 'https://nsei.wistia.com/projects/6ofrm7iz1v',
    DEFAULT_FORTI_CLOUD_RESET_PASSWORD_LINK: 'https://support.fortinet.com/cred/#/forgot/password',
    DEFAULT_SECURITY_AWARENESS_HELP_LINK: 'https://docs.fortinet.com/product/security-awareness-training/23.4',
    FORTIPHISH_AUTO_RELOAD_PER_SEC: 60,
};
