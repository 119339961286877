import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Input, message} from "antd";
import {connect} from "react-redux";
import {user_reset_password} from "../../shared/service";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {LeftOutlined, LockOutlined} from "@ant-design/icons";
import Alert from "antd/es/alert";
import {ThemeContext} from "../../shared/ThemeContext";

function ResetPasswordPage({siteConfig}) {
    const history = useHistory();
    const [resetForm] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submittedMessage, setSubmittedMessage] = useState('');
    const {login_background_img, login_logo} = useContext(ThemeContext);
    const {t} = useTranslation();

    useEffect(() => {
        let reset_token = loadResetToken();
        if (reset_token) {
            storeResetToken(reset_token);
        } else {
            // if no reset token, redirect to login page
            window.location.href = '/login';
        }
    }, []);

    useEffect(() => {
    }, [submittedMessage, resetForm]);

    const onSubmitHandler = (values) => {
        const reset_token = localStorage.getItem('reset_token');

        if (typeof values === 'object') {
            user_reset_password(reset_token, values.password).then(result => {
                if (result.success) {
                    setFormSubmitted(true);
                    setSubmitSuccess(true);
                    setSubmittedMessage(t('resetpassword_successtitle'));
                    resetForm.resetFields();
                } else {
                    setFormSubmitted(true);
                    setSubmitSuccess(false);
                    setSubmittedMessage(result.message ?? t('resetpassword_failed_message'));
                }
            })
        }
    }

    const onFinish = (values) => {
        onSubmitHandler(values);
    };

    const onFinishFailed = (errorInfo) => {
        setFormSubmitted(false);
    };

    const loadResetToken = () => {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('resettoken');
    };

    function storeResetToken(token) {
        localStorage.setItem('reset_token', token);
        window.history.replaceState(null, null, window.location.pathname);
    }

    function toLoginPage() {
        history.push('/login');
    }

    return (
        <div id="reset_page" className="login-process-page">
            <div className="page_inner">
                <div className="form_container">
                    <div className="form_wrap">
                        <div className="page_header">
                            <div className="logo_wrap"
                                 style={{backgroundImage: `url('${siteConfig?.login_logo || login_logo}')`}}/>
                        </div>
                        {
                            submitSuccess ?
                                <div className="login_process_page_content">
                                    <p>{t('resetpassword_submit_success_text')}</p>
                                    <Button className='primary full-width' type="primary" onClick={toLoginPage}>
                                        {t('resetpassword_email_login_button_text')}
                                    </Button>
                                </div>
                                :(
                                    <div className="login_process_page_content">
                                        <p className='welcome'>
                                            {t('resetpassword_main_header')}
                                        </p>
                                        {contextHolder}
                                        <Form
                                            form={resetForm}
                                            name="basic"
                                            initialValues={{
                                                remember: true,
                                            }}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            className="reset-form"
                                        >
                                            <Form.Item
                                                validateTrigger='onSubmit'
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('resetpassword_email_field_required'),
                                                    },
                                                    {
                                                        pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[~\'`{}\\[\\]!*-.|?@#$%^&+=\\\\\\/:;<>()_])[A-Za-z\\d~\'`{}\\[\\]!*-.|?@#$%^&+=\\\\\\/:;<>()_]{8,}$',
                                                        message: t('resetpassword_email_field_rule_regex'),

                                                    }
                                                ]}
                                            >
                                                <Input.Password prefix={<LockOutlined />} placeholder={t('resetpassword_email_input_placeholder')} onBlur={(e) => {

                                                }}/>
                                            </Form.Item>

                                            <Form.Item
                                                validateTrigger='onSubmit'
                                                name="confirm_password"
                                                dependencies={['password']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('resetpassword_email_field_required'),
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, confirm_password_value) {
                                                            if (!confirm_password_value || getFieldValue('password') === confirm_password_value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error(t('resetpassword_email_field_rule_match')));
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password prefix={<LockOutlined />} placeholder={t('resetpassword_email_input_placeholder_confirm')} onBlur={(e) => {
                                                }}/>
                                            </Form.Item>

                                            {
                                                (submittedMessage) && (
                                                    <Form.Item className='reset-failed'>
                                                        <Alert  type="error" message={submittedMessage} />
                                                    </Form.Item>
                                                )
                                            }

                                            <Form.Item>
                                                <Button className='primary full-width' type="primary" htmlType="submit">
                                                    {t('resetpassword_email_reset_button_text')}
                                                </Button>
                                            </Form.Item>

                                            <Form.Item style={{ textAlign: 'right' }}>
                                                {t('forgotpassword_back_login_text')}<a className='ant-typography' onClick={toLoginPage}>{t('forgotpassword_back_login_link')}</a>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                )
                        }

                    </div>
                </div>
                <div className="bg_wrap"
                     style={{backgroundImage: `url('${siteConfig?.login_background_img || login_background_img}')`}}
                     id='bg_wrap'/>
            </div>
        </div>
    );
}


function mapStateToProps(state) {
    return {...state.root};
}

export default connect(
    mapStateToProps,
)(ResetPasswordPage);
