import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Input, message, Space, Typography} from "antd";
import {connect} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {UserOutlined} from "@ant-design/icons";
import Alert from "antd/es/alert";
import {ThemeContext} from "../../shared/ThemeContext";
import {user_forgot_password} from "../../shared/service";

function ForgotPage({siteConfig}) {
    const history = useHistory();
    const { Text, Link } = Typography;
    const [messageApi, contextHolder] = message.useMessage();
    const [submittedEmail, setSubmittedEmail] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submittedMessage, setSubmittedMessage] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const {login_background_img, login_logo} = useContext(ThemeContext);
    const {t} = useTranslation();

    useEffect(() => {
    }, [submittedMessage]);

    const onSubmitHandler = (e) => {
        setSubmitLoading(true);
        if (typeof e === 'object') {
            setSubmittedEmail(e['email']);
            user_forgot_password(e).then(result => {
                if (result.success) {
                    setFormSubmitted(true);
                    setSubmitSuccess(true);
                    setSubmittedMessage(t('forgotpassword_successcontent'));
                } else {
                    setFormSubmitted(true);
                    setSubmitSuccess(false);
                    setSubmittedMessage(result.message ?? t('forgotpassword_failed_message'));
                }
            }).finally(() => {
                setSubmitLoading(false);
            })
        }
    }

    const onFinish = (values) => {
        onSubmitHandler(values);
    };

    const onFinishFailed = (errorInfo) => {
        setFormSubmitted(false);
        setSubmitLoading(false);
    };

    function toLoginPage() {
        history.push('/login');
    }

    return (
        <div id="forgot_page" className="login-process-page">
            <div className="page_inner forgot_page_inner">
                <div className="form_container">
                    <div className="form_wrap">
                        <div className="page_header">
                            <div className="logo_wrap"
                                 style={{backgroundImage: `url('${siteConfig?.login_logo || login_logo}')`}}/>
                        </div>
                        {
                            submitSuccess ?
                                <div className="login_process_page_content">
                                    <div className="forgot_password_submit_success_text_container">
                                        <Trans className={'forgot_password_submit_success_text'}
                                               i18nKey="forgotpassword_submit_success_text"
                                               values={{$a: submittedEmail}}
                                               components={{
                                                   b: <Text strong></Text>
                                               }}/>
                                    </div>
                                    <br/>
                                    <div className="forgot_password_submit_success_link">
                                        {t('forgotpassword_back_login_text')}<a className='ant-typography' onClick={toLoginPage}>{t('forgotpassword_back_login_link')}</a>
                                    </div>
                                </div>
                                :(
                                <div className='forgot_form_container login_process_page_content'>
                                    <p className='welcome'>
                                        {t('forgotpassword_main_header')}
                                    </p>
                                    {contextHolder}
                                    <Form
                                        name="basic"
                                        initialValues={{
                                            remember: true,
                                        }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        className="forgot-form"
                                    >
                                        <Form.Item
                                            validateTrigger='onSubmit'
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('forgotpassword_email_field_required'),
                                                },
                                                {
                                                    type: 'email',
                                                    message: t('forgotpassword_email_field_rule_email'),
                                                },
                                            ]}
                                        >
                                            <Input className={formSubmitted && !submitSuccess ? 'account_not_found' : ''} prefix={<UserOutlined/>} placeholder={t('forgotpassword_email_input_placeholder')} onBlur={(e) => {
                                            }}/>
                                        </Form.Item>

                                        {
                                            (!submitSuccess &&formSubmitted && submittedMessage) && (
                                                <Form.Item className='account_not_found'>
                                                    <Alert type="error" message={submittedMessage} />
                                                </Form.Item>
                                            )
                                        }

                                        <Form.Item>
                                            <Button className='primary full-width' type="primary"
                                                    htmlType="submit" loading={submitLoading}>
                                                {t('forgotpassword_email_button_reset')}
                                            </Button>
                                        </Form.Item>

                                        <Form.Item style={{ textAlign: 'right' }}>
                                            {t('forgotpassword_back_login_text')}<a className='ant-typography' onClick={toLoginPage}>{t('forgotpassword_back_login_link')}</a>
                                        </Form.Item>
                                    </Form>
                                </div>
                            )
                        }

                    </div>
                </div>
                <div className="bg_wrap"
                     style={{backgroundImage: `url('${siteConfig?.login_background_img || login_background_img}')`}}
                     id='bg_wrap'/>
            </div>
        </div>
    );
}


function mapStateToProps(state) {
    return {...state.root};
}

export default connect(
    mapStateToProps,
)(ForgotPage);
