import React from 'react';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Tooltip, Button} from 'antd';
import {Doughnut} from 'react-chartjs-2';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const CompletionPieChart = ({data: _data, percent}) => {
    const data = {
        labels: [],
        datasets: [{
            label: i18next.t('custom_dashboard_training_overview_overall_cmpltn_rate', 'Overall Completion Rate'),
            data: _data,
            backgroundColor: [
                '#58A55C',
                '#CFD8DC'
            ],
            hoverOffset: 4,
            cutout: '80%',
            responsive: true,
            maintainAspectRatio: false,
            borderWidth: 1
        }],
    };

    const options = {
        plugins: {
            tooltip: {
                filter: function (context) {
                    return context.dataIndex === 0;
                },
                callbacks: {
                    label: (context) => (
                        `${context.parsed}%`
                    )
                }
            }
        }
    }

    return (
        <div className="pie_chart_wrap">
            <div className="pie_chart_text">
                {`${percent}%`}
                <div>{i18next.t('custom_dashboard_training_overview_overall_cmpltn_text', 'COMPLETED')}</div>
            </div>
            <Doughnut data={data} options={options}/>
        </div>
    );
}

const EscalationRateChart = ({data: _data}) => {
    const data = {
        labels: [],
        datasets: [{
            label: i18next.t('custom_dashboard_training_overview_esc_rate', 'Escalation Rate'),
            data: _data,
            backgroundColor: [
                '#FF6361',
                '#EDEDED'
            ],
            hoverOffset: 4,
            cutout: '80%',
            responsive: true,
            maintainAspectRatio: false,
            borderWidth: 1
        }]
    };

    const options = {
        plugins: {
            tooltip: {
                filter: (context) => {
                    return context.dataIndex === 0;
                },
                callbacks: {
                    label: (context) => (
                        `${context.parsed}%`
                    )
                }
            }
        }
    }

    return (
        <div className="pie_chart_wrap">
            <div className="pie_chart_text">
                {`${_data[0]}%`}
                <div>{i18next.t('custom_dashboard_training_overview_escalation_text', 'escalated')}</div>
            </div>
            <Doughnut data={data} options={options}/>
        </div>
    );
}

const ProgressPieChart = ({data: _data}) => {
    const data = {
        labels: [],
        datasets: [{
            label: i18next.t('custom_dashboard_training_overview_overall_progress', 'Overall Progress'),
            data: _data,
            backgroundColor: [
                '#58A55C',
                '#F1BE42',
                '#FF6361',
                '#CFD8DC'
            ],
            hoverOffset: 4,
            cutout: '66%',
            responsive: true,
            maintainAspectRatio: false,
        }]
    };

    const options = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => (
                        `${context.parsed}%`
                    )
                }
            }
        }
    }

    return (
        <div className="pie_chart_wrap">
            <Doughnut data={data} options={options}/>
        </div>
    );
}

const OverviewPanel = ({data}) => {
    const {t} = useTranslation();
    // card 1
    const all_campaigns_count = data?.all_campaigns_count || 0;
    const allocated_students_count = data?.allocated_students_count || 0;
    // card 2&3
    const overall_not_start_count = data?.overall_not_started_count || 0;
    const overall_overdue_count = data?.overall_overdue_count || 0;
    const overall_in_progress_count = data?.overall_in_progress_count || 0;
    const overall_completed_count = data?.overall_completed_count || 0;
    const overall_total_count = overall_completed_count + overall_in_progress_count + overall_overdue_count + overall_not_start_count;
    const overall_completed_rate = allocated_students_count > 0 ? Math.round(overall_completed_count * 100 / overall_total_count) : 0;
    const overall_in_progress_rate = allocated_students_count > 0 ? Math.round(overall_in_progress_count * 100 / overall_total_count) : 0;
    const overall_overdue_rate = allocated_students_count > 0 ? Math.round(overall_overdue_count * 100 / overall_total_count) : 0;
    const overall_progress_array = allocated_students_count > 0 ?
        (overall_overdue_count > 0 ?
                [overall_completed_rate, overall_in_progress_rate,
                    Math.min(100 - overall_completed_rate - overall_in_progress_rate, overall_overdue_rate),
                    Math.max(100 - overall_completed_rate - overall_in_progress_rate - overall_overdue_rate, 0)
                ]
                : [overall_completed_rate,
                    overall_in_progress_rate, 0,
                    Math.max(100 - overall_completed_rate - overall_in_progress_rate, 0)
                ]
        ) :
        [0, 0, 0, 100]
    // card 4
    const escalation_rate = data?.escalation_rate || 0;

    return (
        <div className='training_overview_panel'>
            <h4 className="training-detail-title">
                {t('custom_dashboard_training_overview_heading', 'Training Campaigns Overview')}
            </h4>

            <div className="row">
                <div className="col-6 col-xl-3 info_card_container">
                    <div className="info_card">
                        <div className="info_items_wrap">
                            <div className="info_item">
                                <div className="info_item_title">
                                    {t('custom_dashboard_training_overview_all_campaigns_title', 'All Campaigns')}
                                </div>
                                <div className="info_item_text">
                                    {all_campaigns_count}
                                </div>
                            </div>
                            <hr className={'hr'}/>
                            <div className="info_item">
                                <div className="info_item_title">
                                    {t('custom_dashboard_training_overview_alloc_learners', 'Allocated Learners')}
                                </div>
                                <div className="info_item_text">
                                    {allocated_students_count}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-xl-3 info_card_container">
                    <div className="info_card">
                        <div className="info_item_title">
                            {t('custom_dashboard_training_overview_overall_cmpltn_rate', 'Overall Completion Rate')}
                            <Tooltip placement="top"
                                     title={t('custom_dashboard_training_overview_overall_cmpltn_tooltip', 'The Overall Completion Rate shows the percentage of students who have completed their campaigns among all attempts.')}>
                                <InfoCircleOutlined/>
                            </Tooltip>
                        </div>
                        <div className="chart_wrap completion-pie-chart">
                            <CompletionPieChart
                                data={[(overall_completed_rate), (100 - overall_completed_rate)]}
                                percent={overall_completed_rate}/>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-xl-3 info_card_container">
                    <div className="info_card">
                        <div className="info_item_title">
                            {t('custom_dashboard_training_overview_overall_progress', 'Overall Progress')}
                            <Tooltip placement="top"
                                     title={t('custom_dashboard_training_overview_overall_progress_tooltip', 'The Overall Progress shows the percentage of students who are currently working through the campaign among all attempts.')}>
                                <InfoCircleOutlined/>
                            </Tooltip>
                        </div>
                        <div className="chart_wrap">
                            <ProgressPieChart data={overall_progress_array}
                            />
                        </div>
                        <ul className="info_item_desc_list">
                            <li className="notstarted">{t('custom_dashboard_training_details_columns_status_not_started', 'Not Started')}</li>
                            <li className="inprogress">{t('custom_dashboard_training_overview_overall_progress_inprogress', 'In Progress')}</li>
                            <li className="completed">{t('custom_dashboard_training_overview_overall_progress_completed', 'Completed')}</li>
                            <li className="overdue">{t('custom_dashboard_training_overview_overall_progress_overdue', 'Overdue')}</li>
                        </ul>
                    </div>
                </div>
                <div className="col-6 col-xl-3 info_card_container">
                    <div className="info_card">
                        <div className="info_item_title">
                            {t('custom_dashboard_training_overview_esc_rate', 'Escalation Rate')}
                            <Tooltip placement="top"
                                     title={t('custom_dashboard_training_overview_esc_rate_tooltip', 'The Escalation Rate shows the number of times that a remediation rule has been triggered, divided by all attempts.')}>
                                <InfoCircleOutlined/>
                            </Tooltip>
                        </div>
                        <div className="chart_wrap">
                            <EscalationRateChart data={[escalation_rate, (100 - escalation_rate)]}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OverviewPanel;
