import {
    USERTOUR_PATH_HAS_TOUR_REQUEST,
    USERTOUR_PATH_HAS_TOUR_SUCCESS,
    USERTOUR_PATH_HAS_TOUR_FAIL,
    USERTOUR_UPDATE_TOUR_COMPLETION_REQUEST,
    USERTOUR_UPDATE_TOUR_COMPLETION_SUCCESS,
    USERTOUR_UPDATE_TOUR_COMPLETION_FAIL,
    USERTOUR_GET_TOUR_LIST_REQUEST,
    USERTOUR_GET_TOUR_LIST_SUCCESS,
    USERTOUR_GET_TOUR_LIST_FAIL,
    USERTOUR_GET_USER_TOUR_COMPLETION_SUMMARY_REQUEST,
    USERTOUR_GET_USER_TOUR_COMPLETION_SUMMARY_SUCCESS,
    USERTOUR_GET_USER_TOUR_COMPLETION_SUMMARY_FAIL,
    USERTOUR_GET_USER_TOUR_DATA_REQUEST,
    USERTOUR_GET_USER_TOUR_DATA_SUCCESS,
    USERTOUR_GET_USER_TOUR_DATA_FAIL,
    USERTOUR_SET_ONBOARDING_VIDEO_REQUEST,
    USERTOUR_SET_ONBOARDING_VIDEO_SUCCESS,
    USERTOUR_SET_ONBOARDING_VIDEO_FAIL,
    USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_REQUEST,
    USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_SUCCESS,
    USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_FAIL,
    USERTOUR_START_TOUR,
    USERTOUR_END_TOUR, USERTOUR_SET_OPEN_TOUR,
} from "../actiontypes/userTourConstants";


export const userToursReducer = (state = {}, action) => {
    switch (action.type) {
        case USERTOUR_PATH_HAS_TOUR_REQUEST:
            return {
                ...state,
                loadingTour: true,
            };
        case USERTOUR_PATH_HAS_TOUR_SUCCESS:
            return {
                ...state,
                loadingTour: false,
                userTourInfo: action.payload?.response
            };
        case USERTOUR_PATH_HAS_TOUR_FAIL:
            return {
                ...state,
                loadingTour: false
            };
        case USERTOUR_UPDATE_TOUR_COMPLETION_REQUEST:
            return {
                ...state,
                updatingCompletion: true
            };
        case USERTOUR_UPDATE_TOUR_COMPLETION_SUCCESS:
            return {
                ...state,
                updatingCompletion: false
            };
        case USERTOUR_UPDATE_TOUR_COMPLETION_FAIL:
            return {
                ...state,
                updatingCompletion: false
            }
        case USERTOUR_GET_TOUR_LIST_REQUEST:
            return {
                ...state,
                loadingTourList: true
            };
        case USERTOUR_GET_TOUR_LIST_SUCCESS:
            return {
                ...state,
                loadingTourList: false,
                userTourList: action.payload?.response
            };
        case USERTOUR_GET_TOUR_LIST_FAIL:
            return {
                ...state,
                loadingTourList: false,
            }
        case USERTOUR_GET_USER_TOUR_COMPLETION_SUMMARY_REQUEST:
            return {
                ...state,
                loadingUserTourCompletionSummary: true
            };
        case USERTOUR_GET_USER_TOUR_COMPLETION_SUMMARY_SUCCESS:
            return {
                ...state,
                loadingUserTourCompletionSummary: false,
                userTourCompletionSummary: action.payload?.response
            };
        case USERTOUR_GET_USER_TOUR_COMPLETION_SUMMARY_FAIL:
            return {
                ...state,
                loadingUserTourCompletionSummary: false
            };
        case USERTOUR_GET_USER_TOUR_DATA_REQUEST:
            return {
                ...state,
                loadingUserTourData: true,
            };
        case USERTOUR_GET_USER_TOUR_DATA_SUCCESS:
            return {
                ...state,
                loadingUserTourData: false,
                userTourData: action?.payload
            };
        case USERTOUR_GET_USER_TOUR_DATA_FAIL:
            return {
                loadingUserTourData: false
            };
        case USERTOUR_SET_ONBOARDING_VIDEO_REQUEST:
            return {
                ...state,
                updatingOnboardingVideo: true,
            };
        case USERTOUR_SET_ONBOARDING_VIDEO_SUCCESS:
            return {
                ...state,
                updatingOnboardingVideo: false,
            };
        case USERTOUR_SET_ONBOARDING_VIDEO_FAIL:
            return {
                ...state,
                updatingOnboardingVideo: false,
            };
        case USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_REQUEST:
            return {
                ...state,
                resettingOnboardingGuideStatus: true
            };
        case USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_SUCCESS:
            return {
                ...state,
                resettingOnboardingGuideStatus: false,
                resetData: action.payload?.data
            };
        case USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_FAIL:
            return {
                ...state,
                resettingOnboardingGuideStatus: false
            };
        case USERTOUR_SET_OPEN_TOUR:
            return {
                ...state,
                openTour: action.payload
            };
        default:
            return state;

    }
}
