import React from 'react';
import {Bar} from 'react-chartjs-2';
import {useTranslation} from "react-i18next";

const options = {
    maintainAspectRatio: false,
    scales: {
        y: {
            ticks: {
                beginAtZero: true,
                // callback: function (label, index, labels) {
                //     return `${label}%`;
                // }
                precision: 0
            }
        },
    },
};

const CampaignBarChart = ({campaignNames, response, opened, reported, clicked, submitted, passed}) => {
    const {t} = useTranslation();
    const data = {
        labels: campaignNames,
        datasets: [
            {
                type: 'bar',
                label: t('custom_dashboard_FortiPhish_Overview_campaign_stat_noresp', 'No response'),
                data: response,
                backgroundColor: '#5FBE74',
                stack: 'single',
            },
            {
                type: 'bar',
                label: t('custom_dashboard_FortiPhish_Overview_campaign_stat_open', 'Open only'),
                data: opened,
                backgroundColor: '#F1BE42',
                stack: 'single',
            },
            {
                type: 'bar',
                label: t('custom_dashboard_FortiPhish_Overview_campaign_stat_reported', 'Reported'),
                data: reported,
                backgroundColor: '#17E142',
                stack: 'single',
            },
            {
                type: 'bar',
                label: t('custom_dashboard_FortiPhish_Overview_campaign_stat_link_clicked', 'Link Clicked'),
                data: clicked,
                backgroundColor: '#EF2121',
                stack: 'single',
            },
            {
                type: 'bar',
                label: t('custom_dashboard_FortiPhish_Overview_campaign_stat_submitted', 'Submitted'),
                data: submitted,
                backgroundColor: '#AB0000',
                stack: 'single',
            },

            // {
            //     type: 'line',
            //     label: 'Passed',
            //     borderColor: '#52C41A',
            //     borderWidth: 2,
            //     fill: false,
            //     data: passed,
            // }
        ],
    };
    return (
        <Bar data={data} options={options}/>
    )
};

export default CampaignBarChart;


function getGradientDefault(ctx, chartArea) {
    let gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, 'rgba(207, 216, 220, 0.6)');
    gradient.addColorStop(0.5, 'rgba(207, 216, 220, 0.8)');
    gradient.addColorStop(1, 'rgba(207, 216, 220, 1)');
    return gradient;
}

function getGradientPink(ctx, chartArea) {
    let gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, 'rgba(239, 148, 148, 0.6)');
    gradient.addColorStop(0.5, '#EF9494');
    gradient.addColorStop(1, '#ea6161');
    return gradient;
}

function getGradientRed(ctx, chartArea) {
    let gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, 'rgba(255, 1, 1, 0.6)');
    gradient.addColorStop(0.5, 'rgba(255, 1, 1, 0.8)');
    gradient.addColorStop(1, 'rgba(255, 1, 1, 1)');
    return gradient;
}

function getGradientDarkRed(ctx, chartArea) {
    let gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, 'rgba(171, 0, 0, 0.6)');
    gradient.addColorStop(0.5, 'rgba(171, 0, 0, 0.8)');
    gradient.addColorStop(1, 'rgba(171, 0, 0, 1)');
    return gradient;
}

function getGradientGreen(ctx, chartArea) {
    let gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, 'rgba(95, 190, 116, 0.6)');
    gradient.addColorStop(0.5, 'rgba(95, 190, 116, 0.8)');
    gradient.addColorStop(1, 'rgba(95, 190, 116, 1)');
    return gradient;
}

function getGradientLightGreen(ctx, chartArea) {
    let gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, 'rgba(23, 225, 66, 0.6)');
    gradient.addColorStop(0.5, 'rgba(23, 225, 66, 0.8)');
    gradient.addColorStop(1, 'rgba(23, 225, 66, 1)');
    return gradient;
}
