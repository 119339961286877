import React, {useEffect, useState} from 'react';
import {Button, Form, message, Tooltip, Select, Switch, Modal, notification, Space, Spin} from 'antd';
import PasswordResetForm from "../components/PasswordResetForm";
import {update_user_fields} from "../../shared/service";
import {connect, useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {sanitizeLangStr, switchLanguage} from "../../shared/helper";
import Variable from "../../shared/constants/variables";
import {
    clearRedirectNotification,
    forceReloadCampaigns,
    local_user_get_settings_data,
    reload_site_info
} from "../../shared/rootActions";
import dayjs from "dayjs";
import {useHistory, useLocation} from "react-router";
import {DEFAULT_SERVER_TIMEZONE} from "../../shared/constants/globalConstants";
import {LoadingOutlined} from "@ant-design/icons";

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

const {confirm, info} = Modal;

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

const layout = {
    labelCol: {span: 3},
    wrapperCol: {span: 12},
};

export const LANGUAGE_LABELS = {
    en: 'English (UK)',
    en_au: 'English (Australia)',
    en_ca: 'English (Canada)',
    en_us: 'English (US)',
    fr: 'Français',
    fr_ca: 'Français (Canada)',
    es: 'Español (Europe)',
    es_mx: 'Español',
    pt: 'Português (Portugal)',
    pt_br: 'Português (Brasil)',
    it: 'Italiano',
    de: 'Deutsch',
    ja: '日本語',
}

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

dayjs.extend(utc);
dayjs.extend(timezone);

function SettingPage({local_user_get_settings_data, reload_site_info, isSiteAdmin, root}) {
    const {isSsoAdmin, redirect_notify, settingsData, siteInfo, tenantAdmin} = useSelector((state) => state?.root);
    const auth_type = siteInfo?.auth_type;
    const [mFAChecked, setMFAChecked] = useState(false);
    const lang = settingsData?.lang;
    const timezone = settingsData?.timezone;
    const serverTimezone = siteInfo?.timezone;
    const update_enabled = settingsData?.enabled || false;
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const isAdmin = siteInfo?.userissiteadmin || tenantAdmin || isSsoAdmin || false;
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const notification_key = 'redirect-notification';
    const {99: t99, ...timezoneOptions} = timezone?.options || {};

    let config = {
        className: 'update_settings',
        content: t('message_preferences_saving'),
        duration: 2
    };

    useEffect(() => {
        // Load Settings Data synchronously
        local_user_get_settings_data();
    }, []);

    useEffect(() => {
        let timeout;

        if (isAdmin && redirect_notify?.show) {
            if (redirect_notify.target === location?.pathname) {
                timeout = setTimeout(() => {
                    showRedirectNotification(redirect_notify?.returnurl);
                }, 3000);
            }
        }

        return () => {
            if (timeout) {
                notification.destroy(notification_key);
                clearTimeout(timeout);
            }
        };
    }, [redirect_notify]);

    useEffect(() => {
        setMFAChecked(Boolean(settingsData?.mfa_enabled));
    }, [settingsData?.mfa_enabled]);

    useEffect(() => {
        // Handle server timezone. Convert moodle default 99 to standard timezone (e.g. America/Toronto).
        timezone?.value && form.setFieldsValue({timezone: (timezone.value == '99' && serverTimezone) ? serverTimezone : timezone.value});
        lang?.value && form.setFieldsValue({lang: sanitizeLangStr(lang.value)});
    }, [timezone?.value, lang?.value]);

    const onFinish = (values) => {
        const key = 'update_settings';

        let config = {
            key,
            duration: 30,
            content: t('message_preferences_saving'),
        };

        message.loading(config);

        // Handle server timezone. Convert back to default 99
        if (values && values.timezone && serverTimezone) {
            values.timezone = values.timezone === serverTimezone ? '99' : values.timezone;
        }

        update_user_fields(values).then(res => {
            if (res?.success) {
                let {lang} = values;
                switchLanguage(lang);
                config.content = t('message_preferences_saved');
                config.duration = 2;
                message.success(config);
            } else {
                config.content = res?.message ? res.message : t('message_preferences_error');
                config.duration = 2;
                message.error(config);
            }
        }).finally(() => {
            // user language might be changed
            local_user_get_settings_data();
            reload_site_info();
            dispatch(forceReloadCampaigns());
        })
    };

    const showRedirectNotification = (returnurl = '') => {
        notification.info({
            key: notification_key,
            duration: 0,
            message: 'Back to Campaign Settings',
            description: (<Space direction={'vertical'}>
                <div>Click the button below to return to campaign settings.</div>
                <Button type={'primary'} size={'small'} onClick={() => {
                    returnurl ? history.push(returnurl) : history.goBack();
                }}>Back to Campaigns</Button>
            </Space>),
            onClose: () => {
                dispatch(clearRedirectNotification());
            },
        })
    };

    const handleMFASettingChange = (active) => {
        update_user_fields({profile_field_mfa_email: Number(active)}).then(response => {
            if (response.success) {
                setMFAChecked((active));
                config.content = t('message_preferences_saved');
                message.success(config);
            } else {
                config.content = t('message_preferences_error');
                message.error(config);
            }
        })
    };

    if (root.hasOwnProperty('loadingSettingsData') && root.loadingSettingsData) {
        return <Spin indicator={antIcon}/>;
    }

    return (
        <div className={'container setting_page'}>
            <div className='setting-section-container'>
                <div className='setting-section-head'>
                    <h5 className='setting-section-title'>{t('setting_page_account')}</h5>
                </div>
                <div className="setting_wrap">
                    <Form {...layout} form={form} name="settings" onFinish={onFinish}
                          validateMessages={validateMessages}>
                        <Form.Item name={['lang']} label={t('setting_page_preferredlanguage')} initialValue={'en'}>
                            {
                                lang?.options ? (
                                    <Select disabled={!update_enabled}>
                                        {Object.entries(lang.options).map((option, index) => {
                                                const sanitizedLang = sanitizeLangStr(option[0]);
                                                const langLabel = sanitizeLangStr(option[1]);

                                                if (sanitizedLang === 'es') {
                                                    return null;
                                                }

                                                return (<Select.Option key={index}
                                                                       className={`${sanitizedLang} stng_drpdwn_lng`}
                                                                       value={sanitizedLang}>
                                                    {
                                                        LANGUAGE_LABELS[sanitizedLang] || langLabel
                                                    }
                                                </Select.Option>)
                                            }
                                        )}
                                    </Select>
                                ) : (
                                    <Select disabled={!update_enabled}>
                                        <Select.Option value="en">English</Select.Option>
                                    </Select>
                                )
                            }

                        </Form.Item>
                        <Tooltip placement="top" title={timezoneOptions ? '' : t('setting_page_tooltip_timezone')}>
                            <Form.Item className='setting_page_timezone' name={['timezone']}
                                       label={t('setting_page_timezone')}
                                       initialValue={serverTimezone}
                            >
                                {
                                    timezoneOptions && serverTimezone ? (

                                        <Select showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                        >
                                            {Object.entries(timezoneOptions).map(([tz, value]) => {
                                                    return (<Select.Option key={tz}
                                                                           value={tz}>{`${value} (${dayjs().tz(tz).format('z')})`}</Select.Option>)
                                                }
                                            )}
                                        </Select>

                                    ) : (
                                        <Select disabled={true}>
                                            <Select.Option value={'America/Vancouver'}>America/Vancouver</Select.Option>
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Tooltip>
                        <Form.Item wrapperCol={{span: 6}} className={'save_changes_btn'}>
                            <Button className={'primary'} htmlType={'submit'} disabled={!update_enabled}>
                                {t('setting_page_button_save')}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                {
                    // based on isd-1803, currently there is only one security setting section - <reset password>,
                    // hide the whole security setting if <reset password> is disabled (auth_type === 'tenant_sso').
                    (auth_type !== 'tenant_sso') &&
                    <div className='setting-section-head'>
                        <h5 className='setting-section-title'>{t('setting_page_security')}</h5>
                    </div>
                }

                {
                    // based on isd-1803, currently there is only one security setting section - <reset password>,
                    // hide the whole security setting if <reset password> is disabled (auth_type === 'tenant_sso').
                    (auth_type !== 'tenant_sso') &&
                    <div className="setting-section-container">
                        {
                            (Variable.MFA_SETTING_AVAILABILITY && !isSiteAdmin) &&
                            <div className="setting_wrap setting_wrap_mfa">
                                <div className="setting_wrap_mfa_inner">
                                    { /*load the default value */}
                                    <span
                                        className={'label'}>{t('setting_page_mfa_setting', 'Two-Factor Authentication(2FA)')}</span>
                                    <Switch checked={mFAChecked} onChange={value => {
                                        confirm({
                                            content: `Are you sure to turn the MFA ${value ? 'on' : 'off'}?`,
                                            onOk() {
                                                handleMFASettingChange(value);
                                            },
                                            okText: 'Yes',
                                            onCancel() {
                                            },
                                        });
                                    }}/>
                                </div>
                            </div>
                        }
                        <div className="password_reset_wrap">
                            <PasswordResetForm/>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
    local_user_get_settings_data: () => dispatch(local_user_get_settings_data()),
    reload_site_info: () => dispatch(reload_site_info())
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);
