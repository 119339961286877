import React, {useEffect} from 'react';
import "../../admin/styles/dashboard/style.scss";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {loadCampaignStats} from "../redux/actions/dashboardActions";
import OverviewPanel from "../components/dashboard/OverviewPanel";
import CampaignDetailsTable from "../components/dashboard/CampaignDetailsTable";
import FortiPhishBlock from "../components/dashboard/FortiPhishBlock";

let isDemoAccount = false;

const Dashboard = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const dashboardStats = useSelector((state) => state.dashboardStats);
    const table_data = dashboardStats?.data;

    useEffect(() => {
        if (location?.pathname === '/admin' || location?.pathname === '/admin/') {
            history.replace('/admin/dashboard');
        }
        dispatch(loadCampaignStats());
    }, []);

    return (
        <>
            {
                table_data && (
                    <div className="admin-page dashboard-page">
                        <OverviewPanel data={table_data}/>
                        <CampaignDetailsTable />
                        <FortiPhishBlock isDemoAccount={isDemoAccount}/>
                    </div>
                )
            }
        </>
    );
}

export default Dashboard
