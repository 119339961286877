import React, {useEffect, useState} from 'react';
import {buildQuestionDataFromPlaintHTML} from "../../shared/helper";
import {LeftOutlined, MenuOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {useTranslation} from "react-i18next";

function Quiz({question, slot, processAttempt, ...stepInfo}) {
    const [q, setQ] = useState({
        answers: [],
        question: '',
        name: '',
        value: -1,
        sequencecheck: {},
        isMulti: false
    });
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (question && question.html) {
            setQ(buildQuestionDataFromPlaintHTML(question.html, i18n.language));
        }
    }, []);

    return (
        <div className="quiz_wrap_inner">
            <div className="quiz_title">
                <div className="icon">
                            <span className="square">
                                <MenuOutlined/>
                            </span>
                </div>
                {
                    (stepInfo.multipage) ?
                        <h4>{t('take_a_quiz_questionnumber')} {`${stepInfo.current}`}</h4>
                        :
                        <h4>{t('take_a_quiz_questionnumber')} {`${stepInfo.current}/${stepInfo.total}`}</h4>
                }
            </div>
            <div className="quiz_body">
                <p className="quiz_q">
                    {q.question}
                </p>
                <div className="quiz_a">
                    <ol>
                        {
                            q?.answers?.map((answer, key) => (
                                <li onClick={() => {
                                    let _value = q.isMulti ? String(key) : answer.value;
                                    setQ({...q, value: _value});
                                }} key={key}
                                    className={`${
                                        q.isMulti ?
                                            ((q.value == key) ? 'selected' : '') :
                                            ((answer.value === q.value) ? 'selected' : '')
                                    }`}>
                                    {answer.label}
                                </li>
                            ))
                        }
                    </ol>
                </div>
            </div>

            <div className="quiz_btn">
                {
                    (stepInfo.current > 1) &&
                    <Button className={'link'} icon={<LeftOutlined/>} onClick={() => {
                        processAttempt(q, slot, 0, -1);
                    }}>
                        {t('take_a_quiz_button_previous')}
                    </Button>
                }
                {
                    (stepInfo.current < stepInfo.total || (stepInfo.multipage && stepInfo.nextpage > 0)) &&
                    <Button className='default auto-width' onClick={() => {
                        processAttempt(q, slot, 0, 1);
                    }}>
                        {t('take_a_quiz_button_next')}
                    </Button>
                }
                {
                    ((stepInfo.current == stepInfo.total && !stepInfo.multipage) || (stepInfo.multipage && stepInfo.nextpage < 0)) &&
                    <Button className='default auto-width' onClick={() => {
                        let submitData = {...q};
                        processAttempt(submitData, slot, 1);
                    }}>
                        {t('take_a_quiz_button_finish')}
                    </Button>
                }
            </div>
        </div>
    );
}

export default Quiz;
