import {getPayload} from "../../../shared/rootActions";
import {
    DASHBOARD_CAMPAIGN_STATS_FAIL,
    DASHBOARD_CAMPAIGN_STATS_REQUEST,
    DASHBOARD_CAMPAIGN_STATS_SUCCESS,
    DASHBOARD_FORTIPHISH_DATA_FAIL,
    DASHBOARD_FORTIPHISH_DATA_REQUEST,
    DASHBOARD_FORTIPHISH_DATA_SUCCESS
} from "../actiontypes/dashboardConstants";
import {MOODLE_SERVICE_ENDPOINT} from "../../../shared/constants/globalConstants";
import {recursiveObjectParamConverter} from "../../../shared/helper";

export const loadCampaignStats = (departmentids = []) => async (dispatch) => {
    dispatch({type: DASHBOARD_CAMPAIGN_STATS_REQUEST});

    const queryParam = {
        ...recursiveObjectParamConverter({departmentids}),
        wsfunction: 'block_custom_dashboard_get_campaign_stats',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch ({
                type: DASHBOARD_CAMPAIGN_STATS_FAIL,
                payload: response_data,
            });
        } else {
            dispatch ({
                type: DASHBOARD_CAMPAIGN_STATS_SUCCESS,
                payload: response_data
            });
            return response_data;
        }
    } catch (error) {
        dispatch ({
            type: DASHBOARD_CAMPAIGN_STATS_FAIL,
            payload: error,
        });
    }
};
export const loadPhishData = (startdate, enddate) => async (dispatch) => {
    dispatch({type: DASHBOARD_FORTIPHISH_DATA_REQUEST});

    const queryParam = {
        startdate,
        enddate,
        wsfunction: 'local_fortiphish_get_campaign_reports',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch ({
                type: DASHBOARD_FORTIPHISH_DATA_FAIL,
                payload: response_data,
            });
        } else {
            dispatch ({
                type: DASHBOARD_FORTIPHISH_DATA_SUCCESS,
                payload: response_data
            });
        }
    } catch (error) {
        dispatch ({
            type: DASHBOARD_FORTIPHISH_DATA_FAIL,
            payload: error,
        });
    }
};
