import React, {useEffect, useState} from 'react';
import {buildQuestionDataFromPlaintHTML} from "../../shared/helper";
import {useTranslation} from "react-i18next";

const QuestionFeedback = ({question}) => {
    const [q, setQ] = useState({});
    const {i18n} = useTranslation();

    useEffect(() => {
        if (question && question.html) {
            setQ(buildQuestionDataFromPlaintHTML(question.html, i18n.language));
        }
    }, []);

    return (
        <>
            <li className="quiz_item">
                <div className="quiz_body">
                    <p className="quiz_q">
                        {q.question}
                    </p>
                    <div className="quiz_a">
                        <ol>
                            {
                                q?.answers?.map((answer, key) => (
                                    <li key={key}
                                        className={answer.state}>{answer.label}</li>
                                ))
                            }
                        </ol>
                    </div>
                </div>
            </li>
        </>
    );
};

export default QuestionFeedback;
