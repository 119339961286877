import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Badge, Avatar, Menu, Dropdown, Layout, Modal, Button, Tooltip, Space} from "antd";
import {Link, NavLink, useHistory, useLocation} from "react-router-dom";
import {
    BellOutlined, LeftOutlined,
    MenuOutlined,
    SettingOutlined,
    SolutionOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Form, Input} from 'antd';
import {useTranslation} from "react-i18next";
import {FORTINET_SUPPORT_URL} from "../constants/globalConstants";
import provider from "../provider";
import {loginAs} from "../../admin/redux/actions/accountMgmtActions";
import {CustomerSupportIcon, LearnerIcon} from "../../admin/components/CustomIcons";
import {usernameInitial} from "../utility";
import {sso_logout_required} from "../helper";

const {Header: AntHeader} = Layout;

function mapStateToProps(state) {
    return {...state.root};
}

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

function Header({siteInfo, isSiteAdmin, header_logo, onCollapse, collapsed}) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoginasLogoutModalOpen, setIsLoginasLogoutModalOpen] = useState(false);
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const dispatch = useDispatch();
    const loginasInfo = useSelector((state) => state?.root?.siteInfo?.loginas_info);
    const auth_type = useSelector((state) => state?.root?.siteInfo?.auth_type);

    const adminRoute = location?.pathname?.startsWith('/admin') || false;

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const logout = () => {
        // TODO: logout the 'loginas' user for now - implement a design later
        if (loginasInfo && loginasInfo.loginas) {
            handleLoginasLogout();
            return;
        }

        if (isSiteAdmin || sso_logout_required(auth_type)) {
            provider.logoutToHost();
        } else {
            provider.logoutInApp();
        }
    }

    const showLoginasLogoutModal = () => {
        setIsLoginasLogoutModalOpen(true);
    }

    const handleLoginasLogoutCancel = () => {
        setIsLoginasLogoutModalOpen(false);
    }

    function handleLoginasLogout() {
        let {loginasuser, loginbyuser} = loginasInfo;

        if (!loginasuser.userid || !loginbyuser.userid) {
            // or directly logout
            return;
        }
        setIsLoginasLogoutModalOpen(false);
        dispatch(loginAs(loginbyuser.userid, loginasuser.userid, true));
    }

    const goAdmin = () => {
        history.push('/admin/dashboard');
    };

    return (
        <AntHeader className="header">
            <div className="corner_logo_wrap">
                <div className={`hamburger ${collapsed ? 'collapsed' : ''}`} onClick={onCollapse}>
                    <div className="hamburger_icon">
                        <MenuOutlined/>
                    </div>
                </div>
                <NavLink to={adminRoute ? '/admin' : '/'} activeClassName="active">
                    <div className="logo"
                         style={{backgroundImage: `url('${header_logo}')`}}/>
                </NavLink>
                {(loginasInfo && loginasInfo.loginas) &&
                (<div className="sub-account-switch-button">
                    <Button onClick={showLoginasLogoutModal}>
                        <Space>
                            <LeftOutlined style={{display: 'flex'}}/>
                            Back to main account
                        </Space>
                    </Button>
                </div>)
                }
            </div>


            <div className="avatar">
                <Badge disabled dot={false}>
                    <BellOutlined/>
                </Badge>
                <Dropdown trigger={['hover']} overlay={(
                    <Menu className={"profile_dropdown"}>
                        <Menu.Item key="0" onClick={() => {
                            setIsModalVisible(true);
                        }}>
                            <div><UserOutlined/>{t('pageutilities_avatar_profile')}
                            </div>
                        </Menu.Item>
                        <Menu.Item key="1">
                            <Link to={`/setting`}><SettingOutlined/>{t('pageutilities_avatar_settings')}</Link>
                        </Menu.Item>
                        {
                            isSiteAdmin && (
                                adminRoute ? (
                                        <>
                                            <Menu.Item key="customer_support" onClick={() => {
                                                window.open(FORTINET_SUPPORT_URL, '_blank');
                                            }}>
                                                <CustomerSupportIcon/> {t('pageutilities_avatar_getsupport')}
                                            </Menu.Item>
                                            <Menu.Item key="learner" onClick={() => {
                                                history.push('/my')
                                            }}>
                                                <LearnerIcon/> {t('pageutilities_avatar_visitlearnerexperience')}
                                            </Menu.Item>
                                        </>)
                                    :
                                    (
                                        <Menu.Item key="admin" onClick={goAdmin}>
                                            <SolutionOutlined/> {t('pageutilities_avatar_gotoadmin')}
                                        </Menu.Item>
                                    )
                            )
                        }
                        <Menu.Item key="2" onClick={logout}>
                            {t('pageutilities_avatar_logout')}
                        </Menu.Item>
                    </Menu>
                )}>
                    <div className="avatar_wrap">
                        <Avatar style={{verticalAlign: 'middle'}} size="large">
                            {usernameInitial(siteInfo?.firstname, siteInfo?.lastname)}
                        </Avatar>
                        <div className="avatar_right">
                            <h6>{`${siteInfo?.firstname} ${siteInfo?.lastname}`}</h6>
                            <div className="email_txt"><a href="">{siteInfo?.email}</a></div>
                        </div>
                    </div>
                </Dropdown>
            </div>

            <Modal className={'profile_modal'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                   footer={false} width={750}>
                <div className="profile_modal_wrap">
                    <div className="avatar_wrap">
                        <Avatar style={{verticalAlign: 'middle'}} size={99}>
                            {usernameInitial(siteInfo?.firstname, siteInfo?.lastname)}
                        </Avatar>
                    </div>
                    <div className="form_wrap">
                        <Form {...layout} name="nest-messages" validateMessages={validateMessages}>
                            <Form.Item initialValue={siteInfo?.email || siteInfo?.username} name={['user', 'email']}
                                       label={t('resetpassword_useanme')} rules={[{type: 'email'}]}>
                                <Input disabled={true}/>
                            </Form.Item>
                            <Form.Item initialValue={siteInfo?.firstname} name={['user', 'firstname']}
                                       label={t('pageutilities_avatar_profile_firstname')}>
                                <Input disabled={true}/>
                            </Form.Item>
                            <Form.Item initialValue={siteInfo?.lastname} name={['user', 'lastname']}
                                       label={t('pageutilities_avatar_profile_lastname')}>
                                <Input disabled={true}/>
                            </Form.Item>
                            <Form.Item initialValue={siteInfo?.company} name={['user', 'company']}
                                       label={t('studentapp_sac_profile_org')}>
                                <Input disabled={true}/>
                            </Form.Item>
                            <Form.Item initialValue={siteInfo?.manager_email} name={['user', 'manager_email']}
                                       label={t('studentapp_sac_profile_manager_email')}>
                                <Input disabled={true}/>
                            </Form.Item>
                            <Form.Item wrapperCol={{span: 24, offset: 8}} style={{display: 'none'}}>
                                <Tooltip placement="top" title={'Unavailable for the beta.'}>
                                    <Button type="primary" htmlType="submit" disabled>
                                        Save Changes
                                    </Button>
                                </Tooltip>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal okText='Logout' title='Back to Main Account'
                   open={isLoginasLogoutModalOpen} onOk={handleLoginasLogout} onCancel={handleLoginasLogoutCancel}
                   width={376}
                   okButtonProps={{className: "btn-wdt-md"}}
                   cancelButtonProps={{className: "ant-btn-secondary btn-wdt-sm"}}
            >
                <p>Do you want to logout current account and back to your main account?</p>
            </Modal>
        </AntHeader>
    );
}

export default connect(
    mapStateToProps,
)(Header);
