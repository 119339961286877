import React, {useEffect, useState} from "react";
import {Button, Drawer, Menu, Modal, Progress} from "antd";
import Icon from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {
    BulbOutlined,
    CheckCircleFilled,
    CheckOutlined,
    CloseOutlined,
    MailOutlined,
    ShrinkOutlined
} from "@ant-design/icons";
import ReactPlayer from 'react-player'
import {
    getUserTourData,
    setOnboardingVideoWatched,
    setOpenTour
} from "../../redux/actions/userToursActions";
import {useHistory} from "react-router-dom";
import {setDomReady} from "../../../shared/rootActions";


const UserTourWidget = ({widgetVisible, setWidgetVisible}) => {
    const [openTourDrawer, setOpenTourDrawer] = useState(false);
    const [tourProgress, setTourProgress] = useState(0);
    const {userTourData, resetData} = useSelector((state) => state.userTourInfo || {});

    const dispatch = useDispatch();

    const [openKeys, setOpenKeys] = React.useState(['quickstartsubmenu1']);
    const [videoWatched, setVideoWatched] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const ignore_list = ['Deploy training campaign 1', 'New Reports']; // sub menu items having no headings
    const [iframeSrc, setIframeSrc] = useState('');
    const [playVideo, setPlayVideo] = useState(false);

    let history = useHistory();

    const handleTourDrawerOpen = () => {
        dispatch(getUserTourData()).then(userTourData => {
            showTourDrawer(userTourData);
        });
    }

    const showTourDrawer = (_userTourData = null) => {
        let tempTourData = _userTourData || userTourData;
        let finalCount = tempTourData.reduce((grandTotal, menu) => {
            if (menu.type === 'video') {
                if (menu.isCompleted) grandTotal.completed = grandTotal.completed ? ++grandTotal.completed : 1;
                grandTotal.total = grandTotal.total ? ++grandTotal.total : 1;
                return grandTotal;
            } else {
                return Object.values(menu.submenu).reduce((count, tour) => {
                    if (tour.isCompleted) count.completed = count.completed ? ++count.completed : 1;
                    count.total = count.total ? ++count.total : 1;
                    return count;
                }, grandTotal);
            }
        }, {});
        setTourProgress((finalCount.completed / finalCount.total) * 100);
        setOpenTourDrawer(true);
    };

    const onCloseTourDrawer = () => {
        setOpenTourDrawer(false);
    };

    const hideTourWidget = () => {
        setOpenTourDrawer(false);
        setWidgetVisible(false);
    };

    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    };

    const handleOnboardAccept = () => {
        setPlayVideo(false);
        setModalVisible(false);
        setOpenKeys(['quickstartsubmenu-1']);
        dispatch(setOpenTour(true));
        history.push('/admin/domains');
    }

    const handleOnboardCancel = () => {
        setModalVisible(false);
    }

    const handleVideoWatched = () => {
        dispatch(setOnboardingVideoWatched())
        setVideoWatched(true);
    }

    const handleRedirectUserGuide = (link, guide_id) => {
        dispatch(setOpenTour(false));
        setOpenTourDrawer(false);
        let redirect;

        try {
            redirect = new URL(link);
        } catch (e) {
            console.error(e);
        }

        const pathname = window.location.pathname;
        // stay on the current page otherwise reset the dom ready
        if (pathname.includes(redirect.pathname)) {
            dispatch(setOpenTour(true));
            dispatch(setDomReady(true));
        } else {
            dispatch(setDomReady(false)).then(r => {
                dispatch(setOpenTour(true));
            });
            history.push(redirect.pathname);
        }
    }

    const handlePlayVideo = () => {
        setPlayVideo(true);
        setModalVisible(true);
    };

    useEffect(() => {
        if (resetData) {
            window.location.href = resetData;
        }
    }, [resetData]);

    useEffect(() => {
        if (modalVisible === false && videoWatched === true) {
            setOpenKeys(['quickstartsubmenu-1']);
        }
    }, [modalVisible]);

    useEffect(() => {
        if (userTourData) {
            setMenuItems(userTourData.map((item, id) => {
                let count = 0;
                let finished = 0;
                if (item.type === 'video') {
                    count++;
                    if (item.isCompleted) finished++;
                    setIframeSrc(item.link);
                    return {
                        key: id,
                        icon: item.isCompleted
                            ? <CheckCircleFilled className={'completed'}/>
                            : <CheckCircleFilled/>,
                        label: (<>
                            <Button onClick={handlePlayVideo} className={'video-button'}>
                                {item.heading}
                            </Button>
                        </>),
                    };
                } else {
                    let children = Object.values(item.submenu).map((submenu, sid) => {
                        count++;
                        if (submenu.isCompleted) finished++;
                        let submenuIcon = submenu.isCompleted === true
                            ? <CheckOutlined className="completed"/>
                            : <CheckOutlined/>;
                        let icon = ignore_list.indexOf(submenu.heading) > -1 ? null : submenuIcon
                        let noheadingClass = ignore_list.indexOf(submenu.heading) > -1 ? 'no-heading' : null;

                        return {
                            key: id + '-' + sid,
                            icon: icon ? submenuIcon : "",
                            label: (<>
                                <div className="quickstartsubmenu-text">
                                    <h4 className={noheadingClass}>{submenu.heading}</h4>
                                    <p className={noheadingClass}>
                                        {submenu.paragraph}&nbsp;
                                        <a
                                            className="showme_link" href='#'
                                            onClick={e => {
                                                if (submenu.link) {
                                                    handleRedirectUserGuide(submenu.link, submenu.guideId);
                                                } else {
                                                    e.preventDefault();
                                                }
                                            }}>
                                            <span>Show me</span>
                                        </a>
                                    </p>
                                </div>
                            </>)
                        };
                    })
                    return {
                        key: id,
                        icon: count === finished
                            ? <CheckCircleFilled className="completed"/>
                            : <CheckCircleFilled/>,
                        label: item.heading,
                        children
                    };
                }
            }));
        }
    }, [userTourData]);

    return (
        <>
            <div id={'onboarding-widget'} className={'user-tour-widget'}>
                <Button
                    icon={<BulbOutlined/>}
                    className={'fixed-button'}
                    onClick={handleTourDrawerOpen}
                >
                    Getting Started <CloseOutlined style={{fontSize: 12}} onClick={() => hideTourWidget()}/>
                </Button>
            </div>
            <Drawer
                className={'user-tour-drawer'}
                title={(<><BulbOutlined/> Getting Started</>)}
                placement={'right'}
                width={'360px'}
                onClose={onCloseTourDrawer}
                closeIcon={<ShrinkOutlined/>}
                open={openTourDrawer}
                mask={false}
                closable={true}
                footer={<>
                    <a href="mailto:infosec_awareness@fortinet.com" target="_blank"><MailOutlined/>Need help?</a>
                    {
                        widgetVisible
                            ? <button onClick={() => hideTourWidget()}>
                                Hide Getting Started
                            </button>
                            : ""
                    }
                </>}
            >
                <Progress
                    percent={tourProgress}
                    showInfo={false}
                    strokeColor={'#5db082'}
                    className={'tour-progress-bar'}
                />
                <Menu
                    mode="inline"
                    key="Onboarding_menu"
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    items={menuItems}
                    className={'tour-menu'}
                />
            </Drawer>
            <Modal
                className='onboarding-video-modal'
                centered
                width='854px'
                height={'567px'}
                open={modalVisible}
                onOk={handleOnboardAccept}
                onCancel={handleOnboardCancel}
                maskStyle={{background: '#CDCDCD'}}
                destroyOnClose={true}
                closeIcon={<Icon
                    component={() => (
                        <img alt=''
                             src='https://res.cloudinary.com/dx55oi3py/image/upload/v1639512587/infosec/videocloseIcon.png'/>
                    )}/>}
                footer={[
                    <Button key="submit"
                            type="primary"
                            className="take-the-tour-btn ant-btn-primary btn-wdt-md"
                            onClick={handleOnboardAccept}>
                        Take The Tour
                    </Button>,
                    <Button key="back"
                            className={'cancel-tour-btn ant-btn-secondary btn-wdt-md'}
                            onClick={handleOnboardCancel}>
                        Explore by Myself
                    </Button>
                ]}
            >
                <div className="onboarding-modal-text">
                    <h3>Welcome! Let’s get you settled in.</h3>
                    <p>Get started with this onboarding video - we will quickly show you the setting up
                        process.</p>
                    <div className="player-wrap">
                        <ReactPlayer
                            width={'514.25px'}
                            height={'311.47px'}
                            playing={playVideo}
                            onPlay={() => {
                                handleVideoWatched();
                            }}
                            controls
                            url={iframeSrc || 'https://ftntpackcacentral1.s3.ca-central-1.amazonaws.com/AdminPortal_Short.mp4'}
                        />
                    </div>
                </div>
            </Modal>
        </>

    );
};

export default UserTourWidget;
