import {
    GET_SETTINGS_DATA_FAIL,
    GET_SETTINGS_DATA_REQUEST,
    GET_SETTINGS_DATA_SUCCESS,
    LOAD_SITE_INFO,
    LOADED_CAMPAIGNS,
    LOADED_PARENT_SITE_CONFIG,
    LOADED_SITE_CONFIG,
    LOADED_SITE_WHITE_LABEL,
    STORE_WS_TOKEN,
    LOADED_TENANT,
    START_LOAD_CAMPAIGNS,
    FORCE_CAMPAIGNS_RELOAD,
    SET_TENANTADMIN,
    SETTINGS_RESET_PASSWORD_TOKEN_REQUEST,
    SETTINGS_RESET_PASSWORD_TOKEN_SUCCESS,
    SETTINGS_RESET_PASSWORD_TOKEN_FAIL,
    SET_DOM_READY,
    RESET_AUTO_REDIRECTION,
    EXTEND_TOKEN_VALIDITY_REQUEST,
    EXTEND_TOKEN_VALIDITY_FAIL,
    EXTEND_TOKEN_VALIDITY_SUCCESS,
    LOADED_FORTICLOUD_DATA,
    CLEAR_MOODLE_NOTIFICATION,
    GET_SAC_COURSES_REQUEST,
    GET_SAC_COURSES_FAIL,
    GET_SAC_COURSES_SUCCESS,
    NOTIFICATION_ADD_REDIRECT, NOTIFICATION_CLEAR_REDIRECT, LOADING_TENANT
} from "./rootActionTypes";
import {call, encodeObjToParam, getSSORedirectionTimes, getUserToken, mapProgramArrayToObject} from "./helper";
import Variable from "./constants/variables";
import {themes} from "./ThemeContext";
import {loginRedirecting, ssoRedirecting} from "./provider";
import {MOODLE_SERVICE_ENDPOINT} from "./constants/globalConstants";
import variables from "./constants/variables";


export const campaign_get_user_campaigns = () => {
    return (dispatch, getState) => {
        const {root: rootState} = getState();
        if (rootState.loadingCampaigns) {
            return;
        }
        dispatch(startLoadCampaigns());

        return call('local_campaign_get_user_campaigns').then(r => {
            let campaigns = [];
            if (Array.isArray(r)) {
                campaigns = mapProgramArrayToObject(r)
            }
            dispatch(campaignsLoaded(campaigns));
            return r;
        })
    }
};

export const reload_site_info = () => {
    return async (dispatch) => {
        const wsToken = getUserToken();
        let siteInfo = await call('local_bridge_get_site_info', wsToken);
        if (siteInfo) {
            dispatch(siteInfoLoaded(siteInfo));
        }
    }
};

export const core_get_tenant_id = () => {
    return (dispatch) => {
        const wsToken = Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN;
        const SERVER_URL = Variable.MOODLE_HOST + '/webservice/rest/server.php';

        let queryParam = {
            domainname: window.location.hostname,
            moodlewsrestformat: 'json',
            wsfunction: 'local_user_get_tenant_id_by_domain',
            wstoken: wsToken
        };
        let query = encodeObjToParam(queryParam);
        return fetch(`${SERVER_URL}?${query}`).then(async res => res.json()).then(json => {
            if (!json.primarydomain || !json.status) {
                return null;
            }
            if (json.hasOwnProperty('tenantid')) {
                let tenantid = Number(json.tenantid);
                if (tenantid) {
                    return tenantid;
                }
            }

            return null;
        })
            .catch(e => {
                console.error(e);
                return false;
            });
    };
};

export const core_get_tenant_theme = (forceRoot = false) => {
    return (dispatch) => {
        dispatch(tenantLoading());
        // use logged in user's token to grab tenantid->theme otherwise use webservice user token for login/reset/forgot... auth pages
        const wsToken = forceRoot ? Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN : (getUserToken() || Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN);
        const SERVER_URL = Variable.MOODLE_HOST + '/webservice/rest/server.php';

        let queryParam = {
            domainname: window.location.hostname,
            moodlewsrestformat: 'json',
            wsfunction: 'local_bridge_get_style_info_by_domain',
            wstoken: wsToken
        };
        let query = encodeObjToParam(queryParam);

        return fetch(`${SERVER_URL}?${query}`).then(async res => res.json()).then(json => {
            if (json.current) {
                const _token = getUserToken();
                const _redirectTimes = getSSORedirectionTimes();

                // handle SSO auto-redirection if not logged in
                if (!_redirectTimes && !_token && json.hasOwnProperty('auto_sso_launch') && json.auto_sso_launch) {
                    const adminIDP = `${variables.LAUNCH_URL_PATH}?name=${json.current}`;
                    ssoRedirecting(adminIDP);
                    return json;
                }
            }

            if (json.current && json.current_info) {
                const defaultSetting = readFromSiteConfig(json.current_info);
                dispatch(siteConfigLoaded(defaultSetting));
            }

            if (json.parent && json.parent_info) {
                const defaultSetting = readFromSiteConfig(json.parent_info);
                dispatch(siteParentConfigLoaded(defaultSetting));
            }

            if (json.forticloud_common_data) {
                let forticloud_data = JSON.parse(json.forticloud_common_data);
                dispatch(fortiCloudDataLoaded(forticloud_data));
            }

            dispatch(siteWhiteLabelLoaded({
                current: json.has_white_label,
                parent: !!json.parent && !!json.parent_has_white_label
            }));

            dispatch(tenantLoaded(json.current, json.auto_sso_launch));

            return json;
        })
            .catch(e => {
                console.error(e);
                dispatch(tenantLoaded(null, false));
                return false;
            });
    };
};

export const core_get_tenant_parent = (tenantid) => {
    return (dispatch) => {
        const wsToken = Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN;
        const SERVER_URL = Variable.MOODLE_HOST + '/webservice/rest/server.php';

        let queryParam = {
            tenantid: tenantid,
            moodlewsrestformat: 'json',
            wsfunction: 'local_bridge_get_tenant_parent',
            wstoken: wsToken
        };
        let query = encodeObjToParam(queryParam);
        return fetch(`${SERVER_URL}?${query}`).then(async res => res.json()).then(json => {
            return json.parent ? Number(json.parent) : null;
        })
            .catch(e => {
                console.error(e);
                return false;
            });
    };
};

export const core_get_tenant_config = (tenantid) => {
    return (dispatch) => {
        if (!tenantid) {
            return null;
        }
        const wsToken = Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN;
        const SERVER_URL = Variable.MOODLE_HOST + '/webservice/rest/server.php';

        let queryParam = {
            tenantid: tenantid,
            moodlewsrestformat: 'json',
            wsfunction: 'local_fortinet_extend_get_tenant_style_info',
            wstoken: wsToken
        };
        let query = encodeObjToParam(queryParam);
        return fetch(`${SERVER_URL}?${query}`).then(async res => res.json()).then(json => {
            let defaultSetting = readFromSiteConfig(json);
            dispatch(siteConfigLoaded(defaultSetting));
            return defaultSetting;
        })
            .catch(e => {
                console.error(e);
                return false;
            });
    };
};

export const core_get_tenant_parent_config = (parent) => {
    return (dispatch) => {
        const wsToken = Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN;
        const SERVER_URL = Variable.MOODLE_HOST + '/webservice/rest/server.php';

        let queryParam = {
            tenantid: parent,
            moodlewsrestformat: 'json',
            wsfunction: 'local_fortinet_extend_get_tenant_style_info',
            wstoken: wsToken
        };
        let query = encodeObjToParam(queryParam);
        return fetch(`${SERVER_URL}?${query}`).then(async res => res.json()).then(json => {
            let defaultSetting = readFromSiteConfig(json);
            dispatch(siteParentConfigLoaded(defaultSetting));
            return defaultSetting;
        })
            .catch(e => {
                console.error(e);
                return false;
            });
    };
};

export const local_user_get_settings_data = () => {
    return (dispatch) => {
        const wsToken = getUserToken();
        const SERVER_URL = Variable.MOODLE_HOST + '/webservice/rest/server.php';

        dispatch({
            type: GET_SETTINGS_DATA_REQUEST
        });

        let queryParam = {
            moodlewsrestformat: 'json',
            wsfunction: 'local_user_get_settings_data',
            wstoken: wsToken
        };
        let query = encodeObjToParam(queryParam);
        return fetch(`${SERVER_URL}?${query}`).then(async res => res.json()).then(json => {
            Object.keys(json).map((item) => {
                if (json[item].options) {
                    json[item].options = JSON.parse(json[item].options);
                }
            });

            dispatch({
                type: GET_SETTINGS_DATA_SUCCESS,
                payload: json,
            });

            return json;
        })
            .catch(e => {
                window.console.error(e);
                dispatch({
                    type: GET_SETTINGS_DATA_FAIL,
                    payload: e,
                });
                return false;
            });
    };
};

export const setting_get_reset_password_token = (params = {}) => async (dispatch) => {
    dispatch({
        type: SETTINGS_RESET_PASSWORD_TOKEN_REQUEST,
    });

    const wsToken = getUserToken();
    const SERVER_URL = Variable.MOODLE_HOST + '/webservice/rest/server.php';

    let queryParam = {
        moodlewsrestformat: 'json',
        wsfunction: 'local_user_get_reset_password_token',
        wstoken: wsToken
    };

    let query = encodeObjToParam(queryParam);
    try {
        const response = await fetch(`${SERVER_URL}?${query}`);
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: SETTINGS_RESET_PASSWORD_TOKEN_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: SETTINGS_RESET_PASSWORD_TOKEN_SUCCESS,
                payload: response_data
            });
        }
    } catch (error) {
        dispatch({
            type: SETTINGS_RESET_PASSWORD_TOKEN_FAIL,
            payload: error,
        });
    }

};

export const extendTokenValidity = (userid, token) => async (dispatch) => {
    if (!userid || !token) {
        dispatch({
            type: EXTEND_TOKEN_VALIDITY_FAIL,
            payload: {
                success: false,
            }
        });
    }

    dispatch({type: EXTEND_TOKEN_VALIDITY_REQUEST});

    const queryParam = {
        userid,
        token,
        wsfunction: 'local_bridge_auth_extend_token_validity',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception || !response_data?.success) {
            dispatch({
                type: EXTEND_TOKEN_VALIDITY_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: EXTEND_TOKEN_VALIDITY_SUCCESS,
                payload: response_data
            });
        }
    } catch (error) {
        dispatch({
            type: EXTEND_TOKEN_VALIDITY_FAIL,
            payload: error,
        });
    }
};

function readFromSiteConfig(json) {
    let defaultSetting = {...themes.default};
    if (json.hasOwnProperty('files')) {
        let {files} = json;
        defaultSetting.login_background_img = files.loginbackground || defaultSetting.login_background_img;
        defaultSetting.favicon = files.favicon || defaultSetting.favicon;
        defaultSetting.login_logo = files.loginlogo || defaultSetting.login_logo;
        defaultSetting.header_logo = files.headerlogo || defaultSetting.header_logo;
    }
    if (json.hasOwnProperty('colors')) {
        let {colors} = json;
        defaultSetting.nav_bar_color = colors.drawer || defaultSetting.nav_bar_color;
        defaultSetting.primary_color = colors.button || defaultSetting.primary_color;
        defaultSetting.link_color = colors.primary || defaultSetting.link_color;
    }

    return defaultSetting;
}

export const siteInfoLoaded = (siteInfo) => ({
        type: LOAD_SITE_INFO,
        payload: siteInfo
    }
);

export const loadToken = (token) => ({
        type: STORE_WS_TOKEN,
        payload: token
    }
);

export const setAdmin = (isAdmin) => ({
        type: SET_TENANTADMIN,
        payload: isAdmin
    }
);

export const resetAutoRedirection = () => ({
        type: RESET_AUTO_REDIRECTION,
    }
);

export const campaignsLoaded = (campaigns = []) => (
    {
        type: LOADED_CAMPAIGNS,
        payload: campaigns
    }
);

export const siteConfigLoaded = (config) => ({
        type: LOADED_SITE_CONFIG,
        payload: config
    }
)
export const siteParentConfigLoaded = (config) => ({
        type: LOADED_PARENT_SITE_CONFIG,
        payload: config
    }
)

export const fortiCloudDataLoaded = (fortiCloudData) => ({
        type: LOADED_FORTICLOUD_DATA,
        payload: fortiCloudData
    }
)

export const siteWhiteLabelLoaded = (whiteLabelInfo) => ({
        type: LOADED_SITE_WHITE_LABEL,
        payload: whiteLabelInfo
    }
)

export const tenantLoaded = (tenantid, auto_launch_sso = false) => ({
        type: LOADED_TENANT,
        payload: {tenantid, auto_launch_sso}
    }
)


export const tenantLoading = () => ({
        type: LOADING_TENANT
    }
)

export const startLoadCampaigns = () => (
    {
        type: START_LOAD_CAMPAIGNS
    }
)

export const forceReloadCampaigns = () => ({
        type: FORCE_CAMPAIGNS_RELOAD,
    }
)

export const getPayload = (queryParam) => {
    //TODO
    // Check if queryParam is a valid object

    if (queryParam) {
        const wsToken = getUserToken();

        if (!wsToken) {
            loginRedirecting();
            return;
        }

        queryParam['wstoken'] = wsToken;
        queryParam['moodlewsrestformat'] = 'json';

        let query = encodeObjToParam(queryParam);

        return (
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: query,
            }
        );
    }
};

export const getPayloadFormData = (queryParam) => {
    if (queryParam) {
        const wsToken = getUserToken();

        if (!wsToken) {
            loginRedirecting();
            return;
        }

        queryParam['wstoken'] = wsToken;
        queryParam['moodlewsrestformat'] = 'json';

        const formData = new FormData();

        Object.keys(queryParam).forEach(key => {
            formData.append(key, queryParam[key]);
        });

        return (
            {
                method: 'POST',
                mode: 'cors',
                body: formData,
            }
        );
    }
};

export const setDomReady = (isReady) => async (dispatch) => {
    const readyPath = isReady ? window.location.pathname : '';
    dispatch({
        type: SET_DOM_READY,
        payload: readyPath
    });
};

export const clearMoodleNotification = (id) => (dispatch) => {
    dispatch({type: CLEAR_MOODLE_NOTIFICATION});

    if (id) {
        const queryParam = {
            id,
            wsfunction: 'local_bridge_mark_notification_as_served',
        };

        fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));

    }

    // TODO: Implement api call to clear notification at the backend
};

export const getSACCourses = () => async (dispatch) => {
    dispatch({
        type: GET_SAC_COURSES_REQUEST
    });

    const queryParam = {
        wsfunction: 'local_campaign_get_sac_courses',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: GET_SAC_COURSES_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: GET_SAC_COURSES_SUCCESS,
                payload: response_data
            });
        }
    } catch (error) {
        dispatch({
            type: GET_SAC_COURSES_FAIL,
            payload: error,
        });
    }
};

export const updateSACAccessTracker = (courseid, cmid) => {
    const queryParam = {
        courseid,
        cmid,
        wsfunction: 'local_campaign_update_sac_access_tracker',
    };

    try {
        fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
    } catch (error) {
        window.console.error(error);
    }
}

export const queueRedirectNotification = (target, returnurl) => async (dispatch) => {
    if (target) {
        dispatch({
            type: NOTIFICATION_ADD_REDIRECT,
            payload: {
                target,
                returnurl,
            }
        });
    }
};

export const clearRedirectNotification = () => async (dispatch) => {
    dispatch({type: NOTIFICATION_CLEAR_REDIRECT})
};

