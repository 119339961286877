export const USER_USERLIST_REQUEST = 'USER_USERLIST_REQUEST';
export const USER_REFRESH_USERLIST_REQUEST = 'USER_REFRESH_USERLIST_REQUEST';
export const USER_REFRESH_USERLIST_SUCCESS = 'USER_REFRESH_USERLIST_SUCCESS';
export const USER_REFRESH_USERLIST_FAIL = 'USER_REFRESH_USERLIST_FAIL';
export const DEPARTMENT_LEADER_LIST_REQUEST = 'DEPARTMENT_LEADER_LIST_REQUEST';
export const DEPARTMENT_LEADER_LIST_SUCCESS = 'DEPARTMENT_LEADER_LIST_SUCCESS';
export const DEPARTMENT_LEADER_LIST_FAIL = 'DEPARTMENT_LEADER_LIST_FAIL';
export const USER_SAVE_USER_REQUEST = 'USER_SAVE_USER_REQUEST';
export const USER_SAVE_USER_SUCCESS = 'USER_SAVE_USER_SUCCESS';
export const USER_SAVE_USER_FAIL = 'USER_SAVE_USER_FAIL';
export const USER_SAVE_USER_RESET = 'USER_SAVE_USER_RESET';

export const USER_DELETE_USER_SUCCESS = 'USER_DELETE_USER_SUCCESS';
export const USER_DELETE_USER_REQUEST = 'USER_DELETE_USER_REQUEST';
export const USER_DELETE_USER_FAIL = 'USER_DELETE_USER_FAIL';
export const USER_DELETE_USER_RESET = 'USER_DELETE_USER_RESET';
export const USER_BULK_ASSIGN_DEPARTMENT_LEADER_REQUEST = 'USER_BULK_ASSIGN_DEPARTMENT_LEADER_REQUEST';
export const USER_BULK_ASSIGN_DEPARTMENT_LEADER_SUCCESS = 'USER_BULK_ASSIGN_DEPARTMENT_LEADER_SUCCESS';
export const USER_BULK_ASSIGN_DEPARTMENT_LEADER_FAIL = 'USER_BULK_ASSIGN_DEPARTMENT_LEADER_FAIL';
export const USER_BULK_ASSIGN_DEPARTMENT_LEADER_RESET = 'USER_BULK_ASSIGN_DEPARTMENT_LEADER_RESET';

export const USER_UPLOAD_USERS_REQUEST = 'USER_UPLOAD_USERS_REQUEST';
export const USER_UPLOAD_USERS_SUCCESS = 'USER_UPLOAD_USERS_SUCCESS';
export const USER_UPLOAD_USERS_FAIL = 'USER_UPLOAD_USERS_FAIL';
export const USER_UPLOAD_USERS_RESET = 'USER_UPLOAD_USERS_RESET';

export const USER_MODAL_CONTROL = 'USER_MODAL_CONTROL';
export const USER_SELECTION_UPDATE = 'USER_SELECTION_UPDATE';

export const USER_SEARCH_BY_DEPARTMENT = 'USER_SEARCH_BY_DEPARTMENT';
export const USER_SEARCH_BY_TITLE = 'USER_SEARCH_BY_TITLE';
export const USER_SEARCH_BY_NAME_OR_EMAIL = 'USER_SEARCH_BY_NAME_OR_EMAIL';

export const LDAP_USERLIMIT_REACHED_REQUEST = "LDAP_USERLIMIT_REACHED_REQUEST";
export const LDAP_USERLIMIT_REACHED_SUCCESS = "LDAP_USERLIMIT_REACHED_SUCCESS";
export const LDAP_USERLIMIT_REACHED_FAIL = "LDAP_USERLIMIT_REACHED_FAIL";
export const LDAP_PAUSE_ALL_CONFIG_REQUEST = "LDAP_PAUSE_ALL_CONFIG_REQUEST";
export const LDAP_PAUSE_ALL_CONFIG_SUCCESS = "LDAP_PAUSE_ALL_CONFIG_SUCCESS";
export const LDAP_PAUSE_ALL_CONFIG_FAIL = "LDAP_PAUSE_ALL_CONFIG_FAIL";
export const LDAP_PAUSE_SINGLE_CONFIG_REQUEST = "LDAP_PAUSE_SINGLE_CONFIG_REQUEST";
export const LDAP_PAUSE_SINGLE_CONFIG_SUCCESS = "LDAP_PAUSE_SINGLE_CONFIG_SUCCESS";
export const LDAP_PAUSE_SINGLE_CONFIG_FAIL = "LDAP_PAUSE_SINGLE_CONFIG_FAIL";
export const LDAP_PAUSE_SINGLE_CONFIG_RESET = "LDAP_PAUSE_SINGLE_CONFIG_RESET";
export const LDAP_GET_SERVER_LIST_REQUEST = "LDAP_GET_SERVER_LIST_REQUEST";
export const LDAP_GET_SERVER_LIST_SUCCESS = "LDAP_GET_SERVER_LIST_SUCCESS";
export const LDAP_GET_SERVER_LIST_FAIL = "LDAP_GET_SERVER_LIST_FAIL";
export const LDAP_LOAD_CONFIG_REQUEST = "LDAP_LOAD_CONFIG_REQUEST";
export const LDAP_LOAD_CONFIG_SUCCESS = "LDAP_LOAD_CONFIG_SUCCESS";
export const LDAP_LOAD_CONFIG_FAIL = "LDAP_LOAD_CONFIG_FAIL";
export const LDAP_LOAD_CONFIG_RESET = "LDAP_LOAD_CONFIG_RESET";
export const LDAP_DELETE_CONFIG_REQUEST = "LDAP_DELETE_CONFIG_REQUEST";
export const LDAP_DELETE_CONFIG_SUCCESS = "LDAP_DELETE_CONFIG_SUCCESS";
export const LDAP_DELETE_CONFIG_FAIL = "LDAP_DELETE_CONFIG_FAIL";
export const LDAP_DELETE_CONFIG_RESET = "LDAP_DELETE_CONFIG_RESET";
export const LDAP_SAVE_CONFIG_REQUEST = "LDAP_SAVE_CONFIG_REQUEST";
export const LDAP_SAVE_CONFIG_SUCCESS = "LDAP_SAVE_CONFIG_SUCCESS";
export const LDAP_SAVE_CONFIG_FAIL = "LDAP_SAVE_CONFIG_FAIL";
export const LDAP_SAVE_CONFIG_RESET = "LDAP_SAVE_CONFIG_RESET";
export const LDAP_SYNC_USERS_REQUEST = "LDAP_SYNC_USERS_REQUEST";
export const LDAP_SYNC_USERS_SUCCESS = "LDAP_SYNC_USERS_SUCCESS";
export const LDAP_SYNC_USERS_FAIL = "LDAP_SYNC_USERS_FAIL";
export const LDAP_SYNC_USERS_RESET = "LDAP_SYNC_USERS_RESET";

