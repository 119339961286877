import React, {useEffect, useState} from 'react';
import {
    CheckCircleFilled,
    DownCircleTwoTone,
    ClockCircleOutlined,
    PlayCircleOutlined, UpCircleTwoTone,
} from "@ant-design/icons";
import {Button, Collapse} from "antd";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {debounce, getCourseShortName} from "../../shared/helper";
import i18next from "i18next";

function Modules(props) {
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();
    let {courses = [], programid, progress} = props;
    const initialShowCount = 5;
    const [showCount, setShowCount] = useState(initialShowCount);
    const [showMore, setShowMore] = useState(false);
    const isProgramDetail = !!location?.pathname?.includes('/programs');
    const showDiff = courses?.length - showCount;

    useEffect(() => {
        if (courses?.length) {
            setShowMore(showDiff > 0);
        }
    }, [showCount]);

    function jumpIntoCourse(course) {
        history.push(`/programs/${programid}/courses/${course.courseid}`);
    }

    const ModuleSummary = ({course, firstItem}) => {
        let {video_module} = course;
        let videoCompleted = false;
        let showButton = firstItem;
        if (progress > 0) {
            // Campaign in progress. only show buttons for modules in progress.
            showButton = course?.isenrolled && (course?.lastaccess || course?.progress) && !course?.iscompleted;
        }
        let courseDuration = course?.courseduration && course?.courseduration.match(/\d+/g);
        if (Array.isArray(video_module)) {
            video_module.forEach(available_video_module => {
                if (available_video_module.video_completion) {
                    videoCompleted = true;
                }
            });
        }

        return (
            <div
                className={`module ${course.iscompleted ? 'completed' : 'incompleted'} ${course.isenrolled ? '' : 'notenrolled'} unlocked`}>
                <div className="module_left">
                    <div className="module_title">
                        {
                            course.iscompleted ? <CheckCircleFilled/> :
                                showButton ? <ClockCircleOutlined style={{color: '#307FE2'}}/> :
                                    <PlayCircleOutlined style={{color: '#C6C7C9'}}/>
                        }
                        <span className={'course-name'} onClick={() => jumpIntoCourse(course)}>
                            {t(getCourseShortName(course.name), course.name)}
                        </span>
                        {
                            course?.shortdescription && (
                                <span className={'arrow-icon'}>
                                    <DownCircleTwoTone/>
                                </span>
                            )
                        }
                    </div>
                    <div className={'module_details with_separator'}>
                        <span>
                            {course?.courseduration ? i18next.t('studentapp_modules_video_txt', {$a: courseDuration}) : i18next.t('studentapp_modules_video_txt', {$a: '-'})}
                        </span>
                        <span className={'detail_separator'}/>
                        {
                            course?.ismicromodule ?
                                <span>{t('studentapp_modules_quiz_length_1')}</span> :
                                <span>{t('studentapp_modules_quiz_length_7')}</span>
                        }
                    </div>
                </div>
                <div className="module_right">
                    {
                        !!showButton && (
                            <Button
                                type={course?.isenrolled && (course?.lastaccess || course?.progress) ? 'secondary' : 'primary'}
                                className={course?.isenrolled && (course?.lastaccess || course?.progress) ? 'resume_btn' : 'start_now_btn'}
                                onClick={() => {
                                    jumpIntoCourse(course)
                                }}>{course?.isenrolled && (course?.lastaccess || course?.progress) ? t('program_card_button_resume') : t('program_card_button_startnow')}</Button>
                        )
                    }
                </div>
            </div>
        );
    };

    const handleShowMore = () => {
        if (showMore) {
            setShowCount(courses?.length);
        } else {
            setShowCount(initialShowCount);
        }
    }

    const ModuleContent = ({modules}) => {

        if (isProgramDetail) {
            setShowCount(modules?.length);
        }

        const filteredCourseList = modules?.filter((course, index) => index < showCount);
        const moduleCount = filteredCourseList.length;
        const firstItem = filteredCourseList.length > 0 && filteredCourseList[0]?.courseid;
        let showMoreText = showDiff > 0 ? i18next.t('studentapp_modules_showmore_txt') : t('studentapp_modules_showless_txt');

        return (
            <Collapse>
                {
                    filteredCourseList.map((course, id) => {
                            let showButton = firstItem;
                            if (progress > 0) {
                                // Campaign in progress. only show buttons for modules in progress.
                                showButton = course?.isenrolled && (course?.lastaccess || course?.progress) && !course?.iscompleted;
                            }

                            return (
                                <React.Fragment key={`filtered-course-list-${id}`}>
                                    <Collapse.Panel
                                        key={course?.id}
                                        className={`module-panel ${!course?.shortdescription && 'no-short-description'}`}
                                        header={<ModuleSummary course={course} firstItem={firstItem === course?.courseid}/>}
                                        showArrow={false}
                                        collapsible={!!course?.shortdescription ? '' : 'disabled'}
                                    >
                                        {course?.shortdescription}
                                        <div className="mobile_course_btn">
                                            {
                                                !!showButton && (
                                                    <Button
                                                        type={course?.isenrolled && (course?.lastaccess || course?.progress) ? 'secondary' : 'primary'}
                                                        onClick={() => {
                                                            jumpIntoCourse(course)
                                                        }}>{course?.isenrolled && (course?.lastaccess || course?.progress) ? t('program_card_button_resume') : t('program_card_button_startnow')}</Button>
                                                )
                                            }
                                        </div>
                                    </Collapse.Panel>
                                    {
                                        (moduleCount > 1) && <hr/>
                                    }
                                </React.Fragment>
                            )
                        }
                    )
                }
                {
                    (!isProgramDetail && (modules.length > initialShowCount)) && (
                        <Collapse.Panel
                            key={'show-more'}
                            className={'show-more-panel'}
                            header={
                                <Button onClick={handleShowMore} type={'link'}>{showMore ? <DownCircleTwoTone/> :
                                    <UpCircleTwoTone/>}{showMoreText}</Button>}
                            showArrow={false}
                            collapsible={'header'}
                        />
                    )
                }
            </Collapse>
        );
    };

    return (
        <div className={"program_body"}>
            <div className={"program_content"}>
                <div className={"modules"}>
                    <ModuleContent modules={courses}/>
                </div>
            </div>
        </div>
    );
}

export default Modules;
