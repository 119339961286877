export const ADD_DYNAMIC_LOCALES = {
    de: {
        "Cyberbullying": "Cybermobbing",
        "Understanding Cybersecurity": "Cybersicherheit verstehen",
        "Computing and Culture": "Computer und Kultur",
        "Respecting Intellectual Property": "Respekt vor geistigem Eigentum",
        "Social Media Influence": "Einfluss sozialer Medien",
        "Identity in the Digital World": "Identität in der digitalen Welt",
        "Securing Data and Keeping it Private": "Daten schützen und geheim halten",
        "Digital Safety": "Digitale Sicherheit",
        "Cybersecurity Landscape": "Cybersicherheitslandschaft",
        "Recognizing Cyberbullying": "Erkennen von Cybermobbing",
        "Social media": "Soziale Medien ",
        "History of computing innovations": "Geschichte der Computerinnovationen ",
        "Understanding cybersecurity": "Einführung in die Cybersicherheit ",
        "Online identity": "Online-Identität ",
        "Respecting work and ideas online": "Respekt vor der Arbeit und den Ideen online ",
        "Password essentials": "Passwort-Grundlagen ",
        "Digital Impact": "Digitale Auswirkungen",
        "Ethical Integrity": "Ethische Integrität",
        "Online Information": "Online-Informationen",
        "Online Presence": "Online-Präsenz",
        "Secure Privacy": "Sichere Privatsphäre",
        "2 minute read": "Lesedauer: 2 Minuten",
        "Lesson Packages": "{{count}} Lektionspakete",
        "Preparation for the Lesson": "Vorbereitung auf die Lektion",
        "Lesson Packages (Age 12-14)": "Lektionspakete (Alter 12–14)",
        "Lesson Packages (Age 8-11)": "Lektionspakete (Alter 8–11)",
        "Download all": "Alle herunterladen",
        "Age 12-14" : "Alter 12–14",
        "Age 8-11" : "Alter 8–11",
        "English (UK)": "Englisch (UK)",
        "English (Australia)": "Englisch (Australien)",
        "English (Canada)": "Englisch (Kanada)",
        "English (US)": "Englisch (USA)",
        "French": "Französisch",
        "French (Canada)": "Französisch (Kanada)",
        "Spanish (Europe)": "Spanisch (Europa)",
        "Spanish": "Spanisch",
        "Portuguese (Portugal)": "Portugiesisch (Portugal)",
        "Portuguese (Brasil)": "Portugiesisch (Brasilien)",
        "Italian": "Italienisch",
        "German": "Deutsch",
        "Japanese": "Japanisch",
    },
    fr: {
        "Manager Modules": 'Modules pour gestionnaires',
        "Common Attacks": 'Attaques courantes',
        "Authentication": '"Authentification"',
        "Data Privacy and Security": 'Confidentialité et sécurité des données',
        "Physical Security": 'Sécurité physique',
        "Online Safety": 'Sécurité en ligne',
        "Educators - Protecting Sensitive Data": 'Éducateurs : Protection des données sensibles',
        "Educators - Online and Email Best Practices": 'Éducateurs: Meilleures pratiques en ligne et par courriel',
        "Educators - Securing Your Digital World": 'Éducateurs: Sécuriser votre monde digital',
        "Cyberbullying": "Cyberharcèlement",
        "Understanding Cybersecurity": "Saisir les enjeux de la cybersécurité",
        "Computing and Culture": "Informatique et culture",
        "Respecting Intellectual Property": "Respect de la propriété intellectuelle",
        "Social Media Influence": "Influence des réseaux sociaux",
        "Identity in the Digital World": "L’identité dans le monde numérique ",
        "Securing Data and Keeping it Private": "Assurer la protection et la confidentialité des données",
        "Recognizing Cyberbullying": "Reconnaître la cyberintimidation",
        "Social media": "Réseaux sociaux ",
        "History of computing innovations": "L’histoire des innovations informatiques ",
        "Understanding cybersecurity": "Présentation de la cybersécurité ",
        "Online identity": "Identité en ligne",
        "Respecting work and ideas online": "Respecter le travail et les idées en ligne ",
        "Password essentials": "L’essentiel du mot de passe ",
        "Digital Safety": "Sécurité numérique",
        "Cybersecurity Landscape": "Panorama de la cybersécurité",
        "Digital Impact": "Impact du numérique",
        "Ethical Integrity": "Intégrité éthique",
        "Online Information": "Informations en ligne",
        "Online Presence": "Présence en ligne",
        "Secure Privacy": "Protection de la vie privée",
        "2 minute read": "Lecture de 2 minutes",
        "Lesson Packages": "{{count}} formules de cours",
        "Preparation for the Lesson": "Préparation au cours",
        "Lesson Packages (Age 12-14)": "Formules de cours (de 12 à 14 ans)",
        "Lesson Packages (Age 8-11)": "Formules de cours (de 8 à 11 ans)",
        "Download all": "Télécharger tout",
        "Age 12-14" : "de 12 à 14 ans",
        "Age 8-11" : "de 8 à 11 ans",
        "English (UK)": "Anglais (Royaume-Uni)",
        "English (Australia)": "Anglais (Australie)",
        "English (Canada)": "Anglais (Canada)",
        "English (US)": "Anglais (États-Unis)",
        "French": "Français",
        "French (Canada)": "Français (Canada)",
        "Spanish (Europe)": "Espagnol (Europe)",
        "Spanish": "Espagnol",
        "Portuguese (Portugal)": "Portugais (Portugal)",
        "Portuguese (Brasil)": "Portugais (Brésil)",
        "Italian": "Italien",
        "German": "Allemand",
        "Japanese": "Japonais"
    },
    es: {
        "Manager Modules": 'Módulos para gerentes',
        "Common Attacks": 'Ataques comunes',
        "Authentication": '"Autenticación"',
        "Data Privacy and Security": 'Privacidad y seguridad de los datos',
        "Physical Security": 'Seguridad física',
        "Online Safety": 'Seguridad en línea',
        "Educators - Protecting Sensitive Data": 'Educadores: Proteger los datos sensibles',
        "Educators - Online and Email Best Practices": 'Educadores: Mejores prácticas en línea y por correo electrónico',
        "Educators - Securing Your Digital World": 'Educadores: Proteger su mundo digital',
        "Cyberbullying": "Bullying cibernético",
        "Understanding Cybersecurity": "Comprensión de la ciberseguridad",
        "Computing and Culture": "Computación y cultura",
        "Respecting Intellectual Property": "Respeto por la propiedad intelectual",
        "Social Media Influence": "Influencia de las redes sociales",
        "Identity in the Digital World": "Identidad en el mundo digital",
        "Securing Data and Keeping it Private": "Protección y privacidad de datos",
        "Recognizing Cyberbullying": "Reconocimiento del ciberbullying",
        "Social media": "Redes sociales",
        "History of computing innovations": "Historia de las innovaciones informáticas",
        "Understanding cybersecurity": "Introduciendo la ciberseguridad",
        "Online identity": "Identidad en línea",
        "Respecting work and ideas online": "Respeto del trabajo y las ideas en línea",
        "Password essentials": "Conceptos básicos de la contraseña",
        "Digital Safety": "Seguridad digital",
        "Cybersecurity Landscape": "Panorama de la ciberseguridad",
        "Digital Impact": "Impacto digital",
        "Ethical Integrity": "Integridad ética",
        "Online Information": "Información en línea",
        "Online Presence": "Presencia en línea",
        "Secure Privacy": "Protección de la privacidad",
        "2 minute read": "Lectura de 2 minutos",
        "Lesson Packages": "Paquetes de {{count}} lecciones",
        "Preparation for the Lesson": "Preparación de la lección",
        "Lesson Packages (Age 12-14)": "Paquetes de lecciones (12-14 años)",
        "Lesson Packages (Age 8-11)": "Paquetes de lecciones (8-11 años)",
        "Download all": "Descargar todo",
        "Age 12-14" : "12-14 años",
        "Age 8-11" : "8-11 años",
        "English (UK)": "Inglés (Reino Unido)",
        "English (Australia)": "Inglés (Australia)",
        "English (Canada)": "Inglés (Canadá)",
        "English (US)": "Inglés (Estados Unidos)",
        "French": "Francés",
        "French (Canada)": "Francés (Canadá)",
        "Spanish (Europe)": "Español (Europa)",
        "Spanish": "Español",
        "Portuguese (Portugal)": "Portugués (Portugal)",
        "Portuguese (Brasil)": "Portugués (Brasil)",
        "Italian": "Italiano",
        "German": "Alemán",
        "Japanese": "Japonés"
    },
    es_mx: {
        "Manager Modules": 'Módulos para gerentes',
        "Common Attacks": 'Ataques comunes',
        "Authentication": '"Autenticación"',
        "Data Privacy and Security": 'Privacidad y seguridad de los datos',
        "Physical Security": 'Seguridad física',
        "Online Safety": 'Seguridad en línea',
        "Educators - Protecting Sensitive Data": 'Educadores: Proteger los datos sensibles',
        "Educators - Online and Email Best Practices": 'Educadores: Mejores prácticas en línea y por correo electrónico',
        "Educators - Securing Your Digital World": 'Educadores: Proteger su mundo digital',
        "Cyberbullying": "Bullying cibernético",
        "Understanding Cybersecurity": "Comprensión de la ciberseguridad",
        "Computing and Culture": "Computación y cultura",
        "Respecting Intellectual Property": "Respeto por la propiedad intelectual",
        "Social Media Influence": "Influencia de las redes sociales",
        "Identity in the Digital World": "Identidad en el mundo digital",
        "Securing Data and Keeping it Private": "Protección y privacidad de datos",
        "Recognizing Cyberbullying": "Reconocimiento del ciberbullying",
        "Social media": "Redes sociales",
        "History of computing innovations": "Historia de las innovaciones informáticas",
        "Understanding cybersecurity": "Introduciendo la ciberseguridad",
        "Online identity": "Identidad en línea",
        "Respecting work and ideas online": "Respeto del trabajo y las ideas en línea",
        "Password essentials": "Conceptos básicos de la contraseña",
        "Digital Safety": "Seguridad digital",
        "Cybersecurity Landscape": "Panorama de la ciberseguridad",
        "Digital Impact": "Impacto digital",
        "Ethical Integrity": "Integridad ética",
        "Online Information": "Información en línea",
        "Online Presence": "Presencia en línea",
        "Secure Privacy": "Protección de la privacidad",
        "2 minute read": "Lectura de 2 minutos",
        "Lesson Packages": "{{count}} paquetes de lección",
        "Preparation for the Lesson": "Preparación para la lección",
        "Lesson Packages (Age 12-14)": "Paquetes de la lección (edad de 12 a 14)",
        "Lesson Packages (Age 8-11)": "Paquetes de la lección (edad de 8 a 11)",
        "Download all": "Descargar todos",
        "Age 12-14" : "edad de 12 a 14",
        "Age 8-11" : "edad de 8 a 11",
        "English (UK)": "Inglés (Reino Unido)",
        "English (Australia)": "Inglés (Australia)",
        "English (Canada)": "Inglés (Canadá)",
        "English (US)": "Inglés (Estados Unidos)",
        "French": "Francés",
        "French (Canada)": "Francés (Canadá)",
        "Spanish (Europe)": "Español (Europa)",
        "Spanish": "Español",
        "Portuguese (Portugal)": "Portugués (Portugal)",
        "Portuguese (Brasil)": "Portugués (Brasil)",
        "Italian": "Italiano",
        "German": "Alemán",
        "Japanese": "Japonés"
    },
    pt_br: {
        "Manager Modules": 'Módulos para Gerentes',
        "Common Attacks": 'Ataques comuns',
        "Authentication": '"Autenticação"',
        "Data Privacy and Security": 'Privacidade e segurança dos dados',
        "Physical Security": 'Segurança Física',
        "Online Safety": 'Segurança on-line',
        "Educators - Protecting Sensitive Data": 'Educadores: Proteção de dados sensíveis',
        "Educators - Online and Email Best Practices": 'Educadores: Melhores práticas on-line e de e-mail',
        "Educators - Securing Your Digital World": 'Educadores: Protegendo seu mundo digital',
        "Cyberbullying": "Assédio virtual (Cyberbullying)",
        "Understanding Cybersecurity": "Noções básicas sobre cibersegurança",
        "Computing and Culture": "Informática e cultura",
        "Respecting Intellectual Property ": "Respeito à propriedade intelectual ",
        "Social Media Influence ": "Influência das redes sociais ",
        "Identity in the Digital World ": "Identidade no mundo digital ",
        "Securing Data and Keeping it Private ": "Como proteger e manter a privacidade dos dados ",
        "Recognizing Cyberbullying": "Reconhecer o assédio virtual (cyberbullying)",
        "Social media": "Redes sociais",
        "History of computing innovations": "História das inovações em computação",
        "Understanding cybersecurity": "Apresentação da segurança cibernética",
        "Online identity": "Identidade on-line",
        "Respecting work and ideas online": "Respeito ao trabalho e às ideias on-line",
        "Password essentials": "Fundamentos sobre senhas",
        "Digital Safety": "Segurança digital",
        "Cybersecurity Landscape": "Cenário de cibersegurança",
        "Digital Impact": "Impacto digital",
        "Ethical Integrity": "Integridade ética",
        "Online Information": "Informações on-line",
        "Online Presence": "Presença on-line",
        "Secure Privacy": "Privacidade segura",
        "2 minute read": "Leitura de 2 minutos",
        "Lesson Packages": "Pacotes de {{count}} lições",
        "Preparation for the Lesson": "Preparação para a lição",
        "Lesson Packages (Age 12-14)": "Pacotes de lições (12–14 anos)",
        "Lesson Packages (Age 8-11)": "Pacotes de lições (8–11 anos)",
        "Download all": "Baixar tudo",
        "Age 12-14" : "12–14 anos",
        "Age 8-11" : "8–11 anos",
        "English (UK)": "Inglês (Reino Unido)",
        "English (Australia)": "Inglês (Austrália)",
        "English (Canada)": "Inglês (Canadá)",
        "English (US)": "Inglês (Estados Unidos)",
        "French": "Francês",
        "French (Canada)": "Francês (Canadá)",
        "Spanish (Europe)": "Espanhol (Europa)",
        "Spanish": "Espanhol",
        "Portuguese (Portugal)": "Português (Portugal)",
        "Portuguese (Brasil)": "Português (Brasil)",
        "Italian": "Italiano",
        "German": "Alemão",
        "Japanese": "Japonês"
    },
    pt: {
        "Manager Modules": 'Módulos para Gerentes',
        "Common Attacks": 'Ataques comuns',
        "Authentication": '"Autenticação"',
        "Data Privacy and Security": 'Privacidade e segurança dos dados',
        "Physical Security": 'Segurança Física',
        "Online Safety": 'Segurança on-line',
        "Educators - Protecting Sensitive Data": 'Educadores: Proteção de dados sensíveis',
        "Educators - Online and Email Best Practices": 'Educadores: Melhores práticas on-line e de e-mail',
        "Educators - Securing Your Digital World": 'Educadores: Protegendo seu mundo digital',
        "Cyberbullying": "Cyberbullying",
        "Understanding Cybersecurity": "Compreender a cibersegurança",
        "Computing and Culture": "Informática e cultura",
        "Respecting Intellectual Property": "Respeitar a propriedade intelectual",
        "Social Media Influence": "Influência das redes sociais",
        "Identity in the Digital World": "Identidade no mundo digital",
        "Securing Data and Keeping it Private": "Proteger os dados e mantê-los privados",
        "Recognizing Cyberbullying": "Reconhecer o cyberbullying",
        "Social media": "Redes sociais",
        "History of computing innovations": "História das inovações informáticas",
        "Understanding cybersecurity": "Introdução à cibersegurança",
        "Online identity": "Identidade online",
        "Respecting work and ideas online": "Respeitar o trabalho e as ideias online",
        "Password essentials": "Princípios básicos sobre palavras-passe",
        "Digital Safety": "Segurança digital",
        "Cybersecurity Landscape": "Panorama da cibersegurança",
        "Digital Impact": "Impacto digital",
        "Ethical Integrity": "Integridade ética",
        "Online Information": "Informações on-line",
        "Online Presence": "Presença on-line",
        "Secure Privacy": "Garantir a privacidade",
        "2 minute read": "Leitura de 2 minutos",
        "Lesson Packages": "Pacotes de {{count}} aulas",
        "Preparation for the Lesson": "Preparação para a aula",
        "Lesson Packages (Age 12-14)": "Pacotes de aulas (12–14 anos)",
        "Lesson Packages (Age 8-11)": "Pacotes de aulas (8–11 anos)",
        "Download all": "Transferir tudo",
        "Age 12-14" : "12–14 anos",
        "Age 8-11" : "8–11 anos",
        "English (UK)": "Inglês (Reino Unido)",
        "English (Australia)": "Inglês (Austrália)",
        "English (Canada)": "Inglês (Canadá)",
        "English (US)": "Inglês (EUA)",
        "French": "Francês",
        "French (Canada)": "Francês (Canadá)",
        "Spanish (Europe)": "Espanhol (Europa)",
        "Spanish": "Espanhol",
        "Portuguese (Portugal)": "Português (Portugal)",
        "Portuguese (Brasil)": "Português (Brasil)",
        "Italian": "Italiano",
        "German": "Alemão",
        "Japanese": "Japonês"
    },
    ja: {
        "Cyberbullying": "ネットいじめ",
        "Understanding Cybersecurity": "サイバーセキュリティの理解",
        "Computing and Culture": "コンピューティングと文化",
        "Respecting Intellectual Property": "知的財産の尊重",
        "Social Media Influence": "ソーシャルメディアの影響力",
        "Identity in the Digital World": "デジタルワールドにおけるID",
        "Securing Data and Keeping it Private": "データの保護とプライバシーの確保",
        "Recognizing Cyberbullying": "ネットいじめの認識",
        "Social media": "SNS",
        "History of computing innovations": "コンピューティング革新の歴史",
        "Understanding cybersecurity": "サイバーセキュリティの紹介",
        "Online identity": "オンライン・アイデンティティ",
        "Respecting work and ideas online": "オンラインにおける仕事とアイデアの尊重 ",
        "Password essentials": "パスワードの要点",
        "Digital Safety": "デジタルセーフティ",
        "Cybersecurity Landscape": "サイバーセキュリティ環境",
        "Digital Impact": "デジタルが及ぼすインパクト",
        "Ethical Integrity": "倫理的誠実さ",
        "Online Information": "オンライン情報",
        "Online Presence": "オンラインプレゼンス",
        "Secure Privacy": "安全なプライバシー",
        "2 minute read": "読了 2 分",
        "Lesson Packages": "{{count}} 個のレッスンパッケージ",
        "Preparation for the Lesson": "レッスンの準備",
        "Lesson Packages (Age 12-14)": "レッスンパッケージ（12～14 歳）",
        "Lesson Packages (Age 8-11)": "レッスンパッケージ（8～11 歳）",
        "Download all": "すべてダウンロード",
        "Age 12-14" : "12～14 歳",
        "Age 8-11" : "8～11 歳",
        "English (UK)": "英語（イギリス）",
        "English (Australia)": "英語（オーストラリア）",
        "English (Canada)": "英語（カナダ）",
        "English (US)": "英語（アメリカ）",
        "French": "フランス語",
        "French (Canada)": "フランス語（カナダ）",
        "Spanish (Europe)": "スペイン語（ヨーロッパ）",
        "Spanish": "スペイン語",
        "Portuguese (Portugal)": "ポルトガル語（ポルトガル）",
        "Portuguese (Brasil)": "ポルトガル語（ブラジル）",
        "Italian": "イタリア語",
        "German": "ドイツ語",
        "Japanese": "日本語"
    },
    it: {
        "Cyberbullying": "Cyberbullismo",
        "Understanding Cybersecurity": "Comprensione della sicurezza informatica",
        "Computing and Culture": "Informatica e cultura",
        "Respecting Intellectual Property": "Rispetto della proprietà intellettuale",
        "Social Media Influence": "Influenza dei social media",
        "Identity in the Digital World": "L’identità nel mondo digitale ",
        "Securing Data and Keeping it Private": "Protezione dei dati mantenendoli privati ",
        "Recognizing Cyberbullying": "Riconoscere il cyberbullismo",
        "Social media": "Piattaforme social",
        "History of computing innovations": "Storia delle innovazioni informatiche",
        "Understanding cybersecurity": "Introduzione alla sicurezza informatica",
        "Online identity": "Identità online",
        "Respecting work and ideas online": "Rispetto del lavoro e delle idee online",
        "Password essentials": "Elementi essenziali della password",
        "Digital Safety": "Sicurezza digitale",
        "Cybersecurity Landscape": "Panorama della sicurezza informatica",
        "Digital Impact": "Impatto digitale",
        "Ethical Integrity": "Integrità etica",
        "Online Information": "Informazioni online",
        "Online Presence": "Presenza online",
        "Secure Privacy": "Privacy sicura",
        "2 minute read": "2 minuti di lettura",
        "Lesson Packages": "{{count}} pacchetti di lezioni",
        "Preparation for the Lesson": "Preparazione della lezione",
        "Lesson Packages (Age 12-14)": "Pacchetti di lezioni (età 12-14 anni)",
        "Lesson Packages (Age 8-11)": "Pacchetti di lezioni (età 8-11 anni)",
        "Download all": "Scarica tutto",
        "Age 12-14" : "età 12-14 anni",
        "Age 8-11" : "età 8-11 anni",
        "English (UK)": "Inglese (Regno Unito)",
        "English (Australia)": "Inglese (Australia)",
        "English (Canada)": "Inglese (Canada)",
        "English (US)": "Inglese (Stati Uniti)",
        "French": "Francese",
        "French (Canada)": "Francese (Canada)",
        "Spanish (Europe)": "Spagnolo (Europa)",
        "Spanish": "Spagnolo",
        "Portuguese (Portugal)": "Portoghese (Portogallo)",
        "Portuguese (Brasil)": "Portoghese (Brasile)",
        "Italian": "Italiano",
        "German": "Tedesco",
        "Japanese": "Giapponese"
    }
}
