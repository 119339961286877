import {
    DOMAINS_ACTIVE_DOMAINS_LIST_REQUEST,
    DOMAINS_ACTIVE_DOMAINS_LIST_SUCCESS,
    DOMAINS_ACTIVE_DOMAINS_LIST_FAIL,
    DOMAINS_ACTIVE_SUBDOMAINS_LIST_REQUEST,
    DOMAINS_ACTIVE_SUBDOMAINS_LIST_SUCCESS,
    DOMAINS_ACTIVE_SUBDOMAINS_LIST_FAIL,
    DOMAINS_ADD_DOMAIN_REQUEST,
    DOMAINS_ADD_DOMAIN_SUCCESS,
    DOMAINS_ADD_DOMAIN_FAIL,
    DOMAINS_ADD_SUBDOMAIN_REQUEST,
    DOMAINS_ADD_SUBDOMAIN_SUCCESS,
    DOMAINS_ADD_SUBDOMAIN_FAIL,
    DOMAINS_VERIFY_DOMAIN_REQUEST,
    DOMAINS_VERIFY_DOMAIN_SUCCESS,
    DOMAINS_VERIFY_DOMAIN_FAIL,
    DOMAINS_VERIFY_SUBDOMAIN_REQUEST,
    DOMAINS_VERIFY_SUBDOMAIN_SUCCESS,
    DOMAINS_VERIFY_SUBDOMAIN_FAIL,
    DOMAINS_SET_PRIMARY_SUBDOMAIN_REQUEST,
    DOMAINS_SET_PRIMARY_SUBDOMAIN_SUCCESS,
    DOMAINS_SET_PRIMARY_SUBDOMAIN_FAIL,
    DOMAINS_DELETE_DOMAIN_REQUEST,
    DOMAINS_DELETE_DOMAIN_SUCCESS,
    DOMAINS_DELETE_DOMAIN_FAIL,
    DOMAINS_DELETE_SUBDOMAIN_REQUEST,
    DOMAINS_DELETE_SUBDOMAIN_SUCCESS,
    DOMAINS_DELETE_SUBDOMAIN_FAIL,
    DOMAINS_HIDE_DOMAIN_NOTIFICATION,
    DOMAINS_HIDE_SUBDOMAIN_NOTIFICATION,
    DOMAINS_SET_DOMAIN_NOTIFICATION,
    DOMAINS_SET_SUBDOMAIN_NOTIFICATION,
    DOMAINS_ACTIVE_DOMAINS_AND_SUBDOMAINS_LIST_REQUEST,
    DOMAINS_ACTIVE_DOMAINS_AND_SUBDOMAINS_LIST_SUCCESS, DOMAINS_ACTIVE_DOMAINS_AND_SUBDOMAINS_LIST_FAIL,
} from "../actiontypes/domainsConstants";

export const domainsReducer = (state = {}, action) => {
    switch (action.type) {
        case DOMAINS_ACTIVE_DOMAINS_AND_SUBDOMAINS_LIST_REQUEST:
            return {
                ...state,
                loadingDomains: true,
                loadingSubdomains: true
            };
        case DOMAINS_ACTIVE_DOMAINS_AND_SUBDOMAINS_LIST_SUCCESS:
            return {
                ...state,
                loadingDomains: false,
                loadingSubdomains: false,
                domains: action.payload?.response.active_domains,
                subdomains: action.payload?.response.active_subdomains,
                domainMaxLimit: action.payload?.response?.domain_max_limit,
                subdomainMaxLimit: action.payload?.response?.subdomain_max_limit,
            };
        case DOMAINS_ACTIVE_DOMAINS_AND_SUBDOMAINS_LIST_FAIL:
            return {
                ...state,
                loadingDomains: false,
                loadingSubdomains: false
            };
        case DOMAINS_ACTIVE_DOMAINS_LIST_REQUEST:
            return {
                ...state,
                loadingDomains: true,
            };
        case DOMAINS_ACTIVE_DOMAINS_LIST_SUCCESS:
            return {
                ...state,
                loadingDomains: false,
                domains: action.payload?.response.active_domains,
                domainMaxLimit: action.payload?.response?.domain_max_limit,
            };
        case DOMAINS_ACTIVE_DOMAINS_LIST_FAIL:
            return {
                ...state,
                loadingDomains: false
            };
        case DOMAINS_ACTIVE_SUBDOMAINS_LIST_REQUEST:
            return {
                ...state,
                loadingSubdomains: true,
            };
        case DOMAINS_ACTIVE_SUBDOMAINS_LIST_SUCCESS:
            return {
                ...state,
                loadingSubdomains: false,
                subdomains: action.payload?.response.active_subdomains,
                subdomainMaxLimit: action.payload?.response?.subdomain_max_limit,

            };
        case DOMAINS_ACTIVE_SUBDOMAINS_LIST_FAIL:
            return {
                ...state,
                loadingSubdomains: false
            };
        case DOMAINS_ADD_DOMAIN_REQUEST:
            return {
                ...state,
                loadingDomains: true
            };
        case DOMAINS_ADD_DOMAIN_SUCCESS:
            return {
                ...state,
                loadingDomains: false,
                hideDomainNotification: false,
                domainNotificationText: action.payload?.message,
                domainNotificationType: action.payload?.success ? 'success' : 'error'
            };
        case DOMAINS_ADD_DOMAIN_FAIL:
            return {
                ...state,
                loadingDomains: false,
                hideDomainNotification: false,
                domainNotificationText: action.payload?.message,
                domainNotificationType: 'error'
            };
        case DOMAINS_ADD_SUBDOMAIN_REQUEST:
            return {
                ...state,
                loadingSubdomains: true,
            };
        case DOMAINS_ADD_SUBDOMAIN_SUCCESS:
            return {
                ...state,
                loadingSubdomains: false,
                hideSubdomainNotification: false,
                subdomainNotificationText: action.payload?.message === '' ? 'Subdomain added successfully' : action.payload?.message,
                subdomainNotificationType: action.payload?.success ? 'success' : 'error'
            };
        case DOMAINS_ADD_SUBDOMAIN_FAIL:
            return {
                ...state,
                loadingSubdomains: false,
                hideSubdomainNotification: false,
                subdomainNotificationText: action.payload?.message,
                subdomainNotificationType: 'error'
            };
        case DOMAINS_DELETE_DOMAIN_REQUEST:
            return {
                ...state,
                loadingDomains: true
            };
        case DOMAINS_DELETE_DOMAIN_SUCCESS:
            return {
                ...state,
                loadingDomains: false,
                hideDomainNotification: false,
                domainNotificationText: action.payload?.message,
                domainNotificationType: action.payload?.success ? 'success' : 'error'
            };
        case DOMAINS_DELETE_DOMAIN_FAIL:
            return {
                ...state,
                loadingDomains: false,
                hideDomainNotification: false,
                domainNotificationText: action.payload?.message,
                domainNotificationType: 'error'
            };
        case DOMAINS_DELETE_SUBDOMAIN_REQUEST:
            return {
                ...state,
                loadingSubdomains: true
            };
        case DOMAINS_DELETE_SUBDOMAIN_SUCCESS:
            return {
                ...state,
                loadingSubdomains: false,
                hideSubdomainNotification: false,
                subdomainNotificationText: action.payload?.message,
                subdomainNotificationType: action.payload?.success ? 'success' : 'error'
            };
        case DOMAINS_DELETE_SUBDOMAIN_FAIL:
            return {
                ...state,
                loadingSubdomains: false,
                hideSubdomainNotification: false,
                subdomainNotificationText: action.payload?.message,
                subdomainNotificationType: 'error'
            };
        case DOMAINS_VERIFY_DOMAIN_REQUEST:
            return {
                ...state,
                loadingDomains: true
            };
        case DOMAINS_VERIFY_DOMAIN_SUCCESS:
            return {
                ...state,
                loadingDomains: false,
                hideDomainNotification: false,
                domainNotificationText: action.payload?.message,
                domainNotificationType: action.payload?.success ? 'success' : 'error'
            };
        case DOMAINS_VERIFY_DOMAIN_FAIL:
            return {
                ...state,
                loadingDomains: false,
                hideDomainNotification: false,
                domainNotificationText: action.payload?.message,
                domainNotificationType: 'error'
            };
        case DOMAINS_VERIFY_SUBDOMAIN_REQUEST:
            return {
                ...state,
                loadingSubdomains: true
            };
        case DOMAINS_VERIFY_SUBDOMAIN_SUCCESS:
            return {
                ...state,
                loadingSubdomains: false,
                hideSubdomainNotification: false,
                subdomainNotificationText: action.payload?.message,
                subdomainNotificationType: action.payload?.success ? 'success' : 'error'
            };
        case DOMAINS_VERIFY_SUBDOMAIN_FAIL:
            return {
                ...state,
                loadingSubdomains: false,
                hideSubdomainNotification: false,
                subdomainNotificationText: action.payload?.message,
                subdomainNotificationType: 'error'
            };
        case DOMAINS_SET_PRIMARY_SUBDOMAIN_REQUEST:
            return {
                ...state,
                loadingSubdomains: true,
            };
        case DOMAINS_SET_PRIMARY_SUBDOMAIN_SUCCESS:
            return {
                ...state,
                loadingSubdomains: false,
                hideSubdomainNotification: false,
                subdomainNotificationText: action.payload?.message,
                subdomainNotificationType: action.payload?.success ? 'success' : 'error'
            };
        case DOMAINS_SET_PRIMARY_SUBDOMAIN_FAIL:
            return {
                ...state,
                loadingSubdomains: false,
                hideSubdomainNotification: false,
                subdomainNotificationText: action.payload?.message,
                subdomainNotificationType: 'error'
            };
        case DOMAINS_SET_DOMAIN_NOTIFICATION:
            return {
                ...state,
                hideDomainNotification: false,
                domainNotificationText: action.payload?.message,
                domainNotificationType: action.payload?.type
            };
        case DOMAINS_SET_SUBDOMAIN_NOTIFICATION:
            return {
                ...state,
                hideSubdomainNotification: false,
                subdomainNotificationText: action.payload?.message,
                subdomainNotificationType: action.payload?.type
            }
        case DOMAINS_HIDE_DOMAIN_NOTIFICATION:
            return {
                ...state,
                hideDomainNotification: true
            };
        case DOMAINS_HIDE_SUBDOMAIN_NOTIFICATION:
            return {
                ...state,
                hideSubdomainNotification: true
            };
        default:
            return state;

    }
}
