import React, {useEffect, useState} from "react";
import {Table, Button, Empty, Select} from "antd";
import ProgressBar from "./ProgressBar";
import TableMobileCollapse from "./TableMobileCollapse";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {loadCampaignStats} from "../../redux/actions/dashboardActions";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

const CampaignDetailsTable = () => {
    const dispatch = useDispatch();
    const dashboardStats = useSelector((state) => state.dashboardStats);
    const sourceData = dashboardStats?.data;
    const loading = dashboardStats?.loading;

    const pageSize = 7;

    const campaignTableData = sourceData.hasOwnProperty('dashboard_table_campaign_raw') ? sourceData?.dashboard_table_campaign_raw : [];
    const moduleTableData = sourceData.hasOwnProperty('dashboard_table_module_raw') ? sourceData?.dashboard_table_module_raw.reduce((acc, item) => {
        if (!acc[item.campaign_id]) {
            acc[item.campaign_id] = [];
        }
        acc[item.campaign_id].push(item);
        return acc;
    }, {}) : {};

    const campaignsData = campaignTableData.map((campaignRecord, key) => {
        let progressObj = {};
        if (campaignRecord.is_campaign_due) {
            progressObj = [
                {
                    "name": "Completed",
                    "value": campaignRecord.completion_rate,
                    "color": "#58a55c"
                },
                {
                    "name": "Overdue",
                    "value": campaignRecord.overdue_rate,
                    "color": "#ff6361"
                }
            ];
        } else {
            progressObj = [
                {
                    "name": "Completed",
                    "value": campaignRecord.completion_rate,
                    "color": "#58a55c"
                },
                {
                    "name": "In progress",
                    "value": campaignRecord.inprogress_rate,
                    "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                },
                {
                    "name": "Not started",
                    "value": campaignRecord.not_started_rate,
                    "color": "#cfd8dc"
                }
            ];
        }

        return {
            key: key,
            ...campaignRecord,
            progress: progressObj,
            modules: moduleTableData[campaignRecord.id]
        };
    });
    const {t} = useTranslation();
    const [departmentsList, setDepartmentsList] = useState([]);

    const table_columns = [
        {
            title: 'Campaign Name',
            width: '20%',
            render: (record) => {
                return (<TableMobileCollapse record={record}/>)
            },
            className: 'mobile-column',
            responsive: ["xs", "sm", "md"],
        },
        {
            title: 'Campaign Name',
            dataIndex: 'campaign',
            key: 'campaign',
            width: '10%',
            sorter: (a, b) => a.campaign.localeCompare(b.campaign),
            responsive: ["lg"]
        },
        {
            title: 'Campaign status',
            dataIndex: 'campaign_status',
            key: 'campaign_status',
            width: '40%',
            sorter: (a, b) => a.campaign_status.localeCompare(b.campaign_status),
            render: (text, record) => {
                return <span className={`status`}><span
                    className={`status-icon ${text.toLowerCase().replace(' ', '-')}`}></span>{text}</span>;
            },
            responsive: ["lg"]
        },
        {
            title: () => {
                return (
                    <div>
                        {i18next.t('custom_dashboard_training_details_columns_progress', 'Progress')}
                        <div className="progress-icons-container">
                            <div className="progress-icon">
                                <span className="icon-dot not-started-icon-icon-dot"> </span>
                                <span
                                    className="not-started-icon-icon-label">{i18next.t('custom_dashboard_training_details_columns_status_not_started', 'Not started')}</span>
                            </div>
                            <div className="progress-icon">
                                <span className="icon-dot in-progress-icon-icon-dot"> </span>
                                <span
                                    className="in-progress-icon-icon-label">{i18next.t('custom_dashboard_training_details_columns_progress_in_prgrs', 'In Progress')}</span>
                            </div>
                            <div className="progress-icon">
                                <span className="icon-dot overdue-icon-icon-dot"> </span>
                                <span
                                    className="overdue-icon-icon-label">{i18next.t('custom_dashboard_training_details_columns_progress_overdue', 'Overdue')}</span>
                            </div>
                            <div className="progress-icon">
                                <span className="icon-dot completed-icon-dot"> </span>
                                <span
                                    className="completed-icon-label">{i18next.t('custom_dashboard_training_details_columns_progress_cmpltd', 'Completed')}</span>
                            </div>
                        </div>
                    </div>
                );
            },
            key: 'progress',
            dataIndex: 'progress',
            width: '10%',
            render: (cell, row) => {
                return (<ProgressBar table={'main_table'} readings={row.progress}/>)
            },
            responsive: ["lg"]
        },
        {
            title: 'Learners',
            dataIndex: 'allocated_users',
            key: 'allocated_users',
            width: '20%',
            sorter: (a, b) => a.allocated_users - b.allocated_users,
            render: (text, record) => <Link to={{
                pathname: `/admin/campaign/${record?.id}/learners`,
                state: {redirectFrom: 'dashboard'},
            }}>{text}</Link>,
            responsive: ["lg"]
        },
        {
            title: 'Allocation Date',
            dataIndex: 'campaign_date',
            key: 'campaign_date',
            render: (date) => {
                return <span>{date}</span>
            },
            responsive: ["lg"]
        }
    ];

    const module_columns = [
        {
            title: 'Modules',
            dataIndex: 'name',
            key: 'campaign',
            width: '20%',
            responsive: ["lg"]
        },
        {
            title: 'Status',
            dataIndex: 'id',
            key: 'id',
            width: '11%',
            render: (value, record) => {
                return <span></span>
            },
            responsive: ["lg"]
        },
        {
            title: () => {
                return (
                    <div>
                        {i18next.t('custom_dashboard_training_details_columns_progress', 'Progress')}
                        <div className="progress-icons-container">
                            <div className="progress-icon">
                                <span className="icon-dot completed-icon-dot"> </span>
                                <span
                                    className="completed-icon-label">{i18next.t('custom_dashboard_training_details_columns_progress_cmpltd', 'Completed')}</span>
                            </div>
                        </div>
                    </div>
                );
            },
            key: 'progress',
            dataIndex: 'progress',
            width: '40%',
            render: (cell, row) => {
                return (<ProgressBar readings={[
                    {
                        "name": "Completed",
                        "value": row.completion_rate,
                        "color": "#58a55c"
                    },
                    {
                        "name": "Not started",
                        "value": 100 - row.completion_rate,
                        "color": "#cfd8dc"
                    }
                ]}/>)
            },
            responsive: ["lg"]
        },
        {
            title: 'Learners',
            dataIndex: 'allocated_users',
            key: 'allocated_users',
            width: '10%',
            sorter: (a, b) => a.allocated_users - b.allocated_users,
            render: (allocated_users, record) => {
                return <span style={{visibility: 'hidden'}}></span>
            },
            responsive: ["lg"]
        },
        {
            title: 'Campaign Date',
            dataIndex: 'date',
            key: 'date',
            render: (value) => {
                return <span></span>
            },
            responsive: ["lg"]
        }
    ];

    const expandedRowRender = ({modules}) => (
        <div className={'expanded-row'}>
            <Table columns={module_columns} dataSource={modules || []} pagination={false} showHeader={false}/>
        </div>
    );

    const handleDepartmentChange = (val) => {
        if (loading) {
            return;
        }

        val === 0 ? dispatch(loadCampaignStats()) : dispatch(loadCampaignStats([val]));
    }

    let locale = {
        emptyText: (
            <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                description={
                    <p>{t('custom_dashboard_training_details_campaign_data_empty', 'No Training Campaign Data')}</p>}
            >
                <Button type="primary"
                        href='/admin/campaign'>{t('custom_dashboard_training_details_create_campaign_btn', 'Create Campaign Now')}</Button>
            </Empty>
        )
    };

    useEffect(() => {
        if (sourceData?.departments) {
            let temp_dept_list = [{value: 0, label: 'All departments'}, ...sourceData?.departments];
            setDepartmentsList(temp_dept_list);
        }
    }, [sourceData?.departments])

    return (
        <div id="campaign_table_container">
            <div id="campaign_table_header">
                <div className="table-selector-container">
                    <h4 className="campaign-detail-title">
                        {t('custom_dashboard_training_details_title', 'Training Campaign Details')}
                    </h4>
                    {sourceData?.departments && <div className={'filter-section'}>
                        <Select
                            className={'input-ht-md filter-dept'}
                            showArrow={true}
                            showSearch={true}
                            defaultValue={0}
                            disabled={loading}
                            allowClear
                            optionLabelProp={'label'}
                            style={{width: 300}}
                            placeholder="All departments"
                            options={departmentsList}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                                handleDepartmentChange(value);
                            }}
                        />
                    </div>}
                </div>
            </div>
            <div id="campaign_table_main">
                <Table
                    className="campaign-table-content"
                    columns={table_columns}
                    dataSource={campaignsData}
                    pagination={{
                        defaultPageSize: pageSize,
                        position: ['bottomRight'],

                    }}
                    loading={loading}
                    expandable={{
                        expandedRowRender: expandedRowRender,
                        columnWidth: '10px',
                        expandRowByClick: true,
                        module_columns: 'cursor-pointer',

                    }}
                    locale={locale}
                />
            </div>
        </div>
    );
}

export default CampaignDetailsTable;
