import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import "./assets/styles/index.scss";
import './shared/i18n';
import "moment/locale/es";
import "moment/locale/pt-br";
import "moment/locale/fr";
import sharedStore from "./shared/global-store";

require('dotenv').config();

/**
 * reload Route without refresh
 * @param token
 */
function storeToken(token) {
    localStorage.setItem('_token', token);
    window.history.replaceState(null, null, window.location.pathname);
}

const loadRouteToken = () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('active_account')) {
        localStorage.setItem('_active_account', searchParams.get('active_account'));
    }
    return searchParams.get('token');
};

const initApp = () => {
    const token = loadRouteToken();
    if (token) {
        storeToken(token);
    }

    ReactDOM.render(
        <Provider store={sharedStore}>
            <App/>
        </Provider>,
        document.getElementById('root')
    );
};

initApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
