import {combineReducers} from 'redux';
import {
    assignDepartmentLeaderReducer,
    deleteUserReducer,
    departmentLeaderReducer,
    LdapReducer,
    uploadUserReducer,
    userListReducer,
    userSearchReducer
} from "../admin/redux/reducers/userReducers";
import {dashboardStatsReducer, fortiPhishDataReducer} from "../admin/redux/reducers/dashboardReducers";
import {apprReducer} from "../admin/redux/reducers/apprReducers";
import {
    AccMgmtReducer,
    ContractListReducer,
    loginasReducer,
    SubAccountReducer
} from "../admin/redux/reducers/accountMgmtReducers";
import {domainsReducer} from "../admin/redux/reducers/domainsReducers";
import {
    previewReportsSearchReducer,
    reportsReducer,
    savedReportsSearchReducer,
    scheduledReportsSearchReducer
} from "../admin/redux/reducers/reportsReducers";
import {campaignsReducer} from "../admin/redux/reducers/campaignReducers";
import {userToursReducer} from "../admin/redux/reducers/userToursReducers";
import {authDataReducer} from "../admin/redux/reducers/authDataReducer";
import {assetsReducer} from "../admin/redux/reducers/assetsReducers";
import {remediationReducers} from "../admin/redux/reducers/remediationReducers";
import RootReducer from "./rootReducer";

const rootReducer = combineReducers({
    root: RootReducer,
    userList: userListReducer,
    userSearch: userSearchReducer,
    deleteUser: deleteUserReducer,
    uploadUser: uploadUserReducer,
    departmentLeader: departmentLeaderReducer,
    assignDepartmentLeader: assignDepartmentLeaderReducer,
    ldap: LdapReducer,
    dashboardStats: dashboardStatsReducer,
    fortiPhish: fortiPhishDataReducer,
    apprData: apprReducer,
    contractList: ContractListReducer,
    subAccount: SubAccountReducer,
    loginas: loginasReducer,
    accmgmt: AccMgmtReducer,
    domainsPage: domainsReducer,
    reportsPage: reportsReducer,
    savedReportsSearch: savedReportsSearchReducer,
    scheduledReportsSearch: scheduledReportsSearchReducer,
    previewReportsSearch: previewReportsSearchReducer,
    campaigns: campaignsReducer,
    userTourInfo: userToursReducer,
    authData: authDataReducer,
    assets: assetsReducer,
    remediation: remediationReducers,
})

export default rootReducer;
