import React, {Component} from "react";
import "../../assets/styles/components/_rich_editor.scss";
import RichTextEditor from "react-rte";

export class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: RichTextEditor.createValueFromString(props.value, 'html')
        };
    }

    onChange = (value) => {
        this.setState({value});
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChange(
                String(value.toString('html'))
            );
        }
    };

    render() {
        return (
            <div className='rich-text-editor'>
                <RichTextEditor
                    value={this.state.value}
                    onChange={this.onChange}/>
            </div>
        );
    }
}

function escapeHtml(text) {
    const parser = new DOMParser();
    const parsedText = parser.parseFromString(text, 'text/html');
    return parsedText.documentElement.textContent;
}
