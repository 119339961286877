import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Spin} from "antd";
import {Progress} from 'antd';
import Modules from "../components/Modules";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import SpotCyberThreat from "../components/SpotCyberThreat";
import {
    isOngoingCampaign, shorten
} from "../../shared/helper";
import {useTranslation} from "react-i18next";
import EmptyData from "../../shared/components/EmptyData";
import {campaign_get_user_campaigns} from "../../shared/rootActions";

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

function mapStateToProps(state) {
    return {...state.root || {}};
}

function MainPage({siteInfo, tenantAdmin, campaigns, loadingCampaigns}) {
    const {tenantid, timezone, user_timezone} = siteInfo;
    const {t} = useTranslation();
    const _today = Math.floor(Date.now() / 1000);
    const [activeCampaigns, setActiveCampaigns] = useState([]);
    const dispatch = useDispatch();

    let userTimezone = timezone;

    if (user_timezone && user_timezone != '99') {
        userTimezone = user_timezone;
    }
    if (userTimezone.indexOf('GMT-8') >= 0) {
        // fix a issue that Moodle doesn't support 'Pacific Standard Time (GMT-8)'
        userTimezone = 'Pacific/Pitcairn';
    }

    useEffect(() => {
        let newCampaigns = [];
        if (Array.isArray(campaigns)) {
            newCampaigns = [...campaigns];
        } else if (typeof campaigns === 'object') {
            newCampaigns = Object.values(campaigns);
        }
        if (!campaigns || !newCampaigns.length) {
            return true;
        }
        newCampaigns = newCampaigns.map((campaign) => {
            if (!isOngoingCampaign(campaign, _today)) {
                return null;
            }
            if (!!tenantid && campaign.tenantid !== tenantid) {
                return null;
            }
            campaign._duedate = campaign.duedateabsolute || 0;
            campaign._enddate = campaign.expirydateabsolute || 0;
            return campaign;
        });
        const actCampaigns = newCampaigns.filter(campaign => !!campaign && campaign.programvisible && !campaign.iscompleted);
        setActiveCampaigns(actCampaigns);
    }, [campaigns]);

    useEffect(() => {
        campaigns || dispatch(campaign_get_user_campaigns());
    }, []);

    return (
        <div className="main_page container">
            <section className="greeting">
                <h5 className="greeting_txt">{t('welcomeemail_subject')}</h5>
                <p className='greeting_desc'>
                    {
                        tenantAdmin ?
                            t('homepage_admin_content')
                            :
                            t('homepage_student_content')
                    }
                </p>
                <div className="banner_img"></div>
            </section>

            {
                activeCampaigns.length === 0 &&
                <section className={"cyber_sec"}>
                    <h4>{t('homepage_feature')}</h4>
                    <SpotCyberThreat/>
                </section>
            }

            <section className="my_learning">
                <div className="program_container">
                    <h4>{t('homepage_mylearning')}</h4>
                    {
                        loadingCampaigns ? <Spin/> :
                            <div className="program_list">
                                {
                                    activeCampaigns?.map(campaign => {
                                        let {programcourses: courses} = campaign;
                                        let totalCourses = courses.length;
                                        let completedCourses = courses.reduce((acc, course) => course.iscompleted ? acc + 1 : acc, 0);

                                        return (
                                            <div className={`program_wrap program_${campaign.id % 10}`}
                                                 key={campaign.id}>
                                                <div className="program_head">
                                                    <div className="program_vector"></div>
                                                    <div className="program_info">
                                                        <Link to={`/programs/${campaign.id}`}>
                                                            <h5>
                                                                {shorten(campaign.fullname, 64, ' ')}
                                                            </h5>
                                                        </Link>
                                                        <div className="program_date">
                                                            <div className="program_timeinfo with_separator">
                                                                {
                                                                    (!!campaign.duedateabsolute && campaign.duedateabsolute > 0) &&
                                                                    <>
                                                                        <span className="duedate">
                                                                            {`${t('homepage_mylearning_duedate')}: ${dayjs(campaign.duedateabsolute * 1000).tz(userTimezone).format('YYYY-MM-DD HH:mm')}`}
                                                                        </span>
                                                                        <span className={'detail_separator'}/>
                                                                    </>
                                                                }
                                                                <div className="completion_info_status">
                                                                    <span className="completion_info">
                                                                        {t('homepage_mylearning_moduleleft', 'Modules completed') + `: ${completedCourses}/${totalCourses}`}
                                                                    </span>
                                                                    {
                                                                        !!campaign.lastaccess && (
                                                                            campaign.progress < 100
                                                                                ? ((campaign.duedateabsolute && campaign.duedateabsolute < _today)
                                                                                        ? <span
                                                                                            className="overdue status ml-2">{t('program_status_overdue')}</span>
                                                                                        : <span
                                                                                            className="status ml-2">{t('program_status_inprogress')}</span>

                                                                                )
                                                                                : <span
                                                                                    className="status ml-2">{t('program_status_completed')}</span>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="program_progress">
                                                        {
                                                            <Progress
                                                                type="circle"
                                                                percent={campaign.progress}
                                                                width={100}
                                                                strokeColor={'#A6D997'}
                                                                trailColor={'#FFFFFF'}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                <Modules key={campaign?.id} courses={courses} programid={campaign?.id}
                                                         templateid={campaign?.programid}
                                                         progress={campaign?.progress}/>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    (activeCampaigns && !activeCampaigns.length) && (
                                        <EmptyData
                                            title={t('homepage_mylearning_emptytitle')}
                                            message={t('homepage_mylearning_emptycontent')}/>
                                    )
                                }
                            </div>
                    }
                </div>
            </section>

            <section className="need_help">
                <h6>Need help?</h6>
                <p>
                    If you have any questions about your registration, or difficulty accessing the course, <br/>
                    please contact our training
                    team:<br/> <a href="mailto:ISAT_Beta@fortinet.com" target="_blank">ISAT_Beta@fortinet.com</a>
                </p>
            </section>
        </div>
    );
}

export default connect(
    mapStateToProps,
)(MainPage);
