export const DEFAULT_SERVER_TIMEZONE = 'America/Vancouver';
export const REPORTS_LOAD_ALL_REPORT_TYPES_REQUEST = "REPORTS_LOAD_ALL_REPORT_TYPES_REQUEST";
export const REPORTS_LOAD_ALL_REPORT_TYPES_SUCCESS = "REPORTS_LOAD_ALL_REPORT_TYPES_SUCCESS";
export const REPORTS_LOAD_ALL_REPORT_TYPES_FAIL = "REPORTS_LOAD_ALL_REPORT_TYPES_FAIL";
export const REPORTS_SAVE_CREATEREPDATA_REQUEST = "REPORTS_SAVE_CREATEREPDATA_REQUEST";
export const REPORTS_SAVE_CREATEREPDATA_FAIL = "REPORTS_SAVE_CREATEREPDATA_FAIL";
export const REPORTS_SAVE_CREATEREPDATA_SUCCESS = "REPORTS_SAVE_CREATEREPDATA_SUCCESS";
export const REPORTS_REPORTDETAILS_REQUEST = "REPORTS_REPORTDETAILS_REQUEST";
export const REPORTS_REPORTDETAILS_SUCCESS = "REPORTS_REPORTDETAILS_SUCCESS";
export const REPORTS_REPORTDETAILS_FAIL = "REPORTS_REPORTDETAILS_FAIL";
export const REPORTS_LOAD_REPORTS_REQUEST = "REPORTS_LOAD_REPORTS_REQUEST";
export const REPORTS_LOAD_SCHEDULEDREPORTS_SUCCESS = "REPORTS_LOAD_SCHEDULEDREPORTS_SUCCESS";
export const REPORTS_LOAD_SAVEDREPORTS_SUCCESS = "REPORTS_LOAD_SAVEDREPORTS_SUCCESS";
export const REPORTS_LOAD_REPORTS_FAIL = "REPORTS_LOAD_REPORTS_FAIL";
export const REPORT_DELETE_REQUEST = "SCHEDULED_REPORT_DELETE_REQUEST";
export const REPORT_DELETE_SUCCESS = "SCHEDULED_REPORT_DELETE_SUCCESS"
export const REPORT_DELETE_FAIL = "SCHEDULED_REPORT_DELETE_FAIL";
export const REPORT_DELETE_RESET = "REPORT_DELETE_RESET";
export const REPORTS_GENERATE_PREVIEW_TABLE_REQUEST = "REPORTS_GENERATE_PREVIEW_TABLE_REQUEST";
export const REPORTS_GENERATE_PREVIEW_TABLE_SUCCESS = "REPORTS_GENERATE_PREVIEW_TABLE_SUCCESS";
export const REPORTS_GENERATE_PREVIEW_TABLE_FAIL = "REPORTS_GENERATE_PREVIEW_TABLE_FAIL";
export const REPORTS_FORMATTED_PREVIEW_TABLE_SUCCESS = "REPORTS_FORMATTED_PREVIEW_TABLE_SUCCESS";
export const SCHEDULEDREPORTS_SEARCH_BY_TITLE = "SCHEDULEDREPORTS_SEARCH_BY_TITLE";
export const SCHEDULEDREPORTS_SEARCH_BY_STATUS = "SCHEDULEDREPORTS_SEARCH_BY_STATUS";
export const SAVEDREPORTS_SEARCH_BY_TITLE = "SAVEDREPORTS_SEARCH_BY_TITLE";
export const SAVEDREPORTS_SEARCH_BY_TYPE = "SAVEDREPORTS_SEARCH_BY_TYPE";
export const REPORTS_SET_HEADING = "REPORTS_SET_HEADING";
export const REPORTS_RENAME_REPORT_REQUEST = "REPORTS_RENAME_REPORT_REQUEST";
export const REPORTS_RENAME_REPORT_SUCCESS = "REPORTS_RENAME_REPORT_SUCCESS";
export const REPORTS_RENAME_REPORT_FAIL = "REPORTS_RENAME_REPORT_FAIL";
export const REPORTS_PREVIEW_SEARCH_BY_DATE = "REPORTS_PREVIEW_SEARCH_BY_DATE";
export const REPORTS_PREVIEW_SEARCH_BY_DEPARTMENT = "REPORTS_PREVIEW_SEARCH_BY_DEPARTMENT";
export const REPORTS_PREVIEW_SEARCH_BY_CAMPAIGN = "REPORTS_PREVIEW_SEARCH_BY_CAMPAIGN";
export const REPORTS_SET_FORM_VALUES = "REPORTS_SET_FORM_VALUES";
export const REPORTS_RESET_FORM_VALUES_EXCEPT_FILTER = "REPORTS_RESET_FORM_VALUES_EXCEPT_FILTER";
export const REPORTS_UPDATE_FORM_VALUES = "REPORTS_UPDATE_FORM_VALUES";
export const REPORTS_RENAME_REPORT_RESET = "REPORTS_RENAME_REPORT_RESET";
export const REPORTS_STATE_FORM_RESET = "REPORTS_STATE_FORM_RESET";
export const SCHEDULEDREPORTS_STATE_RESET = "SCHEDULEDREPORTS_STATE_RESET";
export const SAVEDREPORTS_STATE_RESET = "SAVEDREPORTS_STATE_RESET";
export const REPORTS_PREVIEW_STATE_RESET = "REPORTS_PREVIEW_STATE_RESET";

export const ReportsIcon = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
        <path d="M6.66016 13.9889H13.3361V15.3241H6.66016V13.9889Z" fill="currentColor"/>
        <path d="M6.66016 9.98337H16.0064V11.3185H6.66016V9.98337Z" fill="#818181"/>
        <path d="M6.66016 16.6593H10.6657V17.9945H6.66016V16.6593Z" fill="currentColor"/>
        <path
            d="M18.5539 4.1181H16.3691V3.40283C16.3691 3.02342 16.2156 2.65955 15.9424 2.39127C15.6693 2.12299 15.2988 1.97227 14.9125 1.97227H9.08625C8.69994 1.97227 8.32946 2.12299 8.0563 2.39127C7.78314 2.65955 7.62969 3.02342 7.62969 3.40283V4.1181H5.44484C5.05854 4.1181 4.68806 4.26882 4.4149 4.5371C4.14174 4.80538 3.98828 5.16925 3.98828 5.54865V20.5695C3.98828 20.9489 4.14174 21.3127 4.4149 21.581C4.68806 21.8493 5.05854 22 5.44484 22H18.5539C18.9402 22 19.3107 21.8493 19.5838 21.581C19.857 21.3127 20.0105 20.9489 20.0105 20.5695V5.54865C20.0105 5.16925 19.857 4.80538 19.5838 4.5371C19.3107 4.26882 18.9402 4.1181 18.5539 4.1181ZM9.08625 3.40283H14.9125V6.26393H9.08625V3.40283ZM18.5539 20.5695H5.44484V5.54865H7.62969V7.69448H16.3691V5.54865H18.5539V20.5695Z"
            fill="currentColor"/>
    </svg>);
