

import {
    USERTOUR_PATH_HAS_TOUR_REQUEST,
    USERTOUR_PATH_HAS_TOUR_SUCCESS,
    USERTOUR_PATH_HAS_TOUR_FAIL,
    USERTOUR_UPDATE_TOUR_COMPLETION_REQUEST,
    USERTOUR_UPDATE_TOUR_COMPLETION_SUCCESS,
    USERTOUR_UPDATE_TOUR_COMPLETION_FAIL,
    USERTOUR_GET_USER_TOUR_DATA_REQUEST,
    USERTOUR_GET_USER_TOUR_DATA_SUCCESS,
    USERTOUR_GET_USER_TOUR_DATA_FAIL,
    USERTOUR_SET_ONBOARDING_VIDEO_REQUEST,
    USERTOUR_SET_ONBOARDING_VIDEO_SUCCESS,
    USERTOUR_SET_ONBOARDING_VIDEO_FAIL,
    USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_REQUEST,
    USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_SUCCESS,
    USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_FAIL, USERTOUR_SET_OPEN_TOUR
} from "../actiontypes/userTourConstants";

import {MOODLE_SERVICE_ENDPOINT} from "../../../shared/constants/globalConstants";
import {getPayload} from "../../../shared/rootActions";

export const checkPathHasTour = (path) => async (dispatch) => {
    dispatch({type: USERTOUR_PATH_HAS_TOUR_REQUEST});

    const queryParam = {
        wsfunction: 'local_bridge_page_has_user_tour',
        path
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data.status) {
            dispatch({
                type: USERTOUR_PATH_HAS_TOUR_SUCCESS,
                payload: response_data
            });
        } else {
            dispatch({
                type: USERTOUR_PATH_HAS_TOUR_FAIL,
                payload: response_data
            });
        }
    } catch (error) {
        dispatch({
            type: USERTOUR_PATH_HAS_TOUR_FAIL,
            payload: error
        });
    }
}

export const updateTourCompletion = (tour_id) => async (dispatch) => {
    dispatch({type: USERTOUR_UPDATE_TOUR_COMPLETION_REQUEST});

    const queryParam = {
        wsfunction: 'local_bridge_update_user_tour_completion',
        tour_id
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data.status) {
            dispatch({type: USERTOUR_UPDATE_TOUR_COMPLETION_SUCCESS})
        } else {
            dispatch({type: USERTOUR_UPDATE_TOUR_COMPLETION_FAIL})
        }
    } catch (error) {
        dispatch({type: USERTOUR_UPDATE_TOUR_COMPLETION_FAIL})
    }
}

export const getUserTourData = () => async (dispatch) => {
    dispatch({type: USERTOUR_GET_USER_TOUR_DATA_REQUEST});
    const queryParam = {wsfunction: 'local_bridge_load_user_onboarding_data'};

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data.success) {
            let parsed_data = JSON.parse(response_data.data);
            dispatch({
                type: USERTOUR_GET_USER_TOUR_DATA_SUCCESS,
                payload: parsed_data
            });
            return parsed_data;
        } else {
            dispatch({type: USERTOUR_GET_USER_TOUR_DATA_FAIL});

            return null;
        }
    } catch (error) {
        dispatch({type: USERTOUR_GET_USER_TOUR_DATA_FAIL});
        return null;
    }
}

export const setOnboardingVideoWatched = () => async (dispatch) => {
    dispatch({type: USERTOUR_SET_ONBOARDING_VIDEO_REQUEST});
    const queryParam = {wsfunction: 'local_bridge_update_onboarding_video_status'}

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data.success) {
            dispatch({type: USERTOUR_SET_ONBOARDING_VIDEO_SUCCESS});
        } else {
            dispatch({type: USERTOUR_SET_ONBOARDING_VIDEO_FAIL});
        }
    } catch (error) {
        dispatch({type: USERTOUR_SET_ONBOARDING_VIDEO_FAIL});
    }
}

export const resetOnboardingGuideStatus = (guide_id) => async (dispatch) => {
    dispatch({type: USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_REQUEST});
    const queryParam = {
        wsfunction: 'local_bridge_reset_user_onboarding_guide_status',
        guide_id
    }

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data.success) {
            dispatch({
                type: USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_SUCCESS,
                payload: response_data
            })
        } else {
            dispatch({type: USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_FAIL})
        }
    } catch (error) {
        dispatch({type: USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_FAIL})
    }
}

export const setOpenTour = (state) => async (dispatch) => {
    dispatch({
        type: USERTOUR_SET_OPEN_TOUR,
        payload: state
    });
}
