import React from "react";
import {
    ACCOUNT_MGMT_ADD_SUBACCOUNT_FAIL,
    ACCOUNT_MGMT_ADD_SUBACCOUNT_REQUEST,
    ACCOUNT_MGMT_ADD_SUBACCOUNT_RESET,
    ACCOUNT_MGMT_ADD_SUBACCOUNT_SUCCESS,
    ACCOUNT_MGMT_CHECK_SUPERADMIN_FAIL,
    ACCOUNT_MGMT_CHECK_SUPERADMIN_REQUEST,
    ACCOUNT_MGMT_CHECK_SUPERADMIN_SUCCESS,
    ACCOUNT_MGMT_DELETE_ADMIN_FAIL,
    ACCOUNT_MGMT_DELETE_ADMIN_REQUEST,
    ACCOUNT_MGMT_DELETE_ADMIN_SUCCESS,
    ACCOUNT_MGMT_DELETE_SUBACCOUNT_FAIL,
    ACCOUNT_MGMT_DELETE_SUBACCOUNT_REQUEST,
    ACCOUNT_MGMT_DELETE_SUBACCOUNT_SUCCESS,
    ACCOUNT_MGMT_GET_CONTRACT_LIST_FAIL,
    ACCOUNT_MGMT_GET_CONTRACT_LIST_REQUEST,
    ACCOUNT_MGMT_GET_CONTRACT_LIST_SUCCESS,
    ACCOUNT_MGMT_GET_SUBACCOUNTS_FAIL,
    ACCOUNT_MGMT_GET_SUBACCOUNTS_REQUEST,
    ACCOUNT_MGMT_GET_SUBACCOUNTS_SUCCESS,
    ACCOUNT_MGMT_LOAD_ADMIN_DATA_FAIL,
    ACCOUNT_MGMT_LOAD_ADMIN_DATA_REQUEST,
    ACCOUNT_MGMT_LOAD_ADMIN_DATA_SUCCESS,
    ACCOUNT_MGMT_LOGINAS_FAIL,
    ACCOUNT_MGMT_LOGINAS_REQUEST,
    ACCOUNT_MGMT_LOGINAS_SUCCESS, ACCOUNT_MGMT_RESET_STATE,
    ACCOUNT_MGMT_SAVE_ADMIN_FAIL,
    ACCOUNT_MGMT_SAVE_ADMIN_REQUEST,
    ACCOUNT_MGMT_SAVE_ADMIN_SUCCESS,
} from "../actiontypes/accountMgmtConstants";

export const ContractListReducer = (state = {}, action) => {
    switch (action.type) {
        case ACCOUNT_MGMT_GET_CONTRACT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ACCOUNT_MGMT_GET_CONTRACT_LIST_SUCCESS:
            return {
                loading: false,
                contracts: action.payload,
            };

        case ACCOUNT_MGMT_GET_CONTRACT_LIST_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const SubAccountReducer = (state = {get_subaccounts: {}}, action) => {
    switch (action.type) {
        case ACCOUNT_MGMT_GET_SUBACCOUNTS_REQUEST:
            return {
                ...state,
                get_subaccounts: {
                    ...state.get_subaccounts,
                    loading: true,
                    error: false,
                },
            };
        case ACCOUNT_MGMT_GET_SUBACCOUNTS_SUCCESS:
            return {
                ...state,
                get_subaccounts: {
                    loading: false,
                    error: false,
                    data: action.payload,
                },
            };
        case ACCOUNT_MGMT_GET_SUBACCOUNTS_FAIL:
            return {
                ...state,
                get_subaccounts: {
                    loading: false,
                    error: action.payload,
                }
            };
        case ACCOUNT_MGMT_ADD_SUBACCOUNT_REQUEST:
            return {
                ...state,
                add_subaccount: {
                    loading: true,
                    error: false,
                },
            };
        case ACCOUNT_MGMT_ADD_SUBACCOUNT_SUCCESS:
            return {
                ...state,
                add_subaccount: {
                    loading: false,
                    error: false,
                    data: action.payload,
                },
            };
        case ACCOUNT_MGMT_ADD_SUBACCOUNT_FAIL:
            return {
                ...state,
                add_subaccount: {
                    loading: false,
                    error: action.payload,
                },
            };
        case ACCOUNT_MGMT_ADD_SUBACCOUNT_RESET:
            return {
                ...state,
                add_subaccount: {},
            };
        case ACCOUNT_MGMT_DELETE_SUBACCOUNT_REQUEST:
            return {
              ...state,
              delete_subaccount: {
                  loading: true,
                  error: false,
              },
            };
        case ACCOUNT_MGMT_DELETE_SUBACCOUNT_SUCCESS:
            return {
                ...state,
                delete_subaccount: {
                    loading: false,
                    error: false,
                    data: action.payload,
                },
            };
        case ACCOUNT_MGMT_DELETE_SUBACCOUNT_FAIL:
            return {
                ...state,
                delete_subaccount: {
                    loading: false,
                    error: action.payload,
                },
            };
        default:
            return state;
    }
};

export const loginasReducer = (state = {}, action) => {
    switch (action.type) {
        case ACCOUNT_MGMT_LOGINAS_REQUEST:
            return {
                loading: true,
                error: false,
                revert: false,
                success: false,
            };
        case ACCOUNT_MGMT_LOGINAS_SUCCESS:
            return {
                loading: false,
                error: false,
                revert: false,
                success: true,
                ...action.payload,
            };
        case ACCOUNT_MGMT_LOGINAS_FAIL:
            return {
                loading: false,
                error: action.payload,
                revert: false,
                success: false,
            };
        default:
            return state;
    }
};

export const AccMgmtReducer = (state = {}, action) => {
    switch (action.type) {
        case ACCOUNT_MGMT_CHECK_SUPERADMIN_REQUEST:
            return {
                ...state,
                superAdmin: {
                    loading: true,
                    error: false,
                    success: false
                }
            };
        case ACCOUNT_MGMT_CHECK_SUPERADMIN_SUCCESS:
            return {
                ...state,
                superAdmin: {
                    loading: false,
                    error: false,
                    success: action?.payload?.success,
                    message: action?.payload?.message,
                }
            };
        case ACCOUNT_MGMT_CHECK_SUPERADMIN_FAIL:
            return {
                ...state,
                superAdmin: {
                    loading: false,
                    error: true,
                    success: false
                }
            };
        case ACCOUNT_MGMT_LOAD_ADMIN_DATA_REQUEST:
            return {
                ...state,
                adminList: {
                    loading: true,
                    error: false,
                    success: false
                }
            };
        case ACCOUNT_MGMT_LOAD_ADMIN_DATA_SUCCESS:
            return {
                ...state,
                adminList: {
                    loading: false,
                    error: false,
                    success: action?.payload?.success,
                    data: action?.payload?.data,
                }
            };
        case ACCOUNT_MGMT_LOAD_ADMIN_DATA_FAIL:
            return {
                ...state,
                adminList: {
                    loading: false,
                    error: true,
                    success: false
                }
            };
        case ACCOUNT_MGMT_DELETE_ADMIN_REQUEST:
            return {
                ...state,
                delete_admin: {
                    loading: true,
                    error: false,
                    success: false
                }
            };
        case ACCOUNT_MGMT_DELETE_ADMIN_SUCCESS:
            return {
                ...state,
                delete_admin: {
                    loading: false,
                    error: false,
                    success: action?.payload?.success,
                }
            };
        case ACCOUNT_MGMT_DELETE_ADMIN_FAIL:
            return {
                ...state,
                delete_admin: {
                    loading: false,
                    error: true,
                    success: false
                }
            };
        case ACCOUNT_MGMT_SAVE_ADMIN_REQUEST:
            return {
                ...state,
                save_admin: {
                    loading: true,
                    error: false,
                    success: false
                }
            };
        case ACCOUNT_MGMT_SAVE_ADMIN_SUCCESS:
            return {
                ...state,
                save_admin: {
                    loading: false,
                    error: false,
                    success: action?.payload?.success,
                }
            };
        case ACCOUNT_MGMT_SAVE_ADMIN_FAIL:
            return {
                ...state,
                save_admin: {
                    loading: false,
                    error: true,
                    success: false
                }
            };
        case ACCOUNT_MGMT_RESET_STATE:
            return {
                ...state,
                save_admin: {},
                delete_admin: {}
            }
        default:
            return state;
    }
}
