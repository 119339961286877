import "../admin/styles/index.scss";
import {Route} from "react-router-dom";
import React from "react";
import {connect} from "react-redux";
import UserTour from "./components/userTours/UserTour";

function mapStateToProps(state) {
    return {
        siteConfig: state.root.siteConfig,
        siteInfo: state.root.siteInfo,
        domReady: state.root.domReady
    };
}

const mapDispatchToProps = dispatch => ({})

function AdminLayout({
                         component: Component,
                         domReady,
                         ...rest
                     }) {
    return (
        <Route {...rest} render={props => (
            <div className="admin-container">
                <Component {...props} isSiteAdmin={true}/>
                {
                    domReady &&
                    <UserTour/>
                }
            </div>
        )}/>
    );
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(AdminLayout);
