import {useDispatch, useSelector} from "react-redux";
import {Alert, message} from "antd";
import {useEffect, useState} from "react";
import {clearMoodleNotification} from "../rootActions";

const MoodleAlert = () => {
    const {notification, auth_type} = useSelector((state) => state?.root?.siteInfo) || {};
    const [notify, setNotify] = useState(notification);

    const dispatch = useDispatch();
    const isAdminRoute = window.location.pathname.includes('admin');
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (notification?.id) {
            setNotify(notification);

            // Clear Notification
            if (notification && !isAdminRoute && auth_type === 'forticloud') {
                messageApi.destroy();
                messageApi.open({
                    type: notification.messagetype || 'info',
                    content: notification.message,
                    duration: 0,
                    onClick: () => {
                        messageApi.destroy();
                        dispatch(clearMoodleNotification(notification.id));
                    }
                })
            }
        }
    }, [notification]);

    return (
        <>
            {contextHolder}
            {
                (isAdminRoute && notify?.message && notify?.messagetype) && (<Alert onClose={() => {
                    dispatch(clearMoodleNotification(notification.id));
                }} className={`${isAdminRoute ? 'admin-route ' : ''}moodle-alert`} message={notify.message}
                                                                                    type={notify.messagetype}
                                                                                    closable/>)
            }
        </>
    );
};

export default MoodleAlert;