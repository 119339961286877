import {
    GET_SETTINGS_DATA_FAIL,
    GET_SETTINGS_DATA_REQUEST,
    GET_SETTINGS_DATA_SUCCESS,
    LOAD_SITE_INFO,
    LOADED_CAMPAIGNS,
    LOADED_PARENT_SITE_CONFIG,
    LOADED_SITE_CONFIG,
    LOADED_SITE_WHITE_LABEL,
    LOADED_TENANT,
    START_LOAD_CAMPAIGNS,
    UPDATE_SETTINGS_DATA,
    STORE_WS_TOKEN,
    FORCE_CAMPAIGNS_RELOAD,
    SET_TENANTADMIN,
    SETTINGS_RESET_PASSWORD_TOKEN_REQUEST,
    SETTINGS_RESET_PASSWORD_TOKEN_FAIL,
    SETTINGS_RESET_PASSWORD_TOKEN_SUCCESS,
    SET_DOM_READY,
    RESET_AUTO_REDIRECTION,
    EXTEND_TOKEN_VALIDITY_REQUEST,
    EXTEND_TOKEN_VALIDITY_SUCCESS,
    EXTEND_TOKEN_VALIDITY_FAIL,
    EXTEND_TOKEN_VALIDITY_RESET,
    UPDATE_SITE_CONFIG,
    CLEAR_MOODLE_NOTIFICATION,
    SET_USER_STATUS_CHECKED,
    SET_SSO_USER_ADMIN,
    GET_SAC_COURSES_REQUEST,
    GET_SAC_COURSES_SUCCESS,
    GET_SAC_COURSES_FAIL, LOADED_FORTICLOUD_DATA,
    NOTIFICATION_ADD_REDIRECT,
    NOTIFICATION_CLEAR_REDIRECT, LOADING_TENANT,
} from "./rootActionTypes";

const initialState = {
    reloadCampaigns: 0,
    tenantid: null,
    auto_launch_sso: false,
    siteConfig: null,
    hasWhiteLabel: false,
    parentHasWhiteLabel: false,
    _token: null,
    loadingCampaigns: false,
    tenantAdmin: false,
    shouldAutoRedirect: null, // handle the first auto-redirection for tenant admin
    // dom ready path -> use to compare between previous&current path
    domReady: '',
    userStatusChecked: false,
};

export default function RootReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_SITE_INFO:
            return {
                ...state,
                siteInfo: action.payload
            };
        case STORE_WS_TOKEN:
            return {
                ...state,
                _token: action.payload
            };
        case SET_TENANTADMIN:
            const isTenantAdmin = Boolean(action.payload);
            return {
                ...state,
                tenantAdmin: isTenantAdmin,
                shouldAutoRedirect: state.shouldAutoRedirect == null && isTenantAdmin
            };
        case RESET_AUTO_REDIRECTION:
            return {
                ...state,
                shouldAutoRedirect: false
            };
        case LOADED_SITE_CONFIG:
            return {
                ...state,
                siteConfig: action.payload
            };
        case UPDATE_SITE_CONFIG:
            let currentSiteConfig = state.siteConfig || {};
            return {
                ...state,
                siteConfig: {
                    ...currentSiteConfig,
                    ...action.payload
                }
            };
        case LOADED_PARENT_SITE_CONFIG:
            return {
                ...state,
                parentSiteConfig: action.payload
            };
        case LOADED_FORTICLOUD_DATA:
            return {
                ...state,
                fortiCloudData: action.payload
            };
        case LOADED_SITE_WHITE_LABEL:
            return {
                ...state,
                hasWhiteLabel: Boolean(action.payload.current),
                parentHasWhiteLabel: Boolean(action.payload.parent)
            };
        case LOADED_TENANT:
            return {
                ...state,
                tenantid: action.payload.tenantid,
                auto_launch_sso: action.payload.auto_launch_sso,
                tenant_loading: false
            };
        case LOADING_TENANT:
            return {
                ...state,
                tenant_loading: true
            };
        case LOADED_CAMPAIGNS:
            if (action.payload) {
                return {
                    ...state,
                    campaigns: {...action.payload},
                    loadingCampaigns: false,
                    reloadCampaigns: 0
                };
            }
            return {...state, campaigns: [], loadingCampaigns: false, reloadCampaigns: 0};
        case FORCE_CAMPAIGNS_RELOAD:
            let reloadCampaigns = state.reloadCampaigns || 0;
            return {
                ...state,
                reloadCampaigns: ++reloadCampaigns
            };
        case START_LOAD_CAMPAIGNS:
            return {
                ...state,
                loadingCampaigns: true
            };
        case GET_SETTINGS_DATA_REQUEST:
            return {
                ...state,
                loadingSettingsData: true,
            };
        case GET_SETTINGS_DATA_SUCCESS:
            return {
                ...state,
                loadingSettingsData: false,
                settingsData: action?.payload,
            };
        case UPDATE_SETTINGS_DATA:
            let existingSettingsData = state.settingsData || {};
            let updatedSettingData = action.payload || {};

            Object.keys(updatedSettingData).forEach(updatedField => {
                if (existingSettingsData[updatedField]) {
                    let updatingObj = existingSettingsData[updatedField];
                    let newObj = updatedSettingData[updatedField];
                    updatedSettingData[updatedField] = {
                        ...updatingObj,
                        ...newObj
                    }
                }
            })

            let mergeSettingData = {...existingSettingsData, ...updatedSettingData};

            return {
                ...state,
                settingsData: mergeSettingData,
            };
        case GET_SETTINGS_DATA_FAIL:
            return {
                ...state,
                loadingSettingsData: false,
                settingsData: {}
            };
        case SETTINGS_RESET_PASSWORD_TOKEN_REQUEST:
            return {
                ...state,
                resetPasswordToken: action?.payload,
                resetModalConfirmLoading: true,
            };
        case SETTINGS_RESET_PASSWORD_TOKEN_SUCCESS:
            return {
                ...state,
                resetPasswordToken: action.payload?.reset_token || null,
                resetModalConfirmLoading: false,
            };
        case SETTINGS_RESET_PASSWORD_TOKEN_FAIL:
            return {
                ...state,
                resetPasswordToken: action.payload?.reset_token || null,
                resetModalConfirmLoading: false,
            };
        case SET_DOM_READY:
            return {
                ...state,
                domReady: action.payload
            };
        case EXTEND_TOKEN_VALIDITY_REQUEST:
            return {
                ...state,
                extend_token: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
        case EXTEND_TOKEN_VALIDITY_SUCCESS:
            return {
                ...state,
                extend_token: {
                    loading: false,
                    error: false,
                    success: true,
                },
                siteInfo: {
                    ...state?.siteInfo,
                    token_validuntil: action.payload?.token_validuntil,
                }
            };
        case EXTEND_TOKEN_VALIDITY_FAIL:
            return {
                ...state,
                extend_token: {
                    loading: false,
                    error: action?.payload || true,
                    success: false,
                }
            };
        case EXTEND_TOKEN_VALIDITY_RESET:
            return {
                ...state,
                extend_token: {}
            };
        case CLEAR_MOODLE_NOTIFICATION:
            return {
                ...state,
                siteInfo: {
                    ...state?.siteInfo,
                    notification: {},
                }
            };
        case SET_USER_STATUS_CHECKED:
            return {
                ...state,
                userStatusChecked: true,
            }
        case SET_SSO_USER_ADMIN: {
            return {
                ...state,
                isSsoAdmin: !!action?.payload,
            }
        }
        case GET_SAC_COURSES_REQUEST:
            return {
                ...state,
                sac_categories: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
        case GET_SAC_COURSES_SUCCESS:
            return {
                ...state,
                sac_categories: {
                    loading: false,
                    error: false,
                    success: true,
                    ...action?.payload
                },
            };
        case GET_SAC_COURSES_FAIL:
            return {
                ...state,
                sac_categories: {
                    loading: false,
                    error: action?.payload.message || true,
                    success: false,
                }
            };
        case NOTIFICATION_ADD_REDIRECT:
            return {
                ...state,
                redirect_notify: {
                    ...action?.payload,
                    show: true,
                }
            };
        case NOTIFICATION_CLEAR_REDIRECT:
            return {
                ...state,
                redirect_notify: {
                    show: false,
                }
            };
        default:
            return state;

    }
}
