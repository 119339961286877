import Variable from './constants/variables';
import {setupSite} from "./service";
import {delay, getUserToken} from "./helper";

export const launchAPP = async (beforeStart) => {
    const token = getUserToken();

    if (typeof beforeStart === 'function') {
        beforeStart();
    }
    if (!token) {
        return false;
    }
    const siteInfo = await setupSite(token);

    if (siteInfo === 'Failed to fetch') {
        return {
            error: 500,
            exception: siteInfo
        }
    }

    if (siteInfo.errorcode && siteInfo.errorcode === "invalidtoken") {
        // localStorage.removeItem('_token');
        loginRedirecting();
        return false;
    }

    return siteInfo;
};

export const logoutToHost = (redirect = null) => {
    setTimeout(() => {
        window.location.href = redirect ?
            `${Variable.MOODLE_HOST}/local/bridge/logout.php?redirect=${redirect}` :
            `${Variable.MOODLE_HOST}/local/bridge/logout.php`;
        markSSORedirectionRequired();
        localStorage.removeItem('_token');
        localStorage.removeItem('_lang');
    }, 0);
}

export const logoutInApp = (redirect = null) => {
    setTimeout(() => {
        localStorage.removeItem('_token');
        localStorage.removeItem('_lang');
        window.location.href = redirect ?
            `${redirect}` :
            `/login`;
    }, 0);
}

export const ssoRedirecting = (idpPath) => {
    if (idpPath) {
        window.location.href = `${Variable.MOODLE_HOST}${idpPath}`;
        return;
    }
    window.location.href = `${Variable.MOODLE_HOST}${Variable.LAUNCH_URL_PATH}`;
}

export const loginRedirecting = (expired = false) => {
    if (window.location.pathname !== '/login') {
        window.location.href = expired ? '/login?expired=true' : '/login';
    }
}

/**
 * Prevent infinite redirection - mark auto-redirection
 */
export function markSSORedirectionRequired() {
    let redirection = Number(localStorage.getItem('_sso_required'));
    localStorage.setItem('_sso_redirection', ++redirection);
}

export default {launchAPP, logoutToHost, logoutInApp};