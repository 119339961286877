import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button} from "antd";
import {useSelector} from "react-redux";

const PageNotFound = () => {
    const userStatusChecked = useSelector((state) => !!state?.root?.userStatusChecked);
    const {t} = useTranslation();
    return (userStatusChecked &&
        <>
            <div className={'page_not_found'}>
                <div className="container">
                    <h1>404<br />{t('studentapp_notfound_title')}</h1>
                    <p>{t('studentapp_notfound_message')}</p>
                    <div className="text-center">
                        <Link to='/'>
                            <Button className={'default'}>{t('pageutilities_gotomainpage')}</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PageNotFound;