import React from 'react';

function ProfilePage() {
    return (
        <div>
            profile page
        </div>
    );
}

export default ProfilePage;