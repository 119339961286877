import {
    DEFAULT_LMS_DATA,
    APPR_LOAD_APPRDATA_REQUEST,
    APPR_LOAD_APPRDATA_SUCCESS,
    APPR_LOAD_APPRDATA_FAIL,
    APPR_IMAGE_CHANGE_SUCCESS,
    APPR_COLORS_RESET_SUCCESS,
    APPR_COLORS_CHANGE_SUCCESS,
    APPR_SAVE_APPRDATA_SUCCESS,
    APPR_SAVE_APPRDATA_FAIL,
    APPR_SAVE_APPRDATA_REQUEST,
    APPR_SAVE_APPRDATA_RESET,
    APPR_SAVE_TEMPLATE_REQUEST,
    APPR_SAVE_TEMPLATE_FAIL,
    APPR_SAVE_TEMPLATE_SUCCESS,
    APPR_SAVE_TEMPLATE_RESET,
    APPR_LOAD_TEMPLATE_REQUEST,
    APPR_LOAD_TEMPLATE_SUCCESS,
    APPR_LOAD_TEMPLATE_FAIL,
} from "../actiontypes/apprConstants";

const initialState = {
    fileList: DEFAULT_LMS_DATA.files,
    colors: DEFAULT_LMS_DATA.colors,
};

export const apprReducer = (state = initialState, action) => {
    switch (action.type) {
        case APPR_LOAD_APPRDATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case APPR_LOAD_APPRDATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                colorbar: '',
                fileList: action.payload.results.files || DEFAULT_LMS_DATA.files,
                colors: {
                    navbar_color: action.payload.results.colors.drawer || DEFAULT_LMS_DATA.colors.navbar_color,
                    primary_color: action.payload.results.colors.button || DEFAULT_LMS_DATA.colors.primary_color,
                    links_color: action.payload.results.colors.primary || DEFAULT_LMS_DATA.colors.links_color,
                }
            };
        case APPR_LOAD_APPRDATA_FAIL:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case APPR_IMAGE_CHANGE_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                error: false,
                fileList: action.payload.newFileList
            };
        case APPR_COLORS_RESET_SUCCESS:
            return {
                ...state,
                colors: action.payload.colors
            }
        case APPR_COLORS_CHANGE_SUCCESS:
            return {
                ...state,
                colorbar: action.payload.colorbar,
                colors: {
                    navbar_color: action.payload.colors.navbar_color || DEFAULT_LMS_DATA.colors.navbar_color,
                    primary_color: action.payload.colors.primary_color || DEFAULT_LMS_DATA.colors.primary_color,
                    links_color: action.payload.colors.links_color || DEFAULT_LMS_DATA.colors.links_color,
                }
            }
        case APPR_SAVE_APPRDATA_REQUEST:
            return {
                ...state,
                save_apprData: {
                    loading: true,
                    success: false,
                    error: false,
                    ...action.payload,
                }
            }
        case APPR_SAVE_APPRDATA_FAIL:
            return {
                ...state,
                save_apprData: {
                    ...state.save_apprData,
                    loading: false,
                    success: false,
                    error: true,
                }
            }
        case APPR_SAVE_APPRDATA_SUCCESS:
            return {
                ...state,
                save_apprData: {
                    ...state.save_apprData,
                    loading: false,
                    error: false,
                    success: action.payload?.success,
                }
            }
        case APPR_SAVE_APPRDATA_RESET:
            return {
                ...state,
                save_apprData: {}
            }
        case APPR_SAVE_TEMPLATE_REQUEST:
            return {
                ...state,
                save_template: {
                    loading: true,
                    success: false,
                    error: false,
                    ...action.payload,
                }
            }
        case APPR_SAVE_TEMPLATE_FAIL:
            return {
                ...state,
                save_template: {
                    ...state.save_template,
                    loading: false,
                    success: false,
                    error: true,
                }
            }
        case APPR_SAVE_TEMPLATE_SUCCESS:
            return {
                ...state,
                save_template: {
                    ...state.save_template,
                    loading: false,
                    error: false,
                    success: action.payload?.success,
                }
            }
        case APPR_SAVE_TEMPLATE_RESET:
            return {
                ...state,
                save_template: {}
            }
        case APPR_LOAD_TEMPLATE_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case APPR_LOAD_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                template_data: {
                    subject: action.payload.results.subject,
                    body: action.payload.results.body,
                    button_text: action.payload.results.button_text,
                    button_description: action.payload.results.button_description,
                    place_holders: action.payload.results.place_holders,
                }
            };
        case APPR_LOAD_TEMPLATE_FAIL:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
}
