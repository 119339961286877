import {
    REMEDIATION_DELETE_RULE_FAIL,
    REMEDIATION_DELETE_RULE_REQUEST,
    REMEDIATION_DELETE_RULE_RESET,
    REMEDIATION_DELETE_RULE_SUCCESS,
    REMEDIATION_ENABLE_RULE_FAIL,
    REMEDIATION_ENABLE_RULE_REQUEST,
    REMEDIATION_ENABLE_RULE_RESET,
    REMEDIATION_ENABLE_RULE_SUCCESS,
    REMEDIATION_GET_RULE_BY_ID_FAIL,
    REMEDIATION_GET_RULE_BY_ID_REQUEST,
    REMEDIATION_GET_RULE_BY_ID_SUCCESS,
    REMEDIATION_GET_RULES_FAIL,
    REMEDIATION_GET_RULES_REQUEST,
    REMEDIATION_GET_RULES_SUCCESS, REMEDIATION_LOAD_ACTION_FIELDS, REMEDIATION_LOAD_CONDITION_FIELDS,
    REMEDIATION_LOAD_INIT_DATA_FAIL,
    REMEDIATION_LOAD_INIT_DATA_REQUEST,
    REMEDIATION_LOAD_INIT_DATA_SUCCESS,
    REMEDIATION_RENAME_RULE_FAIL,
    REMEDIATION_RENAME_RULE_REQUEST,
    REMEDIATION_RENAME_RULE_SUCCESS,
    REMEDIATION_SAVE_RULE_FAIL,
    REMEDIATION_SAVE_RULE_REQUEST,
    REMEDIATION_SAVE_RULE_SUCCESS, REMEDIATION_SET_ACTION_ACTIVEKEY,
    REMEDIATION_SET_ACTION_SELECTED,
    REMEDIATION_SET_CONDITION_SELECTED,
    REMEDIATION_SET_HEADING, REMEDIATION_SET_SHOW_CONDITION,
    REMEDIATION_STATE_FORM_RESET,
} from "../actiontypes/remediationConstants";

const initialValue = {
    activeKey: 0,
    showConditionOption: true,
}

export const remediationReducers = (state = initialValue, action) => {
    switch (action.type) {
        case REMEDIATION_GET_RULES_REQUEST:
            return {
                ...state,
                getRules: {
                    loading: true,
                    success: false,
                    error: false,
                }
            }
        case REMEDIATION_GET_RULES_SUCCESS:
            return {
                ...state,
                getRules: {
                    loading: false,
                    success: true,
                    dynamicRules: action?.payload?.data?.dynamic_rules,
                    message: action?.payload?.message,
                    error: false,
                }
            }
        case REMEDIATION_GET_RULES_FAIL:
            return {
                ...state,
                getRules: {
                    loading: false,
                    success: false,
                    error: true,
                }
            }
        case REMEDIATION_GET_RULE_BY_ID_REQUEST:
            return {
                ...state,
                ruleData: {
                    loading: true,
                    success: false,
                    error: false,
                }
            }
        case REMEDIATION_GET_RULE_BY_ID_SUCCESS:
            return {
                ...state,
                ruleData: {
                    loading: false,
                    success: true,
                    data: action?.payload.data,
                    message: action?.payload?.message,
                    error: false,
                }
            }
        case REMEDIATION_GET_RULE_BY_ID_FAIL:
            return {
                ...state,
                ruleData: {
                    loading: false,
                    success: false,
                    error: true,
                }
            }
        case REMEDIATION_LOAD_INIT_DATA_REQUEST:
            return {
                ...state,
                init_loading: true,
                is_init_loaded: false,
                error: false,
            };
        case REMEDIATION_LOAD_INIT_DATA_SUCCESS:
            return {
                ...state,
                init_loading: false,
                is_init_loaded: true,
                error: false,
                remediationData: action?.payload?.data,
                ...action?.payload,
            };
        case REMEDIATION_LOAD_INIT_DATA_FAIL:
            return {
                ...state,
                init_loading: false,
                is_init_loaded: false,
                error: action?.payload,
            };
        case REMEDIATION_SET_CONDITION_SELECTED:
            return {
                ...state,
                condition_selected: action?.payload
            }
        case REMEDIATION_SET_ACTION_SELECTED:
            return {
                ...state,
                action_selected: action?.payload
            }
        case REMEDIATION_SET_HEADING:
            return {
                ...state,
                heading: action?.payload
            }
        case REMEDIATION_DELETE_RULE_REQUEST:
            return {
                ...state,
                loading: true,
                delete_rule: {
                    loading: true,
                    success: false,
                    error: false,
                },
            }
        case REMEDIATION_DELETE_RULE_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_rule: {
                    loading: false,
                    success: true,
                    message: action.payload.message,
                    error: false,
                },
            }
        case REMEDIATION_DELETE_RULE_FAIL:
            return {
                ...state,
                loading: false,
                delete_rule: {
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload.message,
                },
            }
        case REMEDIATION_DELETE_RULE_RESET:
            return {
                ...state,
                delete_rule: {},
            }
        case REMEDIATION_ENABLE_RULE_REQUEST:
            return {
                ...state,
                enable_rule: {
                    loading: true,
                    success: false,
                    error: false,
                },
            }
        case REMEDIATION_ENABLE_RULE_SUCCESS:
            return {
                ...state,
                enable_rule: {
                    loading: false,
                    success: action.payload.success,
                    message: action.payload.message,
                    error: false,
                },
            }
        case REMEDIATION_ENABLE_RULE_FAIL:
            return {
                ...state,
                enable_rule: {
                    loading: false,
                    success: action.payload.success,
                    error: true,
                    message: action.payload.message,
                },
            }
        case REMEDIATION_ENABLE_RULE_RESET:
            return {
                ...state,
                enable_rule: null,
            }
        case REMEDIATION_STATE_FORM_RESET:
            return {
                ...state,
                heading: '',
                delete_rule: null,
                action_selected: null,
                condition_selected: null,
                rename_rule: null,
                save_rule: null,
                ruleData: null,
                loadInitCondFields: false,
                loadInitActionFields: false,
                activeKey: 0
            }
        case REMEDIATION_RENAME_RULE_REQUEST:
            return {
                ...state,
                rename_rule: {
                    loading: true,
                    success: false,
                    error: false,
                }
            }
        case REMEDIATION_RENAME_RULE_SUCCESS:
            return {
                ...state,
                heading: action.payload,
                rename_rule: {
                    loading: false,
                    success: true,
                    error: false,
                }
            }
        case REMEDIATION_RENAME_RULE_FAIL:
            return {
                ...state,
                rename_rule: {
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload
                }
            }
        case REMEDIATION_SAVE_RULE_REQUEST:
            return {
                ...state,
                save_rule: {
                    loading: true,
                    success: false,
                    error: false,
                }
            }
        case REMEDIATION_SAVE_RULE_SUCCESS:
            return {
                ...state,
                save_rule: {
                    loading: false,
                    success: true,
                    error: false,
                    ruleid: action?.payload.ruleid
                }
            }
        case REMEDIATION_SAVE_RULE_FAIL:
            return {
                ...state,
                save_rule: {
                    loading: false,
                    success: false,
                    error: true,
                    message: action?.payload.message
                }
            }
        case REMEDIATION_SET_SHOW_CONDITION: {
            return {
                ...state,
                showConditionOption: action?.payload
            }
        }
        case REMEDIATION_SET_ACTION_ACTIVEKEY: {
            return {
                ...state,
                activeKey: action?.payload
            }
        }
        case REMEDIATION_LOAD_CONDITION_FIELDS: {
            return {
                ...state,
                loadInitCondFields: action?.payload
            }
        }
        case REMEDIATION_LOAD_ACTION_FIELDS: {
            return {
                ...state,
                loadInitActionFields: action?.payload
            }
        }
        default:
            return state;
    }
};
