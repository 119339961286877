import React, {useContext, useEffect, useState} from 'react';
import {LoginForm} from "../components/LoginForm";
import {Button} from "antd";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {ssoRedirecting} from "../../shared/provider";
import {ThemeContext} from "../../shared/ThemeContext";
import {get_user_login_info} from "../../shared/service";
import {LoginFACForm} from "../components/LoginFACForm";

function LoginPage({siteConfig}) {
    const searchParams = new URLSearchParams(window.location.search);
    const [hash, setHash] = useState(searchParams.get("session") || '');

    const [userExist, setUserExist] = useState(false);
    const [adminIdp, setAdminIdp] = useState(false);
    const [Fac, setFac] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [userIdentifier, setUserIdentifier] = useState('');
    const {login_background_img, login_logo} = useContext(ThemeContext);
    const {t} = useTranslation();

    useEffect(() => {
        if (!!hash) {
            setUserExist(true);
            setFac(false);
        }
    }, []);

    const onSubmitHandler = (e) => {
        // todo: get user by email/username -> not exist -> registration page(FortiCloud/Thru API) -> exist -> allow "continue with SSO"
        if (typeof e === 'object') {
            let formKeys = Object.keys(e);
            if (formKeys.length) {
                let identifier = e[formKeys.pop()];
                if (!identifier) {
                    return;
                }
                identifier = identifier.trim();
                setUserIdentifier(identifier);
                get_user_login_info(e).then(result => {
                    if (result) {
                        setFormSubmitted(true);
                        setUserExist(result.exist);
                        setAdminIdp(result.adminIdp || '');

                        // admin or sso setup tenant can access 'continue' part
                        const hasSSO = (result.hasOwnProperty('isAdmin') && !!result.isAdmin) || /\d+\b/.test(result.adminIdp);
                        setFac(hasSSO);
                    }
                })
            }
        }
    }

    const continueSSOHandler = (e) => {
        ssoRedirecting(adminIdp);
    }

    const loadedHash = () => {
        setUserExist(true);
        setFac(false);
    }

    return (
        <div id="signup_page" className="login-process-page">
            <div className="page_inner">
                <div className="form_container">
                    <div className="form_wrap">
                        <div className="page_header">
                            <div className="logo_wrap"
                                 style={{backgroundImage: `url('${siteConfig?.login_logo || login_logo}')`}}/>
                        </div>
                        {
                            userExist ?
                                (Fac ?
                                        <div className="continue_wrap login_process_page_content">
                                            <p className='welcome'>
                                                {t('studentapp_login_message')} <span>{userIdentifier}</span>
                                            </p>
                                            <Button onClick={continueSSOHandler} className='primary full-width'
                                                    type="primary"
                                                    htmlType="submit">
                                                {t('login_page_button_continue')}
                                            </Button>
                                        </div>
                                        :
                                        <LoginFACForm userIdentifier={userIdentifier}
                                                      setUserIdentifier={setUserIdentifier}
                                                      hash={hash}/>
                                )
                                :
                                <div className="login_process_page_content">
                                    <p className='welcome'>
                                        {t('login_page_main_header')}
                                    </p>
                                    <LoginForm setSubmitted={setFormSubmitted}
                                               userExist={userExist}
                                               submitted={formSubmitted}
                                               onSubmit={onSubmitHandler}
                                    />
                                </div>
                        }
                    </div>
                </div>
                <div className="bg_wrap"
                     style={{backgroundImage: `url('${siteConfig?.login_background_img || login_background_img}')`}}
                     id='bg_wrap'/>
            </div>
        </div>
    );
}


function mapStateToProps(state) {
    return {...state.root};
}

export default connect(
    mapStateToProps,
)(LoginPage);
