import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {Button, notification} from 'antd';
import {useHistory} from "react-router-dom";
import {
    ProfileOutlined
} from "@ant-design/icons";
import {
    get_course_content,
    program_enrol_user_to_course,
    update_user_course_lastaccess,
    video_get_activity_completion,
} from "../../shared/service";
import {connect} from "react-redux";
import ModuleBread from "../components/ModuleBread";
import ModuleVideoFrame from "../components/ModuleVideoFrame";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import Variable from "../../shared/constants/variables";
import {forceReloadCampaigns} from "../../shared/rootActions";
import {getCourseShortName} from "../../shared/helper";

function showCantAttemptQuiz(moduleName = '') {
    notification['info']({
        message: i18next.t('program_page_takethequiz_fail'),
        description: <div
            dangerouslySetInnerHTML={{__html: i18next.t('program_page_takethequiz_fail_content', {$a: i18next.t(getCourseShortName(moduleName), moduleName)})}}></div>,
    });
}

export function jumpIntoCourseQuiz(programid, courseid, courseName, _history, cb = null) {
    get_course_content(courseid).then(response => {
        if (Array.isArray(response)) {
            let firstAvailableSec = response.find(section => (section.visible && section.uservisible));
            if (firstAvailableSec && firstAvailableSec.modules) {
                let {modules} = firstAvailableSec;
                let firstQuizModule = modules.find(module => module.modname === "quiz");
                if (firstQuizModule) {
                    if (firstQuizModule.visible && firstQuizModule.uservisible) {
                        _history.push(`/programs/${programid}/courses/${courseid}/quizzes/${firstQuizModule?.instance}`);
                    } else {
                        showCantAttemptQuiz(courseName);
                    }
                } else {
                    notification['warning']({
                        message: i18next.t('studentapp_modulepage_coursequiz_err'),
                        description:
                            i18next.t('studentapp_modulepage_coursequiz_err_dsc'),
                    });
                }
            }
        } else {
            showCantAttemptQuiz(courseName);
        }
    }).finally(() => {
        if (typeof cb === 'function') {
            cb();
        }
    })
}

const ModulePage = ({campaigns: programs, siteInfo}) => {
    const history = useHistory();
    const [isNewEnrolled, setIsNewEnrolled] = useState(false);
    const [enrolling, setEnrolling] = useState(false);
    const [loadingQuiz, setLoadingQuiz] = useState(false);
    const {t} = useTranslation();

    const [program, setProgram] = useState(null);
    const [course, setCourse] = useState(null);
    const [firstVideoModule, setFirstVideoModule] = useState(null);

    const lang = siteInfo?.lang || Variable.DEFAULT_LANG;
    const userId = siteInfo?.userid || null;

    let {courseid} = useParams();
    let {programid} = useParams();

    useEffect(() => {
        if (programs?.hasOwnProperty(programid)) {
            let _program = programs[programid];
            if (_program) {
                setProgram(_program);
                if (_program.courses && _program.courses[courseid]) {
                    setCourse(_program.courses[courseid]);
                }
            }
        }
    }, [programs]);

    useEffect(() => {
        if (!course) {
            return;
        }

        let firstVideoModule = course.video_module.find(module => {
            let langValue = '(' + lang + ')';
            return module.video_availability.length && module.video_availability.includes(langValue);
        });

        if (firstVideoModule === undefined) {
            firstVideoModule = course.video_module.find(module => module.video_availability === '');
        }

        if (!userId || !firstVideoModule?.video_cmid) {
            return;
        }

        setFirstVideoModule(firstVideoModule);
        video_get_activity_completion(userId, firstVideoModule.video_cmid);
    }, [course])

    useEffect(() => {
        // enrol user into program course if it's not
        if (course && Object.keys(course).length && !course.isenrolled && program.programid) {
            setEnrolling(true);
            program_enrol_user_to_course(program.programid, courseid).then(status => {
                if (status) {
                    setIsNewEnrolled(true);
                }
            }).finally(() => {
                setEnrolling(false);
            })
        }

        if (course?.courseid && course?.isenrolled) {
            update_user_course_lastaccess(course.courseid);
        }
    }, [course, courseid, programid]);

    if (!course || !course?.video_module?.length) {
        return (
            <div className={'module_page'}>
                <div className="module_header">
                    <ModuleBread programid={programid}
                                 courseid={courseid}
                                 programName={t(program?.fullname, program?.fullname)}
                                 courseName={t(course?.name, course?.name)}/>
                    {/*      no data component         */}
                </div>
            </div>
        )
    }

    if (!firstVideoModule) {
        return (
            <div className={'module_page'}>
                <div className="module_header">
                    <ModuleBread programid={programid}
                                 courseid={courseid}
                                 programName={t(program?.fullname, program?.fullname)}
                                 courseName={t(course?.name, course?.name)}/>
                </div>
            </div>
        )
    }

    return (
        <div className={'module_page'}>
            <div className="module_header">
                <ModuleBread programid={programid}
                             courseid={courseid}
                             programName={t(program?.fullname, program?.fullname)}
                             courseName={t(course?.name, course?.name)}/>
                <div className="quiz_btn_wrap">
                    <Button icon={<ProfileOutlined/>} loading={loadingQuiz} onClick={() => {
                        if (!course) {
                            return false;
                        }
                        setLoadingQuiz(true);
                        video_get_activity_completion(userId, firstVideoModule?.video_cmid)
                            .then(response => {
                                if (!!response.complete) {
                                    jumpIntoCourseQuiz(programid, courseid, course.name, history, () => {
                                        setLoadingQuiz(false);
                                    });
                                    forceReloadCampaigns();
                                } else {
                                    showCantAttemptQuiz(course.name);
                                    setLoadingQuiz(false);
                                }
                            });
                    }} className={'secondary'}>{t('program_page_takethequiz')}</Button>
                    <p className='video-sgstn'>{t('studentapp_video_sgstn_mob')}</p>
                </div>
            </div>
            <div className="module_content text-center">
                <ModuleVideoFrame module={firstVideoModule} enrolling={enrolling}/>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {...state.root};
}

export default connect(
    mapStateToProps,
)(ModulePage);
