import Variable from "./constants/variables";
import {loginRedirecting} from "./provider";
import i18next from "i18next";
import moment from "moment";
import {FALLBACK_LOCALE_TRANSITION} from "./translation/fallback_str";
import variables from "./constants/variables";
import dayjs from "dayjs";
import {PointerSensor as LibPointerSensor} from '@dnd-kit/core'
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isSameOrBefore from "dayjs/esm/plugin/isSameOrBefore";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrBefore);

export const call = async (wsFunction, token = null, params = null, payload = null, removeBOM = false) => {
    const SERVER_URL = Variable.MOODLE_HOST + '/webservice/rest/server.php';
    let wsToken = token || getUserToken();

    if (!wsToken) {
        loginRedirecting();
        return;
    }

    let queryParam = {
        ...params,
        moodlewsrestformat: 'json',
        wsfunction: wsFunction,
        wstoken: wsToken
    };

    if (typeof payload === 'object') {
        let strData = recursiveObjectParamConverter(payload);
        queryParam = {
            ...queryParam, ...strData
        };
    }

    let query = encodeObjToParam(queryParam);

    if (removeBOM) {
        return fetch(`${SERVER_URL}?${query}`).then(async res => {
            return res.text();
        }).then(jsonTxt => {
            const cleanedText = jsonTxt.replace(/^\uFEFF/, ''); // Remove BOM if present
            return JSON.parse(cleanedText);
        })
            .catch(e => {
                console.error(e);
                return e.message;
            });
    }

    return fetch(`${SERVER_URL}?${query}`).then(async res => {
        return res.json();
    }).then(json => {
        return json;
    })
        .catch(e => {
            console.error(e);
            return e.message;
        });
};

export const localCall = async (wsFunction, token = null, params = null, payload = null) => {
    const SERVER_URL = Variable.LOCAL_HOST + '/webservice/rest/server.php';

    let queryParam = {
        ...params,
        moodlewsrestformat: 'json',
        wsfunction: wsFunction,
        // todo: use your local environment token
        wstoken: '156a91e019e4ca9aea69a586da3b422f'
    };

    if (typeof payload === 'object') {
        let strData = recursiveObjectParamConverter(payload);
        queryParam = {
            ...queryParam, ...strData
        };
    }

    let query = encodeObjToParam(queryParam);

    return fetch(`${SERVER_URL}?${query}`).then(async res => {
        return res.json();
    }).then(json => {
        return json;
    })
        .catch(e => {
            console.error(e);
            return false;
        });
};

const readFormErrors = (errors) => {
    if (Array.isArray(errors)) {
        return errors.reduce((obj, cur) => {
            return {...obj, [cur.field]: cur.message};
        }, {});
    }

    return errors;
}

export function encodeObjToParam(obj = {}) {
    var esc = encodeURIComponent;
    return Object.keys(obj)
        .map(k => esc(k) + '=' + esc(obj[k]))
        .join('&');
}

export function convertToElement(html) {
    const template = document.createElement('div');
    // Add a div to hold the content, that's the element that will be returned.
    template.innerHTML = '<div>' + html + '</div>';

    return template;
};

export const recursiveObjectParamConverter = (postData) => {
    let params = {};
    recursiveConverter('', postData);

    function recursiveConverter(comb, obj) {
        for (let key in obj) {
            let value = obj[key];
            if (typeof value === 'string' || typeof value === 'number') {
                params[`${comb}[${key}]`] = value;
            } else if (typeof value === 'boolean') {
                params[`${comb}[${key}]`] = value + 0; // boolean to int
            } else {
                if (comb) {
                    recursiveConverter(`${comb}[${key}]`, value);
                } else {
                    recursiveConverter(key, value);
                }
            }
        }
    }

    return params;
};

const convertValuesToString = (data, stripUnicode = false) => {
    const result = Array.isArray(data) ? [] : {};

    for (const key in data) {
        let value = data[key];

        if (value == null) {
            // Skip null or undefined value.
            continue;
        } else if (typeof value == 'object') {
            // Object or array.
            value = convertValuesToString(value, stripUnicode);
            if (value == null) {
                return null;
            }
        } else if (typeof value == 'string') {
            if (stripUnicode) {
                const stripped = stripUnicodeFuc(value);
                if (stripped != value && stripped.trim().length == 0) {
                    return null;
                }
                value = stripped;
            }
        } else if (typeof value == 'boolean') {
            /* Moodle does not allow "true" or "false" in WS parameters, only in POST parameters.
               We've been using "true" and "false" for WS settings "filter" and "fileurl",
               we keep it this way to avoid changing cache keys. */
            if (key == 'moodlewssettingfilter' || key == 'moodlewssettingfileurl') {
                value = value ? 'true' : 'false';
            } else {
                value = value ? '1' : '0';
            }
        } else if (typeof value == 'number') {
            value = String(value);
        } else {
            // Unknown type.
            continue;
        }

        if (Array.isArray(result)) {
            result.push(value);
        } else {
            result[key] = value;
        }
    }

    return result;
};

function stripUnicodeFuc(text) {
    let stripped = '';
    for (let x = 0; x < text.length; x++) {
        if (text.charCodeAt(x) <= 55295) {
            stripped += text.charAt(x);
        }
    }

    return stripped;
}

export function buildQuestionDataFromPlaintHTML(_html, lang = '') {
    const answerSelector = '.content .answer input:not([type="hidden"])';
    const questionSelector = '.content .qtext';
    const sequenceSelector = '.content .formulation>input';
    const feedbackSelector = '.content .feedback';
    const $element = convertToElement(_html);
    const $answers = Array.from($element.querySelectorAll(answerSelector));
    let questText = '';
    if ($element.querySelector(questionSelector)) {
        let $question = $element.querySelector(questionSelector);
        if (lang) {
            let $questionLangLabel = $question.querySelector(`.multilang[lang=${lang}]`);
            if ($questionLangLabel) {
                $question = $questionLangLabel;
            } else if (FALLBACK_LOCALE_TRANSITION.hasOwnProperty(lang)) {
                $question = $question.querySelector(`.multilang[lang=${FALLBACK_LOCALE_TRANSITION[lang]}]`) || $question;
            }
        }
        questText = $question.textContent || $question.innerText;
    }

    let sequencecheck = {};
    if ($element.querySelector(sequenceSelector)) {
        const $sequenceInput = $element.querySelector(sequenceSelector);
        sequencecheck = {
            name: $sequenceInput.name,
            value: $sequenceInput.value
        };
    }

    let feedback;
    if ($element.querySelector(feedbackSelector)) {
        const $feedback = $element.querySelector(feedbackSelector);
        feedback = $feedback.innerText || $feedback.textContent;
    }

    let answers = [];
    let answerName = '';
    let defaultValue = -1;
    let totalValue = 0;

    $answers.forEach((answer) => {
        let isInCorrect = 0;
        let label = '';
        const $parent = answer.parentNode;
        if ($parent) {
            if ($parent.className.includes('incorrect')) {
                isInCorrect = 1;
            } else if ($parent.className.includes('correct')) {
                isInCorrect = -1;
            }
        }
        const $label = $element.querySelector(`#${answer.id.replace(':', '\\:')}_label`);
        if ($label) {
            let $labelText = $label.querySelector('p') || $label.querySelector('div');
            if (lang) {
                let $labelLangText = $labelText.querySelector(`.multilang[lang=${lang}]`);
                if ($labelLangText) {
                    $labelText = $labelLangText;
                } else if (FALLBACK_LOCALE_TRANSITION.hasOwnProperty(lang)) {
                    $labelText = $labelText.querySelector(`.multilang[lang=${FALLBACK_LOCALE_TRANSITION[lang]}]`) || $labelText;
                }
            }
            label = $labelText.textContent || $labelText.innerText;
        }
        if (defaultValue < 0 && answer.checked) {
            defaultValue = answer.value;
        }
        answerName = answer.name;

        let stateClass = (isInCorrect === 0 ? 'selected' : (isInCorrect >= 1 ? 'incorrect' : 'correct'));
        totalValue += Number(answer.value);
        answers.push({
            id: answer.id,
            name: answer.name,
            value: answer.value,
            checked: answer.checked || false,
            disabled: answer.disabled,
            label: label ? label.trim() : label,
            isCorrect: isInCorrect < 0 || undefined,
            isInCorrect: (answer.checked && isInCorrect > 0) || undefined,
            state: answer.checked ? stateClass : 'unselected'
        });
    });

    // if all value same -> multiple answers will be supported later
    let isMulti = false;
    if (totalValue === 4) {
        answers.forEach((answer, key) => {
            if (answer.checked) {
                defaultValue = key;
            }
        });
        isMulti = true;
    }

    return {
        sequencecheck: sequencecheck,
        question: questText,
        answers: answers,
        name: answerName,
        value: defaultValue,
        feedback: feedback || undefined,
        isMulti: isMulti
    };
}

export const mapProgramArrayToObject = (programs, coursesOldKey = 'programcourses', coursesNewKey = 'courses') => {
    let programsObj = {};
    programs.forEach(program => {
        programsObj[program.id] = program;
        if (coursesOldKey && coursesNewKey && program.hasOwnProperty(coursesOldKey)) {
            let oldCourses = program[coursesOldKey] || [];
            let newCourses = {};
            oldCourses.forEach(course => {
                newCourses[course.courseid] = course;
            })
            program[coursesNewKey] = newCourses;
        }
    })
    return programsObj;
};

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const goToAdmin = () => {
    window.open(Variable.MOODLE_HOST, '_blank');
};

export function getProgramLatestCampaign(program) {
    if (!program.hasOwnProperty('certifications') || !Array.isArray(program.certifications) || !program.certifications.length) {
        return null;
    }

    let certifications = program.certifications.sort((certA, certB) => ((certA.id && certB.id) ? (certB.id - certA.id) : true));

    return certifications.length ? certifications[0] : null;
}

export function isOngoingCampaign(certification, currentDate) {
    if (certification.programidnumber === Variable.DEFAULT_PROGRAM_ID) {
        return true;
    }
    // no campaign tied program - invalid
    if (!certification) {
        return false;
    }
    // expiry date < today campaign - ended
    if (certification.expirydateabsolute > 0 && certification.expirydateabsolute < currentDate) {
        return false;
    }
    // start date < today campaign - not started
    if (certification.startdateabsolute > 0 && certification.startdateabsolute > currentDate) {
        return false;
    }
    const config = JSON.parse(certification.description || '{}');

    if (config.hasOwnProperty('launched') && !config.launched) {
        return false;
    }

    return true;
}

export function isProgramUnderSameTenant(program, currentTenant) {
    if (!!currentTenant && program && program.tenantid) {
        if (currentTenant !== program.tenantid) {
            return false;
        }
    }
    return true;
}

export function isLaunchedCampaign(certification, currentDate) {
    if (certification.programidnumber === Variable.DEFAULT_PROGRAM_ID) {
        return true;
    }
    // no campaign tied program - invalid
    if (!certification) {
        return false;
    }
    // start date < today campaign - not started
    if (certification.startdateabsolute > 0 && certification.startdateabsolute > currentDate) {
        return false;
    }
    const config = JSON.parse(certification.description || '{}');
    if (config.hasOwnProperty('launched') && config.launched) {
        return true;
    }

    return false;
}

export const switchLanguage = (lang) => {
    // mw has suffix _wp
    lang = lang.replace('_wp', '');
    storeLang(lang);
    i18nextChangeLanguage(lang)
    moment.locale(getStandardCurrentLangCode(lang));
}

/**
 * Customized func to switch language, if the new lang is the same type of language, e.g 'en' vs 'en_us' -> skip the changes
 * @param lang
 */
export const i18nextChangeLanguage = (lang = variables.DEFAULT_LANG) => {
    let newLang = lang;
    let currentLang = i18next.language;

    // current lang is equal to new one
    if (currentLang === 'en' || currentLang === 'en_au' || currentLang === 'en_us') {
        switch (lang) {
            case 'en':
            case 'en_us':
            case 'en_au':
                newLang = currentLang;
                break;
            default:
                newLang = lang;
                break;
        }
    }

    i18next.changeLanguage(newLang);
}

/**
 * reload Route without refresh
 * @param token
 */
export function storeToken(token) {
    localStorage.setItem('_token', token);
    window.history.replaceState(null, null, window.location.pathname);
}

/**
 * moodle wp lang comes with 'wp' suffix, need to clean
 * @param lang
 */
export function sanitizeLangStr(lang) {
    if (!lang) {
        return '';
    }
    if (typeof lang !== 'string') {
        return '';
    }
    return lang.replace('_wp', '').replace('Workplace', '');
}

export function storeLang(lang) {
    localStorage.setItem('_lang', lang);
}

export function getUserLang() {
    let currentLang = localStorage.getItem('_lang') || detectBrowserLanguage() || Variable.DEFAULT_LANG;

    // use the same english without reloading as they are the same text for now.
    switch (currentLang) {
        case 'en':
        case 'en_us':
        case 'en_au':
            currentLang = Variable.DEFAULT_LANG;
            break;
        default:
            break;
    }

    return currentLang;
}

export function getStandardCurrentLangCode(lang) {
    return lang.replace('-', '_');
}

export function getUserToken() {
    return localStorage.getItem('_token');
}

export function getSSORedirectionTimes() {
    return Number(localStorage.getItem('_sso_redirection'));
}

export const delay = ms => new Promise(res => setTimeout(res, ms));

export function shorten(str, maxLen, separator = ' ', suffix = '...') {
    if (str.length <= maxLen) return str;
    var lastSeparatorIndex = str.lastIndexOf(' ', maxLen);
    var middle = Math.round(maxLen / 2);

    return str.substr(0, lastSeparatorIndex >= middle ? lastSeparatorIndex : maxLen) + suffix;
}

export function authenticateUser() {
    return fetch(Variable.MOODLE_HOST + '/local/bridge/authorize.php').then(function (res) {
        return res.json();
    }).then(function (res) {
        return res && res.active;
    }).catch(function (e) {
        return false;
    })
}

export function detectBrowserLanguage() {
    let lang = Array.isArray(window.navigator.languages) && window.navigator.languages.length ? window.navigator.languages[0] : null;
    lang = lang || window.navigator.language;

    if (!lang.includes('-')) {
        return lang;
    }

    return lang.split('-').shift();
}

export function getCourseShortName(fullname) {
    const middle = ' - ';
    if (!fullname || typeof fullname !== 'string') {
        return '';
    }

    if (fullname.includes(middle)) {
        fullname = fullname.split(middle).join('-');
    }

    return fullname.replaceAll(' ', '-').toLowerCase();
}

export function loadPath(lang, namespace) {
    let loadPath = '';
    switch (namespace[0]) {
        case 'appearance':
            loadPath = `${Variable.MOODLE_HOST}/local/bridge/locales.php?plugin=local_appearance&locale={{lng}}`;
            break;
        default:
            loadPath = `${Variable.MOODLE_HOST}/local/bridge/locales.php?plugin=local_bridge&locale={{lng}}`;
            break;
    }
    return loadPath;
}

export const VALID_URL_REG = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export function isValidURL(string) {
    return string.match(VALID_URL_REG);
}

// utility
export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const parseAjaxResponseData = async (response) => {
    let parsedData = {
        success: false,
        exception: 'Failed to connect the server',
    };

    if (!response) {
        return parsedData;
    }

    let responseData = response.data || response;

    if (response.json && typeof response.json === 'function') {
        responseData = await responseData.json();
    }

    if (Array.isArray(responseData)) {
        responseData = [...responseData].shift();
    }

    if (!responseData) {
        return parsedData;
    }

    return responseData.data || responseData;
};

export function hexToRgb(hex, syntax = true) {
    // Remove the # character, if present
    hex = hex.replace('#', '');

    // Convert the hex string to a number
    var num = parseInt(hex, 16);

    // Extract the red, green, and blue components
    var red = (num >> 16) & 255;
    var green = (num >> 8) & 255;
    var blue = num & 255;

    if (syntax) {
        return 'rgb(' + red + ', ' + green + ', ' + blue + ')';
    }

    return `${red},${green},${blue}`
}

export function debounce(func, timeout = 300) {
    let timer;

    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export function disableTimeByTimezone(currentVal, timezone, offsetHr = 1) {
    var disabledHours = [];
    const relativeToday = dayjs().tz(timezone).startOf('day');
    const startdate = dayjs().tz(timezone);
    const timecontrol = currentVal?.tz(timezone) || dayjs().tz(timezone);

    if (timecontrol?.startOf('day').isSame(relativeToday)) {
        const disableFrom = startdate.add(offsetHr, 'hour');
        const disableFromHour = disableFrom.hour();

        for (let i = 0; i < disableFromHour; i++) {
            disabledHours.push(i);
        }
    }

    return {
        disabledHours: () => disabledHours,
        disabledMinutes: () => [],
        disabledSeconds: () => [],
    };
}

export function sso_logout_required(auth_type) {
    if (!auth_type) {
        return false;
    }

    switch (auth_type) {
        case 'tenant_sso':
        case 'saml2':
        case 'forticloud':
            return true;
        default:
            return false;
    }
}

export class PointerSensor extends LibPointerSensor {
    static activators = [
        {
            eventName: 'onPointerDown',
            handler: ({nativeEvent: event}) => {
                if (event.target.tagName.toLowerCase() !== 'span') {
                    return true;
                }
            }
        }
    ]
}

export const isDateValueInFuture = (value, timezone) => {
    const timeNow = dayjs().tz(timezone);
    const selectedValue = value?.tz(timezone, true).startOf('hour');
    return timeNow.isSameOrBefore(selectedValue);
};

export const hasAlternativePermission = (portals) => {
    let hasPermission = false;
    if (!portals) {
        return hasPermission;
    }
    if (portals.hasOwnProperty(variables.SATS_APPLICATION_ID)) {
        const portalOne = portals[variables.SATS_APPLICATION_ID];
        if (portalOne.hasOwnProperty('visibility')) {
            hasPermission |= portalOne.visibility
        }
    }

    if (portals.hasOwnProperty(variables.FORTIPHISH_APPLICATION_ID)) {
        const portalTwo = portals[variables.FORTIPHISH_APPLICATION_ID];
        if (portalTwo.hasOwnProperty('visibility')) {
            hasPermission |= portalTwo.visibility
        }
    }

    return hasPermission;
}

/**
 * convert the time to client's timezone based on the 'server' timezone
 * @param timestamp
 * @param timezone
 * @returns {*}
 */
export function convertTimestamp(timestamp, timezone = variables.DEFAULT_SERVER_TIMEZONE) {
    return dayjs.unix(timestamp).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
}