import {createStore, compose, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from "./reducer-index";

export const composeEnhancers = process.env.NODE_ENV !== 'production'
&& typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

function configureStore(initialState = {}) {
    const middlewares = [
        thunk
    ];

    const enhancers = [
        applyMiddleware(...middlewares)
    ];

    return createStore(
        rootReducer,
        initialState,
        composeEnhancers(...enhancers)
    );
}

const sharedStore = configureStore()

export default sharedStore;