export const fakeDashboardData = {
    "overall_completed_rate": 46,
    "overall_in_progress_rate": 36,
    "overall_not_started_rate": 100,
    "active_campaigns": 4,
    "escalation_rate": 4.5,
    "selector_records": {
        "19": "InfoSec Campaign 1",
        "20": "InfoSec Campaign 2",
        "30": "Beta Test 1",
        "45": "New test campaign",
        "64": "adding new campaign",
        "65": "adding new campaign"
    },
    "table_records": [
        {
            "campaign": "New Hires",
            "key": 19,
            "rate": 40,
            "is_module": false,
            "active": false,
            "students": [
                174,
                221,
                156,
                161
            ],
            "allocated_users": 64,
            "progress": [
                {
                    "name": "Completed",
                    "value": 40,
                    "color": "#58A55C"
                },
                {
                    "name": "In progress",
                    "value": 20,
                    "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                },
                {
                    "name": "Not started",
                    "value": 40,
                    "color": "#CFD8DC"
                }
            ],
            "progressMap": {
                "completed": 40,
                "in_progress": 20,
                "not_started": 40
            }
        },
        {
            "campaign": "Managers",
            "key": 20,
            "rate": 0,
            "is_module": false,
            "active": true,
            "students": [
                161,
                162
            ],
            "allocated_users": 34,
            "progress": [
                {
                    "name": "Completed",
                    "value": 0,
                    "color": "#58A55C"
                },
                {
                    "name": "In progress",
                    "value": 66,
                    "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                },
                {
                    "name": "Not started",
                    "value": 34,
                    "color": "#CFD8DC"
                }
            ],
            "progressMap": {
                "completed": 0,
                "in_progress": 66,
                "not_started": 34
            }
        },
        {
            "campaign": "Sales",
            "key": 30,
            "rate": 40,
            "is_module": false,
            "active": true,
            "students": [
                174,
                221,
                156,
                161
            ],
            "allocated_users": 146,
            "progress": [
                {
                    "name": "Completed",
                    "value": 40,
                    "color": "#58A55C"
                },
                {
                    "name": "In progress",
                    "value": 20,
                    "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                },
                {
                    "name": "Not started",
                    "value": 40,
                    "color": "#CFD8DC"
                }
            ],
            "progressMap": {
                "completed": 40,
                "in_progress": 20,
                "not_started": 40
            }
        },
        {
            "campaign": "Marketing",
            "key": 45,
            "rate": 0,
            "is_module": false,
            "active": true,
            "students": [
                161,
                162
            ],
            "allocated_users": 254,
            "progress": [
                {
                    "name": "Completed",
                    "value": 0,
                    "color": "#58A55C"
                },
                {
                    "name": "In progress",
                    "value": 50,
                    "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                },
                {
                    "name": "Not started",
                    "value": 50,
                    "color": "#CFD8DC"
                }
            ],
            "progressMap": {
                "completed": 0,
                "in_progress": 50,
                "not_started": 50
            }
        }
    ],
    "module_records": {
        "19": [
            {
                "campaign": "Data Security",
                "key": 8,
                "rate": 33,
                "is_module": true,
                "active": true,
                "students": [
                    "174",
                    "221",
                    "156"
                ],
                "allocated_users": 60,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 33,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 0,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 67,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 33,
                    "in_progress": 0,
                    "not_started": 67
                }
            },
            {
                "campaign": "Phishing",
                "key": 14,
                "rate": 0,
                "is_module": true,
                "active": true,
                "students": [
                    "161"
                ],
                "allocated_users": 61,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 0,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 100,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 0,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 0,
                    "in_progress": 100,
                    "not_started": 0
                }
            },
            {
                "campaign": "Data Privacy",
                "key": 15,
                "rate": 100,
                "is_module": true,
                "active": true,
                "students": [
                    "221"
                ],
                "allocated_users": 63,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 100,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 0,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 0,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 100,
                    "in_progress": 0,
                    "not_started": 0
                }
            }
        ],
        "20": [
            {
                "campaign": "Malware",
                "key": 13,
                "rate": 0,
                "is_module": true,
                "active": true,
                "students": [
                    "161",
                    "162"
                ],
                "allocated_users": 32,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 0,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 50,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 50,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 0,
                    "in_progress": 50,
                    "not_started": 50
                }
            },
            {
                "campaign": "Phishing",
                "key": 14,
                "rate": 0,
                "is_module": true,
                "active": true,
                "students": [
                    "161"
                ],
                "allocated_users": 34,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 0,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 100,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 0,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 0,
                    "in_progress": 100,
                    "not_started": 0
                }
            }
        ],
        "30": [
            {
                "campaign": "Data Security",
                "key": 8,
                "rate": 33,
                "is_module": true,
                "active": true,
                "students": [
                    "174",
                    "221",
                    "156"
                ],
                "allocated_users": 145,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 33,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 0,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 67,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 33,
                    "in_progress": 0,
                    "not_started": 67
                }
            },
            {
                "campaign": "Phishing",
                "key": 14,
                "rate": 0,
                "is_module": true,
                "active": true,
                "students": [
                    "161"
                ],
                "allocated_users": 145,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 0,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 100,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 0,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 0,
                    "in_progress": 100,
                    "not_started": 0
                }
            },
            {
                "campaign": "Data Privacy",
                "key": 15,
                "rate": 100,
                "is_module": true,
                "active": true,
                "students": [
                    "221"
                ],
                "allocated_users": 146,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 100,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 0,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 0,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 100,
                    "in_progress": 0,
                    "not_started": 0
                }
            }
        ],
        "45": [
            {
                "campaign": "Malware",
                "key": 13,
                "rate": 0,
                "is_module": true,
                "active": true,
                "students": [
                    "161",
                    "162"
                ],
                "allocated_users": 253,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 0,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 50,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 50,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 0,
                    "in_progress": 50,
                    "not_started": 50
                }
            },
            {
                "campaign": "Phishing",
                "key": 14,
                "rate": 0,
                "is_module": true,
                "active": true,
                "students": [
                    "161"
                ],
                "allocated_users": 254,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 0,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 100,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 0,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 0,
                    "in_progress": 100,
                    "not_started": 0
                }
            },
            {
                "campaign": "Bad Actors",
                "key": 17,
                "rate": 0,
                "is_module": true,
                "active": true,
                "students": [
                    "161"
                ],
                "allocated_users": 254,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 0,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 0,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 100,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 0,
                    "in_progress": 0,
                    "not_started": 100
                }
            }
        ],
        "64": [
            {
                "campaign": "Malware",
                "key": 13,
                "rate": 0,
                "is_module": true,
                "active": true,
                "students": [
                    "161",
                    "162"
                ],
                "allocated_users": 0,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 0,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 50,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 50,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 0,
                    "in_progress": 50,
                    "not_started": 50
                }
            },
            {
                "campaign": "Phishing",
                "key": 14,
                "rate": 0,
                "is_module": true,
                "active": true,
                "students": [
                    "161"
                ],
                "allocated_users": 0,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 0,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 100,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 0,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 0,
                    "in_progress": 100,
                    "not_started": 0
                }
            }
        ],
        "65": [
            {
                "campaign": "Malware",
                "key": 13,
                "rate": 0,
                "is_module": true,
                "active": true,
                "students": [
                    "161",
                    "162"
                ],
                "allocated_users": 0,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 0,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 50,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 50,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 0,
                    "in_progress": 50,
                    "not_started": 50
                }
            },
            {
                "campaign": "Phishing",
                "key": 14,
                "rate": 0,
                "is_module": true,
                "active": true,
                "students": [
                    "161"
                ],
                "allocated_users": 0,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 0,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 100,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 0,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 0,
                    "in_progress": 100,
                    "not_started": 0
                }
            },
            {
                "campaign": "Bad Actors",
                "key": 17,
                "rate": 0,
                "is_module": true,
                "active": true,
                "students": [
                    "161"
                ],
                "allocated_users": 0,
                "progress": [
                    {
                        "name": "Completed",
                        "value": 0,
                        "color": "#58A55C"
                    },
                    {
                        "name": "In progress",
                        "value": 0,
                        "color": "repeating-linear-gradient(50deg,#f1be42,#f1be42 5px,#fff 0,#fff 6px)"
                    },
                    {
                        "name": "Not started",
                        "value": 100,
                        "color": "#CFD8DC"
                    }
                ],
                "progressMap": {
                    "completed": 0,
                    "in_progress": 0,
                    "not_started": 100
                }
            }
        ]
    },
    "users_count": 498,
    "total_students_number": 6,
    "user_firstname": "Zhuoqin"
}

export const fakeFortiphishData = {
    "items": [
        {
            "uuid": "a23514c3-9816-40a5-a009-4f3f223a5f99",
            "account_id": 1297743,
            "template_uuid": "c2ec2828-6ef9-4e54-bfb0-3b91df11e4d5",
            "landing_use_generic": false,
            "landing_uuid": "e8568427-6906-4a6c-89e9-b12ae0a34aff",
            "redirect_url": "https://www.amazon.com",
            "created_at": 1635430315,
            "updated_at": 1635542254,
            "deleted_at": null,
            "scheduled_at": 1635430315,
            "duration": 7,
            "launch_started_at": 1635430374,
            "launch_finished_at": 1635430434,
            "expected_completed_at": 1636035174,
            "completed_at": 1635542254,
            "name": "Ftnt Academy Campaign",
            "status": "completed",
            "reports": [
                {
                    "campaign_uuid": "a23514c3-9816-40a5-a009-4f3f223a5f99",
                    "target_uuid": "52c9b906-4710-4a6b-8872-9e485c2a72b4",
                    "usergroup_uuid": "de2894ed-463c-4515-b9ec-cdd0708492b5",
                    "recipient_uuid": "3dc2209d-7c95-4dad-acb4-a5c9db9e88d6",
                    "account_id": 1297743,
                    "created_at": 1635430374,
                    "updated_at": 1635430642,
                    "sent_at": 1635430376,
                    "opened_at": 1635430642,
                    "clicked_at": 1635430642,
                    "submitted_at": 0,
                    "reported_at": 0,
                    "source_ip": "76.69.148.159",
                    "error_id": 0
                },
                {
                    "campaign_uuid": "a23514c3-9816-40a5-a009-4f3f223a5f99",
                    "target_uuid": "cd2e0e7e-38c7-4c45-a0ee-32381a4192e9",
                    "usergroup_uuid": "de2894ed-463c-4515-b9ec-cdd0708492b5",
                    "recipient_uuid": "16f79b8b-c5da-47be-9b69-3cbbdd595bdb",
                    "account_id": 1297743,
                    "created_at": 1635430374,
                    "updated_at": 1635430377,
                    "sent_at": 1635430377,
                    "opened_at": 0,
                    "clicked_at": 0,
                    "submitted_at": 0,
                    "reported_at": 0,
                    "source_ip": "",
                    "error_id": 0
                },
                {
                    "campaign_uuid": "a23514c3-9816-40a5-a009-4f3f223a5f99",
                    "target_uuid": "f7d81772-49fc-49b3-8db8-3344767fcb02",
                    "usergroup_uuid": "de2894ed-463c-4515-b9ec-cdd0708492b5",
                    "recipient_uuid": "dccc0434-6a22-45f4-b4c8-da453ba51e0e",
                    "account_id": 1297743,
                    "created_at": 1635430374,
                    "updated_at": 1635542175,
                    "sent_at": 1635430377,
                    "opened_at": 1635542168,
                    "clicked_at": 1635542168,
                    "submitted_at": 1635542175,
                    "reported_at": 0,
                    "source_ip": "68.56.134.247",
                    "error_id": 0
                }
            ]
        },
        {
            "uuid": "1e7a43e2-6478-48ed-bf4c-465ac2ab7bd8",
            "account_id": 1297743,
            "template_uuid": "734d5846-c95d-41de-b903-835f1d1383b9",
            "landing_use_generic": false,
            "landing_uuid": "",
            "redirect_url": "http://apple.com/",
            "created_at": 1635539926,
            "updated_at": 1635539926,
            "deleted_at": null,
            "scheduled_at": 1635539926,
            "duration": 7,
            "launch_started_at": 1635539934,
            "launch_finished_at": 1635539994,
            "expected_completed_at": 1636144734,
            "completed_at": 1636144794,
            "name": "Apple Test Campaign",
            "status": "completed",
            "reports": [
                {
                    "campaign_uuid": "1e7a43e2-6478-48ed-bf4c-465ac2ab7bd8",
                    "target_uuid": "52c9b906-4710-4a6b-8872-9e485c2a72b4",
                    "usergroup_uuid": "de2894ed-463c-4515-b9ec-cdd0708492b5",
                    "recipient_uuid": "dc56180f-aaea-4c1c-9869-dd61ae9b95b4",
                    "account_id": 1297743,
                    "created_at": 1635539934,
                    "updated_at": 1635539935,
                    "sent_at": 1635539935,
                    "opened_at": 0,
                    "clicked_at": 0,
                    "submitted_at": 0,
                    "reported_at": 0,
                    "source_ip": "",
                    "error_id": 0
                },
                {
                    "campaign_uuid": "1e7a43e2-6478-48ed-bf4c-465ac2ab7bd8",
                    "target_uuid": "cd2e0e7e-38c7-4c45-a0ee-32381a4192e9",
                    "usergroup_uuid": "de2894ed-463c-4515-b9ec-cdd0708492b5",
                    "recipient_uuid": "064975d2-fc0c-4796-9f94-5949fe63f94f",
                    "account_id": 1297743,
                    "created_at": 1635539934,
                    "updated_at": 1635539937,
                    "sent_at": 1635539937,
                    "opened_at": 0,
                    "clicked_at": 0,
                    "submitted_at": 0,
                    "reported_at": 0,
                    "source_ip": "",
                    "error_id": 0
                },
                {
                    "campaign_uuid": "1e7a43e2-6478-48ed-bf4c-465ac2ab7bd8",
                    "target_uuid": "f7d81772-49fc-49b3-8db8-3344767fcb02",
                    "usergroup_uuid": "de2894ed-463c-4515-b9ec-cdd0708492b5",
                    "recipient_uuid": "201db45b-17c3-4c82-933f-1cf1d7135ce9",
                    "account_id": 1297743,
                    "created_at": 1635539934,
                    "updated_at": 1635539935,
                    "sent_at": 1635539935,
                    "opened_at": 0,
                    "clicked_at": 0,
                    "submitted_at": 0,
                    "reported_at": 0,
                    "source_ip": "",
                    "error_id": 0
                }
            ]
        },
        {
            "uuid": "90ae2998-8941-42f6-8ac9-548b896f07f8",
            "account_id": 1297743,
            "template_uuid": "c2ec2828-6ef9-4e54-bfb0-3b91df11e4d5",
            "landing_use_generic": false,
            "landing_uuid": "e8568427-6906-4a6c-89e9-b12ae0a34aff",
            "redirect_url": "https://www.amazon.com",
            "created_at": 1635542388,
            "updated_at": 1635542388,
            "deleted_at": null,
            "scheduled_at": 1635542388,
            "duration": 7,
            "launch_started_at": 1635542394,
            "launch_finished_at": 1635542454,
            "expected_completed_at": 1636147194,
            "completed_at": 1636147254,
            "name": "Amazon Campaign 2",
            "status": "completed",
            "reports": [
                {
                    "campaign_uuid": "90ae2998-8941-42f6-8ac9-548b896f07f8",
                    "target_uuid": "52c9b906-4710-4a6b-8872-9e485c2a72b4",
                    "usergroup_uuid": "de2894ed-463c-4515-b9ec-cdd0708492b5",
                    "recipient_uuid": "997645b3-1cfa-4548-9f6b-25c183ee2646",
                    "account_id": 1297743,
                    "created_at": 1635542394,
                    "updated_at": 1635542396,
                    "sent_at": 1635542396,
                    "opened_at": 0,
                    "clicked_at": 0,
                    "submitted_at": 0,
                    "reported_at": 0,
                    "source_ip": "",
                    "error_id": 0
                },
                {
                    "campaign_uuid": "90ae2998-8941-42f6-8ac9-548b896f07f8",
                    "target_uuid": "cd2e0e7e-38c7-4c45-a0ee-32381a4192e9",
                    "usergroup_uuid": "de2894ed-463c-4515-b9ec-cdd0708492b5",
                    "recipient_uuid": "6fdfa28e-5f15-47d4-8204-de8bf452f601",
                    "account_id": 1297743,
                    "created_at": 1635542394,
                    "updated_at": 1635542396,
                    "sent_at": 1635542396,
                    "opened_at": 0,
                    "clicked_at": 0,
                    "submitted_at": 0,
                    "reported_at": 0,
                    "source_ip": "",
                    "error_id": 0
                },
                {
                    "campaign_uuid": "90ae2998-8941-42f6-8ac9-548b896f07f8",
                    "target_uuid": "f7d81772-49fc-49b3-8db8-3344767fcb02",
                    "usergroup_uuid": "de2894ed-463c-4515-b9ec-cdd0708492b5",
                    "recipient_uuid": "8201c6bd-5190-4733-ad88-6e68e4ee0095",
                    "account_id": 1297743,
                    "created_at": 1635542394,
                    "updated_at": 1635542444,
                    "sent_at": 1635542396,
                    "opened_at": 1635542444,
                    "clicked_at": 1635542444,
                    "submitted_at": 0,
                    "reported_at": 0,
                    "source_ip": "68.56.134.247",
                    "error_id": 0
                },
                {
                    "campaign_uuid": "90ae2998-8941-42f6-8ac9-548b896f07f8",
                    "target_uuid": "6780085a-1f37-4aa0-a52d-05549c782a95",
                    "usergroup_uuid": "6430de2a-6917-48e5-b003-3e6a6da8e24d",
                    "recipient_uuid": "63e4c7d6-01b5-4f19-990b-3108e39d59cc",
                    "account_id": 1297743,
                    "created_at": 1635542394,
                    "updated_at": 1635542396,
                    "sent_at": 1635542396,
                    "opened_at": 0,
                    "clicked_at": 0,
                    "submitted_at": 0,
                    "reported_at": 0,
                    "source_ip": "",
                    "error_id": 0
                }
            ]
        },
        {
            "uuid": "522a8f86-a0f8-47db-936c-ebde32a13d85",
            "account_id": 1297743,
            "template_uuid": "734d5846-c95d-41de-b903-835f1d1383b9",
            "landing_use_generic": false,
            "landing_uuid": "",
            "redirect_url": "http://apple.com/",
            "created_at": 1636337234,
            "updated_at": 1636337234,
            "deleted_at": null,
            "scheduled_at": 1636337234,
            "duration": 7,
            "launch_started_at": 1636337274,
            "launch_finished_at": 1636337334,
            "expected_completed_at": 1636942074,
            "completed_at": 1636942134,
            "name": "Apple Infosec",
            "status": "completed",
            "reports": [
                {
                    "campaign_uuid": "522a8f86-a0f8-47db-936c-ebde32a13d85",
                    "target_uuid": "52c9b906-4710-4a6b-8872-9e485c2a72b4",
                    "usergroup_uuid": "de2894ed-463c-4515-b9ec-cdd0708492b5",
                    "recipient_uuid": "d6c19f48-42a2-454f-b45c-080d83049faa",
                    "account_id": 1297743,
                    "created_at": 1636337274,
                    "updated_at": 1636337277,
                    "sent_at": 1636337277,
                    "opened_at": 0,
                    "clicked_at": 0,
                    "submitted_at": 0,
                    "reported_at": 0,
                    "source_ip": "",
                    "error_id": 0
                },
                {
                    "campaign_uuid": "522a8f86-a0f8-47db-936c-ebde32a13d85",
                    "target_uuid": "cd2e0e7e-38c7-4c45-a0ee-32381a4192e9",
                    "usergroup_uuid": "de2894ed-463c-4515-b9ec-cdd0708492b5",
                    "recipient_uuid": "c89bb15b-93a1-4c18-b9bc-21819643e004",
                    "account_id": 1297743,
                    "created_at": 1636337274,
                    "updated_at": 1636337277,
                    "sent_at": 1636337277,
                    "opened_at": 0,
                    "clicked_at": 0,
                    "submitted_at": 0,
                    "reported_at": 0,
                    "source_ip": "",
                    "error_id": 0
                },
                {
                    "campaign_uuid": "522a8f86-a0f8-47db-936c-ebde32a13d85",
                    "target_uuid": "f7d81772-49fc-49b3-8db8-3344767fcb02",
                    "usergroup_uuid": "de2894ed-463c-4515-b9ec-cdd0708492b5",
                    "recipient_uuid": "2af128e5-ff29-417d-ab68-29ba12a3dddf",
                    "account_id": 1297743,
                    "created_at": 1636337274,
                    "updated_at": 1636337277,
                    "sent_at": 1636337277,
                    "opened_at": 0,
                    "clicked_at": 0,
                    "submitted_at": 0,
                    "reported_at": 0,
                    "source_ip": "",
                    "error_id": 0
                }
            ]
        }
    ],
    "meta": {
        "startdate": 1631216452,
        "enddate": 1646858452,
        "campaigncount": 4
    }
};
