import React, {useEffect, useState} from 'react';
import {Form, Input, Button, Modal} from 'antd';
import {connect, useSelector} from "react-redux";
import provider, {logoutToHost} from "../../shared/provider";
import {ExclamationCircleFilled} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import Variable from "../../shared/constants/variables";
import {useDispatch} from "react-redux";
import {setting_get_reset_password_token} from "../../shared/rootActions";

const PasswordResetForm = ({type = 'link'}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const {t} = useTranslation();
    const {resetPasswordToken = null, resetModalConfirmLoading = false, tenantAdmin} =
        useSelector((state) => state.root || {});
    const auth_type = useSelector((state) => state?.root?.siteInfo?.auth_type);

    const layout = {
        labelCol: {span: 3},
        wrapperCol: {span: 12},
    };

    useEffect(() => {
        if (resetPasswordToken) {
            tenantAdmin ? provider.logoutToHost(Variable.DEFAULT_ADMIN_PASSWORD_FORGOT_LINK) :
                provider.logoutInApp(`/reset?resettoken=${resetPasswordToken}`);
        }
    }, [resetPasswordToken]);

    const handleSave = () => {
        form.validateFields().then(values => {
            form.resetFields();
            //call action api to handle password reset
        }).catch(() => {
            // Implement error handling
        });
    };

    const showResetModal = () => {
        setIsResetModalOpen(true);
    };

    const handleResetConfirm = () => {
        switch (auth_type) {
            case 'manual':
                dispatch(setting_get_reset_password_token());
                setIsResetModalOpen(false);
                break;
            case 'forticloud':
                window.open(Variable.DEFAULT_FORTI_CLOUD_RESET_PASSWORD_LINK, '_blank');
                logoutToHost();
                break;
            default:
                logoutToHost();
                return;
        }
    };

    const handleResetCancel = () => {
        setIsResetModalOpen(false);
    };

    return (
        <div>
            {
                type === 'form' && (
                    <Form
                        {...layout}
                        form={form}
                        layout={'vertical'}
                        name={'password-reset-form'}
                        onFinish={handleSave}
                    >
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                    message: t('studentapp_pwdreset_inputpwd_err', 'Please input your password!'),
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password/>
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: t('studentapp_pwdreset_cnfrmpwd_err', 'Please confirm your password!'),
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            setButtonDisabled(false);
                                            return Promise.resolve();
                                        }
                                        setButtonDisabled(true);
                                        return Promise.reject(new Error(t('studentapp_pwdreset_pwd_mtch_err', 'The two passwords that you entered do not match!')));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>
                        <Form.Item>
                            <Button disabled={buttonDisabled} type={'primary'} htmlType={'submit'}>
                                {t('studentapp_pwdreset_updt_pwd_err', 'Update Password')}
                            </Button>
                        </Form.Item>
                    </Form>
                )
            }
            {
                type === 'link' && (
                    <div className={'close_account_wrap'}>
                        <div className={'warning_txt'}>
                            <span>{t('setting_page_pswreset_notify', 'You will be logged out and redirected to an external page for password reset.')}</span>
                        </div>
                        <div className={'btn_wrap'}>
                            <Button className={'primary btn_change_password'}
                                    onClick={showResetModal}>{t('setting_page_button_pswreset', 'Change My Password')}</Button>
                        </div>
                    </div>
                )
            }

            <Modal className={'notification-modal'}
                   title={t('setting_page_pswreset', 'Password Reset')}
                   visible={isResetModalOpen}
                   confirmLoading={resetModalConfirmLoading}
                   onCancel={handleResetCancel}
                   footer={[
                       <Button key="submit"
                               type="primary"
                               onClick={handleResetConfirm}>{t('resetpassword_email_button_reset', 'Reset My Password')}</Button>
                   ]}
            >
                <p>{t('setting_page_pswreset_modal_content', 'You are about to logout before navigating to the Password Reset page.')}</p>
            </Modal>
        </div>
    )
}

export default PasswordResetForm
