import React from "react";
import { Collapse } from "antd";
const { Panel } = Collapse;


export default class TableMobileProgressBar extends React.Component {
    constructor(props) {
        super(props);
        this.progress = props.progress;
    }

    render() {
        return(
            <div className={`progress-data ${this.progress.name}-data`}>
                <span className={`progress-icon ${this.progress.name}-icon`}> </span>
                <span className='progress-percentage'>{this.progress.value}</span>% {this.progress.name}
            </div>
        );
    }
}
