import {
    DASHBOARD_CAMPAIGN_STATS_FAIL,
    DASHBOARD_CAMPAIGN_STATS_REQUEST,
    DASHBOARD_CAMPAIGN_STATS_SUCCESS,
    DASHBOARD_FORTIPHISH_DATA_FAIL,
    DASHBOARD_FORTIPHISH_DATA_REQUEST,
    DASHBOARD_FORTIPHISH_DATA_SUCCESS
} from "../actiontypes/dashboardConstants";
import i18next from "i18next";

export const dashboardStatsReducer = (state = {}, action) => {
    switch (action.type) {
        case DASHBOARD_CAMPAIGN_STATS_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                success: false
            };
        case DASHBOARD_CAMPAIGN_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
                data: action.payload,
            };
        case DASHBOARD_CAMPAIGN_STATS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            };
        default:
            return state;
    }
};

export const fortiPhishDataReducer = (state = {}, action) => {
    switch (action.type) {
        case DASHBOARD_FORTIPHISH_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                success: false
            };
        case DASHBOARD_FORTIPHISH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
                data: action.payload,
            };
        case DASHBOARD_FORTIPHISH_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
                message: i18next.t('custom_dashboard_loading_failed'),
            };
        default:
            return state;
    }
};