import React, {useEffect, useState} from 'react';
import {Checkbox, Select, Tooltip, Empty, Button, Spin} from "antd";
import {InfoCircleOutlined} from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {Doughnut} from "react-chartjs-2";
import CampaignBarChart from "./CampaignBarChart";
import {loadPhishData} from "../../redux/actions/dashboardActions";
import ProfileIcon from "../../../assets/images/dashboard/Profile.svg";
import ExclamationIcon from "../../../assets/images/dashboard/Exclamation.svg";
import GraphIcon from "../../../assets/images/dashboard/Graph.svg";
import GroupIcon from "../../../assets/images/dashboard/Group.svg";
import PaperIcon from "../../../assets/images/dashboard/Paper.svg";
import SwapIcon from "../../../assets/images/dashboard/Swap.svg";
import {fakeFortiphishData} from "./data/fakeData";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {useDispatch, useSelector} from "react-redux";
import variables from "../../../shared/constants/variables";
import dayjs from "dayjs";

const {Option} = Select;

const LABEL_MAP = {
    no_response: i18next.t('custom_dashboard_FortiPhish_Overview_campaign_stat_noresp', 'No response'),
    reported: i18next.t('custom_dashboard_FortiPhish_Overview_campaign_stat_reported', 'Reported'),
    opened: i18next.t('custom_dashboard_FortiPhish_Overview_campaign_stat_open', 'Open only'),
    clicked: i18next.t('custom_dashboard_FortiPhish_Overview_campaign_stat_link_clicked', 'Link Clicked'),
    submitted: i18next.t('custom_dashboard_FortiPhish_Overview_campaign_stat_submitted', 'Submitted'),
};

const ResponsesPieChart = ({data: _data}) => {
    const data = {
        labels: [i18next.t('custom_dashboard_FortiPhish_Overview_campaign_stat_noresp', 'No response'), i18next.t('custom_dashboard_FortiPhish_Overview_campaign_stat_submitted', 'Submitted'), i18next.t('custom_dashboard_FortiPhish_Overview_campaign_stat_link_clicked', 'Link Clicked'), i18next.t('custom_dashboard_FortiPhish_Overview_campaign_stat_open', 'Open only'), i18next.t('custom_dashboard_FortiPhish_Overview_campaign_stat_reported', 'Reported')],
        datasets: [{
            label: 'Overall Responses',
            data: _data,
            backgroundColor: [
                '#5FBE74',
                '#AB0000',
                '#EF2121',
                '#F1BE42',
                '#17E142',
            ],
            hoverOffset: 4,
            borderWidth: 4,
            cutout: '70%',
            responsive: true,
            maintainAspectRatio: false,
        }]
    };

    return (
        <div className="pie_chart_wrap">
            <Doughnut data={data}/>
        </div>
    );
}

const FortiPhishBlock = ({isDemoAccount}) => {
    const now = new Date();
    const dispatch = useDispatch();

    const allowFortiPhish = useSelector((state) => state?.root?.siteInfo?.is_fortiphish_api_enabled) || false;
    const fortiPhishData = useSelector((state) => state.fortiPhish);

    const CAMPAIGN_DISPLAY_LIMIT = 10;
    const [monthRange, setMonthRange] = useState(6);
    const [checkboxes, setCheckboxes] = useState({
        no_response: false,
        reported: false,
        opened: false,
        clicked: false,
        submitted: false,
    });
    const [chartData, setChartData] = useState(null);
    const [campaignData, setCampaignData] = useState(null);
    const [overviewData, setOverviewData] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        loadCampaignData();
    }, [monthRange]);

    useEffect(() => {
        calculateDisplayData();
    }, [campaignData]);

    useEffect(() => {
        if (fortiPhishData?.data?.items) {
            setCampaignData(fortiPhishData.data);
        }
    }, [fortiPhishData?.data])

    const loadCampaignData = () => {
        let fromDate = dayjs().subtract(monthRange, 'months').unix();
        let toDate = dayjs().unix();
        if (isDemoAccount) {
            setCampaignData(fakeFortiphishData);
            return;
        }
        dispatch(loadPhishData(fromDate, toDate));
    }

    const calculateDisplayData = () => {
        if (!campaignData || !campaignData.hasOwnProperty('items')) {
            return;
        }

        let overviewCount = {
            totalRecipients: 0,
            sent: 0,
            delivered: 0,
            opened: 0,
            openedOnly: 0,
            noResponse: 0,
            clicked: 0,
            // : )  status can be either clicked/submitted -> worst one
            clickedButSubmitted: 0,
            submitted: 0,
            reported: 0
        };
        let _campaignCount = {
            campaigns: [],
            delivered: [],
            opened: [],
            openedOnly: [],
            noResponse: [],
            clicked: [],
            clickedButSubmitted: [],
            submitted: [],
            reported: [],
            passed: []
        };
        // todo: calculate those and return from backend later : )
        let {items} = campaignData;
        if (!Array.isArray(items)) {
            return;
        }
        let recipientsMap = new Set();
        items.forEach((item, index) => {
            let reports = item.reports || [];
            let campaignCount = {
                sent: 0,
                delivered: 0,
                opened: 0,
                openedOnly: 0,
                noResponse: 0,
                clicked: 0,
                // : )  status can be either clicked/submitted -> worst one
                clickedButSubmitted: 0,
                submitted: 0,
                reported: 0,
                passed: 0
            };
            reports.forEach(report => {
                if (report.recipient_uuid) {
                    recipientsMap.add(report.recipient_uuid);
                }

                campaignCount.sent++;

                // delivered
                if (report.sent_at) {
                    campaignCount.delivered++;
                }

                // failed
                if (!report.clicked_at && !report.submitted_at) {
                    campaignCount.passed++;
                }

                // to do more actions, user have to open first
                if (report.opened_at) {
                    campaignCount.opened++;
                    if (!report.clicked_at && !report.submitted_at && !report.reported_at) {
                        campaignCount.openedOnly++;
                    } else {
                        if (report.clicked_at) {
                            campaignCount.clicked++;

                            if (report.submitted_at) {
                                campaignCount.submitted++;
                            } else {
                                campaignCount.clickedButSubmitted++;
                            }
                        }
                        if (report.reported_at) {
                            campaignCount.reported++;
                        }
                    }
                } else {
                    // no opened means no response
                    campaignCount.noResponse++;
                }
            });

            // overview values
            overviewCount.sent += campaignCount.sent;
            overviewCount.delivered += campaignCount.delivered;
            overviewCount.opened += campaignCount.opened;
            overviewCount.noResponse += campaignCount.noResponse;
            overviewCount.openedOnly += campaignCount.openedOnly;
            overviewCount.clicked += campaignCount.clicked;
            overviewCount.clickedButSubmitted += campaignCount.clickedButSubmitted;
            overviewCount.submitted += campaignCount.submitted;
            overviewCount.reported += campaignCount.reported;

            // chart values
            _campaignCount.delivered.push(campaignCount.delivered);
            _campaignCount.opened.push(campaignCount.opened);
            _campaignCount.noResponse.push(campaignCount.noResponse);
            _campaignCount.openedOnly.push(campaignCount.openedOnly);
            _campaignCount.clicked.push(campaignCount.clicked);
            _campaignCount.clickedButSubmitted.push(campaignCount.clickedButSubmitted);
            _campaignCount.submitted.push(campaignCount.submitted);
            _campaignCount.reported.push(campaignCount.reported);
            _campaignCount.passed.push(campaignCount.passed);

            _campaignCount.campaigns.push(item.name || '');
            _campaignCount[item.name] = campaignCount;

            return index <= CAMPAIGN_DISPLAY_LIMIT;
        });
        overviewCount.totalRecipients = recipientsMap.size;
        Object.keys(overviewCount).forEach((key, index) => {
            if (key === 'totalRecipients') {
                return true;
            }
            overviewCount[`${key}_percent`] = percentageCalculator(overviewCount[key], overviewCount.sent);
        })
        setOverviewData(overviewCount);
        setChartData(_campaignCount);
    }

    return (
        <>
            {
                allowFortiPhish && (
                    <div className="fortiphish_block">
                        <div className="head_bar">
                            <h4 className="chart_title">
                                {t('custom_dashboard_FortiPhish_Overview_title', 'FortiPhish Campaigns Overview')}
                            </h4>

                            <div className="bar_right d-flex">
                                {
                                    !!overviewData && overviewData.totalRecipients > 0 &&
                                    <Button className="primary fortiphish_nav_btn"
                                            href={variables.FORTIPHISH_HOST}
                                            target="__blank">{t('custom_dashboard_FortiPhish_Overview_goto_fortiphish_btn', 'Go to FortiPhish')}</Button>
                                }
                                <div className="date_range">
                                    <Select
                                        showSearch={false}
                                        // allowClear
                                        className='date_range_selector'
                                        placeholder="Last 6 months"
                                        optionFilterProp="children"
                                        onChange={(val) => {
                                            if (typeof val === 'number') {
                                                setMonthRange(val);
                                            }
                                        }}
                                        disabled={false}
                                        defaultValue={6}
                                    >
                                        <Option
                                            value={1}>{t('custom_dashboard_FortiPhish_Overview_option_lastmonth', 'Last 30 days')}</Option>
                                        <Option
                                            value={3}>{t('custom_dashboard_FortiPhish_Overview_option_lastthreemonths', 'Last 3 months')}</Option>
                                        <Option
                                            value={6}>{t('custom_dashboard_FortiPhish_Overview_lastsixmonths', 'Last 6 months')}</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {overviewData === null ? (<Spin size="large"/>) : overviewData && overviewData.totalRecipients === 0 ?
                            (<>
                                <Empty
                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                    description={
                                        <p>{t('custom_dashboard_FortiPhish_Overview_no_campaigndata', 'No FortiPhish Campaign Data')}</p>}
                                >
                                    <Button className="btn-wdt-lg" type="primary"
                                            href={variables.FORTIPHISH_HOST}
                                            target="__blank">{t('custom_dashboard_FortiPhish_Overview_goto_fortiphish_btn', 'Go to FortiPhish')}</Button>
                                </Empty>
                            </>)
                            : (<div className="phish_content">
                                <div className="status_wrap">
                                    <ul className="status_list">
                                        <Tooltip placement={'top'}
                                                 title={t('custom_dashboard_FortiPhish_Overview_total_recp_tooltip', 'The number of emails that have been sent.')}>
                                            <li className="col-md-4 col-lg-3 col-xl-2">
                                                <div className="icon_wrap default"><Icon
                                                    component={() => (<img src={ProfileIcon}/>)}/>
                                                </div>
                                                <div className="info_wrap">
                                                    <div className="status_title"
                                                         dangerouslySetInnerHTML={{__html: i18next.t('custom_dashboard_FortiPhish_Overview_total_recp_title', 'Total Recipients')}}></div>
                                                    <div className="status_num">{overviewData?.totalRecipients || 'N/A'}</div>
                                                </div>
                                            </li>
                                        </Tooltip>
                                        <Tooltip placement={'top'}
                                                 title={t('custom_dashboard_FortiPhish_Overview_delivered_tooltip', 'The number of emails that have been received successfully by recipients.')}>
                                            <li className="col-md-4 col-lg-3 col-xl-2">
                                                <div className="icon_wrap delivered"><Icon
                                                    component={() => (<img src={GraphIcon}/>)}/>
                                                </div>
                                                <div className="info_wrap">
                                                    <div
                                                        className="status_title">{t('custom_dashboard_FortiPhish_Overview_delivered_title', 'Delivered')}</div>
                                                    <div
                                                        className="status_percentage">{overviewData?.delivered_percent || 0}%
                                                    </div>
                                                    <div className="status_num">{overviewData?.delivered}</div>
                                                </div>
                                            </li>
                                        </Tooltip>
                                        <Tooltip placement={'top'}
                                                 title={t('custom_dashboard_FortiPhish_Overview_opened_tooltip', 'The number of emails that were opened but not clicked.')}>
                                            <li className="col-md-4 col-lg-3 col-xl-2">
                                                <div className="icon_wrap opened"><Icon component={() => (<img src={PaperIcon}/>)}/>
                                                </div>
                                                <div className="info_wrap">
                                                    <div
                                                        className="status_title">{t('custom_dashboard_FortiPhish_Overview_opened_title', 'Opened Only')}</div>
                                                    <div
                                                        className="status_percentage">{overviewData?.openedOnly_percent || 0}%
                                                    </div>
                                                    <div className="status_num">{overviewData?.openedOnly}</div>
                                                </div>
                                            </li>
                                        </Tooltip>
                                        <Tooltip placement={'top'}
                                                 title={t('custom_dashboard_FortiPhish_Overview_link_clikd_tooltip', 'The number of recipients who clicked the redirect link but not submitted.')}>
                                            <li className="col-md-4 col-lg-3 col-xl-2">
                                                <div className="icon_wrap clicked"><Icon component={() => (<img src={SwapIcon}/>)}/>
                                                </div>
                                                <div className="info_wrap">
                                                    <div
                                                        className="status_title">{t('custom_dashboard_FortiPhish_Overview_link_clikd_title', 'Link Clicked')}</div>
                                                    <div
                                                        className="status_percentage">{overviewData?.clicked_percent || 0}%
                                                    </div>
                                                    <div className="status_num">{overviewData?.clicked}</div>
                                                </div>
                                            </li>
                                        </Tooltip>
                                        <Tooltip placement={'top'}
                                                 title={t('custom_dashboard_FortiPhish_Overview_submitted_tooltip', 'The number of recipients who entered information on the phishing page.')}>
                                            <li className="col-md-4 col-lg-3 col-xl-2">
                                                <div className="icon_wrap submitted"><Icon
                                                    component={() => (<img src={GroupIcon}/>)}/>
                                                </div>
                                                <div className="info_wrap">
                                                    <div
                                                        className="status_title">{t('custom_dashboard_FortiPhish_Overview_submitted_title', 'Submitted')}</div>
                                                    <div
                                                        className="status_percentage">{overviewData?.submitted_percent || 0}%
                                                    </div>
                                                    <div className="status_num">{overviewData?.submitted}</div>
                                                </div>
                                            </li>
                                        </Tooltip>
                                        <Tooltip placement={'top'}
                                                 title={t('custom_dashboard_FortiPhish_Overview_reported_tooltip', 'The number of recipients who reported the email as suspicious.')}>
                                            <li className="col-md-4 col-lg-3 col-xl-2">
                                                <div className="icon_wrap reported"><Icon
                                                    component={() => (<img src={ExclamationIcon}/>)}/></div>
                                                <div className="info_wrap">
                                                    <div
                                                        className="status_title">{t('custom_dashboard_FortiPhish_Overview_reported_title', 'Reported')}</div>
                                                    <div
                                                        className="status_percentage">{overviewData?.reported_percent || 0}%
                                                    </div>
                                                    <div className="status_num">{overviewData?.reported}</div>
                                                </div>
                                            </li>
                                        </Tooltip>
                                    </ul>
                                </div>
                                <div className="chart_container">
                                    <div className="chart_member bar_chart">
                                        <div className="bar_chart_head">
                                            <div className="chart_title">
                                                {t('custom_dashboard_FortiPhish_Overview_campaign_stat_title', 'Campaign Stats')}
                                                <Tooltip placement="top"
                                                         title={<div
                                                             dangerouslySetInnerHTML={{__html: i18next.t('custom_dashboard_FortiPhish_Overview_campaign_stat_tooltip', 'This shows up to {{$a}} campaigns for the date range you selected', {$a: CAMPAIGN_DISPLAY_LIMIT})}}></div>}>
                                                    <InfoCircleOutlined/>
                                                </Tooltip>
                                            </div>
                                            {/*<ul className="status_checkboxes">*/}
                                            {/*    {*/}
                                            {/*        Object.keys(checkboxes).map(key => (*/}
                                            {/*            <li key={key}>*/}
                                            {/*                <Checkbox checked={checkboxes[key]} onChange={(e) => {*/}
                                            {/*                    checkboxesonChange(key, e);*/}
                                            {/*                }}>{LABEL_MAP[key]}</Checkbox>*/}
                                            {/*            </li>*/}
                                            {/*        ))*/}
                                            {/*    }*/}
                                            {/*</ul>*/}
                                        </div>
                                        <div className="chart_wrap">
                                            <CampaignBarChart
                                                campaignNames={chartData?.campaigns}
                                                response={chartData?.noResponse}
                                                opened={chartData?.openedOnly}
                                                reported={chartData?.reported}
                                                clicked={chartData?.clickedButSubmitted}
                                                submitted={chartData?.submitted}
                                                passed={chartData?.passed}
                                            />
                                        </div>
                                    </div>
                                    <div className="chart_member pie_chart">
                            <span
                                className="chart_title">{t('custom_dashboard_FortiPhish_Overview_campaign_stat_overall_resp', 'Overall Responses')}</span>
                                        <div className="chart_wrap">
                                            <ResponsesPieChart data={[
                                                overviewData?.noResponse,
                                                overviewData?.submitted,
                                                overviewData?.clickedButSubmitted,
                                                overviewData?.openedOnly,
                                                overviewData?.reported,
                                            ]}/>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                )
            }
        </>
    );
};

export const percentageCalculator = (percent, total) => {
    if (percent <= 0 || total <= 0) {
        return 0;
    }

    return Math.round((percent / total) * 100);
}

export default FortiPhishBlock;
