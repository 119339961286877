import {
    CAMPAIGN_ADD_LEARNER_FAIL,
    CAMPAIGN_ADD_LEARNER_MODAL,
    CAMPAIGN_ADD_LEARNER_REQUEST,
    CAMPAIGN_ADD_LEARNER_SUCCESS,
    CAMPAIGN_ARCHIVE_CAMPAIGN_FAIL,
    CAMPAIGN_ARCHIVE_CAMPAIGN_REQUEST,
    CAMPAIGN_ARCHIVE_CAMPAIGN_SUCCESS,
    CAMPAIGN_CURRENT_CAMPAIGN_NAME,
    CAMPAIGN_CURRENT_MODULE_REORDER,
    CAMPAIGN_DELETE_CAMPAIGN_FAIL,
    CAMPAIGN_DELETE_CAMPAIGN_REQUEST,
    CAMPAIGN_DELETE_CAMPAIGN_SUCCESS,
    CAMPAIGN_FETCH_PROGRAMS_FAIL,
    CAMPAIGN_FETCH_PROGRAMS_REQUEST,
    CAMPAIGN_FETCH_PROGRAMS_SUCCESS,
    CAMPAIGN_LEARNERS_LIST_FAIL,
    CAMPAIGN_LEARNERS_LIST_REQUEST,
    CAMPAIGN_LEARNERS_LIST_SUCCESS,
    CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_FAIL,
    CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_REQUEST,
    CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_SUCCESS,
    CAMPAIGN_LOAD_CAMPAIGNS_FAIL,
    CAMPAIGN_LOAD_CAMPAIGNS_REQUEST,
    CAMPAIGN_LOAD_CAMPAIGNS_SUCCESS,
    CAMPAIGN_LOAD_SINGLE_CAMPAIGN_FAIL,
    CAMPAIGN_LOAD_SINGLE_CAMPAIGN_REQUEST,
    CAMPAIGN_LOAD_SINGLE_CAMPAIGN_SUCCESS,
    CAMPAIGN_MESSAGE_STATUS_RESET,
    CAMPAIGN_MODULE_DETAIL_MODAL,
    CAMPAIGN_MODULE_DETAIL_MODAL_RESET,
    CAMPAIGN_PROGRAM_CREATION_STATUS,
    CAMPAIGN_PROGRAM_EDIT_STATUS,
    CAMPAIGN_REMOVE_LEARNER_FAIL,
    CAMPAIGN_REMOVE_LEARNER_REQUEST,
    CAMPAIGN_REMOVE_LEARNER_SUCCESS,
    CAMPAIGN_RENAME_CAMPAIGN_FAIL,
    CAMPAIGN_RENAME_CAMPAIGN_REQUEST,
    CAMPAIGN_RENAME_CAMPAIGN_SUCCESS,
    CAMPAIGN_RESTORE_CAMPAIGN_FAIL,
    CAMPAIGN_RESTORE_CAMPAIGN_REQUEST,
    CAMPAIGN_RESTORE_CAMPAIGN_SUCCESS,
    CAMPAIGN_SELECTED_MODULE_LIST
} from "../actiontypes/campaignConstants";
import {
    EDUCATION_MICRO_MODULE_PREFIX,
    EDUCATION_MODULE_PREFIX,
    EDUCATION_TEMPLATE_PREFIX, LOCAL_ENV, MICRO_MODULE_PREFIX,
    MOODLE_SERVICE_ENDPOINT
} from "../../../shared/constants/globalConstants";
import i18next from "i18next";
import {getPayload} from "../../../shared/rootActions";
import {recursiveObjectParamConverter} from "../../../shared/helper";
import {fakeCampaignData, fakeCampaignsData} from "../../../shared/constants/fakeCampaignData";


export const parsePrograms = (programs) => {
    const programOptions = [];
    const programsNew = {};

    if (programs) {
        programs.forEach((program) => {
            programOptions.push({
                value: Number(program.id),
                label: program.name,
                disabled: false,
                isTemplate: false,
                presetup: Boolean(program.presetup),
            });
            programsNew[program.id] = program;
        });
        return {
            programOptions: programOptions,
            programs: programsNew,
        };
    }

    return {};
};

const readCampaignFromResult = (result) => {
    if (!result.campaign) {
        return;
    }
    let {campaign} = result;
    let rules = campaign.rules || [];
    let rulesMap = {};
    rules.forEach(rule => {
        // todo: change this magic string replacement and read from backend query instead
        if (rule?.classname) {
            rulesMap[rule?.classname.replace(/\\/g, "/")] = rule.enabled;
        }
    });

    return {
        ...campaign,
        rulesMap,
        config: (!!campaign.config && typeof campaign.config === 'string' && /{.+}/g.test(campaign.config)) ? JSON.parse(campaign.config) : {},
    };
};

export const loadCampaigns = (startdate = 0, enddate = 0, archived = 0) => async (dispatch, getState) => {
    dispatch({type: CAMPAIGN_LOAD_CAMPAIGNS_REQUEST});
    const {env} = getState()?.root?.siteInfo || {};

    if (env === LOCAL_ENV) {
        dispatch({
            type: CAMPAIGN_LOAD_CAMPAIGNS_SUCCESS,
            payload: {
                items: [...fakeCampaignsData],
            },
        });
        return;
    }

    const queryParam = {
        startdate,
        enddate,
        archived,
        wsfunction: 'local_campaign_get_campaigns',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: CAMPAIGN_LOAD_CAMPAIGNS_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: CAMPAIGN_LOAD_CAMPAIGNS_SUCCESS,
                payload: response_data,
            });
        }
    } catch (error) {
        dispatch({
            type: CAMPAIGN_LOAD_CAMPAIGNS_FAIL,
            payload: error,
        });
    }
};

export const loadCampaign = (campaign_id) => async (dispatch, getState) => {
    if (!campaign_id) {
        return;
    }
    dispatch({type: CAMPAIGN_LOAD_SINGLE_CAMPAIGN_REQUEST});
    const {env} = getState()?.root?.siteInfo || {};

    if (env === LOCAL_ENV) {
        dispatch({
            type: CAMPAIGN_LOAD_SINGLE_CAMPAIGN_SUCCESS,
            payload: readCampaignFromResult({...fakeCampaignData}),
        });
        return;
    }

    const queryParam = {
        campaign_id,
        wsfunction: 'local_campaign_get_campaign',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: CAMPAIGN_LOAD_SINGLE_CAMPAIGN_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: CAMPAIGN_LOAD_SINGLE_CAMPAIGN_SUCCESS,
                payload: {
                    campaign: readCampaignFromResult(response_data),
                },
            });
        }
    } catch (error) {
        dispatch({
            type: CAMPAIGN_LOAD_SINGLE_CAMPAIGN_FAIL,
            payload: error,
        });
    }
};
export const loadCampaignExtras = () => async (dispatch) => {
    dispatch({type: CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_REQUEST});

    const queryParam = {
        wsfunction: 'local_campaign_get_campaign_extras',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_SUCCESS,
                payload: response_data,
            });
        }
    } catch (error) {
        dispatch({
            type: CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_FAIL,
            payload: error,
        });
    }
};

export const renameCampaign = (campaign_id, name) => async (dispatch) => {
    dispatch({type: CAMPAIGN_RENAME_CAMPAIGN_REQUEST});

    if (name === '') {
        dispatch({
            type: CAMPAIGN_RENAME_CAMPAIGN_FAIL,
            payload: 'Campaign name cannot be empty',
        });
        return;
    } else if (name.toLowerCase().includes("<script") || name.toLowerCase().includes("&lt;script")) {
        dispatch({
            type: CAMPAIGN_RENAME_CAMPAIGN_FAIL,
            payload: 'Invalid input',
        });
        return;
    }

    if (campaign_id === 0) { // New campaign
        dispatch({
            type: CAMPAIGN_RENAME_CAMPAIGN_SUCCESS,
            payload: {
                success: true,
                newName: name,
            },
        });
        return;
    }

    const queryParam = {
        campaign_id,
        name,
        wsfunction: 'local_campaign_rename_campaign',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: CAMPAIGN_RENAME_CAMPAIGN_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: CAMPAIGN_RENAME_CAMPAIGN_SUCCESS,
                payload: {
                    ...response_data,
                    newName: name,
                },
            });
        }
    } catch (error) {
        dispatch({
            type: CAMPAIGN_RENAME_CAMPAIGN_FAIL,
            payload: error,
        });
    }
};

export const archiveCampaign = (campaign_id) => async (dispatch) => {
    dispatch({type: CAMPAIGN_ARCHIVE_CAMPAIGN_REQUEST});

    const queryParam = {
        campaign_id,
        wsfunction: 'local_campaign_archive_campaign',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: CAMPAIGN_ARCHIVE_CAMPAIGN_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: CAMPAIGN_ARCHIVE_CAMPAIGN_SUCCESS,
                payload: response_data,
            });
        }
    } catch (error) {
        dispatch({
            type: CAMPAIGN_ARCHIVE_CAMPAIGN_FAIL,
            payload: error,
        });
    }
};

export const restoreCampaign = (campaign_id) => async (dispatch) => {
    dispatch({type: CAMPAIGN_RESTORE_CAMPAIGN_REQUEST});

    const queryParam = {
        campaign_id,
        wsfunction: 'local_campaign_restore_campaign',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: CAMPAIGN_RESTORE_CAMPAIGN_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: CAMPAIGN_RESTORE_CAMPAIGN_SUCCESS,
                payload: response_data,
            });
        }
    } catch (error) {
        dispatch({
            type: CAMPAIGN_RESTORE_CAMPAIGN_FAIL,
            payload: error,
        });
    }
};

export const deleteCampaign = (campaign_id) => async (dispatch) => {
    dispatch({type: CAMPAIGN_DELETE_CAMPAIGN_REQUEST});

    const queryParam = {
        campaign_id,
        wsfunction: 'local_campaign_delete_campaign',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: CAMPAIGN_DELETE_CAMPAIGN_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: CAMPAIGN_DELETE_CAMPAIGN_SUCCESS,
                payload: response_data,
            });
        }
    } catch (error) {
        dispatch({
            type: CAMPAIGN_DELETE_CAMPAIGN_FAIL,
            payload: error,
        });
    }
};

export const fetchPrograms = () => async (dispatch, getState) => {
    dispatch({type: CAMPAIGN_FETCH_PROGRAMS_REQUEST});

    const queryParam = {
        wsfunction: 'local_campaign_get_programs',
    };
    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: CAMPAIGN_FETCH_PROGRAMS_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: CAMPAIGN_FETCH_PROGRAMS_SUCCESS,
                payload: parsePrograms(response_data?.items),
            });
        }
    } catch (error) {
        dispatch({
            type: CAMPAIGN_FETCH_PROGRAMS_FAIL,
            payload: error,
        });
    }
};

export const listCampaignLearners = (campaign_id, search = '') => async (dispatch) => {
    dispatch({type: CAMPAIGN_LEARNERS_LIST_REQUEST});

    const queryParam = {
        campaign_id,
        search,
        wsfunction: 'local_campaign_list_campaign_learners',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: CAMPAIGN_LEARNERS_LIST_FAIL,
                payload: response_data
            });
        } else {
            dispatch({
                type: CAMPAIGN_LEARNERS_LIST_SUCCESS,
                payload: response_data
            });

            if (!response_data?.launched) {
                dispatch({
                    type: CAMPAIGN_LEARNERS_LIST_FAIL,
                    payload: {
                        message: i18next.t('local_campaigns_home_learner_align_launched_info', 'Training content will only be assigned to learners once campaign launched.'),
                        message_type: 'warning',
                    },
                });
            }
        }

    } catch (error) {
        dispatch({
            type: CAMPAIGN_LEARNERS_LIST_FAIL,
            payload: error
        });
    }
};

export const addCampaignLearner = (campaign_id, userids = []) => async (dispatch) => {
    dispatch({type: CAMPAIGN_ADD_LEARNER_REQUEST});

    const queryParam = {
        campaign_id,
        ...recursiveObjectParamConverter({userids}),
        wsfunction: 'local_campaign_add_campaign_learner',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: CAMPAIGN_ADD_LEARNER_FAIL,
                payload: response_data
            });
        } else {
            dispatch({
                type: CAMPAIGN_ADD_LEARNER_SUCCESS,
                payload: response_data
            });
        }
    } catch (error) {
        dispatch({
            type: CAMPAIGN_ADD_LEARNER_FAIL,
            payload: error,
        });
    }
};

export const removeCampaignLearner = (campaign_id, userids) => async (dispatch) => {
    dispatch({type: CAMPAIGN_REMOVE_LEARNER_REQUEST});

    const queryParam = {
        campaign_id,
        ...recursiveObjectParamConverter({userids}),
        wsfunction: 'local_campaign_remove_campaign_learner',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();
        if (response_data?.exception) {
            dispatch({
                type: CAMPAIGN_REMOVE_LEARNER_FAIL,
                payload: response_data
            });
        } else {
            dispatch({
                type: CAMPAIGN_REMOVE_LEARNER_SUCCESS,
                payload: response_data
            });
        }
    } catch (error) {
        dispatch({
            type: CAMPAIGN_REMOVE_LEARNER_FAIL,
            payload: error,
        });
    }
};

export const showEnrolLearnerModal = (visible = false) => async (dispatch) => {
    dispatch({
        type: CAMPAIGN_ADD_LEARNER_MODAL,
        payload: {
            visible: visible,
        },
    });
};

export const addCampaignWithProgram = async (name, program_id, startdate = 0, enddate = 0,
                                             expirydate = 0) => {
    const queryParam = {
        name,
        startdate,
        enddate,
        expirydate,
        program_id,
        wsfunction: 'local_campaign_add_campaign',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            return {
                error: response_data,
                success: false,
                message: i18next.t('local_campaigns_add_campaign_error', 'Failed to add campaign, please try one more time.'),
            };
        } else {
            return response_data;
        }
    } catch (error) {
        window.console.error(error);
    }
};

export const addCampaignWithModules = async (name, program_name, program_intro, module_ids = [], startdate = 0, enddate = 0, expirydate = 0) => {
    const queryParam = {
        name,
        startdate,
        enddate,
        expirydate,
        program_name,
        program_intro,
        ...recursiveObjectParamConverter({module_ids}),
        wsfunction: 'local_campaign_add_campaign_with_modules',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            return {
                error: response_data,
                success: false,
                message: i18next.t('local_campaigns_add_campaign_error', 'Failed to add campaign, please try one more time.'),
            };
        } else {
            return response_data;
        }
    } catch (error) {
        window.console.error(error);
    }
};

export const saveCampaignReport = async (campaign_id, active, schedule, report_format, sendto_type, audience_ids) => {
    if (!active && Array.isArray(audience_ids) && !audience_ids.length) {
        return;
    }

    const queryParam = {
        campaign_id,
        active: active ? 1 : 0,
        schedule,
        report_format,
        ...recursiveObjectParamConverter({audience_ids}),
        sendto_type,
        wsfunction: 'local_campaign_update_campaign_report',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            return {
                error: response_data,
                success: false,
                message: i18next.t('local_campaigns_save_campaign_error', 'Failed to save campaign report, please try one more time.'),
            };
        } else {
            return {
                data: response_data,
                success: true,
            };
        }
    } catch (error) {
        window.console.error(error);
    }
};

export const saveCampaignRules = async (campaign_id, learnerActive, managerActive) => {
    const queryParam = {
        campaign_id,
        learner_rule_active: learnerActive ? 1 : 0,
        manager_rule_active: managerActive ? 1 : 0,
        wsfunction: 'local_campaign_update_campaign_rules',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            return {
                error: response_data,
                success: false,
                message: i18next.t('local_campaigns_save_campaign_remed_error', 'Failed to save campaign remediation, please try one more time.'),
            };
        } else {
            return {
                data: response_data,
                success: true,
            };
        }
    } catch (error) {
        window.console.error(error);
    }
};

export const updateProgramModules = async (program_id, module_ids = []) => {
    const queryParam = {
        program_id,
        ...recursiveObjectParamConverter({module_ids}),
        wsfunction: 'local_campaign_update_program_modules',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            return {
                error: response_data,
                success: false,
                message: i18next.t('local_campaigns_update_modules_error', 'Updating failed, please try one more time.'),
            };
        } else {
            return {
                data: response_data,
                success: true,
            };
        }
    } catch (error) {
        window.console.error(error);
    }
};

export const saveProgramDetails = async (program_id = 0, program_name, module_ids = []) => {
    const queryParam = {
        program_id,
        program_name,
        description: '',
        ...recursiveObjectParamConverter({module_ids}),
        wsfunction: 'local_campaign_save_program_details',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            return {
                error: response_data,
                success: false,
                message: i18next.t('local_campaigns_home_saved_failed_message', 'Saving error, please try again'),
            };
        } else {
            return {
                data: response_data,
                success: true,
                message: i18next.t('local_campaigns_home_saved_success_message', 'The template has been saved'),
            };
        }
    } catch (error) {
        window.console.error(error);
    }
};

export const deleteProgram = async (program_id) => {
    const queryParam = {
        program_id,
        wsfunction: 'local_campaign_delete_program',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            return {
                error: response_data,
                success: false,
                message: 'Deletion error, please try again',
            };
        } else {
            return {
                data: response_data,
                success: true,
                message: 'The template has been deleted',
            };
        }
    } catch (error) {
        window.console.error(error);
    }
};

export const updateCampaign = async (campaign_id, name, program_id, program_intro = '', source_type = '', source_ids = [], notification_settings = [],
                                     startdate = 0, enddate = 0,
                                     expirydate = 0, student_ids = [], launch = false) => {
    const queryParam = {
        campaign_id,
        name,
        startdate,
        enddate,
        expirydate,
        program_id,
        program_intro,
        source_type,
        ...recursiveObjectParamConverter({source_ids}),
        ...recursiveObjectParamConverter({student_ids}),
        ...recursiveObjectParamConverter({notification_settings}),
        launch: launch ? 1 : 0,
        wsfunction: 'local_campaign_update_campaign',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            return {
                error: response_data,
                success: false,
                message: i18next.t('local_campaigns_update_modules_error', 'Updating failed, please try one more time.'),
            };
        } else {
            return {
                data: response_data,
                success: true,
            };
        }
    } catch (error) {
        window.console.error(error);
    }
};

export const updateCampaignWithNewModules = async (campaign_id, name, program_name, program_intro = '', source_type = '', source_ids = [], notification_settings = [],
                                                   startdate = 0, enddate = 0,
                                                   expirydate = 0, module_ids = [], launch = false) => {
    const queryParam = {
        campaign_id,
        name,
        startdate,
        enddate,
        expirydate,
        program_name,
        program_intro,
        source_type,
        ...recursiveObjectParamConverter({source_ids}),
        ...recursiveObjectParamConverter({module_ids}),
        ...recursiveObjectParamConverter({notification_settings}),
        launch: launch ? 1 : 0,
        wsfunction: 'local_campaign_update_campaign_with_modules',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            return {
                error: response_data,
                success: false,
                message: i18next.t('local_campaigns_update_modules_error', 'Updating failed, please try one more time.'),
            };
        } else {
            return {
                data: response_data,
                success: true,
            };
        }
        ;
    } catch (error) {
        window.console.error(error);
    }
};

export const fuzzySearchUsers = async (term) => {

    const queryParam = {
        term,
        currenttenant: 1,
        wsfunction: 'local_bridge_fuzzy_search_users',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            return {
                error: response_data,
                success: false,
                message: i18next.t('local_campaigns_fetch_campaign_error', 'Failed to fetch users, please try again...')
            };
        } else {
            return response_data;
        }
    } catch (error) {
        window.console.error(error);
    }
};

export const getOverlappingCampaigns = async (startdate, enddate, campaign, program) => {
    const queryParam = {
        startdate,
        enddate,
        campaign,
        program,
        wsfunction: 'local_campaign_get_overlapping_campaigns',
    };
    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            return {
                error: response_data,
                success: false
            };
        } else {
            return response_data;
        }
    } catch (error) {
        console.error(error);
    }
};

export const setProgramCreationVisible = (value) => (dispatch) => {
    dispatch({
        type: CAMPAIGN_PROGRAM_CREATION_STATUS,
        payload: value,
    });
};

export const setProgramEditVisible = (value) => (dispatch) => {
    dispatch({
        type: CAMPAIGN_PROGRAM_EDIT_STATUS,
        payload: value,
    });
};

export const resetCampaignMessageStatus = () => (dispatch) => {
    dispatch({
        type: CAMPAIGN_MESSAGE_STATUS_RESET,
    });
};

export const parseAntFormData = (formData) => {
    if (typeof formData !== 'object') {
        return formData;
    }

    let sanitizedFormData = {};
    Object.keys(formData).forEach(field => {
        let value = formData[field];
        if (!value) {
            sanitizedFormData[field] = value;
            return true;
        }
        if (typeof value.format === 'function') {
            // convert to utc time - server reads utc and store using system timezone
            // const currentDate = value.utc(true);
            // sanitizedFormData[field] = currentDate.unix();
            sanitizedFormData[field] = value.format('MM/DD/YYYY');
        } else {
            sanitizedFormData[field] = value;
        }
    });

    return sanitizedFormData;
};

export const categorizeModules = (moduleOptions, categorize = true) => {
    const edu_reg = new RegExp(`\\b${EDUCATION_MODULE_PREFIX}.+`);
    const edu_micro_reg = new RegExp(`\\b${EDUCATION_MICRO_MODULE_PREFIX}.+`);
    const micro_reg = new RegExp(`\\b${MICRO_MODULE_PREFIX}.+`);

    // todo: use string lang from backend
    let genericModules = {
        label: 'Base Training Modules',
        key: 'base',
        children: []
    };
    let educationModules = {
        label: 'Modules for Education Industry',
        key: 'education',
        children: []
    };
    let microModules = {
        label: 'Micro Training Modules',
        key: 'micro',
        children: []
    };
    // referencing somewhere else
    let newModuleOptions = [...moduleOptions];
    newModuleOptions.forEach(module => {
        const [first, ...rest] = module.label.split(' - ');
        const new_label = rest?.length > 0 ? rest?.join(' - ') : first;

        if (edu_micro_reg.test(module?.label)) {
            const new_module = {
                ...module,
                original_label: module.label,
                label: new_label,
                tag: 'Education Micro'
            }
            educationModules.children.push(new_module);
        } else if (edu_reg.test(module?.label)) {
            const new_module = {
                ...module,
                original_label: module.label,
                label: new_label,
                tag: 'Education Base'
            }
            educationModules.children.push(new_module);
        } else if (micro_reg.test(module.label)) {
            const new_module = {
                ...module,
                original_label: module.label,
                label: new_label,
                tag: 'Micro'
            }
            microModules.children.push(new_module)
        } else {
            const new_module = {
                ...module,
                original_label: module.label,
                label: new_label,
                tag: 'Base Training'
            }
            genericModules.children.push(new_module);
        }
    });

    if (categorize) {
        return [
            genericModules.children.length ? genericModules : null,
            educationModules.children.length ? educationModules : null,
            microModules.children.length ? microModules : null,
        ].filter(category => category);
    } else {
        return [
            ...genericModules?.children,
            ...educationModules.children,
            ...microModules.children
        ];
    }


};

export const categorizeTemplates = (templateOptions) => {
    if (!Array.isArray(templateOptions)) {
        return [];
    }
    const edu_reg = new RegExp(`\\b${EDUCATION_TEMPLATE_PREFIX}.+`);

    // todo: use string lang from backend
    let genericTemplates = {
        label: 'Templates for Base Training',
        children: []
    };
    let educationTemplates = {
        label: 'Templates for Education Industry',
        children: []
    };
    let customizedTemplates = {
        label: 'Your Templates',
        children: []
    };
    // referencing somewhere else
    let newTemplateOptions = [...templateOptions];

    newTemplateOptions.forEach(template => {
        if (edu_reg.test(template.label)) {
            educationTemplates.children.push(template);
        } else if (template.presetup) {
            genericTemplates.children.push(template);
        } else {
            customizedTemplates.children.push(template);
        }
    });

    return [
        customizedTemplates.children.length ? customizedTemplates : null,
        genericTemplates.children.length ? genericTemplates : null,
        educationTemplates.children.length ? educationTemplates : null,
    ].filter(category => category);
};

export const setSelectedModules = (values) => (dispatch) => {
    dispatch({
        type: CAMPAIGN_SELECTED_MODULE_LIST,
        payload: values,
    });
};

export const setReorderModules = (value) => (dispatch) => {
    dispatch({
        type: CAMPAIGN_CURRENT_MODULE_REORDER,
        payload: value,
    });
};

export const setCurrentCampaignName = (name) => (dispatch) => {
    dispatch({
        type: CAMPAIGN_CURRENT_CAMPAIGN_NAME,
        payload: name,
    });
};

export const updateModuleDetailModal = (moduleDetail = {}, show = true) => (dispatch) => {
    if (moduleDetail && Object.keys(moduleDetail).length > 0) {
        dispatch({
            type: CAMPAIGN_MODULE_DETAIL_MODAL,
            payload: {
                moduleDetail,
                show,
            },
        })
    }
}

export const resetModuleDetailModal = () => (dispatch) => {
    dispatch({
        type: CAMPAIGN_MODULE_DETAIL_MODAL_RESET
    });
}
