import React, {useEffect, useState} from "react";
import {Tour} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {checkPathHasTour, updateTourCompletion, setOpenTour} from "../../redux/actions/userToursActions";
import {setDomReady} from "../../../shared/rootActions";

const UserTour = () => {
    const {userTourInfo, openTour} = useSelector((state) => state.userTourInfo || {});
    const {domReady} = useSelector((state) => state.root || {});

    const dispatch = useDispatch();
    const [steps, setSteps] = useState([]);

    const handleClose = (current) => {
        dispatch(updateTourCompletion(userTourInfo.id));
        dispatch(setOpenTour(false));
        dispatch(setDomReady(false));
    }

    useEffect(() => {
        dispatch(checkPathHasTour(window.location.pathname));
    }, []);

    useEffect(() => {
        if (steps?.length > 0 && !openTour) {
            dispatch(setOpenTour(userTourInfo && !userTourInfo.user_completed));
        }
    }, [steps]);

    useEffect(() => {
        if (domReady) {
            setSteps(userTourInfo?.steps.reduce((result, step) => {
                var selector = step.targetvalue || '';
                let element = selector ? document.querySelector(selector.trim()) : null;
                let config = JSON.parse(step.configdata);
                if (config.hasOwnProperty("placement")) {
                    switch (config["placement"].toLowerCase()) {
                        case "above":
                            config["placement"] = "top";
                            break;
                        case "below":
                            config["placement"] = "bottom";
                            break;
                        default:
                    }
                }
                if (element !== null) {
                    result.push({
                        title: step.title,
                        description: step.content,
                        placement: config.hasOwnProperty("placement") ? config["placement"] : "bottom",
                        target: () => element
                    });
                }
                return result;
            }, []));
        }
    }, [userTourInfo, domReady]);

    return (
        <>
            <Tour
                open={openTour}
                onClose={(current) => handleClose(current)}
                steps={steps}
            />
        </>
    );
};

export default UserTour;
