import React, {lazy, Suspense, useEffect} from "react";
import {useHistory, Switch} from "react-router-dom";
import AdminLayout from "./AdminLayout";
import {useDispatch, useSelector} from "react-redux";
import {resetAutoRedirection} from "../shared/rootActions";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import Dashboard from "./pages/Dashboard";

const UserMainPage = lazy(() => import("./pages/users/MainPage"));
const ReportsPage = lazy(() => import("./pages/reports/ReportsPage"));
const CreateReportsPage = lazy(() => import("./pages/reports/CreateReportsPage"));
const UserCsvUploadPage = lazy(() => import("./pages/users/CsvUploadPage"));
const AccountMgmt = lazy(() => import("./pages/AccountMgmt"));
const CampaignDetail = lazy(() => import("./pages/campaign/CampaignDetail"));
const CampaignLearners = lazy(() => import("./pages/campaign/CampaignLearners"));
const Authentication = lazy(() => import("./pages/authentication/Authentication"));
const EditReportsPage = lazy(() => import("./pages/reports/EditReportsPage"));
const RemediationHome = lazy(() => import("./pages/remediation/RemediationHome"));
const RulePage = lazy(() => import("./pages/remediation/RulePage"));
const LdapHome = lazy(() => import("./pages/users/LdapHome"));
const DomainsPage = lazy(() => import("./pages/DomainsPage"));
const AppearancePage = lazy(() => import("./pages/appearance/AppearancePage"));
const NotificationEditPage = lazy(() => import("./pages/appearance/NotificationEditPage"));
const LdapConfig = lazy(() => import("./pages/users/LdapConfig"));
const AssetsPage = lazy(() => import("./pages/assets/AssetsPage"));
const CampaignHome = lazy(() => import("./pages/campaign/CampaignHome"));

const antIcon = <LoadingOutlined style={{fontSize: 14}} spin/>;


const AdminRoute = () => {
    const root = useSelector((state) => state?.root);
    const isAdmin = root?.siteInfo?.userissiteadmin || root?.tenantAdmin || root?.isSsoAdmin || false;
    const manageappearance = Boolean(root?.siteInfo?.capabilities?.manageappearance);
    const managesso = Boolean(root?.siteInfo?.capabilities?.managesso);
    const manageldap = Boolean(root?.siteInfo?.capabilities?.manageldap);
    const userStatusChecked = !!root?.userStatusChecked;

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(resetAutoRedirection());
    }, []);

    useEffect(() => {
        // Check if user is admin. if not, redirect to /
        userStatusChecked && (isAdmin || history.push('/'));
    }, [isAdmin, userStatusChecked]);

    return (
        isAdmin
            ? <div className={'admin-page-wrap'}>
                <Switch>
                    {/* Lazy load large components */}
                    <Suspense fallback={<Spin indicator={antIcon}/>}>
                        <AdminLayout component={Dashboard} path="/admin" exact/>
                        <AdminLayout component={Dashboard} path="/admin/dashboard" exact/>
                        <AdminLayout component={AccountMgmt} path="/admin/subaccount" exact/>
                        <AdminLayout component={UserMainPage} path="/admin/users" exact/>
                        {
                            manageappearance &&
                            <AdminLayout component={AppearancePage} path="/admin/appearance" exact/>
                        }
                        {
                            manageappearance &&
                            <AdminLayout component={NotificationEditPage} path="/admin/appearance/notification/edit/:id" exact/>
                        }
                        {
                            managesso &&
                            <AdminLayout component={Authentication} path="/admin/authentication" exact/>
                        }
                        <AdminLayout component={AssetsPage} path="/admin/assets" exact/>
                        <AdminLayout component={UserCsvUploadPage} path="/admin/users/upload" exact/>
                        {
                            manageldap &&
                            <AdminLayout component={LdapHome} path="/admin/users/ldap" exact/>
                        }
                        {
                            manageldap &&
                            <AdminLayout component={LdapConfig} path="/admin/users/ldap/add" exact/>
                        }
                        {
                            manageldap &&
                            <AdminLayout component={LdapConfig} path="/admin/users/ldap/edit/:id" exact/>
                        }
                        <AdminLayout component={DomainsPage} path="/admin/domains" exact/>
                        <AdminLayout component={CampaignHome} path="/admin/campaign" exact/>
                        <AdminLayout component={CampaignDetail} path="/admin/campaign/create" exact/>
                        <AdminLayout component={CampaignDetail} path="/admin/campaign/:id/edit" exact/>
                        <AdminLayout component={CampaignLearners} path="/admin/campaign/:id/learners" exact/>
                        <AdminLayout component={ReportsPage} path="/admin/reports" exact/>
                        <AdminLayout component={CreateReportsPage} path="/admin/reports/create" exact/>
                        <AdminLayout component={EditReportsPage} path="/admin/reports/edit/:id" exact/>
                        <AdminLayout component={RemediationHome} path="/admin/remediation" exact/>
                        <AdminLayout component={RulePage} path="/admin/remediation/rule/add" exact/>
                        <AdminLayout component={RulePage} path="/admin/remediation/rule/:id/edit" exact/>
                    </Suspense>
                </Switch>
            </div>
            : <></>
    )
};

export default AdminRoute;
