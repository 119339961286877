export const CAMPAIGN_LOAD_CAMPAIGNS_REQUEST = 'CAMPAIGN_LOAD_CAMPAIGNS_REQUEST';
export const CAMPAIGN_LOAD_CAMPAIGNS_SUCCESS = 'CAMPAIGN_LOAD_CAMPAIGNS_SUCCESS';
export const CAMPAIGN_LOAD_CAMPAIGNS_FAIL = 'CAMPAIGN_LOAD_CAMPAIGNS_FAIL';
export const CAMPAIGN_LOAD_SINGLE_CAMPAIGN_REQUEST = 'CAMPAIGN_LOAD_SINGLE_CAMPAIGN_REQUEST';
export const CAMPAIGN_LOAD_SINGLE_CAMPAIGN_SUCCESS = 'CAMPAIGN_LOAD_SINGLE_CAMPAIGN_SUCCESS';
export const CAMPAIGN_LOAD_SINGLE_CAMPAIGN_FAIL = 'CAMPAIGN_LOAD_SINGLE_CAMPAIGN_FAIL';
export const CAMPAIGN_LOAD_SINGLE_CAMPAIGN_RESET = 'CAMPAIGN_LOAD_SINGLE_CAMPAIGN_RESET';
export const CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_REQUEST = 'CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_REQUEST';
export const CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_SUCCESS = 'CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_SUCCESS';
export const CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_FAIL = 'CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_FAIL';
export const CAMPAIGN_FETCH_PROGRAMS_REQUEST = 'CAMPAIGN_FETCH_PROGRAMS_REQUEST';
export const CAMPAIGN_FETCH_PROGRAMS_SUCCESS = 'CAMPAIGN_FETCH_PROGRAMS_SUCCESS';
export const CAMPAIGN_FETCH_PROGRAMS_FAIL = 'CAMPAIGN_FETCH_PROGRAMS_FAIL';
export const CAMPAIGN_ADD_CAMPAIGN_REQUEST = 'CAMPAIGN_ADD_CAMPAIGN_REQUEST';
export const CAMPAIGN_ADD_CAMPAIGN_SUCCESS = 'CAMPAIGN_ADD_CAMPAIGN_SUCCESS';
export const CAMPAIGN_ADD_CAMPAIGN_FAIL = 'CAMPAIGN_ADD_CAMPAIGN_FAIL';
export const CAMPAIGN_UPDATE_CAMPAIGN_REQUEST = 'CAMPAIGN_UPDATE_CAMPAIGN_REQUEST';
export const CAMPAIGN_UPDATE_CAMPAIGN_SUCCESS = 'CAMPAIGN_UPDATE_CAMPAIGN_SUCCESS';
export const CAMPAIGN_UPDATE_CAMPAIGN_FAIL = 'CAMPAIGN_UPDATE_CAMPAIGN_FAIL';
export const CAMPAIGN_DELETE_CAMPAIGN_REQUEST = 'CAMPAIGN_DELETE_CAMPAIGN_REQUEST';
export const CAMPAIGN_DELETE_CAMPAIGN_SUCCESS = 'CAMPAIGN_DELETE_CAMPAIGN_SUCCESS';
export const CAMPAIGN_DELETE_CAMPAIGN_FAIL = 'CAMPAIGN_DELETE_CAMPAIGN_FAIL';
export const CAMPAIGN_ARCHIVE_CAMPAIGN_REQUEST = 'CAMPAIGN_ARCHIVE_CAMPAIGN_REQUEST';
export const CAMPAIGN_ARCHIVE_CAMPAIGN_SUCCESS = 'CAMPAIGN_ARCHIVE_CAMPAIGN_SUCCESS';
export const CAMPAIGN_ARCHIVE_CAMPAIGN_FAIL = 'CAMPAIGN_ARCHIVE_CAMPAIGN_FAIL';
export const CAMPAIGN_RENAME_CAMPAIGN_REQUEST = 'CAMPAIGN_RENAME_CAMPAIGN_REQUEST';
export const CAMPAIGN_RENAME_CAMPAIGN_SUCCESS = 'CAMPAIGN_RENAME_CAMPAIGN_SUCCESS';
export const CAMPAIGN_RENAME_CAMPAIGN_FAIL = 'CAMPAIGN_RENAME_CAMPAIGN_FAIL';
export const CAMPAIGN_RENAME_CAMPAIGN_RESET = 'CAMPAIGN_RENAME_CAMPAIGN_RESET';
export const CAMPAIGN_RESTORE_CAMPAIGN_REQUEST = 'CAMPAIGN_RESTORE_CAMPAIGN_REQUEST';
export const CAMPAIGN_RESTORE_CAMPAIGN_SUCCESS = 'CAMPAIGN_RESTORE_CAMPAIGN_SUCCESS';
export const CAMPAIGN_RESTORE_CAMPAIGN_FAIL = 'CAMPAIGN_RESTORE_CAMPAIGN_FAIL';
export const CAMPAIGN_MESSAGE_STATUS_RESET = 'CAMPAIGN_MESSAGE_STATUS_RESET';
export const CAMPAIGN_PROGRAM_CREATION_STATUS = 'CAMPAIGN_PROGRAM_CREATION_STATUS';
export const CAMPAIGN_PROGRAM_EDIT_STATUS = 'CAMPAIGN_PROGRAM_EDIT_STATUS';
export const CAMPAIGN_GET_OVERLAPPING_REQUEST = 'CAMPAIGN_GET_OVERLAPPING_REQUEST';
export const CAMPAIGN_GET_OVERLAPPING_SUCCESS = 'CAMPAIGN_GET_OVERLAPPING_SUCCESS';
export const CAMPAIGN_FUZZY_SEARCH_USERS_REQUEST = 'CAMPAIGN_FUZZY_SEARCH_USERS_REQUEST';
export const CAMPAIGN_FUZZY_SEARCH_USERS_SUCCESS = 'CAMPAIGN_FUZZY_SEARCH_USERS_SUCCESS';
export const CAMPAIGN_FUZZY_SEARCH_USERS_FAIL = 'CAMPAIGN_FUZZY_SEARCH_USERS_FAIL';
export const CAMPAIGN_LEARNERS_LIST_REQUEST = 'CAMPAIGN_LEARNERS_LIST_REQUEST';
export const CAMPAIGN_LEARNERS_LIST_FAIL = 'CAMPAIGN_LEARNERS_LIST_FAIL';
export const CAMPAIGN_LEARNERS_LIST_SUCCESS = 'CAMPAIGN_LEARNERS_LIST_SUCCESS';
export const CAMPAIGN_LEARNERS_LIST_SEARCH = 'CAMPAIGN_LEARNERS_LIST_SEARCH';
export const CAMPAIGN_LEARNERS_SELECTION_UPDATE = 'CAMPAIGN_LEARNERS_SELECTION_UPDATE';
export const CAMPAIGN_ADD_LEARNER_MODAL = 'CAMPAIGN_ADD_LEARNER_MODAL';
export const CAMPAIGN_ADD_LEARNER_REQUEST = 'CAMPAIGN_ADD_LEARNER_REQUEST';
export const CAMPAIGN_ADD_LEARNER_FAIL = 'CAMPAIGN_ADD_LEARNER_FAIL';
export const CAMPAIGN_ADD_LEARNER_SUCCESS = 'CAMPAIGN_ADD_LEARNER_SUCCESS';
export const CAMPAIGN_ADD_LEARNER_RESET = 'CAMPAIGN_ADD_LEARNER_RESET';
export const CAMPAIGN_REMOVE_LEARNER_REQUEST = 'CAMPAIGN_REMOVE_LEARNER_REQUEST';
export const CAMPAIGN_REMOVE_LEARNER_FAIL = 'CAMPAIGN_REMOVE_LEARNER_FAIL';
export const CAMPAIGN_REMOVE_LEARNER_SUCCESS = 'CAMPAIGN_REMOVE_LEARNER_SUCCESS';
export const CAMPAIGN_GET_OVERLAPPING_FAIL = 'CAMPAIGN_GET_OVERLAPPING_FAIL';
export const CAMPAIGN_GET_OVERLAPPING_RESET = 'CAMPAIGN_GET_OVERLAPPING_RESET';
export const CAMPAIGN_SELECTED_MODULE_LIST = 'CAMPAIGN_SELECTED_MODULE_LIST';
export const CAMPAIGN_CURRENT_CAMPAIGN_NAME = 'CAMPAIGN_CURRENT_CAMPAIGN_NAME';
export const CAMPAIGN_CURRENT_MODULE_REORDER = 'CAMPAIGN_CURRENT_MODULE_REORDER';
export const CAMPAIGN_MODULE_DETAIL_MODAL = 'CAMPAIGN_MODULE_DETAIL_MODAL';
export const CAMPAIGN_MODULE_DETAIL_MODAL_RESET = 'CAMPAIGN_MODULE_DETAIL_MODAL_RESET';
export const CAMPAIGN_CURRENT_TEMPLATE_EDIT_DISABLE = 'CAMPAIGN_CURRENT_TEMPLATE_EDIT_DISABLE';
export const CAMPAIGN_SORTED_LEARNERS_LIST = 'CAMPAIGN_SORTED_LEARNERS_LIST';

