import {
    LDAP_DELETE_CONFIG_FAIL,
    LDAP_DELETE_CONFIG_REQUEST, LDAP_DELETE_CONFIG_RESET,
    LDAP_DELETE_CONFIG_SUCCESS,
    LDAP_GET_SERVER_LIST_FAIL,
    LDAP_GET_SERVER_LIST_REQUEST,
    LDAP_GET_SERVER_LIST_SUCCESS,
    LDAP_LOAD_CONFIG_FAIL,
    LDAP_LOAD_CONFIG_REQUEST, LDAP_LOAD_CONFIG_RESET,
    LDAP_LOAD_CONFIG_SUCCESS,
    LDAP_PAUSE_ALL_CONFIG_FAIL,
    LDAP_PAUSE_ALL_CONFIG_REQUEST,
    LDAP_PAUSE_ALL_CONFIG_SUCCESS,
    LDAP_PAUSE_SINGLE_CONFIG_FAIL,
    LDAP_PAUSE_SINGLE_CONFIG_REQUEST, LDAP_PAUSE_SINGLE_CONFIG_RESET,
    LDAP_PAUSE_SINGLE_CONFIG_SUCCESS,
    LDAP_SAVE_CONFIG_FAIL,
    LDAP_SAVE_CONFIG_REQUEST, LDAP_SAVE_CONFIG_RESET,
    LDAP_SAVE_CONFIG_SUCCESS,
    LDAP_SYNC_USERS_FAIL,
    LDAP_SYNC_USERS_REQUEST, LDAP_SYNC_USERS_RESET,
    LDAP_SYNC_USERS_SUCCESS,
    LDAP_USERLIMIT_REACHED_FAIL,
    LDAP_USERLIMIT_REACHED_REQUEST,
    LDAP_USERLIMIT_REACHED_SUCCESS,
    USER_DELETE_USER_FAIL,
    USER_DELETE_USER_REQUEST,
    USER_DELETE_USER_SUCCESS,
    USER_DELETE_USER_RESET,
    USER_MODAL_CONTROL,
    USER_REFRESH_USERLIST_FAIL,
    USER_REFRESH_USERLIST_REQUEST,
    USER_REFRESH_USERLIST_SUCCESS,
    USER_SAVE_USER_FAIL,
    USER_SAVE_USER_REQUEST,
    USER_SAVE_USER_RESET,
    USER_SAVE_USER_SUCCESS,
    USER_SEARCH_BY_DEPARTMENT,
    USER_SEARCH_BY_NAME_OR_EMAIL,
    USER_SEARCH_BY_TITLE,
    USER_SELECTION_UPDATE,
    USER_USERLIST_REQUEST,
    USER_UPLOAD_USERS_FAIL,
    USER_UPLOAD_USERS_REQUEST,
    USER_UPLOAD_USERS_SUCCESS,
    USER_UPLOAD_USERS_RESET,
    DEPARTMENT_LEADER_LIST_REQUEST,
    DEPARTMENT_LEADER_LIST_SUCCESS,
    DEPARTMENT_LEADER_LIST_FAIL,
    USER_BULK_ASSIGN_DEPARTMENT_LEADER_REQUEST,
    USER_BULK_ASSIGN_DEPARTMENT_LEADER_SUCCESS,
    USER_BULK_ASSIGN_DEPARTMENT_LEADER_FAIL,
    USER_BULK_ASSIGN_DEPARTMENT_LEADER_RESET,
} from "../actiontypes/userConstants";

export const userListReducer = (state = {users: []}, action) => {
    switch (action.type) {
        case USER_USERLIST_REQUEST:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case USER_REFRESH_USERLIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case USER_REFRESH_USERLIST_SUCCESS:
            return {
                loading: false,
                error: false,
                ...action.payload,
            };
        case USER_REFRESH_USERLIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case USER_MODAL_CONTROL:
            return {
                ...state,
                modal: action.payload,
            };
        case USER_SAVE_USER_REQUEST:
            return {
                ...state,
                save_user: {
                    loading: true,
                    success: false,
                    error: false,
                    ...action.payload,
                }
            };
        case USER_SAVE_USER_SUCCESS:
            return {
                ...state,
                save_user: {
                    ...state.save_user,
                    loading: false,
                    success: true,
                    error: false,
                    message: action.payload?.message,
                    message_type: action.payload?.message_type || 'info',
                    ...action.payload
                }
            };
        case USER_SAVE_USER_FAIL:
            return {
                ...state,
                save_user: {
                    ...state.save_user,
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload?.message,
                }
            };
        case USER_SAVE_USER_RESET:
            return {
                ...state,
                save_user: {}
            };
        case USER_SELECTION_UPDATE:
            return {
                ...state,
                selected_user_ids: action.payload,
            };
        default:
            return state;
    }
};

export const departmentLeaderReducer = (state = {users: []}, action) => {
    switch (action.type) {
        case DEPARTMENT_LEADER_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case DEPARTMENT_LEADER_LIST_SUCCESS:
            return {
                loading: false,
                error: false,
                ...action.payload,
            };
        case DEPARTMENT_LEADER_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const assignDepartmentLeaderReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_BULK_ASSIGN_DEPARTMENT_LEADER_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                message_content: action.payload?.message,
                message_type: 'loading',
            };
        case USER_BULK_ASSIGN_DEPARTMENT_LEADER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: false,
                message_content: action.payload?.message,
                message_type: action.payload?.message_type || 'info',
            };
        case USER_BULK_ASSIGN_DEPARTMENT_LEADER_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                message_content: action.payload?.message,
                message_type: 'error',
            };
        case USER_BULK_ASSIGN_DEPARTMENT_LEADER_RESET:
            return {};
        default:
            return state;
    }
}

export const userSearchReducer = (state = {department: {}, title: {}, name_or_email: ""}, action) => {
    switch (action.type) {
        case USER_SEARCH_BY_DEPARTMENT:
            return {
                ...state,
                department: action.payload
            };
        case USER_SEARCH_BY_TITLE:
            return {
                ...state,
                title: action.payload
            };
        case USER_SEARCH_BY_NAME_OR_EMAIL:
            return {
                ...state,
                name_or_email: action.payload
            };
        default:
            return state;
    }
};

export const deleteUserReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_DELETE_USER_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                message_content: action.payload?.message,
                message_type: 'loading',
            };
        case USER_DELETE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: false,
                message_content: action.payload?.message,
                message_type: action.payload?.message_type || 'info',
            };
        case USER_DELETE_USER_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                message_content: action.payload?.message,
                message_type: 'error',
            };
        case USER_DELETE_USER_RESET:
            return {};
        default:
            return state;
    }
};

export const uploadUserReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_UPLOAD_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            };

        case USER_UPLOAD_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: false,
                message_content: action.payload?.message,
                message_type: action.payload?.message_type || 'info',
                upload_results: action.payload?.results,
            };

        case USER_UPLOAD_USERS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                message_content: action.payload?.message,
                message_type: 'error',
                upload_results: action.payload?.results,
            };

        case USER_UPLOAD_USERS_RESET:
            return {
                ...state,
                loading: false,
                success: true,
                error: false,
                message_content: undefined,
                message_type: undefined,
                upload_results: undefined,
                hasUploaded: false,
            };

        default:
            return state;
    }
}

export const LdapReducer = (state = {}, action) => {
    switch (action?.type) {
        case LDAP_USERLIMIT_REACHED_REQUEST:
            return {
                ...state,
                user_limit: {
                    loading: true,
                    error: false,
                },
            };
        case LDAP_USERLIMIT_REACHED_SUCCESS:
            return {
                ...state,
                user_limit: {
                    loading: false,
                    error: false,
                    message_type: 'success',
                    ...action.payload,
                },
            };
        case LDAP_USERLIMIT_REACHED_FAIL:
            return {
                ...state,
                user_limit: {
                    loading: false,
                    error: action.payload,
                    message: 'Failed to get Tenant Users limit, please try again...',
                    message_type: 'error',
                },
            };
        case LDAP_PAUSE_ALL_CONFIG_REQUEST:
            return {
                ...state,
                pause_all_servers: {
                    loading: true,
                    error: false,
                },
            };
        case LDAP_PAUSE_ALL_CONFIG_SUCCESS:
            return {
                ...state,
                pause_all_servers: {
                    loading: false,
                    error: false,
                    message_type: action.payload?.success ? 'success' : 'error',
                    ...action.payload,
                },
            };
        case LDAP_PAUSE_ALL_CONFIG_FAIL:
            return {
                ...state,
                pause_all_servers: {
                    loading: false,
                    error: action.payload,
                    message: 'Failed to pause all Server configuration, please try again...',
                    message_type: 'error',
                },
            };
        case LDAP_PAUSE_SINGLE_CONFIG_REQUEST:
            return {
                ...state,
                pause_single_server: {
                    loading: true,
                    error: false,
                },
            };
        case LDAP_PAUSE_SINGLE_CONFIG_SUCCESS:
            return {
                ...state,
                pause_single_server: {
                    loading: false,
                    error: false,
                    message_type: action.payload?.success ? 'success' : 'error',
                    ...action.payload,
                },
            };
        case LDAP_PAUSE_SINGLE_CONFIG_FAIL:
            return {
                ...state,
                pause_single_server: {
                    loading: false,
                    error: action.payload,
                    message: 'Failed to pause Server configuration, please try again...',
                    message_type: 'error',
                },
            };
        case LDAP_PAUSE_SINGLE_CONFIG_RESET:
            return {
                ...state,
                pause_single_server: {}
            };
        case LDAP_GET_SERVER_LIST_REQUEST:
            return {
                ...state,
                server_list: {
                    loading: true,
                    error: false,
                },
            };
        case LDAP_GET_SERVER_LIST_SUCCESS:
            return {
                ...state,
                server_list: {
                    loading: false,
                    error: false,
                    data: action.payload
                },
            };
        case LDAP_GET_SERVER_LIST_FAIL:
            return {
                ...state,
                server_list: {
                    loading: false,
                    error: action.payload,
                },
            };
        case LDAP_LOAD_CONFIG_REQUEST:
            return {
                ...state,
                server_config: {
                    loading: true,
                    error: false,
                },
            };
        case LDAP_LOAD_CONFIG_SUCCESS:
            return {
                ...state,
                server_config: {
                    loading: false,
                    error: false,
                    ...action.payload,
                },
            };
        case LDAP_LOAD_CONFIG_FAIL:
            return {
                ...state,
                server_config: {
                    loading: false,
                    error: action.payload,
                },
            };
        case LDAP_LOAD_CONFIG_RESET:
            return {
                ...state,
                server_config: {},
            };
        case LDAP_DELETE_CONFIG_REQUEST:
            return {
                ...state,
                delete_config: {
                    loading: true,
                    success: false,
                    error: false,
                },
            };
        case LDAP_DELETE_CONFIG_SUCCESS:
            return {
                ...state,
                delete_config: {
                    loading: false,
                    success: true,
                    error: false,
                    ...action.payload
                },
            };
        case LDAP_DELETE_CONFIG_FAIL:
            return {
                ...state,
                delete_config: {
                    loading: false,
                    success: false,
                    error: action.payload,
                },
            };
        case LDAP_DELETE_CONFIG_RESET:
            return {
                ...state,
                delete_config: {},
            };
        case LDAP_SAVE_CONFIG_REQUEST:
            return {
                ...state,
                save_config: {
                    loading: true,
                    success: false,
                    error: false,
                },
            };
        case LDAP_SAVE_CONFIG_SUCCESS:
            return {
                ...state,
                save_config: {
                    loading: false,
                    success: true,
                    error: false,
                    ...action.payload
                },
            };
        case LDAP_SAVE_CONFIG_FAIL:
            return {
                ...state,
                save_config: {
                    loading: false,
                    success: false,
                    error: action.payload,
                },
            };
        case LDAP_SAVE_CONFIG_RESET:
            return {
                ...state,
                save_config: {},
            };
        case LDAP_SYNC_USERS_REQUEST:
            return {
                ...state,
                sync_users: {
                    loading: true,
                    success: false,
                    error: false,
                },
            };
        case LDAP_SYNC_USERS_SUCCESS:
            return {
                ...state,
                sync_users: {
                    loading: false,
                    success: true,
                    error: false,
                    ...action.payload
                },
            };
        case LDAP_SYNC_USERS_FAIL:
            return {
                ...state,
                sync_users: {
                    loading: false,
                    success: false,
                    error: action.payload,
                },
            };
        case LDAP_SYNC_USERS_RESET:
            return {
                ...state,
                sync_users: {},
            };
        default:
            return state;
    }
};
