import React, {useEffect, useRef, useState} from 'react';
import {Spin, Button, Tabs} from "antd";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {connect} from "react-redux";
import {
    isLaunchedCampaign, isProgramUnderSameTenant, shorten,
} from "../../shared/helper";
import {useTranslation} from "react-i18next";
import Variable from "../../shared/constants/variables";
import EmptyData from "../../shared/components/EmptyData";
import {LoadingOutlined} from "@ant-design/icons";

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const {TabPane} = Tabs;

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

function callback(key) {
}

function mapStateToProps(state) {
    return {...state?.root || {}};
}

function ProgramsPage({campaigns, siteInfo}) {
    const {tenantid, timezone, user_timezone} = siteInfo;
    const _today = Math.floor(Date.now() / 1000);
    const [inProgressPrograms, setInProgressPrograms] = useState([]);
    const [completedPrograms, setCompletedPrograms] = useState([]);
    const [maxHeight, setMaxHeight] = useState(0);
    const {t} = useTranslation();
    const progRef = useRef([]);
    // allocated program within one week - new tag

    let userTimezone = timezone;

    if (user_timezone && user_timezone != '99') {
        userTimezone = user_timezone;
    }

    useEffect(() => {
        let max = 0;
        progRef.current.forEach((item) => {
            max = Math.max(max, item.clientHeight);
        });
        setMaxHeight(max);
    }, [inProgressPrograms]);

    useEffect(() => {
        let newCampaigns = [];
        if (Array.isArray(campaigns)) {
            newCampaigns = [...campaigns];
        } else if (typeof campaigns === 'object') {
            newCampaigns = Object.values(campaigns);
        }

        if (!campaigns || !newCampaigns.length) {
            return true;
        }
        let inProgress = [];
        let completed = [];

        newCampaigns.forEach(campaign => {
            if (!isLaunchedCampaign(campaign, _today)) {
                return true;
            }
            if (!isProgramUnderSameTenant(campaign, tenantid)) {
                return true;
            }
            campaign._duedate = campaign.duedateabsolute || 0;
            campaign._enddate = campaign.expirydateabsolute || 0;

            let isCampaignEnded = campaign.expirydateabsolute > 0 && campaign.expirydateabsolute < _today;
            if (campaign.programidnumber === Variable.DEFAULT_PROGRAM_ID) {
                isCampaignEnded = false;
            }

            if (campaign.iscompleted) {
                completed.push(campaign);
            } else if (!isCampaignEnded) {
                inProgress.push(campaign);
            }
        });
        setInProgressPrograms(inProgress);
        setCompletedPrograms(completed);
    }, [campaigns]);

    return (
        <div className='program_page'>
            <div className="container program_container">
                <h4>{t('program_page_title')}</h4>
                <section className="keep_learning">
                    <div className="program_container">
                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab={t('program_status_inprogress')} key="1">
                                {
                                    !campaigns ? <Spin indicator={antIcon}/> :
                                        <div className="program_cards row">
                                            {
                                                inProgressPrograms.map((program, index) => {
                                                    return (
                                                        <div className="program_row col-sm-12 col-lg-6 col-xl-4"
                                                             key={program.id}
                                                             ref={(el) => (progRef.current[index] = el)}
                                                        >
                                                            <div className="program_card card"
                                                                 style={{minHeight: maxHeight}}>
                                                                <div className="card_head">
                                                                    <div
                                                                        className={`card_img banner_${program.id % 10}`}></div>
                                                                    {
                                                                        !!program.lastaccess && (
                                                                            program.progress < 100 &&
                                                                            ((program.duedateabsolute && program.duedateabsolute < _today)
                                                                                    ? <span
                                                                                        className="overdue status">{t('program_status_overdue', 'Overdue')}</span>
                                                                                    : <span
                                                                                        className="status">{t('program_status_inprogress', 'In progress')}</span>

                                                                            )
                                                                        )
                                                                    }
                                                                </div>
                                                                <div className="card_body">
                                                                    <h6 className="title">
                                                                        {shorten(t(program.fullname, program.fullname), 64, ' ')}
                                                                    </h6>
                                                                    <div className="btn_wrap">
                                                                        <Link to={`/programs/${program.id}`}>
                                                                            <Button className={'secondary btn-ht-lg'}>
                                                                                {
                                                                                    (!!program.lastaccess || (0 < program.progress && 100 > program.progress)) ? t('homepage_mylearning_button_continue_learning', 'Continue Learning') : t('homepage_mylearning_button_startnow', 'Start Now')
                                                                                }
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="due_date">
                                                                        <span>
                                                                            {(!!program._duedate && program._duedate > 0) && `${t('homepage_mylearning_duedate')}: ${dayjs(program._duedate * 1000).tz(userTimezone).format('YYYY-MM-DD HH:mm')}`}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                (inProgressPrograms && !inProgressPrograms.length) && (
                                                    <div className="col-12">
                                                        <EmptyData title={''}
                                                                   message={t('message_no_program_inprogress', 'No program in progress.')}/>
                                                    </div>
                                                )
                                            }
                                        </div>
                                }
                            </TabPane>
                            <TabPane tab={t('program_status_completed')} key="2">
                                <div className="program_cards row">
                                    {
                                        completedPrograms.map((program) => {
                                                return (
                                                    <div className="program_row col-sm-12 col-lg-6 col-xl-4"
                                                         key={program.id}>
                                                        <div className="program_card card">
                                                            <div className="card_head">
                                                                <div className="card_img"></div>
                                                                <span
                                                                    className="status completed">{t('program_status_completed', 'Completed')}</span>
                                                            </div>
                                                            <div className="card_body">
                                                                <h6 className="title">
                                                                    {shorten(t(program.fullname, program.fullname), 64, ' ')}
                                                                </h6>
                                                                <div className="btn_wrap">
                                                                    <Link to={`/programs/${program.id}`}>
                                                                        <Button className='secondary'>
                                                                            {t('program_card_button_view', 'View')}
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                                <div className="due_date">
                                                                            <span>
                                                                                {(!!program._duedate && program._duedate > 0) && `${t('homepage_mylearning_duedate')}: ${dayjs(program._duedate * 1000).tz(userTimezone).format('YYYY-MM-DD HH:mm')}`}
                                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )
                                    }
                                    {
                                        (completedPrograms && !completedPrograms.length) && (
                                            <div className="col-12">
                                                <EmptyData title={''}
                                                           message={t('message_no_program_complete', 'No program completed.')}/>
                                            </div>
                                        )
                                    }
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default connect(
    mapStateToProps,
)(ProgramsPage);
