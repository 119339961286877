import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router";
import {Alert, Button, Spin} from "antd";
import {Link, useHistory} from "react-router-dom";
import {BulbOutlined, LeftOutlined, MenuOutlined} from "@ant-design/icons";
import {get_quiz_attempts, quiz_get_attempt_review, quiz_get_user_best_grade, quiz_start_attempt} from "../../shared/service";
import QuestionFeedback from "../components/QuestionFeedback";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Trans} from 'react-i18next';
import {getCourseShortName} from "../../shared/helper";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const QuizFeedback = ({programs, siteInfo}) => {
    let query = useQuery();
    const {t} = useTranslation();
    const history = useHistory();
    const {programid} = useParams();
    const {courseid} = useParams();
    const {quizid} = useParams();
    const [attempts, setAttempts] = useState([]);
    const {attemptid} = useParams();
    const [questions, setQuestions] = useState({});
    const [gradeToPass, setGradeToPass] = useState(8);
    const [totalGrade, setTotalGrade] = useState(0);
    const [accessForbid, setAccessForbid] = useState('');
    const [loading, setLoading] = useState(true);
    let program = {};
    let course = {};
    let nextCourse;
    if (programs?.hasOwnProperty(programid)) {
        program = programs[programid];
        if (program.courses && program.courses[courseid]) {
            course = program.courses[courseid];
            if (course.iscompleted) {
                let {sortorder} = course;
                nextCourse = Object.values(program.courses).find(course => course.sortorder == (sortorder + 1));
            }
        }
    }

    useEffect(() => {
        if (siteInfo?.userid && programid && courseid && quizid) {
            get_quiz_attempts(siteInfo.userid, programid, courseid, quizid).then(atmps => {
                atmps = atmps.sort((a, b) => b.timemodified - a.timemodified);
                setAttempts(atmps);
            })
        }
    }, [siteInfo, programid, courseid, quizid]);

    useEffect(() => {
        if (attemptid && !!attempts.length && !!attempts.map((attempt) => `${attempt.id}`).includes(attemptid)) {
            quiz_get_attempt_review(attemptid).then(data => {
                if (data.errorcode && data.errorcode === "noreview") {
                    setAccessForbid(data.message || t('studentapp_quizfeedback_review_msg'));
                }
                setLoading(false);
                if (!data || !data.questions || !data.attempt) {
                    return true;
                }
                if (data.grade) {
                    setTotalGrade(data.grade);
                }
                let q = {};
                let lastactionStep = 1;
                let maxactiontime = 0;
                data.questions.forEach((question) => {
                    if (question.lastactiontime > maxactiontime) {
                        lastactionStep = question.slot;
                        maxactiontime = question.lastactiontime
                    }
                    q[question.slot] = question;
                });
                setQuestions(q);
            })
        }

        quiz_get_user_best_grade(quizid).then(res => {
            if (res && res.gradetopass) {
                setGradeToPass(Number(res.gradetopass));
            }
        })
    }, [attempts]);

    let failed = totalGrade < gradeToPass;
    let recentAttempt = attempts[0];
    const startNewAttempt = () => {
        quiz_start_attempt(quizid).then(res => {
            if (res.attempt && res.attempt.id) {
                history.push(`/programs/${programid}/courses/${courseid}/quizzes/${quizid}/attempts/${res.attempt.id}`);
            }
        });
    };

    return (
        <div className="quiz_page">
            <div className="quiz_feedback_btn">
                <Link
                    to={`/programs/${programid}/courses/${courseid}/quizzes/${quizid}`}>
                    <Button className={'link secondary no-frame'} icon={<LeftOutlined/>}>
                        {t('quiz_status_quizsummary_button_backtosummary')}
                    </Button>
                </Link>
                {recentAttempt?.state === "inprogress" ?
                    <Link
                        to={`/programs/${programid}/courses/${courseid}/quizzes/${quizid}/attempts/${recentAttempt.id}`}>
                        <Button className={'default auto-width'}>
                            {t('quiz_status_button_continue')}
                        </Button>
                    </Link>
                    :
                    <Button className='default auto-width' onClick={startNewAttempt}>
                        {t('quiz_status_button_tryagain')}
                    </Button>
                }
            </div>
            {
                loading ? <Spin/> :
                    (
                        <div className="quiz_wrap feedback">
                            <div className="feedback_wrapper">
                                <div className="quiz_title">
                                    <div className="icon_wrap">
                                        <span className="square"><MenuOutlined/></span>
                                        <span>{t('homepage_mylearning_quiz')}</span>
                                    </div>
                                    <h6>{t(getCourseShortName(course?.name), course?.name)}</h6>
                                </div>
                                {
                                    accessForbid && (
                                        <Alert message={accessForbid} type="warning" showIcon closable/>
                                    )
                                }
                                <div className="quiz_wrap_inner">
                                    <ol className="quiz_list">
                                        {
                                            Object.keys(questions)?.map((slot, index) => (
                                                <QuestionFeedback question={questions[slot]} slot={slot} key={index}/>
                                            ))
                                        }
                                    </ol>
                                </div>
                                {
                                    failed &&
                                    <div className="feedback_msg">
                                        <div className="icon_light">
                                            <BulbOutlined/>
                                        </div>
                                        <p>
                                            <Trans i18nKey="quiz_status_quizsummary_feedback"
                                                   values={{$a: t(getCourseShortName(course?.name), course?.name)}}
                                                   components={{
                                                       a: <Link to={`/programs/${programid}/courses/${courseid}`}/>
                                                   }}/>
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    )
            }
        </div>
    );
};


function mapStateToProps(state) {
    return {...state.root};
}

export default connect(
    mapStateToProps,
)(QuizFeedback);
