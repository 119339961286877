import React from 'react';

const EmptyData = ({message, title = 'Ready to go?'}) => {
    return (
        <div className={'empty_data_wrap'}>
            <div className="left_icon"></div>
            <div className="right_part">
                <h6>{title}</h6>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default EmptyData;