import {
    REPORTS_SAVE_CREATEREPDATA_REQUEST,
    REPORTS_SAVE_CREATEREPDATA_FAIL,
    REPORTS_SAVE_CREATEREPDATA_SUCCESS,
    REPORTS_LOAD_REPORTS_REQUEST,
    REPORTS_LOAD_SCHEDULEDREPORTS_SUCCESS,
    REPORTS_LOAD_SAVEDREPORTS_SUCCESS,
    REPORTS_LOAD_REPORTS_FAIL,
    REPORT_DELETE_SUCCESS,
    REPORT_DELETE_REQUEST,
    REPORT_DELETE_FAIL,
    REPORT_DELETE_RESET,
    REPORTS_GENERATE_PREVIEW_TABLE_REQUEST,
    REPORTS_GENERATE_PREVIEW_TABLE_SUCCESS,
    REPORTS_GENERATE_PREVIEW_TABLE_FAIL,
    REPORTS_FORMATTED_PREVIEW_TABLE_SUCCESS,
    SCHEDULEDREPORTS_SEARCH_BY_TITLE,
    SCHEDULEDREPORTS_SEARCH_BY_STATUS,
    SAVEDREPORTS_SEARCH_BY_TITLE,
    SAVEDREPORTS_SEARCH_BY_TYPE,
    REPORTS_RENAME_REPORT_REQUEST,
    REPORTS_RENAME_REPORT_SUCCESS,
    REPORTS_RENAME_REPORT_FAIL,
    REPORTS_PREVIEW_SEARCH_BY_CAMPAIGN,
    REPORTS_PREVIEW_SEARCH_BY_DEPARTMENT,
    REPORTS_PREVIEW_SEARCH_BY_DATE,
    REPORTS_SET_HEADING,
    REPORTS_SET_FORM_VALUES,
    REPORTS_RESET_FORM_VALUES_EXCEPT_FILTER,
    REPORTS_UPDATE_FORM_VALUES,
    REPORTS_RENAME_REPORT_RESET,
    REPORTS_REPORTDETAILS_SUCCESS,
    REPORTS_REPORTDETAILS_REQUEST,
    REPORTS_REPORTDETAILS_FAIL,
    REPORTS_STATE_FORM_RESET,
    REPORTS_LOAD_ALL_REPORT_TYPES_FAIL,
    REPORTS_LOAD_ALL_REPORT_TYPES_SUCCESS,
    REPORTS_LOAD_ALL_REPORT_TYPES_REQUEST,
    SCHEDULEDREPORTS_STATE_RESET,
    REPORTS_PREVIEW_STATE_RESET,
    SAVEDREPORTS_STATE_RESET,
} from "../actiontypes/reportsConstants";
import dayjs from "dayjs";

const initialState = {
    initialValues: {
        enable_schedule: false,
        audience_ids: [],
        frequency: 'onetime',
        filter_campaign: '',
        filter_campaign_name: '',
        filter_department: '',
        filter_department_name: '',
        format: 'csv',
    },
    formValues: {
        enable_schedule: false,
        audience_ids: [],
        frequency: 'onetime',
        filter_campaign: '',
        filter_campaign_name: '',
        filter_department: '',
        filter_department_name: '',
        format: 'csv',
    }
}

export const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORTS_REPORTDETAILS_REQUEST:
            return {
                ...state,
                report_details: {
                    loading: true,
                    success: false,
                    error: false,
                },
            }
        case REPORTS_REPORTDETAILS_SUCCESS:
            return {
                ...state,
                report_details: {
                    loading: false,
                    success: true,
                    error: false,
                    reportDetails: action.payload
                },
            }
        case REPORTS_REPORTDETAILS_FAIL:
            return {
                ...state,
                report_details: {
                    loading: false,
                    success: false,
                    error: true,
                },
            }
        case REPORTS_SAVE_CREATEREPDATA_REQUEST:
            return {
                ...state,
                save_repData: {
                    loading: true,
                    success: false,
                    error: false,
                    ...action.payload,
                }
            }
        case REPORTS_SAVE_CREATEREPDATA_FAIL:
            return {
                ...state,
                save_repData: {
                    ...state.save_repData,
                    loading: false,
                    success: false,
                    error: true,
                }
            }
        case REPORTS_SAVE_CREATEREPDATA_SUCCESS:
            return {
                ...state,
                save_repData: {
                    ...state.save_repData,
                    loading: false,
                    error: false,
                    success: action.payload?.status,
                    message: action.payload?.message,
                }
            }
        case REPORTS_LOAD_ALL_REPORT_TYPES_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            }
        case REPORTS_LOAD_ALL_REPORT_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: false,
                allTypes: action.payload,
            }
        case REPORTS_LOAD_ALL_REPORT_TYPES_FAIL:
            return {
                ...state,
                loading: true,
                success: false,
                error: true,
            }
        case REPORTS_LOAD_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            }
        case REPORTS_LOAD_SCHEDULEDREPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: false,
                scheduledreports: action.payload,
            }
        case REPORTS_LOAD_SAVEDREPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: false,
                savedreports: action.payload,
            }
        case REPORTS_LOAD_REPORTS_FAIL:
            return {
                ...state,
                loading: true,
                success: false,
                error: true,
            }
        case REPORT_DELETE_REQUEST:
            return {
                ...state,
                delete_report: {
                    loading: true,
                    success: false,
                    error: false,
                },
            }
        case REPORT_DELETE_SUCCESS:
            return {
                ...state,
                delete_report: {
                    loading: false,
                    success: true,
                    message: action.payload.message,
                    error: false,
                },
            }
        case REPORT_DELETE_FAIL:
            return {
                ...state,
                delete_report: {
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload.message,
                },
            }
        case REPORT_DELETE_RESET:
            return {
                ...state,
                delete_report: {},
            }
        case REPORTS_GENERATE_PREVIEW_TABLE_REQUEST:
            return {
                ...state,
                previewdata_list: {
                    loading: true,
                    success: false,
                    error: false,
                }
            }
        case REPORTS_GENERATE_PREVIEW_TABLE_SUCCESS:
            return {
                ...state,
                previewdata_list: {
                    loading: false,
                    success: true,
                    error: false,
                    campaignData: action.payload.data,
                    filterCampaignList: action.payload.filters,
                    tableColumns: action.payload.columns
                }
            }
        case REPORTS_GENERATE_PREVIEW_TABLE_FAIL:
            return {
                ...state,
                previewdata_list: {
                    loading: false,
                    success: false,
                    error: true,
                }
            }
        case REPORTS_FORMATTED_PREVIEW_TABLE_SUCCESS:
            return {
                ...state,
                previewdata_list: {
                    campaignData: action.payload
                }
            }
        case REPORTS_SET_HEADING:
            return {
                ...state,
                heading: action.payload,
            }
        case REPORTS_RENAME_REPORT_REQUEST:
            return {
                ...state,
                rename_report: {
                    loading: true,
                    success: false,
                    error: false,
                }
            }
        case REPORTS_RENAME_REPORT_SUCCESS:
            return {
                ...state,
                heading: action.payload,
                rename_report: {
                    loading: false,
                    success: true,
                    error: false,
                }
            }
        case REPORTS_RENAME_REPORT_FAIL:
            return {
                ...state,
                rename_report: {
                    loading: false,
                    success: false,
                    error: true,
                    message: action?.payload
                }
            }
        case REPORTS_RENAME_REPORT_RESET:
            return {
                ...state,
                rename_report: {}
            }
        case REPORTS_SET_FORM_VALUES:
            return {
                ...state,
                initialValues: {
                    enable_schedule: action.payload?.enable_schedule,
                    audience_ids: action.payload?.audience_ids,
                    frequency: action.payload?.frequency,
                    filter_campaign: action.payload?.filter_campaign ?? '',
                    filter_campaign_name: action.payload?.filter_campaign_name ?? '',
                    filter_department: action.payload?.filter_department ?? '',
                    filter_department_name: action.payload?.filter_department_name ?? '',
                    filter_date_range: action.payload?.filter_date_range ?? 'Entire History',
                    startdate_absolute_unix: action.payload?.senddateabsolute,
                    enddate_absolute: action.payload?.enddate !== '' ? dayjs(action.payload?.enddate) : '',
                    format: action.payload?.format,
                },
                formValues: {
                    enable_schedule: action.payload?.enable_schedule,
                    audience_ids: action.payload?.audience_ids,
                    frequency: action.payload?.frequency,
                    filter_campaign: action.payload?.filter_settings?.campaign ?? '',
                    filter_campaign_name: action.payload?.filter_settings?.campaign ?? '',
                    filter_department: action.payload?.filter_settings?.department ?? '',
                    filter_department_name: action.payload?.filter_settings?.department ?? '',
                    filter_date_range: action.payload?.filter_settings?.date_range ?? 'Entire History',
                    startdate_absolute_unix: action.payload?.senddateabsolute,
                    enddate_absolute: action.payload?.enddate !== '' ? dayjs(action.payload?.enddate) : '',
                    format: action.payload?.format,
                }
            }
        case REPORTS_UPDATE_FORM_VALUES:
            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    ...action?.payload,
                }
            }
        case REPORTS_RESET_FORM_VALUES_EXCEPT_FILTER:
            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    enable_schedule: false,
                    audience_ids: [],
                    frequency: 'onetime',
                    startdate_absolute: null,
                    enddate_absolute: null,
                    format: 'csv',
                }
            }
        case REPORTS_STATE_FORM_RESET:
            return {
                ...state,
                heading: '',
                initialValues: {...initialState.initialValues},
                delete_report: {},
                formValues: {},
                previewdata_list: {},
                report_details: {},
                rename_report: {},
                save_repData: {}
            };
        default:
            return state;
    }
}

export const savedReportsSearchReducer = (state = {type: {}, title: ""}, action) => {
    switch (action.type) {
        case SAVEDREPORTS_SEARCH_BY_TYPE:
            return {
                ...state,
                type: action.payload
            };
        case SAVEDREPORTS_SEARCH_BY_TITLE:
            return {
                ...state,
                title: action.payload
            };
        case SAVEDREPORTS_STATE_RESET:
            return {};
        default:
            return state;
    }
};

export const scheduledReportsSearchReducer = (state = {status: {}, title: ""}, action) => {
    switch (action.type) {
        case SCHEDULEDREPORTS_SEARCH_BY_STATUS:
            return {
                ...state,
                status: action.payload
            };
        case SCHEDULEDREPORTS_SEARCH_BY_TITLE:
            return {
                ...state,
                title: action.payload
            };
        case SCHEDULEDREPORTS_STATE_RESET:
            return {};
        default:
            return state;
    }
};

export const previewReportsSearchReducer = (state = {}, action) => {
    switch (action.type) {
        case REPORTS_PREVIEW_SEARCH_BY_CAMPAIGN:
            return {
                ...state,
                campaign: action.payload
            };
        case REPORTS_PREVIEW_SEARCH_BY_DEPARTMENT:
            return {
                ...state,
                department: action.payload
            };
        case REPORTS_PREVIEW_SEARCH_BY_DATE:
            return {
                ...state,
                date: action.payload
            };
        case REPORTS_PREVIEW_STATE_RESET:
            return {};
        default:
            return state;
    }
};


