import {CampaignCertified} from "../../components/remediation/ConditionInputItems/CampaignCertified";
import {CampaignExpired} from "../../components/remediation/ConditionInputItems/CampaignExpired";
import {CampaignAllocateUsers} from "../../components/remediation/ActionInputItems/CampaignAllocateUsers";
import {Notification} from "../../components/remediation/ActionInputItems/Notification";
import {CampaignOverdue} from "../../components/remediation/ConditionInputItems/CampaignOverdue";
import {CampaignUsersAllocated} from "../../components/remediation/ConditionInputItems/CampaignUsersAllocated";
import {FortiphishCampaignCompleted} from "../../components/remediation/ConditionInputItems/FortiphishCampaignCompleted";
import {FortiphishCampaignLaunched} from "../../components/remediation/ConditionInputItems/FortiphishCampaignLaunched";
import {FortiphishUserClicked} from "../../components/remediation/ConditionInputItems/FortiphishUserClicked";
import {FortiphishUserFailed} from "../../components/remediation/ConditionInputItems/FortiphishUserFailed";
import {FortiphishUserOpened} from "../../components/remediation/ConditionInputItems/FortiphishUserOpened";
import {FortiphishUserPassed} from "../../components/remediation/ConditionInputItems/FortiphishUserPassed";
import {FortiphishUserReported} from "../../components/remediation/ConditionInputItems/FortiphishUserReported";
import {FortiphishUserSubmitted} from "../../components/remediation/ConditionInputItems/FortiphishUserSubmitted";

export const REMEDIATION_LOAD_INIT_DATA_REQUEST = "REMEDIATION_LOAD_INIT_DATA_REQUEST";
export const REMEDIATION_LOAD_INIT_DATA_SUCCESS = "REMEDIATION_LOAD_INIT_DATA_SUCCESS";
export const REMEDIATION_LOAD_INIT_DATA_FAIL = "REMEDIATION_LOAD_INIT_DATA_FAIL";
export const REMEDIATION_GET_RULES_REQUEST = "REMEDIATION_GET_RULES_REQUEST";
export const REMEDIATION_GET_RULES_SUCCESS = "REMEDIATION_GET_RULES_SUCCESS";
export const REMEDIATION_GET_RULES_FAIL = "REMEDIATION_GET_RULES_FAIL";
export const REMEDIATION_GET_RULE_BY_ID_REQUEST = "REMEDIATION_GET_RULE_BY_ID_REQUEST"
export const REMEDIATION_GET_RULE_BY_ID_FAIL = "REMEDIATION_GET_RULE_BY_ID_FAIL"
export const REMEDIATION_GET_RULE_BY_ID_SUCCESS = "REMEDIATION_GET_RULE_BY_ID_SUCCESS"
export const REMEDIATION_ARCHIVE_RULE_REQUEST = "REMEDIATION_ARCHIVE_RULE_REQUEST";
export const REMEDIATION_ARCHIVE_RULE_SUCCESS = "REMEDIATION_ARCHIVE_RULE_SUCCESS";
export const REMEDIATION_ARCHIVE_RULE_FAIL = "REMEDIATION_ARCHIVE_RULE_FAIL";
export const REMEDIATION_DELETE_RULE_REQUEST = "REMEDIATION_DELETE_RULE_REQUEST";
export const REMEDIATION_DELETE_RULE_SUCCESS = "REMEDIATION_DELETE_RULE_SUCCESS";
export const REMEDIATION_DELETE_RULE_FAIL = "REMEDIATION_DELETE_RULE_FAIL";
export const REMEDIATION_DELETE_RULE_RESET = "REMEDIATION_DELETE_RULE_RESET";
export const REMEDIATION_ENABLE_RULE_REQUEST = "REMEDIATION_ENABLE_RULE_REQUEST";
export const REMEDIATION_ENABLE_RULE_SUCCESS = "REMEDIATION_ENABLE_RULE_SUCCESS";
export const REMEDIATION_ENABLE_RULE_FAIL = "REMEDIATION_ENABLE_RULE_FAIL";
export const REMEDIATION_ENABLE_RULE_RESET = "REMEDIATION_ENABLE_RULE_RESET";
export const REMEDIATION_SET_CONDITION_SELECTED = "REMEDIATION_SET_CONDITION_SELECTED";
export const REMEDIATION_SET_ACTION_SELECTED = "REMEDIATION_SET_ACTION_SELECTED";
export const REMEDIATION_SET_HEADING = "REMEDIATION_SET_HEADING";
export const REMEDIATION_STATE_FORM_RESET = "REMEDIATION_STATE_FORM_RESET";
export const REMEDIATION_RENAME_RULE_REQUEST = "REMEDIATION_RENAME_RULE_REQUEST";
export const REMEDIATION_RENAME_RULE_FAIL = "REMEDIATION_RENAME_RULE_FAIL";
export const REMEDIATION_RENAME_RULE_SUCCESS = "REMEDIATION_RENAME_RULE_SUCCESS"
export const REMEDIATION_SAVE_RULE_REQUEST = "REMEDIATION_SAVE_RULE_REQUEST";
export const REMEDIATION_SAVE_RULE_FAIL = "REMEDIATION_SAVE_RULE_FAIL";
export const REMEDIATION_SAVE_RULE_SUCCESS = "REMEDIATION_SAVE_RULE_SUCCESS";
export const REMEDIATION_SET_SHOW_CONDITION = "REMEDIATION_SET_SHOW_CONDITION";
export const REMEDIATION_SET_ACTION_ACTIVEKEY = "REMEDIATION_SET_ACTION_ACTIVEKEY";
export const REMEDIATION_LOAD_CONDITION_FIELDS = "REMEDIATION_LOAD_CONDITION_FIELDS";
export const REMEDIATION_LOAD_ACTION_FIELDS = "REMEDIATION_LOAD_ACTION_FIELDS";

export const REMEDIATION_CONDITION_ITEMS = {
    'campaign_certified': (conditionId) => (<CampaignCertified conditionId={conditionId}/>),
    'campaign_expired': (conditionId) => (<CampaignExpired conditionId={conditionId}/>),
    'campaign_overdue': (conditionId) => (<CampaignOverdue conditionId={conditionId}/>),
    'campaign_users_allocated': (conditionId) => (<CampaignUsersAllocated conditionId={conditionId}/>),
    'fortiphish_campaign_completed': (conditionId) => (<FortiphishCampaignCompleted conditionId={conditionId}/>),
    'fortiphish_campaign_launched': (conditionId) => (<FortiphishCampaignLaunched conditionId={conditionId}/>),
    'fortiphish_user_clicked': (conditionId, form) => (<FortiphishUserClicked conditionId={conditionId} form={form}/>),
    'fortiphish_user_failed': (conditionId, form) => (<FortiphishUserFailed conditionId={conditionId} form={form}/>),
    'fortiphish_user_opened': (conditionId, form) => (<FortiphishUserOpened conditionId={conditionId} form={form}/>),
    'fortiphish_user_passed': (conditionId, form) => (<FortiphishUserPassed conditionId={conditionId} form={form}/>),
    'fortiphish_user_reported': (conditionId, form) => (<FortiphishUserReported conditionId={conditionId} form={form}/>),
    'fortiphish_user_submitted': (conditionId, form) => (<FortiphishUserSubmitted conditionId={conditionId} form={form}/>),
};

export const REMEDIATION_ACTION_ITEMS = {
    'campaign_allocate_users': (form, actionId) => (<CampaignAllocateUsers form={form} actionId={actionId}/>),
    'notification': (form, actionId) => (<Notification form={form} actionId={actionId}/>),
}

// todo: use i18t to translate those in the future
export const REMEDIATION_CONDITIONS_LABEL = {
    "campaign_certified": "Campaign certified",
    "campaign_expired": "Campaign expired",
    "campaign_overdue": "Campaign overdue",
    "campaign_users_allocated": "Users allocated to campaign",
    "fortiphish_campaign_completed": "FortiPhish campaign completed",
    "fortiphish_campaign_launched": "FortiPhish campaign launched",
    "fortiphish_user_clicked": "User clicked",
    "fortiphish_user_failed": "User failed",
    "fortiphish_user_opened": "User opened",
    "fortiphish_user_passed": "User passed",
    "fortiphish_user_reported": "User reported",
    "fortiphish_user_submitted": "User submitted",
}

export const REMEDIATION_CONDITIONS_SELECTOR_LABEL = {
    "campaign_selector": "Apply to",
    "fortiphish_clicked_number_selector": "Total number of times clicked",
    "fortiphish_failed_number_selector": "Total number of times failed",
    "fortiphish_opened_number_selector": "Total number of times opened",
    "fortiphish_passed_number_selector": "Total number of times passed",
    "fortiphish_submitted_number_selector": "Total number of times submitted",
    "fortiphish_reported_number_selector": "Total number of times reported",
}

export const REMEDIATION_ACTIONS_LABEL = {
    'campaign_allocate_users': 'Allocate users to a training campaign',
    'notification': 'Send notification',
}

export const REMEDIATION_CONDITION_FIELDS = {
    "campaign_certified": ['condition_certificationid'],
    "campaign_expired": ['condition_certificationid'],
    "campaign_overdue": ['condition_certificationid'],
    "campaign_users_allocated": ['condition_certificationid'],
    "fortiphish_campaign_completed": ['condition_campaignid'],
    "fortiphish_campaign_launched": ['condition_campaignid'],
    "fortiphish_user_clicked": ['condition_numberoftimes'],
    "fortiphish_user_failed": ['condition_campaignid', 'condition_numberoftimes'],
    "fortiphish_user_opened": ['condition_campaignid', 'condition_numberoftimes'],
    "fortiphish_user_passed": ['condition_campaignid', 'condition_numberoftimes'],
    "fortiphish_user_reported": ['condition_campaignid', 'condition_numberoftimes'],
    "fortiphish_user_submitted": ['condition_campaignid', 'condition_numberoftimes'],
}

export const REMEDIATION_ACTION_FIELDS = {
    'campaign_allocate_users': ['campaign_allocate_users'],
    'notification': ['notification'],
}
