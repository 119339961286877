import {useEffect, useState} from "react";
import {Modal} from "antd";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
import {useDispatch, useSelector} from "react-redux";
import {extendTokenValidity} from "../rootActions";
import {getUserToken} from "../helper";
import {logoutInApp, logoutToHost} from "../provider";

const TokenExpiryTracker = () => {
    const {userid, userissiteadmin, token_validuntil} = useSelector((state) => state?.root?.siteInfo) || {};
    const {extend_token, tenantAdmin} = useSelector((state) => state?.root);
    const {confirm, info} = Modal;
    const countdownDefault = 'a moment.';
    const dispatch = useDispatch();

    const isAdmin = userissiteadmin || tenantAdmin || false;

    let confirmModal;
    let infoModal;

    dayjs.extend(duration);

    const logoutUser = () => {
        if (isAdmin) {
            logoutToHost();
        } else {
            logoutInApp();
        }
    };

    const showStillThereConfirm = () => {
        confirmModal = confirm({
            title: 'Still there?',
            keyboard: false,
            content: `You’ve been inactive for a while. Your session will expire in ${countdownDefault}. Do you wish to stay connected?`,
            okText: 'Stay connected',
            cancelText: 'Logout',
            onOk() {
                // Extend token validity
                dispatch(extendTokenValidity(userid, getUserToken()));
            },
            onCancel() {
                // Logout
                logoutUser();
            },
        });
    };

    const showSignoutNotify = () => {
        infoModal = info({
            title: 'You have been signed out',
            keyboard: false,
            content: 'For security reasons, your connection times out after you’ve been inactive for a while.',
            okText: 'Sign in',
            onOk() {
                // Logout
                logoutUser();
            },
        });
    };

    useEffect(() => {
        if (token_validuntil) {
            const token_expiry = dayjs.unix(token_validuntil);
            const notify_time = token_expiry.subtract(5, 'minutes');

            let interval;
            // This will run 5 minutes before expiry.
            const shouldShowStillThereConfirm = dayjs.duration(notify_time.diff()).asSeconds() < 0;
            shouldShowStillThereConfirm && showStillThereConfirm();

            interval = setInterval(() => {
                const time_left = dayjs.duration(token_expiry.diff());
                const largerThanOneMin = time_left.asMinutes() > 1;
                const format = largerThanOneMin ? 'm' : 'ss';
                const time_suffix = largerThanOneMin ? 'minutes' : 'seconds';
                const countdown = time_left.format(format) > 0 ? time_left.format(format) : 0;

                shouldShowStillThereConfirm && confirmModal.update({
                    content: `You’ve been inactive for a while. Your session will expire in ${countdown} ${time_suffix}. Do you wish to stay connected?`,
                });

                if (time_left.asSeconds() < 0) {
                    !!confirmModal && confirmModal.destroy();

                    if (!infoModal) {
                        clearInterval(interval);
                        showSignoutNotify();
                    }
                }
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [token_validuntil]);

    useEffect(() => {
        if (extend_token) {
            if (!extend_token.success && extend_token.error) {
                // unable to extend token validity
                // Verify from Sherry/Zhuoqin what should happen should token extend fails
            }
        }
    }, [extend_token]);


    return (<></>);
}

export default TokenExpiryTracker;