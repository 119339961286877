export const DOMAINS_ACTIVE_DOMAINS_AND_SUBDOMAINS_LIST_REQUEST = 'DOMAINS_ACTIVE_DOMAINS_AND_SUBDOMAINS_LIST_REQUEST';
export const DOMAINS_ACTIVE_DOMAINS_AND_SUBDOMAINS_LIST_SUCCESS = 'DOMAINS_ACTIVE_DOMAINS_AND_SUBDOMAINS_LIST_SUCCESS';
export const DOMAINS_ACTIVE_DOMAINS_AND_SUBDOMAINS_LIST_FAIL = 'DOMAINS_ACTIVE_DOMAINS_AND_SUBDOMAINS_LIST_FAIL';

export const DOMAINS_ACTIVE_DOMAINS_LIST_REQUEST = 'DOMAINS_ACTIVE_DOMAINS_LIST_REQUEST';
export const DOMAINS_ACTIVE_DOMAINS_LIST_SUCCESS = 'DOMAINS_ACTIVE_DOMAINS_LIST_SUCCESS';
export const DOMAINS_ACTIVE_DOMAINS_LIST_FAIL = 'DOMAINS_ACTIVE_DOMAINS_LIST_FAIL';

export const DOMAINS_ACTIVE_SUBDOMAINS_LIST_REQUEST = 'DOMAINS_ACTIVE_SUBDOMAINS_LIST_REQUEST';
export const DOMAINS_ACTIVE_SUBDOMAINS_LIST_SUCCESS = 'DOMAINS_ACTIVE_SUBDOMAINS_LIST_SUCCESS';
export const DOMAINS_ACTIVE_SUBDOMAINS_LIST_FAIL = 'DOMAINS_ACTIVE_SUBDOMAINS_LIST_FAIL';

export const DOMAINS_DELETE_DOMAIN_REQUEST = 'DOMAINS_DELETE_DOMAIN_REQUEST';
export const DOMAINS_DELETE_DOMAIN_SUCCESS = 'DOMAINS_DELETE_DOMAIN_SUCCESS';
export const DOMAINS_DELETE_DOMAIN_FAIL = 'DOMAINS_DELETE_DOMAIN_FAIL';

export const DOMAINS_DELETE_SUBDOMAIN_REQUEST = 'DOMAINS_DELETE_SUBDOMAIN_REQUEST';
export const DOMAINS_DELETE_SUBDOMAIN_SUCCESS = 'DOMAINS_DELETE_SUBDOMAIN_SUCCESS';
export const DOMAINS_DELETE_SUBDOMAIN_FAIL = 'DOMAINS_DELETE_SUBDOMAIN_FAIL';

export const DOMAINS_SET_PRIMARY_SUBDOMAIN_REQUEST = 'DOMAINS_SET_PRIMARY_SUBDOMAIN_REQUEST';
export const DOMAINS_SET_PRIMARY_SUBDOMAIN_SUCCESS = 'DOMAINS_SET_PRIMARY_SUBDOMAIN_SUCCESS';
export const DOMAINS_SET_PRIMARY_SUBDOMAIN_FAIL = 'DOMAINS_SET_PRIMARY_SUBDOMAIN_FAIL';

export const DOMAINS_VERIFY_DOMAIN_REQUEST = 'DOMAINS_VERIFY_DOMAIN_REQUEST';
export const DOMAINS_VERIFY_DOMAIN_SUCCESS = 'DOMAINS_VERIFY_DOMAIN_SUCCESS';
export const DOMAINS_VERIFY_DOMAIN_FAIL = 'DOMAINS_VERIFY_DOMAIN_FAIL';

export const DOMAINS_VERIFY_SUBDOMAIN_REQUEST = 'DOMAINS_VERIFY_SUBDOMAIN_REQUEST';
export const DOMAINS_VERIFY_SUBDOMAIN_SUCCESS = 'DOMAINS_VERIFY_SUBDOMAIN_SUCCESS';
export const DOMAINS_VERIFY_SUBDOMAIN_FAIL = 'DOMAINS_VERIFY_SUBDOMAIN_FAIL';

export const DOMAINS_ADD_DOMAIN_REQUEST = 'DOMAINS_ADD_DOMAIN_REQUEST';
export const DOMAINS_ADD_DOMAIN_SUCCESS = 'DOMAINS_ADD_DOMAIN_SUCCESS';
export const DOMAINS_ADD_DOMAIN_FAIL = 'DOMAINS_ADD_DOMAIN_FAIL';

export const DOMAINS_ADD_SUBDOMAIN_REQUEST = 'DOMAINS_ADD_SUBDOMAIN_REQUEST';
export const DOMAINS_ADD_SUBDOMAIN_SUCCESS = 'DOMAINS_ADD_SUBDOMAIN_SUCCESS';
export const DOMAINS_ADD_SUBDOMAIN_FAIL = 'DOMAINS_ADD_SUBDOMAIN_FAIL';

export const DOMAINS_HIDE_DOMAIN_NOTIFICATION = 'DOMAINS_HIDE_DOMAIN_NOTIFICATION';
export const DOMAINS_HIDE_SUBDOMAIN_NOTIFICATION = 'DOMAINS_HIDE_SUBDOMAIN_NOTIFICATION';

export const DOMAINS_SET_DOMAIN_NOTIFICATION = 'DOMAINS_SET_DOMAIN_NOTIFICATION';
export const DOMAINS_SET_SUBDOMAIN_NOTIFICATION = 'DOMAINS_SET_SUBDOMAIN_NOTIFICATION';

export const DOMAINS_SET_MAXIMUM_LIMIT = 10;
