export const USERTOUR_PATH_HAS_TOUR_REQUEST = 'USERTOUR_PATH_HAS_TOUR_REQUEST';
export const USERTOUR_PATH_HAS_TOUR_SUCCESS = 'USERTOUR_PATH_HAS_TOUR_SUCCESS';
export const USERTOUR_PATH_HAS_TOUR_FAIL = 'USERTOUR_PATH_HAS_TOUR_FAIL';

export const USERTOUR_UPDATE_TOUR_COMPLETION_REQUEST = 'USERTOUR_UPDATE_TOUR_COMPLETION_REQUEST';
export const USERTOUR_UPDATE_TOUR_COMPLETION_SUCCESS = 'USERTOUR_UPDATE_TOUR_COMPLETION_SUCCESS';
export const USERTOUR_UPDATE_TOUR_COMPLETION_FAIL = 'USERTOUR_UPDATE_TOUR_COMPLETION_FAIL';

export const USERTOUR_GET_TOUR_LIST_REQUEST = 'USERTOUR_GET_TOUR_LIST_REQUEST';
export const USERTOUR_GET_TOUR_LIST_SUCCESS = 'USERTOUR_GET_TOUR_LIST_SUCCESS';
export const USERTOUR_GET_TOUR_LIST_FAIL = 'USERTOUR_GET_TOUR_LIST_FAIL';

export const USERTOUR_GET_USER_TOUR_COMPLETION_SUMMARY_REQUEST = 'USERTOUR_GET_USER_TOUR_COMPLETION_SUMMARY_REQUEST';
export const USERTOUR_GET_USER_TOUR_COMPLETION_SUMMARY_SUCCESS = 'USERTOUR_GET_USER_TOUR_COMPLETION_SUMMARY_SUCCESS';
export const USERTOUR_GET_USER_TOUR_COMPLETION_SUMMARY_FAIL = 'USERTOUR_GET_USER_TOUR_COMPLETION_SUMMARY_FAIL';

export const USERTOUR_GET_USER_TOUR_DATA_REQUEST = 'USERTOUR_GET_USER_TOUR_DATA_REQUEST';
export const USERTOUR_GET_USER_TOUR_DATA_SUCCESS = 'USERTOUR_GET_USER_TOUR_DATA_SUCCESS';
export const USERTOUR_GET_USER_TOUR_DATA_FAIL = 'USERTOUR_GET_USER_TOUR_DATA_FAIL';

export const USERTOUR_SET_ONBOARDING_VIDEO_REQUEST = 'USERTOUR_SET_ONBOARDING_VIDEO_REQUEST';
export const USERTOUR_SET_ONBOARDING_VIDEO_SUCCESS = 'USERTOUR_SET_ONBOARDING_VIDEO_SUCCESS';
export const USERTOUR_SET_ONBOARDING_VIDEO_FAIL = 'USERTOUR_SET_ONBOARDING_VIDEO_FAIL';

export const USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_REQUEST = 'USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_REQUEST';
export const USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_SUCCESS = 'USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_SUCCESS';
export const USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_FAIL = 'USERTOUR_RESET_ONBOARDING_GUIDE_STATUS_FAIL';

export const USERTOUR_SET_OPEN_TOUR = 'USERTOUR_SET_OPEN_TOUR';
