import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router";
import {Alert, Button, Tooltip} from "antd";
import {Link, useHistory} from "react-router-dom";
import {
    CheckCircleTwoTone, CloseCircleTwoTone,
    MenuOutlined, MoreOutlined
} from "@ant-design/icons";
import {connect} from "react-redux";
import {
    get_course_quizzes,
    get_overlapping_campaigns,
    get_quiz_attempts,
    quiz_get_user_best_grade,
    quiz_start_attempt, quiz_view_quiz
} from "../../shared/service";
import ModuleBread from "../components/ModuleBread";
import {useTranslation} from "react-i18next";
import {getCourseShortName} from "../../shared/helper";
import EmptyData from "../../shared/components/EmptyData";

function mapStateToProps(state) {
    return {...state.root};
}

const QUIZ_LEFT_MESSAGE = "quiz_status_comment_attempt";
const QUIZ_NOT_PASS_MESSAGE = "quiz_status_comment_goodtry";
const QUIZ_PASSED_MESSAGE = 'quiz_status_comment_passed';
const QUIZ_COMPLETED_MESSAGE = "Well done! You completed the quiz.";

const QUIZ_STATE_TEXT_MAP = {
    'inprogress': 'In progress'
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const QuizPage = ({campaigns: programs, siteInfo}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const {programid} = useParams();
    const {courseid} = useParams();
    const {quizid} = useParams();
    const {state} = useParams();
    const [currentState, setCurrentState] = useState(state);
    const [gradeToPass, setGradeToPass] = useState(0);
    const [totalGrade, setTotalGrade] = useState(10);
    const [hasMoreAttempt, setHasMoreAttempt] = useState(false);
    const [attempts, setAttempts] = useState([]);
    const [allAttempts, setAllAttempts] = useState([]);
    const [isQuestionEmpty, setIsQuestionEmpty] = useState(false);
    let program;
    let course;
    if (programs?.hasOwnProperty(programid)) {
        program = programs[programid];
        if (program.courses && program.courses[courseid]) {
            course = program.courses[courseid];
        }
    }

    useEffect(() => {
        if (siteInfo?.userid && programid && courseid && quizid) {
            get_quiz_attempts(siteInfo.userid, programid, courseid, quizid).then(atmps => {
                if (!!atmps?.length) {
                    setCurrentState('summary');
                } else {
                    startNewAttempt();
                }

                // put newest one at beginning
                if (atmps.length && atmps.length > 3) {
                    setHasMoreAttempt(true);
                }

                atmps = atmps.sort((a, b) => b.timemodified - a.timemodified);
                setAllAttempts([...atmps]);
                atmps = atmps.slice(0, 3);
                setAttempts(atmps);
                if (atmps.length) {
                    get_course_quizzes(courseid).then(res => {
                        if (Array.isArray(res)) {
                            let quiz = res.find(mod => mod.name === "Quiz");
                            if (quiz && quiz.hasOwnProperty('grade')) {
                                setTotalGrade(quiz.sumgrades);
                            }
                        }
                    })
                }
            })
        }
    }, [siteInfo, programid, courseid, quizid]);

    useEffect(() => {
        // view quiz on each visit
        quiz_view_quiz(quizid).then();

        quiz_get_user_best_grade(quizid).then(res => {
            if (res && res.gradetopass) {
                let _totalGrade = res.grade ? Number(res.grade) : 10;
                setGradeToPass(res.gradetopass * 10);
            }
        })
    }, []);

    const startNewAttempt = () => {
        quiz_start_attempt(quizid).then(res => {
            if (res.attempt && res.attempt.id) {
                history.push(`/programs/${programid}/courses/${courseid}/quizzes/${quizid}/attempts/${res.attempt.id}`);
            } else {
                setIsQuestionEmpty(true);
            }
        });
    }

    let _bestGrade = 0;
    attempts.forEach(atmp => {
        _bestGrade = Math.max(atmp.sumgrades, _bestGrade);
    });
    let isPassed = _bestGrade / totalGrade * 100 >= gradeToPass;

    if (currentState === 'summary') {
        let recentAttempt = attempts[0];
        return (
            <div className="quiz_page">
                <ModuleBread programid={programid}
                             programName={t(program?.fullname, program?.fullname)}
                             courseid={courseid}
                             courseName={t(course?.name, course?.name)} isQuiz={true}/>
                <div className="quiz_wrap summary">
                    <div className="quiz_wrap_inner">
                        <div className="quiz_title">
                            <div className="icon_wrap">
                                <span className="square"><MenuOutlined/></span>
                                <span>{t('take_a_quiz_navbar_quiz')}</span>
                            </div>
                            <h6>{t(getCourseShortName(course?.name), course?.name)}</h6>
                            {
                                !!gradeToPass && (
                                    isPassed ?
                                        <p><CheckCircleTwoTone className={'mr-2'}
                                                               twoToneColor="#52c41a"/>
                                            {t('quiz_status_congrats')}
                                        </p>
                                        :
                                        <p><CloseCircleTwoTone className={'mr-2'}
                                                               twoToneColor="#DA291C"/>
                                            {t('quiz_status_ruletopass')}
                                        </p>
                                )
                            }
                        </div>
                        <hr/>
                        <div className="attempts_wrap">
                            {
                                attempts.map((attempt, key) => {
                                    const isAttemptPassed = !!gradeToPass && (attempt.sumgrades / totalGrade * 100) >= gradeToPass;

                                    return (
                                        <div className="summary_content" key={key}>
                                            <div className="content_left">
                                                <h6>{t('quiz_status_yourgrade')}</h6>
                                                <p>{attempt.state === 'inprogress' && t(QUIZ_LEFT_MESSAGE)}</p>
                                                <p>
                                                    {(attempt.state === 'finished' && (
                                                        !!gradeToPass ?
                                                            (isAttemptPassed ? t(QUIZ_PASSED_MESSAGE) : t(QUIZ_NOT_PASS_MESSAGE))
                                                            :
                                                            t(QUIZ_COMPLETED_MESSAGE)
                                                    ))}
                                                </p>
                                                <div className='mobile-viewfeedback'>
                                                    {
                                                        (attempt.timefinish > 0) && (
                                                            <Link
                                                                to={`/programs/${programid}/courses/${courseid}/quizzes/${quizid}/attempts/${attempt.id}/feedback`}>
                                                                {t('quiz_status_button_viewfeedback')}
                                                            </Link>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="content_right">
                                                <div
                                                    className={`grade ${attempt.state} ${isAttemptPassed ? 'passed' : ''}`}>
                                                    {(!!attempt.sumgrades || attempt.sumgrades === 0) ? `${(attempt.sumgrades * 100 / totalGrade).toFixed(0)}%` : ''}
                                                    <div>
                                                        {
                                                            (attempt.timefinish > 0) && (
                                                                <Link
                                                                    to={`/programs/${programid}/courses/${courseid}/quizzes/${quizid}/attempts/${attempt.id}/feedback`}>
                                                                    {t('quiz_status_button_viewfeedback')}
                                                                </Link>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            hasMoreAttempt &&
                            <Tooltip placement="top" title={t('quiz_status_tooltop_viewmore')}>
                                <div className='text-center mt-3 mb-4 cursor-pointer' onClick={() => {
                                    if (allAttempts.length > attempts.length) {
                                        setAttempts(allAttempts);
                                        setHasMoreAttempt(false);
                                    }
                                }}>
                                    <MoreOutlined style={{fontSize: 20}}/>
                                </div>
                            </Tooltip>
                        }
                        <hr style={{marginBottom: 34}}/>
                        <div className="quiz_btn">
                            {recentAttempt?.state === "inprogress" ?
                                <Button className={'default auto-width'} onClick={() => {
                                    history.push(`/programs/${programid}/courses/${courseid}/quizzes/${quizid}/attempts/${recentAttempt.id}`);
                                }}>
                                    {t('quiz_status_button_continue')}
                                </Button>
                                :
                                !!gradeToPass && !isPassed ?
                                    <>
                                        <Button className='secondary backtoprogram mr-3' onClick={() => {
                                            history.push(`/programs/${programid}`);
                                        }}>
                                            {t('quiz_status_button_backtoprogram')}
                                        </Button>
                                        <Button className='default auto-width' onClick={startNewAttempt}>
                                            {t('quiz_status_button_tryagain')}
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Button className='secondary backtoprogram mr-3' onClick={startNewAttempt}>
                                            {t('quiz_status_button_tryagain')}
                                        </Button>
                                        <Button className='default auto-width' onClick={() => {
                                            history.push(`/programs/${programid}`);
                                        }}>
                                            {t('quiz_status_button_keeplearning')}
                                        </Button>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='quiz_page'>
            <ModuleBread programid={programid}
                         programName={t(program?.fullname, program?.fullname)}
                         courseid={courseid}
                         courseName={t(course?.name, course?.name)} isQuiz={true}/>
            {
                isQuestionEmpty && (
                    <EmptyData title={t('no_question_found')} message={t('no_question_found_in_quiz')}/>
                )
            }
        </div>
    );
};

export default connect(
    mapStateToProps,
)(QuizPage);
