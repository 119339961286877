export const ACCOUNT_MGMT_GET_CONTRACT_LIST_FAIL = "ACCOUNT_MGMT_GET_CONTRACT_LIST_FAIL";
export const ACCOUNT_MGMT_GET_CONTRACT_LIST_REQUEST = "ACCOUNT_MGMT_GET_CONTRACT_LIST_REQUEST";
export const ACCOUNT_MGMT_GET_CONTRACT_LIST_SUCCESS = "ACCOUNT_MGMT_GET_CONTRACT_LIST_SUCCESS";
export const ACCOUNT_MGMT_GET_SUBACCOUNTS_REQUEST = "ACCOUNT_MGMT_GET_SUBACCOUNTS_REQUEST";
export const ACCOUNT_MGMT_GET_SUBACCOUNTS_SUCCESS = "ACCOUNT_MGMT_GET_SUBACCOUNTS_SUCCESS";
export const ACCOUNT_MGMT_GET_SUBACCOUNTS_FAIL = "ACCOUNT_MGMT_GET_SUBACCOUNTS_FAIL";
export const ACCOUNT_MGMT_ADD_SUBACCOUNT_REQUEST = "ACCOUNT_MGMT_ADD_SUBACCOUNT_REQUEST";
export const ACCOUNT_MGMT_ADD_SUBACCOUNT_SUCCESS = "ACCOUNT_MGMT_ADD_SUBACCOUNT_SUCCESS";
export const ACCOUNT_MGMT_ADD_SUBACCOUNT_FAIL = "ACCOUNT_MGMT_ADD_SUBACCOUNT_FAIL";
export const ACCOUNT_MGMT_ADD_SUBACCOUNT_RESET = "ACCOUNT_MGMT_ADD_SUBACCOUNT_RESET";
export const ACCOUNT_MGMT_DELETE_SUBACCOUNT_REQUEST = "ACCOUNT_MGMT_DELETE_SUBACCOUNT_REQUEST";
export const ACCOUNT_MGMT_DELETE_SUBACCOUNT_SUCCESS = "ACCOUNT_MGMT_DELETE_SUBACCOUNT_SUCCESS";
export const ACCOUNT_MGMT_DELETE_SUBACCOUNT_FAIL = "ACCOUNT_MGMT_DELETE_SUBACCOUNT_FAIL";
export const ACCOUNT_MGMT_LOGINAS_REQUEST = "ACCOUNT_MGMT_LOGINAS_REQUEST";
export const ACCOUNT_MGMT_LOGINAS_SUCCESS = "ACCOUNT_MGMT_LOGINAS_SUCCESS";
export const ACCOUNT_MGMT_LOGINAS_FAIL = "ACCOUNT_MGMT_LOGINAS_FAIL";
export const ACCOUNT_MGMT_REVERT_LOGINAS_REQUEST = "ACCOUNT_MGMT_REVERT_LOGINAS_REQUEST";
export const ACCOUNT_MGMT_REVERT_LOGINAS_SUCCESS = "ACCOUNT_MGMT_REVERT_LOGINAS_SUCCESS";
export const ACCOUNT_MGMT_REVERT_LOGINAS_FAIL = "ACCOUNT_MGMT_REVERT_LOGINAS_FAIL";
export const ACCOUNT_MGMT_DELETE_PRESET_ACCOUNT_REQUEST = "ACCOUNT_MGMT_DELETE_PRESET_ACCOUNT_REQUEST";
export const ACCOUNT_MGMT_DELETE_PRESET_ACCOUNT_SUCCESS = "ACCOUNT_MGMT_DELETE_PRESET_ACCOUNT_SUCCESS";
export const ACCOUNT_MGMT_DELETE_PRESET_ACCOUNT_FAIL = "ACCOUNT_MGMT_DELETE_PRESET_ACCOUNT_FAIL";
export const ACCOUNT_MGMT_CHECK_SUPERADMIN_REQUEST = "ACCOUNT_MGMT_CHECK_SUPERADMIN_REQUEST";
export const ACCOUNT_MGMT_CHECK_SUPERADMIN_SUCCESS = "ACCOUNT_MGMT_CHECK_SUPERADMIN_SUCCESS";
export const ACCOUNT_MGMT_CHECK_SUPERADMIN_FAIL = "ACCOUNT_MGMT_CHECK_SUPERADMIN_FAIL";
export const ACCOUNT_MGMT_LOAD_ADMIN_DATA_REQUEST = "ACCOUNT_MGMT_LOAD_ADMIN_DATA_REQUEST";
export const ACCOUNT_MGMT_LOAD_ADMIN_DATA_SUCCESS = "ACCOUNT_MGMT_LOAD_ADMIN_DATA_SUCCESS";
export const ACCOUNT_MGMT_LOAD_ADMIN_DATA_FAIL = "ACCOUNT_MGMT_LOAD_ADMIN_DATA_FAIL";
export const ACCOUNT_MGMT_DELETE_ADMIN_REQUEST = "ACCOUNT_MGMT_DELETE_ADMIN_REQUEST";
export const ACCOUNT_MGMT_DELETE_ADMIN_SUCCESS = "ACCOUNT_MGMT_DELETE_ADMIN_SUCCESS";
export const ACCOUNT_MGMT_DELETE_ADMIN_FAIL = "ACCOUNT_MGMT_DELETE_ADMIN_FAIL";
export const ACCOUNT_MGMT_SAVE_ADMIN_REQUEST = "ACCOUNT_MGMT_SAVE_ADMIN_REQUEST";
export const ACCOUNT_MGMT_SAVE_ADMIN_SUCCESS = "ACCOUNT_MGMT_SAVE_ADMIN_SUCCESS";
export const ACCOUNT_MGMT_SAVE_ADMIN_FAIL = "ACCOUNT_MGMT_SAVE_ADMIN_FAIL";
export const ACCOUNT_MGMT_RESET_STATE = "ACCOUNT_MGMT_RESET_STATE";
