import {
    ASSETS_GET_ASSETS_COURSE_CONTENTS_REQUEST,
    ASSETS_GET_ASSETS_COURSE_CONTENTS_SUCCESS,
    ASSETS_GET_ASSETS_COURSE_CONTENTS_FAIL
} from "../actiontypes/assetsConstants";

export const assetsReducer = (state = {}, action) => {
    switch (action.type) {
        case ASSETS_GET_ASSETS_COURSE_CONTENTS_REQUEST:
            return {
                ...state,
                loadingAssets: true
            };
        case ASSETS_GET_ASSETS_COURSE_CONTENTS_SUCCESS:
            return {
                ...state,
                loadingAssets: false,
                assetsCourseContents: action?.payload
            };
        case ASSETS_GET_ASSETS_COURSE_CONTENTS_FAIL:
            return {
                ...state,
                loadingAssets: false,
            };
        default:
            return state;

    }
}
