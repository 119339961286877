import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {Link, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {quiz_get_attempt_data, quiz_process_attempt, quiz_save_attempt} from "../../shared/service";
import Quiz from "../components/Quiz";
import ModuleBread from "../components/ModuleBread";
import {useTranslation} from "react-i18next";
import {forceReloadCampaigns} from "../../shared/rootActions";

function mapStateToProps(state) {
    return {...state.root};
}

const QuizPlayer = ({campaigns: programs, forceReloadCampaigns}) => {
    const history = useHistory();
    const {programid} = useParams();
    const {courseid} = useParams();
    const {quizid} = useParams();
    const {attemptid} = useParams();
    const [questions, setQuestions] = useState({});
    const [stepInfo, setStepInfo] = useState({current: 1, total: 1, nextpage: 0, multipage: null});
    const {t} = useTranslation();

    let ajaxLock = false;
    let program = {};
    let course = {};
    if (programs?.hasOwnProperty(programid)) {
        program = programs[programid];
        if (program.courses && program.courses[courseid]) {
            course = program.courses[courseid];
        }
    }

    useEffect(() => {
        if (attemptid) {
            get_attempt_data(attemptid);
        }
    }, []);

    function get_attempt_data(attemptid, page = 0) {
        if (!attemptid) {
            return;
        }
        quiz_get_attempt_data(attemptid, page).then(data => {
            if (!data || !data.questions || !data.attempt) {
                return true;
            }
            const q = {};
            let lastactionStep = 1;
            let maxactiontime = 0;
            data.questions.forEach((question) => {
                if (question.lastactiontime > maxactiontime) {
                    lastactionStep = question.slot;
                    maxactiontime = question.lastactiontime
                }
                q[question.slot] = question;
            });
            setQuestions(q);
            let questionSlots = Object.keys(q);

            let multipage = stepInfo.multipage;
            if (stepInfo.multipage === null) {
                if (data.nextpage > 0) {
                    multipage = true;
                }
            }

            setStepInfo({
                total: questionSlots.length,
                current: lastactionStep,
                nextpage: data.nextpage,
                multipage: multipage
            });
        })
    }

    const processAttempt = (data, slot, finishattempt = 0, stepChange = 0) => {
        if (ajaxLock) {
            return;
        }
        ajaxLock = true;
        if (!stepInfo.multipage) {
            if (stepChange < 0 && stepInfo.current == 1) {
                return;
            }
            if (stepChange > 0 && stepInfo.current == stepInfo.total) {
                return;
            }
        }

        questions[slot] = data;
        let destructedData = {
            data: []
        };
        let {data: dataObj} = destructedData;
        Object.keys(questions).forEach((key, index) => {
            let question = questions[key];
            dataObj.push({
                name: question.name,
                value: question.value,
            })

            dataObj.push({...question.sequencecheck});
        });

        if (finishattempt) {
            quiz_process_attempt(attemptid, finishattempt, destructedData).then(res => {
                if (res.state) {
                    if (finishattempt && res.state === 'finished') {
                        forceReloadCampaigns()
                            .then(_ => history.push(`/programs/${programid}/courses/${courseid}/quizzes/${quizid}`));
                    } else if (res.state === 'inprogress') {
                        setStepInfo({...stepInfo, current: stepInfo.current + 1})
                    }
                }
            }).finally(() => {
                ajaxLock = false;
            })
        } else {
            quiz_save_attempt(attemptid, destructedData).then(res => {
                if (res.status) {
                    let current = stepInfo.current + stepChange;
                    setStepInfo({...stepInfo, current: current})
                    if (stepInfo.multipage) {
                        get_attempt_data(attemptid, current - 1);
                    }
                }
            }).finally(() => {
                ajaxLock = false;
            })
        }
    }

    return (
        <div className='quiz_page'>
            <ModuleBread programid={programid}
                         programName={t(program?.fullname, program?.fullname)}
                         courseid={course.courseid}
                         courseName={t(course?.name, course?.name)} isQuiz={true}/>

            <div className="quiz_wrap">
                {
                    Object.keys(questions).map((slot, index) => (
                        <div className={`quiz_body ${slot == stepInfo?.current ? '' : 'hidden'}`} key={slot}>
                            <Quiz question={questions[slot]} slot={slot}
                                  processAttempt={processAttempt}
                                  setStepInfo={stepInfo}
                                  {...stepInfo}/>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    forceReloadCampaigns: () => Promise.resolve(dispatch(forceReloadCampaigns()))
})

export default connect(
    mapStateToProps, mapDispatchToProps
)(QuizPlayer);