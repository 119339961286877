/* eslint-disable camelcase */
import React from 'react'
import {useTranslation} from "react-i18next";

const SpotCyberThreats = () => {
    const {t} = useTranslation();

    return (
        <div className='spotcyberthreats_panel'>
            <div className='spotcyberthreats_table row'>
                <li className='spotcyberthreats_listitem listitem_1 col-6 col-sm-6 col-lg-4'>
                    <div>
                        <div className='spotcyberthreats_content'>
                            <figure className="spot_icon spot_icon_one"></figure>
                            <h5>{t('homepage_feature_educate_title')}</h5>
                            <p>{t('homepage_feature_educate_content')}</p>
                        </div>
                    </div>
                </li>
                <li className='spotcyberthreats_listitem listitem_2 col-6 col-sm-6 col-lg-4'>
                    <div>
                        <div className='spotcyberthreats_content'>
                            <figure className="spot_icon spot_icon_two"></figure>
                            <h5>{t('homepage_feature_flexibility_title')}</h5>
                            <p>{t('homepage_feature_flexibility_content')}</p>
                        </div>
                    </div>
                </li>
                <li className='spotcyberthreats_listitem listitem_3 col-6 col-sm-6 col-lg-4'>
                    <div>
                        <div className='spotcyberthreats_content'>
                            <figure className="spot_icon spot_icon_three"></figure>
                            <h5>{t('homepage_feature_user_title')}</h5>
                            <p>{t('homepage_feature_user_content')}</p>
                        </div>
                    </div>
                </li>
                <li className='spotcyberthreats_listitem listitem_4 col-6 col-sm-6 col-lg-4'>
                    <div>
                        <div className='spotcyberthreats_content'>
                            <figure className="spot_icon spot_icon_four"></figure>
                            <h5>{t('homepage_feature_interactive_title')}</h5>
                            <p>{t('homepage_feature_interactive_content')}</p>
                        </div>
                    </div>
                </li>
                <li className='spotcyberthreats_listitem listitem_5 col-6 col-sm-6 col-lg-4'>
                    <div>
                        <div className='spotcyberthreats_content'>
                            <figure className="spot_icon spot_icon_five"></figure>
                            <h5>{t('homepage_feature_better_title')}</h5>
                            <p>{t('homepage_feature_better_content')}</p>
                        </div>
                    </div>
                </li>
                <li className='spotcyberthreats_listitem listitem_6 col-6 col-sm-6 col-lg-4'>
                    <div>
                        <div className='spotcyberthreats_content'>
                            <figure className="spot_icon spot_icon_six"></figure>
                            <h5>{t('homepage_feature_suitable_title')}</h5>
                            <p>{t('homepage_feature_suitable_content')}</p>
                        </div>
                    </div>
                </li>
            </div>
        </div>
    )
}

export default SpotCyberThreats
