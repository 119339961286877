import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import Modules from "../components/Modules";
import {BuildOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {GradCapIcon} from "../components/CustomIcons.js";

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

function mapStateToProps(state) {
    return {...state.root || {}};
}

function ProgramDetail({campaigns, siteInfo}) {
    const {timezone, user_timezone} = siteInfo;
    const [campaign, setCampaign] = useState(null);
    let {programid} = useParams();
    const {t} = useTranslation();

    let userTimezone = timezone;

    if (user_timezone && user_timezone != '99') {
        userTimezone = user_timezone;
    }

    useEffect( () => {
        if (campaigns?.hasOwnProperty(programid)) {
            setCampaign(campaigns[programid]);
        }
    }, [campaigns]);
    // todo: move these magic numbers like 1, 5 to constraint
    let dueDate = '';
    let completedCourses = 0;
    let unfinishedCourses = 0;

    if (campaign) {
        if(campaign.duedateabsolute) {
            dueDate = dayjs(campaign.duedateabsolute * 1000).tz(userTimezone).format('YYYY-MM-DD HH:mm');
        }
        let {programcourses: courses} = campaign;
        completedCourses = courses.reduce((acc, course) => course.iscompleted ? acc + 1 : acc, 0);
        unfinishedCourses = courses.length - completedCourses;
    }

    return (
        <div className='program_detail'>
            <div className="banner">
                <div className="banner_content">
                    <div className="content_text">
                        <h4>
                            {t(campaign?.fullname, campaign?.fullname)}
                        </h4>
                        <div className='content_desc' dangerouslySetInnerHTML={{__html: campaign?.programdescription}}></div>

                        { campaign?.certificateurl &&
                            <a className='download_certificate_link' href={`${campaign.certificateurl}`} target='__blank' >
                                <span>{t('program_page_download_certificate')}</span>
                            </a>}
                    </div>
                    <div className="content_svg">
                        <div className={`program_card_img program_${programid % 10}`}></div>
                    </div>
                </div>
            </div>
            <div className="program_detail_content mt-5">
                <div className="how_to_pass">
                    <h5><GradCapIcon />{t('program_page_howtopass')}</h5>
                    <div>
                        <ul>
                            <li>
                                {t('program_page_howtopass_passquiz')}
                            </li>
                            {
                                dueDate && (
                                    <li>
                                        {t('program_page_howtopass_bydate')} <b>{dueDate}</b>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>

                <div className="modules program_container">
                    <h5><BuildOutlined/>{t('program_page_modules')}</h5>
                    <div className="program_wrap">
                        {
                            unfinishedCourses > 0 &&
                            <p className='overview_info'>
                                {`${unfinishedCourses} ${i18next.t('program_page_modulesleft', {count: unfinishedCourses})}`}
                            </p>
                        }
                        <Modules key={campaign?.id} courses={campaign?.programcourses} programid={campaign?.id} templateid={campaign?.programid} progress={campaign?.progress} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(
    mapStateToProps,
)(ProgramDetail);
