import {Form, Input, Button} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import Alert from "antd/es/alert";
import React from "react";
import {useTranslation} from "react-i18next";

export const LoginForm = ({onSubmit, submitted, setSubmitted, userExist}) => {
    const formRef = React.createRef();
    const {t} = useTranslation();

    const onFinish = (values) => {
        onSubmit(values);
    };

    const onFinishFailed = (errorInfo) => {
        setSubmitted(false);
    };

    return (
        <Form
            name="basic"
            initialValues={{
                remember: true,
            }}
            ref={formRef}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="login-form"
        >
            <Form.Item
                validateTrigger='onSubmit'
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'This field is required',
                    },
                    {
                        type: 'email',
                        message: 'Please provide a properly formatted email address',
                    },
                ]}
            >
                <Input className={submitted && !userExist?'account_not_found':''} prefix={<UserOutlined/>} placeholder={'example@email.com'} onBlur={(e) => {
                    let email = e.target.value.trim();
                    formRef.current.setFieldsValue({email: email});
                }}/>
            </Form.Item>

            {
                (submitted && !userExist) && (
                    <Form.Item className='account_not_found'>
                        <Alert className='ant-form-item-explain ant-form-item-explain-error' message={t('account_not_found')} />
                    </Form.Item>
                )
            }

            <Form.Item>
                <Button className='primary full-width' type="primary" htmlType="submit">
                    {t('login_page_button_continue')}
                </Button>
            </Form.Item>
        </Form>
    );
};
