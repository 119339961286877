import variables from "./variables";

export const MOODLE_SERVICE_ENDPOINT = variables.MOODLE_HOST + variables.SERVER_REQUEST_PATH;
export const LDAP_CONNECTION_MAX = 10;
export const LDAP_SERVER_STATUS_DISABLED = 0;
export const LDAP_SERVER_STATUS_ACTIVE = 1;
export const LDAP_SERVER_STATUS_LIMIT_REACHED = 2;
export const LOCAL_ENV = 'local';
export const DEFAULT_SERVER_TIMEZONE = '99';
export const EDUCATION_MODULE_PREFIX = 'Education';
export const EDUCATION_MICRO_MODULE_PREFIX = 'Education Micro';
export const MICRO_MODULE_PREFIX = 'Micro Module';
export const EDUCATION_TEMPLATE_PREFIX = 'Educators';
export const DEFAULT_CAMPAIGN_SELECTABLE_DATE_RANGE = 3; //in years
export const FORTINET_SUPPORT_URL = 'https://helpdesk.ftnt.info';
export const SAC_DOWNLOAD_ASSETS_DEFAULT_TOPIC = 'Downloadable Packages';
export const CAMPAIGN_MODULE_DEFAULT_DURATION = '[default]';
export const CAMPAIGN_NOTIFICATION_DEFAULT_ENABLED = true;
export const CAMPAIGN_ASSIGN_USERS_ALL = 'all';
export const CAMPAIGN_ASSIGN_USERS_GROUP = 'group';
export const DOMAIN_REG_EXP = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const ENVIRONMENT_VAR_DEV = 'development';
export const ENVIRONMENT_VAR_STAGING = 'staging';
export const ENVIRONMENT_VAR_PRODUCTION = 'production';
