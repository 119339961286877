import {
    ACCOUNT_MGMT_ADD_SUBACCOUNT_FAIL,
    ACCOUNT_MGMT_ADD_SUBACCOUNT_REQUEST,
    ACCOUNT_MGMT_ADD_SUBACCOUNT_RESET,
    ACCOUNT_MGMT_ADD_SUBACCOUNT_SUCCESS,
    ACCOUNT_MGMT_CHECK_SUPERADMIN_FAIL,
    ACCOUNT_MGMT_CHECK_SUPERADMIN_REQUEST,
    ACCOUNT_MGMT_CHECK_SUPERADMIN_SUCCESS,
    ACCOUNT_MGMT_DELETE_ADMIN_FAIL,
    ACCOUNT_MGMT_DELETE_ADMIN_REQUEST,
    ACCOUNT_MGMT_DELETE_ADMIN_SUCCESS,
    ACCOUNT_MGMT_DELETE_SUBACCOUNT_FAIL,
    ACCOUNT_MGMT_DELETE_SUBACCOUNT_REQUEST,
    ACCOUNT_MGMT_DELETE_SUBACCOUNT_SUCCESS,
    ACCOUNT_MGMT_GET_CONTRACT_LIST_FAIL,
    ACCOUNT_MGMT_GET_CONTRACT_LIST_REQUEST,
    ACCOUNT_MGMT_GET_CONTRACT_LIST_SUCCESS,
    ACCOUNT_MGMT_GET_SUBACCOUNTS_FAIL,
    ACCOUNT_MGMT_GET_SUBACCOUNTS_REQUEST,
    ACCOUNT_MGMT_GET_SUBACCOUNTS_SUCCESS,
    ACCOUNT_MGMT_LOAD_ADMIN_DATA_FAIL,
    ACCOUNT_MGMT_LOAD_ADMIN_DATA_REQUEST,
    ACCOUNT_MGMT_LOAD_ADMIN_DATA_SUCCESS,
    ACCOUNT_MGMT_LOGINAS_FAIL,
    ACCOUNT_MGMT_LOGINAS_REQUEST,
    ACCOUNT_MGMT_LOGINAS_SUCCESS,
    ACCOUNT_MGMT_SAVE_ADMIN_FAIL,
    ACCOUNT_MGMT_SAVE_ADMIN_REQUEST,
    ACCOUNT_MGMT_SAVE_ADMIN_SUCCESS,
} from "../actiontypes/accountMgmtConstants";
import {getPayload} from "../../../shared/rootActions";
import i18next from "i18next";
import {MOODLE_SERVICE_ENDPOINT} from "../../../shared/constants/globalConstants";

export const listContracts = () => async (dispatch) => {
    dispatch({
        type: ACCOUNT_MGMT_GET_CONTRACT_LIST_REQUEST,
    });

    const queryParam = {
        wsfunction: 'local_bridge_get_contract_list',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: ACCOUNT_MGMT_GET_CONTRACT_LIST_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: ACCOUNT_MGMT_GET_CONTRACT_LIST_SUCCESS,
                payload: response_data,
            });
        }
    } catch (error) {
        dispatch({
            type: ACCOUNT_MGMT_GET_CONTRACT_LIST_FAIL,
            payload: error,
        });
    }
};

export const getSubAccounts = (tenantid) => async (dispatch) => {
    dispatch({
        type: ACCOUNT_MGMT_GET_SUBACCOUNTS_REQUEST,
    });

    const queryParam = {
        tenantid,
        wsfunction: 'local_bridge_get_sub_accounts',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: ACCOUNT_MGMT_GET_SUBACCOUNTS_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: ACCOUNT_MGMT_GET_SUBACCOUNTS_SUCCESS,
                payload: response_data && response_data.map((item, key) => {
                    item.key = key;
                    item.account_number = item?.account_id || item?.tenant_idnumber || i18next.t('account_management_not_yet_created', 'Not yet created');
                    return item;
                }),
            });
        }
    } catch (error) {
        dispatch({
            type: ACCOUNT_MGMT_GET_SUBACCOUNTS_FAIL,
            payload: error,
        });
    }
};

export const addSubAccount = (email, parent, ignore_error = false) => async (dispatch) => {
    dispatch({
        type: ACCOUNT_MGMT_ADD_SUBACCOUNT_REQUEST,
    });

    const queryParam = {
        email,
        parent,
        ignore_error: ignore_error ? 1 : 0,
        wsfunction: 'local_bridge_add_sub_account',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: ACCOUNT_MGMT_ADD_SUBACCOUNT_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: ACCOUNT_MGMT_ADD_SUBACCOUNT_SUCCESS,
                payload: response_data,
            });
        }
    } catch (error) {
        dispatch({
            type: ACCOUNT_MGMT_ADD_SUBACCOUNT_FAIL,
            payload: error,
        });
    } finally {
        dispatch({
            type: ACCOUNT_MGMT_ADD_SUBACCOUNT_RESET,
            payload: {}
        })
    }
};

export const deleteSubAccount = (tenantid) => async (dispatch) => {
    dispatch({
        type: ACCOUNT_MGMT_DELETE_SUBACCOUNT_REQUEST,
    });

    const queryParam = {
        tenantid,
        wsfunction: 'local_bridge_delete_sub_account',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json() || response.ok;

        if (response_data?.exception) {
            dispatch({
                type: ACCOUNT_MGMT_DELETE_SUBACCOUNT_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: ACCOUNT_MGMT_DELETE_SUBACCOUNT_SUCCESS,
                payload: response_data,
            });
        }
    } catch (error) {
        dispatch({
            type: ACCOUNT_MGMT_DELETE_SUBACCOUNT_FAIL,
            payload: error,
        });
    }
};

export const loginAs = (loginbyuserid, loginasuserid, revert = false) => async (dispatch) => {
    if (!loginbyuserid || !loginasuserid) {
        return;
    }

    dispatch({
        type: ACCOUNT_MGMT_LOGINAS_REQUEST,
    });

    const queryParam = {
        loginbyuserid,
        loginasuserid,
        revert: Number(revert),
        wsfunction: 'local_bridge_auth_loginas',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json() || response.ok;

        if (response_data?.exception) {
            dispatch({
                type: ACCOUNT_MGMT_LOGINAS_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: ACCOUNT_MGMT_LOGINAS_SUCCESS,
                payload: response_data,
            });
        }
    } catch (error) {
        dispatch({
            type: ACCOUNT_MGMT_LOGINAS_FAIL,
            payload: error,
        });
    }
};

export const checkSuperAdmin = () => async (dispatch) => {
    dispatch({
        type: ACCOUNT_MGMT_CHECK_SUPERADMIN_REQUEST,
    });

    const queryParam = {
        wsfunction: 'local_bridge_check_superadmin',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: ACCOUNT_MGMT_CHECK_SUPERADMIN_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: ACCOUNT_MGMT_CHECK_SUPERADMIN_SUCCESS,
                payload: response_data,
            });
        }
    } catch (error) {
        dispatch({
            type: ACCOUNT_MGMT_CHECK_SUPERADMIN_FAIL,
            payload: error,
        });
    }
};

export const loadAdminData = () => async (dispatch) => {
    dispatch({
        type: ACCOUNT_MGMT_LOAD_ADMIN_DATA_REQUEST,
    });

    const queryParam = {
        wsfunction: 'local_bridge_load_admin_data',
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: ACCOUNT_MGMT_LOAD_ADMIN_DATA_FAIL,
                payload: response_data,
            });
        } else {
            let response = {
                success: response_data.success,
                data: JSON.parse(response_data.data)
            }

            dispatch({
                type: ACCOUNT_MGMT_LOAD_ADMIN_DATA_SUCCESS,
                payload: response,
            });
        }
    } catch (error) {
        dispatch({
            type: ACCOUNT_MGMT_LOAD_ADMIN_DATA_FAIL,
            payload: error,
        });
    }
};

export const deleteAdmin = (user_id) => async (dispatch) => {
    dispatch({
        type: ACCOUNT_MGMT_DELETE_ADMIN_REQUEST,
    });

    const queryParam = {
        wsfunction: 'local_bridge_delete_admin',
        user_id: user_id
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: ACCOUNT_MGMT_DELETE_ADMIN_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: ACCOUNT_MGMT_DELETE_ADMIN_SUCCESS,
                payload: response_data,
            });
        }
    } catch (error) {
        dispatch({
            type: ACCOUNT_MGMT_DELETE_ADMIN_FAIL,
            payload: error,
        });
    }
};

export const saveAdmins = (params) => async (dispatch) => {
    dispatch({
        type: ACCOUNT_MGMT_SAVE_ADMIN_REQUEST,
    });

    const queryParam = {
        wsfunction: 'local_bridge_save_admin',
        ...params
    };

    try {
        const response = await fetch(MOODLE_SERVICE_ENDPOINT, getPayload(queryParam));
        const response_data = await response.json();

        if (response_data?.exception) {
            dispatch({
                type: ACCOUNT_MGMT_SAVE_ADMIN_FAIL,
                payload: response_data,
            });
        } else {
            dispatch({
                type: ACCOUNT_MGMT_SAVE_ADMIN_SUCCESS,
                payload: response_data,
            });
        }
    } catch (error) {
        dispatch({
            type: ACCOUNT_MGMT_SAVE_ADMIN_FAIL,
            payload: error,
        });
    }
};
