import React, {useState} from "react";
import { withTranslation } from "react-i18next";
import { Collapse } from "antd";
import TableMobileProgressBar from "./TableMobileProgressBar";
const { Panel } = Collapse;

class TableMobileCollapse extends React.Component {
    constructor(props) {
        super(props);
        this.record = props.record;
    }

    render() {
        const { t } = this.props;
        const mobileProgressArray = this.record.progress.map((progress,id) => (
            <TableMobileProgressBar key={`${progress.name}-${id}`} progress={progress} />
        ));

        return (
            <Collapse
                expandIconPosition='right'
            >
                <Panel header={this.record.campaign} key={this.record.key}>
                    <div className="row">
                        <div className="column column-title">
                            <p>{t('custom_dashboard_training_details_columns_status', 'Status')}</p>
                        </div>
                        <div className="column column-value">
                            <p>{this.record.campaign_status}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column column-title">
                            <p>{t('custom_dashboard_training_details_columns_learners', 'Learners')}</p>
                        </div>
                        <div className="column column-value">
                            <p>{this.record.allocated_users}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="column column-title">
                            <p>{t('custom_dashboard_training_details_columns_progress', 'Progress')}</p>
                        </div>
                        <div className="column column-value">
                            {mobileProgressArray}
                        </div>
                    </div>

                    <div className="row">
                        <div className="column column-title">
                            <p>{t('custom_dashboard_training_details_columns_campaign_date', 'Campaign Date')}</p>
                        </div>
                        <div className="column column-value">
                            <p>{this.record.campaign_start_date} to {this.record.campaign_end_date}</p>
                        </div>
                    </div>
                </Panel>
            </Collapse>
        );
    }
}

export default withTranslation()(TableMobileCollapse);
