import {encodeObjToParam} from "../../shared/helper";
import {Alert, Spin} from "antd";
import Variable from "../../shared/constants/variables";
import {connect} from "react-redux";

import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {forceReloadCampaigns} from "../../shared/rootActions";
import {video_get_activity_completion, video_get_activity_params} from "../../shared/service";

const incognitofunc = async () => {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
        const {usage, quota} = await navigator.storage.estimate();
        const quotainMB = Math.round(quota / (1024 * 1024));
        if (quota < 120000000) {
            return true;
        } else {
            return false;
        }
    }
}

const ModuleVideoFrame = ({module, siteInfo, enrolling, forceReloadCampaigns, _token}) => {
    const {video_cmid: moduleId, video_context_id: contextid, video_activity_params: activityParams} = module;
    const [isSessionOut, setIsSessionOut] = useState(null);

    const _node = useRef();
    const {t} = useTranslation();

    const userId = siteInfo?.userid || null;
    const lang = siteInfo?.lang || Variable.DEFAULT_LANG;

    useEffect(() => {
        if (!_node.current) {
            return;
        }
        _node.current.addEventListener("load", function (e) {
            // no content loaded - no path list
            const loadedContent = Object.keys(this.contentWindow);
            const isSiteBlocked = !loadedContent.length;
            const isPrivateContent = e.hasOwnProperty('path') && (Array.isArray(e.path) && e.path.length === 0);
            if (isSiteBlocked && isPrivateContent) {
                setIsSessionOut(true);
                console.error('Detect session expired');
                // setReloginRequired(true);
                // todo: session expired -> show re-login required popup instead of kick off
                // provider.logout();
            }
        });
    }, [_node.current])

    // force campaign reload after video iframe destroyed
    useEffect(() => {
        return () => {
            forceReloadCampaigns();
        }
    }, []);

    if (!moduleId) {
        return <Alert
            message={t('warning_title')}
            description={t('video_loading_failed')}
            type="warning"
            showIcon
            closable
        />;
    }

    if (!contextid) {
        return <Alert
            message={t('warning_title')}
            description={t('video_loading_failed')}
            type="warning"
            showIcon
            closable
        />;
    }

    if (enrolling || !activityParams || !activityParams?.success) {
        return <Spin/>
    }

    if (!userId) {
        return <Alert
            message={t('warning_title')}
            description={t('video_loading_failed')}
            type="warning"
            showIcon
            closable
        />;
    }

    // Construct the launch URL.
    const filteredParams = {...activityParams};
    delete filteredParams['success'];
    delete filteredParams['message'];

    const query = encodeObjToParam(filteredParams);

    const launchURL = `${Variable.MOODLE_HOST}/local/bridge/tincanfile.php/${_token}/${contextid}/content/index_lms.html?${query}&embed=1&lang=${lang}`;

    return (
        <div className="module_video_frame">
            {isSessionOut ?
                <div className='play-video-area'>
                    <a className='play-video' onClick={(e) => {
                        e.preventDefault();
                        window.open(launchURL, '_blank', "location=1,status=1,scrollbars=1, width=1280,height=720").focus();
                    }} target='_blank'>{t('video_play_btn')}</a>
                </div> :
                <iframe ref={_node} src={launchURL} className={'iframe responsive-iframe'}
                        title={module.video_name}></iframe>
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {...state.root};
}

const mapDispatchToProps = dispatch => ({
    forceReloadCampaigns: () => dispatch(forceReloadCampaigns())
})

export default connect(
    mapStateToProps, mapDispatchToProps
)(ModuleVideoFrame);
