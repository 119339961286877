import {call} from "./helper";
import Variable from "./constants/variables";

export const get_user_campaigns = async () => {
    return call('local_campaign_get_user_campaigns').then(r => r || []);
}

export const get_course_content = async (courseid) => {
    return call('core_course_get_contents', null, {courseid: courseid}).then(res => {
        if (Array.isArray(res)) {
            return res;
        }

        if (res && res.errorcode) {
            return res.errorcode;
        }

        return [];
    })
}

export const update_user_course_lastaccess = async (courseid) => {
    return call('local_campaign_update_user_course_lastaccess', null, {courseid}).then(res => {
        return res;
    })
}

export const course_get_recent_courses = async (userid) => {
    return call('core_course_get_recent_courses', null, {userid: userid}).then(courses => {
        if (Array.isArray(courses)) {
            return courses;
        }

        return [];
    })
}

/** @deprecated No longer used to fetch user emails. Logic has moved to using userIds which are fetched with siteInfo. */
export const user_get_users_by_field = async (userid) => {
    return call('core_user_get_users_by_field', null, {field: 'id', 'values[0]': userid}).then(users => {
        if (Array.isArray(users)) {
            return users;
        }

        return [];
    })
}

export const get_course_quizzes = async (courseid) => {
    return call('mod_quiz_get_quizzes_by_courses  ', null, {'courseids[0]': courseid}).then(res => {
        return res.quizzes || [];
    })
}

export const quiz_get_user_best_grade = async (quizid) => {
    return call('mod_quiz_get_user_best_grade  ', null, {'quizid': quizid}).then(res => res)
}

export const quiz_view_quiz = async (quizid) => {
    return call('mod_quiz_view_quiz', null, {'quizid': quizid}).then(res => res)
}

export const program_enrol_user_to_course = async (programid, courseid) => {
    return call('tool_program_enrol_user_to_course  ', null, {courseid: courseid, programid: programid}).then(res => {
        return res.status || false;
    })
}
export const enrol_user_to_course = async (courseid) => {
    return call('enrol_self_enrol_user  ', null, {courseid: courseid}).then(res => {
        return res.status || false;
    })
}

/**
 * @override `mod_quiz_get_user_attempts`
 */
export const get_quiz_attempts = async (userid, campaignid, courseid, quizid) => {
    return call('local_campaign_get_quiz_attempts', null,
        {
            user: userid,
            campaign: campaignid,
            course: courseid,
            quiz: quizid,
        }).then(r => {
        if (r.attempts && Array.isArray(r.attempts)) {
            return r.attempts || [];
        }

        return [];
    })
}

export const quiz_start_attempt = async (quizID) => {
    return call('mod_quiz_start_attempt', null,
        {
            quizid: quizID,
        }).then(r => r)
}

export const quiz_process_attempt = async (attemptID, finishattempt, data) => {
    return call('mod_quiz_process_attempt', null,
        {
            attemptid: attemptID,
            finishattempt: finishattempt
        }, data).then(r => r)
}

export const quiz_save_attempt = async (attemptID, data) => {
    return call('mod_quiz_save_attempt', null,
        {
            attemptid: attemptID,
        }, data).then(r => r)
}

export const quiz_get_attempt_data = async (attemptid, page = 0) => {
    return call('mod_quiz_get_attempt_data', null,
        {
            page: page,
            attemptid: attemptid
        }).then(r => r)
}

export const quiz_get_attempt_review = async (attemptid) => {
    return call('mod_quiz_get_attempt_review', null,
        {
            attemptid: attemptid
        }).then(r => r)
}

/**
 * Fetch a video completion record, given an course module id and a user id.
 *
 * @param {int} userid
 * @param {int} activityid
 *
 * @returns {Array}
 */
export const video_get_activity_completion = async (userid, cmid) => (
    call('local_bridge_get_xapi_activity_completion', Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN,
        {
            userid: userid,
            cmid: cmid
        }).then(r => r)
);

export const video_get_activity_params = async (userid, cmid) => (
    call('local_bridge_get_xapi_activity_params', Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN,
        {
            userid: userid,
            cmid: cmid
        }).then(r => r)
);

export const setupSite = async (token) => {
    if (!token) {
        return false;
    }

    let siteInfo = await call('local_bridge_get_site_info', token);
    // todo: setup global setInfo
    return siteInfo;
}

export const get_user_login_info = async (identifier) => {
    return call('local_bridge_sso_search_user', Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN,
        {
            ...identifier
        }).then(r => r);
}

export const update_user_fields = async (values) => {
    let fields;

    if (typeof values === 'object') {
        fields = Object.keys(values).map((key) => {
            let type = 'text';
            return {
                'name': key,
                'value': values[key],
                'type': type,
            };
        });
    }

    return call('local_user_update_user_fields', null, null,
        {
            fields: fields,
        }, true).then(res => res);
};

export const user_forgot_password = async (identifier) => {
    return call('local_user_forgot_password', Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN,
        {
            ...identifier
        }).then(r => r);
}

export const user_reset_password = async (reset_token, new_password) => {
    return call('local_user_reset_password', Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN,
        {
            token: reset_token,
            new_password: new_password
        }).then(r => r);
}


/**
 *
 * external app login using username and password
 * @param _username
 * @param _password
 * @param hash
 * @returns {Promise<void>}
 */
export const local_user_login = async (_username, _password, hash = null) => {
    let postData = {
        username: _username,
        password: _password,
    };
    if (hash) {
        postData.hash = hash
    }
    return call('local_user_login', Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN, postData).then(r => r);
};

/**
 *
 * Both factor and user id are required(hashed) since you shouldn't keep trying others' factorid
 * @param hash
 * @param code
 * @returns {Promise<*>}
 */
export const local_user_mfa_code_verify = async (hash, code) => {
    return call('local_user_mfa_code_verify', Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN,
        {
            hash: hash,
            code: code,
        }).then(r => r);
};

/**
 * Try to resend token emails *There's a timeout lock
 * @param hash
 * @returns {Promise<void>}
 */
export const local_user_mfa_code_resend = async (hash) => {
    return call('local_user_mfa_code_resend', Variable.MOODLE_ADDITIONAL_SERVICE_TOKEN,
        {
            hash: hash,
        }).then(r => r);
}
