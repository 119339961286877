import {useSelector} from "react-redux";
import {Form, Select} from "antd";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";

export const CampaignAllocateUsers = ({form, actionId = 0}) => {
    const {
        remediationData,
        condition_selected: condition,
        is_init_loaded
    } = useSelector((state) => state?.remediation) || {};
    const {id: editMode} = useParams();

    const allCampaignOptions = remediationData?.campaigns_for_options;
    const [campaignOptions, setCampaignOptions] = useState(allCampaignOptions);
    const [fieldInitData, setFieldInitData] = useState(false);
    const conditionId = condition?.id ?? 0;
    const conditionValue = Form.useWatch(`${conditionId}_condition_certificationid`, form);

    useEffect(() => {
        if (editMode && condition) {
            setFieldInitData(true);
        }
    }, []);

    useEffect(() => {
        if (remediationData && conditionValue) {
            let options = allCampaignOptions.filter(item => item.value !== conditionValue);
            setCampaignOptions(options);
            if (fieldInitData) setFieldInitData(false);
            else form.setFieldValue([`campaign_allocate_users_${actionId}`, "action_certificationid"], undefined);
        }
    }, [conditionValue, remediationData, is_init_loaded]);

    const inputItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 6,
            },
            lg: {
                span: 4,
            }
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 12,
            },
        },
    };

    return (
        <div key={actionId} className={'condition-input-item-content'}>
            <Form.Item name={[`campaign_allocate_users_${actionId}`, "action_certificationid"]}
                       fieldKey={[actionId, 'action_certificationid']}
                       {...inputItemLayout}
                       label={'Campaigns'}
                       rules={[{required: true, message: 'You must select at least one campaign'}]}>
                <Select showSearch
                        placeholder={'Select campaigns'}
                        className={'input-ht-lg'}
                        options={campaignOptions}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }></Select>
            </Form.Item>
        </div>
    )
}
