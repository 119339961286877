import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Badge, Avatar, Menu, Dropdown, Layout, Modal, Button, Space, Tooltip, Empty, message} from "antd";
import {Link, NavLink} from "react-router-dom";
import Icon, {
    BellOutlined, CaretDownOutlined, DownOutlined, LeftOutlined,
    MenuOutlined, QuestionCircleOutlined, SettingOutlined,
    UpOutlined,
} from '@ant-design/icons';
import {FORTINET_SUPPORT_URL} from "../../shared/constants/globalConstants";
import {ReactComponent as ServiceIcon} from "../../assets/images/services_icon.svg";
import {ReactComponent as SupportIcon} from "../../assets/images/support_icon.svg";
import {useTranslation} from "react-i18next";
import provider from "../../shared/provider";
import {CustomerSupportIcon, LearnerIcon} from "../../admin/components/CustomIcons";
import {usernameInitial} from "../../shared/utility";
import Variable from "../../shared/constants/variables"
import {loginAs} from "../redux/actions/accountMgmtActions";
import {sso_logout_required} from "../../shared/helper";

const {Header: AntHeader} = Layout;

function simulateFormPost(URL, accountID, userID = '0', newTab = false, sourceAPP = 'FortiSAT') {
    const formId = 'submissionForm__js';
    const inputId = 'submissionInput__js';
    let form = document.getElementById(formId);
    let input = document.getElementById(inputId);

    const h_key = {
        source_app: sourceAPP,
        account_id: accountID,
        user_id: userID, // Optional field
    };

    if (!input) {
        input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'h_key';
    }

    if (!form) {
        form = document.createElement('form');
        form.id = formId;
        form.method = 'POST';
        form.appendChild(input);
        document.body.appendChild(form);
    }

    form.action = URL;
    form.target = newTab ? '_blank' : '';

    input.value = JSON.stringify(h_key);
    form.submit();
}

function mapStateToProps(state) {
    return {...state.root};
}

const MAX_SERVICE_DISPLAY = 8;

function AdminHeader({siteInfo, isSiteAdmin, onCollapse, fortiCloudData, collapsed}) {
    const [isLoginasLogoutModalOpen, setIsLoginasLogoutModalOpen] = useState(false);
    const [serviceHeaderMenu, setServiceHeaderMenu] = useState(null);
    const [supportHeaderMenu, setSupportHeaderMenu] = useState(null);
    const [profileHeaderMenu, setProfileHeaderMenu] = useState(null);
    const [showFull, setShowFull] = useState(false);
    const [showMoreOption, setShowMoreOption] = useState(false);
    const [fortiCloudLogo, setFortiCloudLogo] = useState(null);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const loginasInfo = useSelector((state) => state?.root?.siteInfo?.loginas_info);
    const auth_type = useSelector((state) => state?.root?.siteInfo?.auth_type);
    const forticloud_permissions = useSelector((state) => state?.root?.siteInfo?.forticloud_permissions);
    const forticloud_permissions_data = forticloud_permissions ? JSON.parse(forticloud_permissions) : [];
    let {
        fc_premium_logo_info,
        portal_menu_items,
        support_menu_items,
        user_display_info,
        user_menu_items,
        portal_menu_items_visible,
        account_id,
        user_id,
    } = forticloud_permissions_data;
    let availableServiceItemsCount = 0;


    useEffect(() => {
        let serviceMenu = {
            key: 'forti_services',
            label: 'Services',
            Assets: {class: 'assets', children: []},
            CloudManagement: {class: 'cloud_management', children: []},
            CloudServices: {class: 'cloud_services', children: []},
        };

        let supportMenu = {
            key: 'forti_support',
            label: 'Support',
            downloads: {},
            resources: {},
            forticare: {},
            forticare_beta: {}
        };

        if (fortiCloudData?.portal_menu_headers) {
            fortiCloudData?.portal_menu_headers.map(headerItem => {
                switch (headerItem.display_name) {
                    case 'Assets & Accounts':
                        serviceMenu.Assets = {...serviceMenu.Assets, ...headerItem};
                        break;
                    case 'Cloud Services':
                        serviceMenu.CloudServices = {...serviceMenu.CloudServices, ...headerItem};
                        break;
                    case 'Cloud Management':
                        serviceMenu.CloudManagement = {...serviceMenu.CloudManagement, ...headerItem};
                        break;
                }
            });
        }

        if (fortiCloudData?.portal_menu_items) {
            availableServiceItemsCount = 0;

            fortiCloudData?.portal_menu_items.sort((a, b) => a.order - b.order).map(serviceitem => {
                let visible = false;
                if (portal_menu_items && portal_menu_items.hasOwnProperty(serviceitem.app_id)) {
                    visible = portal_menu_items[serviceitem.app_id].visibility || false;
                }

                let new_serviceitem = {...serviceitem, visible};

                switch (serviceitem.section_header) {
                    case 'Assets & Accounts':
                        serviceMenu.Assets.children = Array.isArray(serviceMenu.Assets.children) ?
                            serviceMenu.Assets.children : [];
                        serviceMenu.Assets.children.push(new_serviceitem);
                        break;
                    case 'Cloud Services':
                        if (new_serviceitem.visible) {
                            availableServiceItemsCount++;
                        }
                        serviceMenu.CloudServices.children = Array.isArray(serviceMenu.CloudServices.children) ?
                            serviceMenu.CloudServices.children : [];
                        serviceMenu.CloudServices.children.push(new_serviceitem);
                        break;
                    case 'Cloud Management':
                        serviceMenu.CloudManagement.children = Array.isArray(serviceMenu.CloudManagement.children) ?
                            serviceMenu.CloudManagement.children : [];
                        serviceMenu.CloudManagement.children.push(new_serviceitem);
                        break;
                }
            });
        }

        if (availableServiceItemsCount < MAX_SERVICE_DISPLAY) {
            setShowFull(true);
            setShowMoreOption(false);
        } else if (serviceMenu?.CloudServices?.children?.length > MAX_SERVICE_DISPLAY) {
            setShowMoreOption(true);
        }

        if (fortiCloudData?.support_menu_headers) {
            fortiCloudData?.support_menu_headers.map(headerItem => {
                switch (headerItem.display_name) {
                    case 'Downloads':
                        supportMenu.downloads = headerItem;
                        supportMenu.downloads.children = [];
                        break;
                    case 'Resources':
                        supportMenu.resources = headerItem;
                        supportMenu.resources.children = [];
                        break;
                    case 'FortiCare New':
                        supportMenu.forticare_beta = headerItem;
                        supportMenu.forticare_beta.children = [];
                        break;
                    case 'FortiCare Legacy':
                        supportMenu.forticare = headerItem;
                        supportMenu.forticare.children = [];
                        break;
                }
            });
        }

        if (fortiCloudData?.support_menu_items) {
            fortiCloudData?.support_menu_items.sort((a, b) => a.order - b.order).map(supportitem => {
                let visible = false;
                if (support_menu_items && support_menu_items.hasOwnProperty(supportitem.item_id)) {
                    visible = support_menu_items[supportitem.item_id].visibility || false;
                }

                let new_supportitem = {...supportitem, visible};
                switch (supportitem.section_header) {
                    case 'Downloads':
                        supportMenu.downloads.children = Array.isArray(supportMenu.downloads.children) ?
                            supportMenu.downloads.children : [];
                        supportMenu.downloads.children.push(new_supportitem);
                        break;
                    case 'Resources':
                        supportMenu.forticare_beta.children = Array.isArray(supportMenu.forticare_beta.children) ?
                            supportMenu.forticare_beta.children : [];
                        supportMenu.resources.children.push(new_supportitem);
                        break;
                    case 'FortiCare Legacy':
                        supportMenu.forticare.children = Array.isArray(supportMenu.forticare.children) ?
                            supportMenu.forticare.children : [];
                        supportMenu.forticare.children.push(new_supportitem);
                        break;
                    case 'FortiCare New':
                        supportMenu.forticare_beta.children = Array.isArray(supportMenu.forticare_beta.children) ?
                            supportMenu.forticare_beta.children : [];
                        supportMenu.forticare_beta.children.push(new_supportitem);
                        break;
                }
            });
        }

        let userMenu = [];
        if (fortiCloudData?.user_menu_items) {
            fortiCloudData?.user_menu_items.map(headerItem => {
                if (user_menu_items && user_menu_items[headerItem.item_id] && user_menu_items[headerItem.item_id].visibility) {
                    userMenu.push(headerItem);
                }
            });
        }
        setProfileHeaderMenu(userMenu);

        if (fortiCloudData?.logos && fc_premium_logo_info) {
            let {image_content} = fortiCloudData?.logos.find(logo => logo.logo_id === fc_premium_logo_info?.logo_id);
            if (image_content) setFortiCloudLogo(`data:image/svg+xml;base64,${image_content}`);
        }

        setServiceHeaderMenu(serviceMenu);
        setSupportHeaderMenu(supportMenu);
    }, [fortiCloudData]);

    const logout = () => {
        // TODO: logout the 'loginas' user for now - implement a design later
        if (loginasInfo && loginasInfo.loginas) {
            handleLoginasLogout();
            return;
        }

        if (isSiteAdmin || sso_logout_required(auth_type)) {
            provider.logoutToHost();
        } else {
            provider.logoutInApp();
        }
    }

    const showLoginasLogoutModal = () => {
        setIsLoginasLogoutModalOpen(true);
    }

    const handleLoginasLogoutCancel = () => {
        setIsLoginasLogoutModalOpen(false);
    }

    function handleLoginasLogout() {
        let {loginasuser, loginbyuser} = loginasInfo;

        if (!loginasuser.userid || !loginbyuser.userid) {
            // or directly logout
            return;
        }
        setIsLoginasLogoutModalOpen(false);
        dispatch(loginAs(loginbyuser.userid, loginasuser.userid, true));
    }

    const DropdownServiceItems = ({serviceHeader, align, showFull}) => {
        let servItems;
        if (align === 'left') {
            servItems = serviceHeader.children
        } else {
            servItems = showFull ? serviceHeader.children : serviceHeader.children.slice(0, MAX_SERVICE_DISPLAY);
        }

        return (
            <div className={serviceHeader.class}>
                <h6>{serviceHeader.display_name}</h6>
                <div className="list-container">
                    {(servItems && servItems.length > 0) && servItems.map((serviceitem) => (
                        serviceitem.visible ?
                            (<div key={serviceitem.app_name} className="list-item" title={serviceitem.display_name}>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    simulateFormPost(serviceitem.url, account_id, user_id, true);
                                }}>
                                    <img src={`data:image/svg+xml;base64,${serviceitem.image_content}`}
                                         alt={serviceitem.display_name}/>
                                    <span className='menu-name'>{serviceitem.display_name}</span>
                                </a>
                            </div>)
                            : undefined
                    ))}
                </div>
                {
                    showMoreOption && align === 'right' && (
                        <div className={'show-more-option'}>
                            <Button type={'link'}
                                    onClick={() => setShowFull(!showFull)}>
                                {showFull ? <>{t('studentapp_modules_showless_txt')}<UpOutlined/></> : <>{t('studentapp_modules_showmore_txt')}<DownOutlined/></>}
                            </Button>
                        </div>
                    )
                }
            </div>
        )
    }

    const servicedropdownRender = () => {
        return (
            <div className="dropdown-content portal_row">
                <div className="portal_col service-wrapper">
                    <div className={'left-col'}>
                        <DropdownServiceItems serviceHeader={serviceHeaderMenu.Assets} align={'left'}/>
                        <DropdownServiceItems serviceHeader={serviceHeaderMenu.CloudManagement} align={'left'}/>
                    </div>
                    <div className={'right-col'}>
                        <DropdownServiceItems showFull={showFull} serviceHeader={serviceHeaderMenu.CloudServices}
                                              align={'right'}/>
                    </div>
                </div>
            </div>
        );
    }

    const DropdownSupportItems = ({supportHeader}) => {
        return (
            <>
                <a href={supportHeader.url}>
                    <h6>
                        <div className='list-header'>{supportHeader.display_name}</div>
                    </h6>
                </a>
                <div className='list-container'>
                    {supportHeader?.children?.length > 0 && supportHeader.children.map(supportitem => (
                        supportitem.visible ?
                            <div key={supportitem.display_name} className='list-item' title={supportitem.description}>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    simulateFormPost(supportitem.url, account_id, user_id);
                                }}>
                                    <img src={`data:image/svg+xml;base64,${supportitem.image_content}`}
                                         alt={supportitem.display_name} hidden/>
                                    <span className={'menu-name'}>{supportitem.display_name}</span>
                                </a>
                            </div>
                            : undefined
                    ))}
                </div>
            </>
        )
    }

    const supportdropdownRender = () => {
        let {downloads, resources, forticare, forticare_beta} = supportHeaderMenu;
        return (
            <div className='dropdown-content portal_row'>
                <div className='portal_col service-wrapper support_col'>
                    {downloads && <div className='downloads-container'>
                        <DropdownSupportItems supportHeader={downloads}/>
                    </div>}
                    {resources && <div className='resources-container'>
                        <DropdownSupportItems supportHeader={resources}/>
                    </div>}
                    <div className="forticare-group-container">
                        {forticare_beta && <div className="forticare-container-beta">
                            <DropdownSupportItems supportHeader={forticare_beta}/>
                        </div>}
                        {forticare && <div className="forticare-container">
                            <DropdownSupportItems supportHeader={forticare}/>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }

    const adminAccountSubMenu = () => (
        <div className='adminprofile-dropdown-content'>
            <div className='dropdown-list-container'>
                <div className='account-overview-section'>
                    {user_display_info?.length ? user_display_info?.map(displayInfo => (
                            <div className='account-list' key={`user_display_info ${displayInfo?.value}`}>
                                <span className='dname'>{displayInfo?.name}</span>
                                <span className='dvalue'>{displayInfo?.value}</span>
                            </div>
                        ))
                        : (
                            <div className='empty_user_display_info'>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                            </div>
                        )
                    }
                </div>
                <div className='iconed-list-section'>
                    {profileHeaderMenu.map((useritem, id) => {
                        if (id === profileHeaderMenu.length - 1) {
                            return (
                                <React.Fragment key={useritem.item_id}>
                                    <hr/>
                                    <Link className={'forticloud-item'}
                                          to={`/setting`}><SettingOutlined/> {t('pageutilities_avatar_settings')}
                                    </Link>
                                    <a className={'forticloud-item'}
                                       onClick={() => {
                                           window.open(FORTINET_SUPPORT_URL, '_blank');
                                       }}>
                                        <CustomerSupportIcon/> {t('pageutilities_avatar_getsupport')}
                                    </a>
                                    <Link className={'forticloud-item'}
                                          to={`/my`}><LearnerIcon/> {t('pageutilities_avatar_visitlearnerexperience')}
                                    </Link>
                                    <div key={`admin_account_menu ${useritem.item_id}`}
                                         className='forticloud-item' onClick={logout}>
                                        <a onClick={(e) => e.preventDefault()}>
                                            <img src={`data:image/svg+xml;base64,${useritem.image_content}`}
                                                 alt={useritem.display_name}/>{useritem.display_name}
                                        </a>
                                    </div>
                                </React.Fragment>
                            );
                        } else {
                            // no account-switch for now.
                            if (!useritem.url) {
                                return undefined;
                            }
                            return (
                                <div key={`admin_account_menu ${useritem.item_id}`}
                                     onClick={() => {
                                         if (!useritem.url) {
                                             return;
                                         }
                                         simulateFormPost(useritem.url, account_id, user_id);
                                     }}
                                     className='forticloud-item'>
                                    <img src={`data:image/svg+xml;base64,${useritem.image_content}`}
                                         alt={useritem.display_name}/>{useritem.display_name}
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </div>
    )

    return (
        <AntHeader className="header">
            <div className="corner_logo_wrap">
                {
                    !!siteInfo &&
                    <div className={`hamburger ${collapsed ? 'collapsed' : ''}`}
                         onClick={onCollapse}>
                        <div className="hamburger_icon">
                            <MenuOutlined/>
                        </div>
                    </div>
                }

                <NavLink to={'/admin'} activeClassName="active">
                    <div className='forticloud_logo logo'
                         style={{backgroundImage: `url(${fortiCloudLogo ? fortiCloudLogo : '/forticloud.svg'})`}}/>
                </NavLink>

                {serviceHeaderMenu && <div>
                    <Dropdown key={serviceHeaderMenu.key}
                              className={'service-header-menu'}
                              dropdownRender={servicedropdownRender}
                              getPopupContainer={triggerNode => triggerNode.parentNode}
                              trigger={['click']}
                              overlayClassName={'service-dropdown dropdown-portal'}>
                        <a onClick={(e) => e.preventDefault()}>
                            <span className='service_icon'><ServiceIcon/></span> {serviceHeaderMenu.label}
                            <CaretDownOutlined/>
                        </a>
                    </Dropdown>
                </div>}

                {(supportHeaderMenu && portal_menu_items_visible) && <div>
                    <Dropdown key={supportHeaderMenu.order}
                              className={'support-header-menu'}
                              dropdownRender={supportdropdownRender}
                              getPopupContainer={triggerNode => triggerNode.parentNode}
                              trigger={['click']}
                              overlayClassName={'support-dropdown dropdown-portal'}>
                        <a onClick={(e) => e.preventDefault()}>
                            <span
                                className='support_icon'><SupportIcon/></span>{supportHeaderMenu.label}<CaretDownOutlined/>
                        </a>
                    </Dropdown>
                </div>}

                {(loginasInfo && loginasInfo.loginas) && <div className="sub-account-switch-button">
                    <Button onClick={showLoginasLogoutModal}>
                        <Space>
                            <LeftOutlined style={{display: 'flex'}}/>
                            Back to main account
                        </Space>
                    </Button>
                </div>}
            </div>

            <div className="avatar">
                <Badge disabled dot={false}>
                    <BellOutlined/>
                </Badge>
                <div className="help-action">
                    <a href={Variable.DEFAULT_SECURITY_AWARENESS_HELP_LINK} target='__blank'>
                        <Tooltip title='Help Info'
                                 placement={'bottom'}
                                 getPopupContainer={triggerNode => triggerNode.parentNode}>
                            <QuestionCircleOutlined/>
                        </Tooltip>
                    </a>
                </div>
                {
                    !!siteInfo &&
                    <Dropdown trigger={['click']}
                              overlay={adminAccountSubMenu}
                              getPopupContainer={triggerNode => triggerNode.parentNode}
                              overlayClassName={'adminprofile-menu'}>
                        <div className='avatar_wrap'>
                            <Avatar style={{verticalAlign: 'middle'}} size="large">
                                {usernameInitial(siteInfo?.firstname, siteInfo?.lastname)}
                            </Avatar>
                            <div className='avatar_right'>
                                <h6>{`${siteInfo?.firstname} ${siteInfo?.lastname}`}</h6>
                                <div className='email_txt'><a href="">{siteInfo?.email}</a></div>
                            </div>
                        </div>
                    </Dropdown>
                }
            </div>

            <Modal okText='Logout' title='Back to Main Account'
                   open={isLoginasLogoutModalOpen} onOk={handleLoginasLogout}
                   onCancel={handleLoginasLogoutCancel}
                   width={376}
                   okButtonProps={{className: "btn-wdt-md"}}
                   cancelButtonProps={{className: "ant-btn-secondary btn-wdt-sm"}}
            >
                <p>Do you want to logout current account and back to your main account?</p>
            </Modal>
        </AntHeader>
    );
}

export default connect(
    mapStateToProps,
)(AdminHeader);
