import React from 'react';
import {Tooltip} from "antd";

export default class ProgressBar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const parent = this.props;
        const progress_order = ['Completed', 'In progress', 'Overdue', 'Not started'];
        const sorted_parent = (parent.readings && parent.readings.length) && parent.readings.sort((a, b) => progress_order.indexOf(a.name) - progress_order.indexOf(b.name));
        const has_completed_users = sorted_parent && sorted_parent?.some(progress => progress.name === 'Completed' && progress.value > 0);
        const classCompletedUsers = has_completed_users ? 'completed_users' : 'no_completed_users';
        const classTable = parent?.table === 'main_table' ? 'main_table' : 'sub_table';

        const values = sorted_parent && sorted_parent?.map((item, i) => {
            if (item.value > 0) {
                return (
                    <div className="value" style={{'color': '#65708D', 'width': item.value + '%'}} key={`value-${i}`}>
                        <span>{item.value}%</span>
                    </div>
                )
            }
        });

        const calibrations = sorted_parent && sorted_parent?.map((item, i) => {
            if (item.value > 0) {
                return (
                    <div className="graduation" style={{'color': item.color, 'width': item.value + '%'}}
                         key={`calibrations-${i}`}>
                    </div>
                )
            }
        });

        const bars = sorted_parent && sorted_parent?.map((item, i) => {
            if (item.value > 0) {
                let barClass = item.name === 'In progress' || item.name === 'Not started' ? 'fnt-blk' : 'fnt-wht';
                return (
                    parent?.table === 'main_table' ?
                        <Tooltip title={<div className={'bar-tooltip'}><span style={{background: item.color}}></span>{`${item.name} (${item.value}%)`}</div>}>
                            <div className={`bar ${barClass}`}
                                 style={{'background': item.color, 'width': item.value + '%'}}
                                 key={`bar-${i}`}>
                                <span>{`${item.value > 5 ? item.value + '%' : ''}`}</span>
                            </div>
                        </Tooltip>
                        : <div className={`module-data ${item.name.toLowerCase().replace(' ', '-')}`}
                               key={`bar-subtable-${i}-${item.name}`}>
                            <div className="bar" style={{'background': item.color, 'width': item.value + '%'}}></div>
                            {item.name === 'Completed' && <span className={'completed-value'}>{item.value + '%'}</span>}
                        </div>
                )
            }
        });

        const legends = sorted_parent && sorted_parent?.map((item, i) => {
            if (item.value > 0) {
                return (
                    <div className="legend" key={`legends-${i}`}>
                        <span className="dot" style={{'color': item.color}}>●</span>
                        <span className="label">{item.name}</span>
                    </div>
                )
            }
        });

        return (
            <div className={`multicolor-bar ${parent?.table === 'main_table' ? 'main_table' : ''}`}>
                <div className="scale">
                    {calibrations == '' ? '' : calibrations}
                </div>
                <div className={`bars ${classCompletedUsers} ${classTable}`}>
                    {bars == '' ? '' : bars}
                    {parent?.table !== 'main_table' && !has_completed_users &&
                        <span className={'completed-value'}>0%</span>}
                </div>
            </div>
        );
    }
}
