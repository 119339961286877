export const APPR_LOAD_APPRDATA_REQUEST = 'APPR_LOAD_APPRDATA_REQUEST';
export const APPR_LOAD_APPRDATA_SUCCESS = 'APPR_LOAD_APPRDATA_SUCCESS';
export const APPR_LOAD_APPRDATA_FAIL = 'APPR_LOAD_APPRDATA_FAIL';
export const APPR_IMAGE_CHANGE_SUCCESS = 'APPR_IMAGE_CHANGE_SUCCESS';
export const APPR_COLORS_RESET_SUCCESS = 'APPR_COLORS_RESET_SUCCESS';
export const APPR_COLORS_CHANGE_SUCCESS = 'APPR_COLORS_CHANGE_SUCCESS';
export const APPR_SAVE_APPRDATA_REQUEST = 'APPR_SAVE_APPRDATA_REQUEST';
export const APPR_SAVE_APPRDATA_SUCCESS = 'APPR_SAVE_APPRDATA_SUCCESS';
export const APPR_SAVE_APPRDATA_FAIL = 'APPR_REFRESH_APPRDATA_FAIL';
export const APPR_SAVE_APPRDATA_RESET = 'APPR_SAVE_APPRDATA_RESET';
export const APPR_SAVE_TEMPLATE_REQUEST = 'APPR_SAVE_TEMPLATE_REQUEST';
export const APPR_SAVE_TEMPLATE_SUCCESS = 'APPR_SAVE_TEMPLATE_SUCCESS';
export const APPR_SAVE_TEMPLATE_FAIL = 'APPR_REFRESH_TEMPLATE_FAIL';
export const APPR_SAVE_TEMPLATE_RESET = 'APPR_SAVE_TEMPLATE_RESET';
export const APPR_LOAD_TEMPLATE_REQUEST = 'APPR_LOAD_TEMPLATE_REQUEST';
export const APPR_LOAD_TEMPLATE_SUCCESS = 'APPR_LOAD_TEMPLATE_SUCCESS';
export const APPR_LOAD_TEMPLATE_FAIL = 'APPR_LOAD_TEMPLATE_FAIL';

export const ApprIcon = () => ( <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M7 3C8.86384 3 10.4299 4.27477 10.874 6H19V8H10.874C10.4299 9.72523 8.86384 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3ZM7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9Z" /><path d="M17 20C15.1362 20 13.5701 18.7252 13.126 17H5V15H13.126C13.5701 13.2748 15.1362 12 17 12C19.2091 12 21 13.7909 21 16C21 18.2091 19.2091 20 17 20ZM17 18C18.1046 18 19 17.1046 19 16C19 14.8954 18.1046 14 17 14C15.8954 14 15 14.8954 15 16C15 17.1046 15.8954 18 17 18Z" /></svg>);

export const DEFAULT_LMS_DATA = {
    files: {
        "headerlogo": "",
        "loginlogo": "",
        "loginbackground": "",
        "favicon": "",
    },
    colors: {
        "navbar_color": "#307FE2",
        "primary_color": "#1890FF",
        "links_color": "#307FE2"
    }
}

export const APPR_NOTIFICATION_TEMPLATES = [
    {
        key: 1,
        type: 'Logging In for the First Time',
        description: 'This email provides users with a link to confirm their account and log in for training.',
        timeframe: 'Automatically sent when the first campaign begins.',
    },
    {
        key: 2,
        type: 'Training Enrollment Confirmation',
        description: 'This email notifies users of their enrollment in a new training campaign.',
        timeframe: 'Sent when the training campaign starts, including late enrollees.',
    },
    {
        key: 3,
        type: 'Due Date Alert',
        description: 'This email notifies users who have incomplete campaign assignments.',
        timeframe: 'Sent on the due date.',
    },
    {
        key: 4,
        type: 'Campaign Completion',
        description: 'This email notifies a user when they have completed their campaign assignments.',
        timeframe: 'Sent upon user completion of the training.',
    },
    {
        key: 5,
        type: 'Your Report Is Ready',
        description: 'This email informs recipients that a specific report is now available and accessible.',
        timeframe: 'Sent according to the report schedule.',
    },
];

export const APPR_NOTIFICATION_TEMPLATES_SENDER_EMAIL = 'noreply@ftnt.info';
export const APPR_NOTIFICATION_TEMPLATES_SENDER_NAME = 'Security Awareness and Training Service';
