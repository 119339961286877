import {
    CAMPAIGN_ADD_LEARNER_FAIL,
    CAMPAIGN_ADD_LEARNER_MODAL,
    CAMPAIGN_ADD_LEARNER_REQUEST,
    CAMPAIGN_ADD_LEARNER_RESET,
    CAMPAIGN_ADD_LEARNER_SUCCESS,
    CAMPAIGN_ARCHIVE_CAMPAIGN_FAIL,
    CAMPAIGN_ARCHIVE_CAMPAIGN_REQUEST,
    CAMPAIGN_ARCHIVE_CAMPAIGN_SUCCESS,
    CAMPAIGN_CURRENT_CAMPAIGN_NAME,
    CAMPAIGN_CURRENT_MODULE_REORDER,
    CAMPAIGN_CURRENT_TEMPLATE_EDIT_DISABLE,
    CAMPAIGN_DELETE_CAMPAIGN_FAIL,
    CAMPAIGN_DELETE_CAMPAIGN_REQUEST,
    CAMPAIGN_DELETE_CAMPAIGN_SUCCESS,
    CAMPAIGN_FETCH_PROGRAMS_FAIL,
    CAMPAIGN_FETCH_PROGRAMS_REQUEST,
    CAMPAIGN_FETCH_PROGRAMS_SUCCESS,
    CAMPAIGN_FUZZY_SEARCH_USERS_FAIL,
    CAMPAIGN_FUZZY_SEARCH_USERS_REQUEST,
    CAMPAIGN_FUZZY_SEARCH_USERS_SUCCESS,
    CAMPAIGN_GET_OVERLAPPING_FAIL,
    CAMPAIGN_GET_OVERLAPPING_REQUEST,
    CAMPAIGN_GET_OVERLAPPING_RESET,
    CAMPAIGN_GET_OVERLAPPING_SUCCESS,
    CAMPAIGN_LEARNERS_LIST_FAIL,
    CAMPAIGN_LEARNERS_LIST_REQUEST,
    CAMPAIGN_LEARNERS_LIST_SEARCH,
    CAMPAIGN_LEARNERS_LIST_SUCCESS,
    CAMPAIGN_LEARNERS_SELECTION_UPDATE,
    CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_FAIL,
    CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_REQUEST,
    CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_SUCCESS,
    CAMPAIGN_LOAD_CAMPAIGNS_FAIL,
    CAMPAIGN_LOAD_CAMPAIGNS_REQUEST,
    CAMPAIGN_LOAD_CAMPAIGNS_SUCCESS,
    CAMPAIGN_LOAD_SINGLE_CAMPAIGN_FAIL,
    CAMPAIGN_LOAD_SINGLE_CAMPAIGN_REQUEST,
    CAMPAIGN_LOAD_SINGLE_CAMPAIGN_RESET,
    CAMPAIGN_LOAD_SINGLE_CAMPAIGN_SUCCESS,
    CAMPAIGN_MESSAGE_STATUS_RESET,
    CAMPAIGN_MODULE_DETAIL_MODAL,
    CAMPAIGN_MODULE_DETAIL_MODAL_RESET,
    CAMPAIGN_PROGRAM_CREATION_STATUS,
    CAMPAIGN_PROGRAM_EDIT_STATUS,
    CAMPAIGN_REMOVE_LEARNER_FAIL,
    CAMPAIGN_REMOVE_LEARNER_REQUEST,
    CAMPAIGN_REMOVE_LEARNER_SUCCESS,
    CAMPAIGN_RENAME_CAMPAIGN_FAIL,
    CAMPAIGN_RENAME_CAMPAIGN_REQUEST,
    CAMPAIGN_RENAME_CAMPAIGN_RESET,
    CAMPAIGN_RENAME_CAMPAIGN_SUCCESS,
    CAMPAIGN_RESTORE_CAMPAIGN_FAIL,
    CAMPAIGN_RESTORE_CAMPAIGN_REQUEST,
    CAMPAIGN_RESTORE_CAMPAIGN_SUCCESS,
    CAMPAIGN_SELECTED_MODULE_LIST, CAMPAIGN_SORTED_LEARNERS_LIST
} from "../actiontypes/campaignConstants";
import {parsePrograms} from "../actions/campaignActions";

export const campaignsReducer = (state = {}, action) => {
    switch (action.type) {
        case CAMPAIGN_LOAD_CAMPAIGNS_REQUEST:
            return {
                ...state,
                campaignsList: {
                    loading: true,
                    error: false,
                },
            };
        case CAMPAIGN_LOAD_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                campaignsList: {
                    loading: false,
                    error: false,
                    ...action.payload,
                },
            };
        case CAMPAIGN_LOAD_CAMPAIGNS_FAIL:
            return {
                ...state,
                campaignsList: {
                    loading: false,
                    error: action.payload,
                },
            };
        case CAMPAIGN_LOAD_SINGLE_CAMPAIGN_REQUEST:
            return {
                ...state,
                current: {
                    loading: true,
                    error: false,
                },
            };
        case CAMPAIGN_LOAD_SINGLE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                current: {
                    loading: false,
                    error: false,
                    ...action.payload,
                },
            };
        case CAMPAIGN_LOAD_SINGLE_CAMPAIGN_FAIL:
            return {
                ...state,
                current: {
                    loading: false,
                    error: action.payload,
                },
            };
        case CAMPAIGN_LOAD_SINGLE_CAMPAIGN_RESET:
            return {
                ...state,
                current: {}
            };
        case CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_SUCCESS:
            const {payload} = action;

            let newState = {
                ...state,
                extras: {
                    loading: false,
                    error: false,
                    ...payload,
                },
            };

            if (payload.hasOwnProperty('programs') && Array.isArray(payload.programs)) {
                newState = {
                    ...newState,
                    programsList: {
                        loading: false,
                        error: false,
                        ...parsePrograms(payload.programs),
                    },
                };
            }

            return newState;
        case CAMPAIGN_LOAD_CAMPAIGN_EXTRAS_FAIL:
            return {
                ...state,
                extras: {
                    loading: false,
                    error: action.payload,
                },
            };
        case CAMPAIGN_FETCH_PROGRAMS_REQUEST:
            return {
                ...state,
                programsList: {
                    loading: true,
                    error: false,
                },
            };
        case CAMPAIGN_FETCH_PROGRAMS_SUCCESS:
            return {
                ...state,
                programsList: {
                    loading: false,
                    error: false,
                    ...action.payload,
                },
            };
        case CAMPAIGN_FETCH_PROGRAMS_FAIL:
            return {
                ...state,
                programsList: {
                    loading: false,
                    error: action.payload,
                },
            };
        case CAMPAIGN_ARCHIVE_CAMPAIGN_REQUEST:
            return {
                ...state,
                campaignMessageStatus: {
                    loading: true,
                    message: 'Archiving campaign...',
                    success: false,
                    error: false,
                },
            };
        case CAMPAIGN_ARCHIVE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                campaignMessageStatus: {
                    loading: false,
                    message: action.payload ? 'Campaign successfully archived' : 'Error archiving campaign',
                    success: action.payload,
                    error: false,
                },
            };
        case CAMPAIGN_ARCHIVE_CAMPAIGN_FAIL:
            return {
                ...state,
                campaignMessageStatus: {
                    loading: false,
                    message: 'Error archiving campaign',
                    success: false,
                    error: action.payload,
                },
            };
        case CAMPAIGN_RESTORE_CAMPAIGN_REQUEST:
            return {
                ...state,
                campaignMessageStatus: {
                    loading: true,
                    message: 'Restoring campaign...',
                    success: false,
                    error: false,
                },
            };
        case CAMPAIGN_RESTORE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                campaignMessageStatus: {
                    loading: false,
                    message: action.payload ? 'Campaign successfully restored' : 'Error restoring campaign',
                    success: action.payload,
                    error: false,
                },
            };
        case CAMPAIGN_RESTORE_CAMPAIGN_FAIL:
            return {
                ...state,
                campaignMessageStatus: {
                    loading: false,
                    message: 'Error restoring campaign',
                    success: false,
                    error: action.payload,
                },
            };
        case CAMPAIGN_DELETE_CAMPAIGN_REQUEST:
            return {
                ...state,
                campaignMessageStatus: {
                    loading: true,
                    message: 'Deleting campaign...',
                    success: false,
                    error: false,
                },
            };
        case CAMPAIGN_DELETE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                campaignMessageStatus: {
                    loading: false,
                    message: action.payload ? 'Campaign successfully deleted' : 'Error deleting campaign',
                    success: action.payload,
                    error: false,
                },
            };
        case CAMPAIGN_DELETE_CAMPAIGN_FAIL:
            return {
                ...state,
                campaignMessageStatus: {
                    loading: false,
                    message: 'Error deleting campaign',
                    success: false,
                    error: action.payload,
                },
            };
        case CAMPAIGN_GET_OVERLAPPING_REQUEST:
            return {
                ...state,
                overlaps: {
                    loading: true,
                    success: false,
                    error: false,
                },
            };
        case CAMPAIGN_GET_OVERLAPPING_SUCCESS:
            return {
                ...state,
                overlaps: {
                    loading: false,
                    error: false,
                    ...action.payload,
                },
            };
        case CAMPAIGN_GET_OVERLAPPING_FAIL:
            return {
                ...state,
                overlaps: {
                    loading: false,
                    success: false,
                    error: action.payload,
                },
            };
        case CAMPAIGN_RENAME_CAMPAIGN_REQUEST:
            return {
                ...state,
                rename: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
        case CAMPAIGN_RENAME_CAMPAIGN_SUCCESS:
            return {
                ...state,
                rename: {
                    loading: false,
                    error: false,
                    ...action.payload
                }
            };
        case CAMPAIGN_RENAME_CAMPAIGN_FAIL:
            return {
                ...state,
                rename: {
                    loading: false,
                    error: true,
                    success: false,
                    message: action.payload
                }
            };
        case CAMPAIGN_RENAME_CAMPAIGN_RESET:
            return {
                ...state,
                rename: {}
            }
        case CAMPAIGN_LEARNERS_LIST_REQUEST:
            return {
                ...state,
                learnerList: {
                    ...state.learnerList,
                    loading: true,
                    searching: false,
                    error: false,
                },
            };
        case CAMPAIGN_LEARNERS_LIST_SEARCH:
            return {
                ...state,
                learnerList: {
                    ...state.learnerList,
                    searching: true,
                    loading: false,
                    error: false,
                },
            };
        case CAMPAIGN_LEARNERS_LIST_SUCCESS:
            return {
                ...state,
                learnerList: {
                    loading: false,
                    searching: false,
                    error: false,
                    ...action.payload,
                },
            };
        case CAMPAIGN_LEARNERS_LIST_FAIL:
            return {
                ...state,
                learnerList: {
                    ...state.learnerList,
                    loading: false,
                    searching: false,
                    error: action.payload,
                },
            };
        case CAMPAIGN_LEARNERS_SELECTION_UPDATE:
            return {
                ...state,
                learnerList: {
                    ...state.learnerList,
                    selected_learners_ids: action.payload,
                },
            };
        case CAMPAIGN_SORTED_LEARNERS_LIST:
            return {
                ...state,
                learnerList: {
                    ...state.learnerList,
                    sortedlearners: action.payload,
                },
            };
        case CAMPAIGN_ADD_LEARNER_REQUEST:
            return {
                ...state,
                addLearner: {
                    ...state.addLearner,
                    loading: true,
                    success: false,
                    error: false,
                    ...action.payload,
                },
            };
        case CAMPAIGN_ADD_LEARNER_SUCCESS:
            return {
                ...state,
                addLearner: {
                    ...state.addLearner,
                    loading: false,
                    success: true,
                    error: false,
                    message: action.payload?.message,
                    message_type: action.payload?.message_type || 'info',
                    ...action.payload
                },
            };
        case CAMPAIGN_ADD_LEARNER_FAIL:
            return {
                ...state,
                addLearner: {
                    ...state.addLearner,
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload?.message,
                },
            };
        case CAMPAIGN_ADD_LEARNER_RESET:
            return {
                ...state,
                addLearner: {}
            };
        case CAMPAIGN_ADD_LEARNER_MODAL:
            return {
                ...state,
                addLearner: {
                    ...(state?.addLearner || {}),
                    modal: action.payload,
                }
            };
        case CAMPAIGN_REMOVE_LEARNER_REQUEST:
            return {
                ...state,
                removeLearner: {
                    loading: true,
                    success: false,
                    error: false,
                },
            };
        case CAMPAIGN_REMOVE_LEARNER_SUCCESS:
            return {
                ...state,
                removeLearner: {
                    loading: false,
                    success: true,
                    error: false,
                    message: action.payload?.message,
                    message_type: action.payload?.message_type || 'info',
                },
            };
        case CAMPAIGN_REMOVE_LEARNER_FAIL:
            return {
                ...state,
                removeLearner: {
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload?.message,
                },
            };
        case CAMPAIGN_FUZZY_SEARCH_USERS_REQUEST:
            return {
                ...state,
                fuzzy_search: {
                    loading: true,
                    error: false,
                }
            };
        case CAMPAIGN_FUZZY_SEARCH_USERS_SUCCESS:
            return {
                ...state,
                fuzzy_search: {
                    loading: false,
                    error: false,
                    data: action.payload,
                }
            };
        case CAMPAIGN_FUZZY_SEARCH_USERS_FAIL:
            return {
                ...state,
                fuzzy_search: {
                    loading: false,
                    error: action.payload,
                }
            };
        case CAMPAIGN_GET_OVERLAPPING_RESET:
            return {
                ...state,
                overlaps: {},
            };
        case CAMPAIGN_PROGRAM_CREATION_STATUS:
            return {
                ...state,
                programCreationVisible: !!action.payload,
            };
        case CAMPAIGN_PROGRAM_EDIT_STATUS:
            return {
                ...state,
                programEditVisible: !!action.payload,
            };
        case CAMPAIGN_MESSAGE_STATUS_RESET:
            return {
                ...state,
                campaignMessageStatus: {},
            };
        case CAMPAIGN_SELECTED_MODULE_LIST:
            return {
                ...state,
                selectedModules: action?.payload,
            };
        case CAMPAIGN_CURRENT_CAMPAIGN_NAME:
            return {
                ...state,
                currentCampaignName: action?.payload
            };
        case CAMPAIGN_CURRENT_MODULE_REORDER:
            return {
                ...state,
                moduleReordered: action?.payload
            };
        case CAMPAIGN_CURRENT_TEMPLATE_EDIT_DISABLE:
            return {
                ...state,
                templateEditDisable: action?.payload
            }
        case CAMPAIGN_MODULE_DETAIL_MODAL:
            return {
                ...state,
                moduleDetailModal: action?.payload
            };
        case CAMPAIGN_MODULE_DETAIL_MODAL_RESET:
            return {
                ...state,
                moduleDetailModal: {}
            }
        default:
            return state;
    }
};
