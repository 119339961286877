import {Skeleton} from "antd";

const PageLoading = () => {
    return (
        <>
            <Skeleton active />
        </>
    )
}

export default PageLoading