import React, {useEffect, useState} from 'react';
import AdminHeader from "../components/AdminHeader";
import {useDispatch, useSelector} from "react-redux";
import {Button, Layout, message} from "antd";
import {debounce, sso_logout_required} from "../../shared/helper";
import {
    ENVIRONMENT_VAR_DEV,
    ENVIRONMENT_VAR_PRODUCTION,
    ENVIRONMENT_VAR_STAGING
} from "../../shared/constants/globalConstants";
import {loginAs} from "../redux/actions/accountMgmtActions";
import provider from "../../shared/provider";
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import IceCream from "../../assets/images/ice-cream.svg";
import FortinetLogo from "../../assets/images/fortinet-logo.svg";
import Variables from "../../shared/constants/variables";

const TABLE_WIDTH_BREAKPOINT = 992;

const loadPermissionParam = (key = 'denied') => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key);
};

export const FCBlockagePage = () => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        siteInfo,
        isSiteAdmin,
        tenantAdmin,
    } = useSelector((state) => (state.root || {}));
    const loginasInfo = useSelector((state) => state?.root?.siteInfo?.loginas_info);
    const [messageApi, contextHolder] = message.useMessage();
    const auth_type = useSelector((state) => state?.root?.siteInfo?.auth_type);

    const dispatch = useDispatch();

    const {Footer} = Layout;

    useEffect(() => {
        let isTabletView = window.innerWidth <= TABLE_WIDTH_BREAKPOINT;
        setCollapsed(isTabletView);

        window.addEventListener('resize', debounce(() => {
            isTabletView = window.innerWidth <= TABLE_WIDTH_BREAKPOINT;
            setCollapsed(isTabletView);
        }, 500));

        const denied = loadPermissionParam();
        if (denied) {
            messageApi.destroy();
            messageApi.open({
                type: 'warning',
                content: "It seems you don't have ADMIN permission. " +
                    "You may need to be logged in as a Master user to manage this tenant",
                duration: 10,
                onClick: () => {
                    messageApi.destroy();
                }
            })
        }
    }, []);

    const onCollapseOnclick = () => {
        setCollapsed(!collapsed);
    };

    const getFortiCloudHomeURL = () => {
        console.log(Variables.FORTICLOUD_HOST);
        window.location.href = Variables?.FORTICLOUD_HOST ?? 'https://support.fortinet.com/asset/#/dashboard';
    }

    const logout = () => {
        // TODO: logout the 'loginas' user for now - implement a design later
        if (loginasInfo && loginasInfo.loginas) {
            handleLoginasLogout();
            return;
        }

        if (isSiteAdmin || sso_logout_required(auth_type)) {
            provider.logoutToHost();
        } else {
            provider.logoutInApp();
        }
    }

    const handleLoginasLogout = () => {
        let {loginasuser, loginbyuser} = loginasInfo;

        if (!loginasuser.userid || !loginbyuser.userid) {
            // or directly logout
            return;
        }
        dispatch(loginAs(loginbyuser.userid, loginasuser.userid, true));
    }

    return (
        <Layout>
            <div className="fc-blockage-page">
                <AdminHeader isSiteAdmin={tenantAdmin} onCollapse={onCollapseOnclick}
                             collapsed={collapsed}/>
                <div className={'fc-err-content'}>
                    {contextHolder}
                    <div className={'fc-err-container'}>
                        <h1>Sorry...</h1>
                        <div className={'fc-err-logo'}>
                            <img src={IceCream}/>
                        </div>
                        <div className={'fc-err-body'}>
                            <h4>…this user is not authorised to access this portal.</h4>
                            <p>Please select a different portal or log out, or contact your Account
                                Administrator.</p>
                            <div className={'d-flex fc-err-btns'}>
                                <Button type={'primary'}
                                        className={'btn-primary mr-3'}
                                        icon={<ArrowLeftOutlined/>}
                                        onClick={getFortiCloudHomeURL}>Back to FortiCloud</Button>

                                {
                                    !!siteInfo &&
                                    <Button type={'secondary'}
                                            onClick={logout}>Logout <ArrowRightOutlined/></Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer className={'fc-err-footer'}>
                    <img src={FortinetLogo}/>
                </Footer>
            </div>
        </Layout>
    );
}
