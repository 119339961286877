import React from "react";

export const themes = {
    default: {
        header_logo: '/logo_red.png',
        login_logo: '/logo_red.png',
        footer_logo: '/logo_red.png',
        login_background_img: '/login_bg.png',
        favicon: '/logo_red.png',
        nav_bar_color: '#307FE2',
        primary_color: '#1890ff',
        link_color: '#307FE2'
    }
};

export const ThemeContext = React.createContext(
    themes.default // default value
);
