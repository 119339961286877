import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import Backend from 'i18next-http-backend';
import Variable from './constants/variables';
import {getUserLang, loadPath} from "./helper";
import {FALLBACK_LOCALE_STRING} from "./translation/fallback_str";
import {ADD_DYNAMIC_LOCALES} from "./translation/additional_dynamic_locales";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            loadPath: loadPath,
            crossDomain: true,
            // addPath: '/locales/{{lng}}/{{ns}}.json',
            parse: (data, languages, ns) => {
                document.dispatchEvent(new Event('loaded.locales'));
                let sanitizedJSON = {};
                let isDefaultLang = languages === Variable.DEFAULT_LANG;
                try {
                    let mlJson = JSON.parse(data);
                    if (isDefaultLang) {
                        sanitizedJSON = {...mlJson, ...FALLBACK_LOCALE_STRING};
                    } else {
                        sanitizedJSON = {...FALLBACK_LOCALE_STRING, ...mlJson};
                    }
                } catch (e) {
                    sanitizedJSON = FALLBACK_LOCALE_STRING;
                }
                if (typeof languages === 'string' && ADD_DYNAMIC_LOCALES[languages]) {
                    sanitizedJSON = {...sanitizedJSON, ...ADD_DYNAMIC_LOCALES[languages]};
                }
                const stringKeys = Object.keys(sanitizedJSON);
                // xxx: is considered as namespace -> need to filter them
                stringKeys.forEach(key => {
                    if (key.includes('coursename:')) {
                        sanitizedJSON[key.replace('coursename:', '')] = sanitizedJSON[key];
                        delete sanitizedJSON[key];
                    }
                });
                return sanitizedJSON;
            }
        },
        lng: getUserLang(),
        fallbackLng: Variable.DEFAULT_LANG,
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false
        }
    });


export default i18n;
